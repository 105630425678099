import styled from 'styled-components'
import { Dayjs } from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker from 'antd/es/date-picker/generatePicker'

import 'antd/es/date-picker/style/index'
import 'antd/dist/antd.css'

import {
  Action,
  Alignment,
  Border,
  Display,
  FontPreset,
  FontStyle,
  Size,
  TActionMixin,
  TAlignmentMixin,
  TBorderMixin,
  TContainerProps,
  TDisplayMixin,
  TFontStyleMixin,
  Truncate,
  TruncatePreset,
  TSizeMixin,
  TTruncateMixin,
} from '../../styles/mixins'
import { IThemeProps } from '../../../../models/interfaces'

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)

export const StyledDatePicker = styled(DatePicker)<TContainerProps>`
  && {
    background: ${(props) => props.background || ''};
    padding: ${(props) =>
      props.padding || 'var(--padding-default) var(--gutter-4x)'};
    margin: ${(props) => props.margin || ''};
    ${(props: TDisplayMixin) => Display({ ...props })};
    ${FontPreset('paragraph medium')};
    ${(props: TFontStyleMixin) => FontStyle({ ...props })};

    ${(props: TBorderMixin) =>
      Border({
        ...props,
      })};

    ${(props: TSizeMixin) =>
      Size({
        height: 'var(--button-size-lg)',
        ...props,
      })};

    ${(props: TAlignmentMixin) =>
      Alignment({
        ...props,
      })};

    ${(props: TActionMixin) => Action({ ...props })}

    ${(props: TTruncateMixin) => Truncate({ ...props })}

    & ::placeholder {
      opacity: 0.5;
      color: ${(props: IThemeProps) =>
        props.theme?.colors?.font?.primary || 'var(--font-color-default)'};
      ${TruncatePreset('100%')};
    }

    .ant-picker-input > input {
      color: var(--font-color-default);
    }
  }
`
