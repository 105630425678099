import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconVisible = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 15"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M11.999.751C7.968.683 3.799 3.5 1.178 6.385a1.663 1.663 0 0 0 0 2.226c2.564 2.824 6.72 5.706 10.82 5.637 4.1.069 8.259-2.813 10.825-5.637.57-.632.57-1.593 0-2.226C20.199 3.5 16.03.683 11.999.751z" />
      <path d="M15.748 7.501a3.75 3.75 0 1 1-7.5-.001 3.75 3.75 0 0 1 7.5.001z" />
    </g>
  </svg>
)

export default SvgIconVisible
