import styled from 'styled-components'
import { Action, TruncatePreset } from '../../styles/mixins'
import { StyledBadge } from './StyledBadge'

export const StyledStatusBadge = styled(StyledBadge)`
  padding: 0 var(--gutter-default);

  ${Action({
    pointerEvents: 'none',
    textTransform: 'lowercase',
    fontSize: 'var(--font-size-xs)',
    height: 'var(--font-size-h5)',
    lineHeight: '1.6',
    border: {
      style: 'hidden',
    },
  })}

  ${TruncatePreset()};
`
