import { EEntityType, EInventoryType } from '@unfoldrtech/portal-mic'

export function convertPermittedInventory(inventory: EInventoryType) {
  switch (inventory) {
    case EInventoryType.Category:
      return EEntityType.AdGroupCategory
    case EInventoryType.Keyword:
      return EEntityType.AdGroupKeyword
    case EInventoryType.PagePosition:
      return EEntityType.AdGroupPosition
    default:
      break
  }
}
