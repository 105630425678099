import React from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, SVGContainer, StyledImage } from '../Global'

const LogoTagline = () => {
  const navigate = useNavigate()

  return (
    <Container width="100%">
      <Container
        display="flex"
        justifyContent="center"
        paddingTop="var(--gutter-5x)"
      >
        <SVGContainer
          cursor="pointer"
          onClick={() => navigate('/')}
          position="relative"
          zIndex={1}
        >
          <StyledImage
            src={`${process.env.REACT_APP_CDN_URL}/images/d75e7fa3-56f2-41aa-ac8b-7cc49f1c7a33.svg`}
            width="200px"
          />
        </SVGContainer>
      </Container>
      <Container position="relative" marginTop="-92.3px">
        <StyledImage
          src={`${process.env.REACT_APP_CDN_URL}/images/62be409c-9382-42fd-923f-bd8248138e81.svg`}
          width="100%"
        />
      </Container>
    </Container>
  )
}

export default LogoTagline
