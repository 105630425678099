import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError, IOrgAuthenticate } from '@unfoldrtech/portal-mic'
import { coreAPI } from '../services/coreAPI'
import { generateCorrelationId } from '../utils/helpers'
import firebaseAuth from '../services/firebaseAuth'
import { IUserAuthenticateResponse } from '../models/core'

export default function useAuthenticateUser() {
  return useMutation<
    AxiosResponse<IUserAuthenticateResponse>,
    AxiosError<IError>,
    IOrgAuthenticate
  >(['authenticateUser'], (orgAuthenticate: IOrgAuthenticate) =>
    coreAPI
      .misc({
        accessToken: firebaseAuth.getIdToken(),
      })
      .authenticateUser(generateCorrelationId(), orgAuthenticate)
  )
}
