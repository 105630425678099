import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconToastAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 17"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M8.271 1.017.716 13.993C-.06 15.326.902 17 2.444 17h15.11c1.544 0 2.506-1.674 1.73-3.007L11.727 1.017c-.771-1.325-2.685-1.325-3.457 0zm1.727 2.406a1 1 0 0 1 1 1v5.749a1 1 0 1 1-2 0V4.423a1 1 0 0 1 1-1zm0 11.249a1.25 1.25 0 1 0 0-2.5 1.25 1.25 0 0 0 0 2.5z"
      fill="#fff"
    />
  </svg>
)

export default SvgIconToastAlert
