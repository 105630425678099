import React, { ReactNode, useEffect, useState } from 'react'
import styled from 'styled-components'
import { Select, SelectProps } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { IThemeProps } from '../../../../models/interfaces'
import {
  Action,
  Border,
  FontPreset,
  FontStyle,
  TBorderMixin,
  TContainerProps,
  TFontPresetMixin,
  TFontStyleMixin,
  TSizeMixin,
  TruncatePreset,
  TPaddingMixin,
  Padding,
} from '../../styles/mixins'
import { Container } from '../../containers'
import {
  TSelectOptions,
  TFormFieldError,
  TDataTest,
} from '../../../../models/types'
import { sanitizeDataTestId } from '../../../../utils/sanitizeDataTestId'

export type TAntSelect2Props<T> = SelectProps<T> &
  TSelectOptions &
  TDataTest &
  TFormFieldError &
  TContainerProps & {
    children?: ReactNode | ReactNode[]
  } & { label?: string }

const SelectContainer = styled(Container)<
  TContainerProps & { isError?: boolean }
>`
  position: relative;
  $(props: TContainerProps) => Size(props)};

  label {
    color: ${(props) =>
      props.isError
        ? 'var(--font-color-error)'
        : 'var(--font-color-placeholder)'};
    transform-origin: top left;
    position: absolute;
    z-index: 0;
    top: 0;
    left: 0;
    transform: translate(23px, 20px) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &.float {
      transform: translate(14px, -9px) scale(0.75);
    }
  }

  input {
    border: none;
  }

  fieldset {
    text-align: left;
    position: absolute;
    inset: -5px 0px 0px;
    margin: 0px;
    padding: 0px 8px;
    pointer-events: none;
    border-radius: inherit;
    overflow: hidden;
    min-width: 0%;
    ${(props: TBorderMixin) => {
      return Border({
        border: {
          style: 'solid',
          width: '1px',
          color: 'var(--border-color-default)',
          ...props.border,
        },
      })
    }};

    legend {
      float: unset;
      width: auto;
      overflow: hidden;
      display: block;
      padding: 0;
      height: 11px;
      font-size: 0.75em;
      visibility: hidden;
      max-width: 0.01px;

      span {
        padding: 0 var(--gutter-mini);
        display: inline-block;
        opacity: 0;
        visibility: visible;
      }
    }
    &.float {
      legend {
        width: auto;
        max-width: 100%;
        span {
          opacity: 0;
        }
      }
    }
  }
`

export const AntSelect2 = styled(Select).withConfig({
  shouldForwardProp: (prop) =>
    ![
      'isError',
      'justifyContent',
      'maxWidth',
      'minWidth',
      'alignItems',
      'flexDirection',
      'borderRight',
      'borderLeft',
      'borderTop',
      'borderBottom',
      'border',
      'textAlign',
      'lineHeight',
      'textTransform',
    ].includes(prop as string),
})<TContainerProps & { search?: boolean; isError?: boolean }>`
  &&.ant-select {
    width: 100%;
    height: 100%;

    &.input-error {
      ${Border({
        borderBottom: {
          width: '4px',
          style: 'solid',
          color: 'var(--border-color-error)',
          radius: '2px',
        },
      })};
    }
  }
  && .ant-select-selector {
    ${(props: TPaddingMixin) =>
      Padding({
        padding: '0 var(--gutter-3x)',
        ...props,
      })};
    border-style: none;
    box-shadow: none;
    border: none !important;
    outline: none !important;
    box-shadow: none;

    ${(props: IThemeProps) =>
      Action({
        cursor: 'pointer',
        height: '100%',
        // height: 'var(--button-size-lg)',
        backgroundColor:
          props.theme?.colors?.background?.primary ||
          'var(--background-color-default)',

        fontFamily: 'OpenSans-SemiBold',
        fontSize: 'var(--font-size-pa)',
        textTransform: 'uppercase',
        background: 'transparent',
        color:
          props.theme?.colors?.font?.primary || 'var(--font-color-default)',
      })};

    .ant-select-selection-search {
      input {
        display: ${(props) => (props.search ? '' : 'none')};
      }
    }
  }

  && .ant-select-selection-item,
  .ant-select-selection-placeholder {
    margin: auto;
    ${(props: TFontStyleMixin) =>
      FontStyle({ textTransform: 'none', ...props })};
    ${(props: TFontPresetMixin) => FontPreset(props.preset)};
  }

  &&.metric-selector {
    display: flex;
    text-align: center;

    .ant-select-arrow {
      top: 28px;
      left: var(--gutter-7x);
      right: unset;
      width: unset;
      height: unset;
      margin-top: unset;
      color: unset;
      font-size: unset;
      line-height: unset;
      text-align: unset;
    }
  }

  && .ant-select-item-option-content {
    text-transform: none;
  }
  && .ant-select-selection-placeholder {
    color: ${(props) =>
      props.isError
        ? 'var(--font-color-error)'
        : 'var(--font-color-placeholder)'};
    -webkit-text-fill-color: ${(props) =>
      props.isError
        ? 'var(--font-color-error)'
        : 'var(--font-color-placeholder)'};
    ${TruncatePreset('100%')};
  }
`

const unwrapSizeProps = (props: TSizeMixin) => ({
  ...props,
})

const unwrapBorderProps = (props: TBorderMixin) => ({
  ...props,
})

// todo: Rename to StyledSelect and replace current styled select
export const StyledSelect2 = React.forwardRef<
  HTMLSelectElement,
  TAntSelect2Props<SelectValue>
>(
  (
    {
      label,
      value,
      error,
      className,
      padding,
      dataTestId,
      showSearch,
      children,
      ...props
    },
    ref
  ) => {
    const selectRef = ref || null
    const sizeProps = unwrapSizeProps(props)
    const borderProps = unwrapBorderProps(props)
    const customClass = className || ''

    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
      setIsActive(!!value)
    }, [value])

    return (
      <SelectContainer
        display="flex"
        height="64px"
        position="relative"
        isError={!!error}
        {...sizeProps}
        {...borderProps}
        width="100%"
      >
        {label && (
          <label htmlFor={props.id} className={isActive ? 'float' : ''}>
            {label}
          </label>
        )}
        <AntSelect2
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          {...props}
          value={value}
          padding={padding}
          ref={selectRef}
          className={error ? `${customClass} input-error` : `${customClass}`}
          data-testid={sanitizeDataTestId(dataTestId || '')}
          showSearch={showSearch}
          search={showSearch}
          isError={!!error}
          filterOption
        >
          {children || <></>}
        </AntSelect2>
        <fieldset
          className={isActive && label ? 'float' : ''}
          aria-hidden="true"
        >
          <legend>
            <span>{label}</span>
          </legend>
        </fieldset>
      </SelectContainer>
    )
  }
)
