import {
  IAdGroupKeyword,
  IAdGroupKeywordOptional,
} from '@unfoldrtech/portal-mic'

export function transformKeywordTarget(
  keyTarget: IAdGroupKeyword
): IAdGroupKeywordOptional {
  return {
    ...keyTarget,
    id: undefined,
    bidPrice: keyTarget.bidPrice ? Number(keyTarget.bidPrice) : undefined,
  }
}
