import { EUserRole } from '@unfoldrtech/portal-mic'

export const isUserAuthorised = (
  userRole?: EUserRole,
  accessRole?: EUserRole
) => {
  if (userRole && accessRole) {
    switch (accessRole) {
      case EUserRole.SuperAdmin:
        return isSuperAdmin(userRole)
      case EUserRole.RetailerAdmin:
        return isRetailerAdmin(userRole)
      case EUserRole.RetailerInventory:
        return isRetailerInventory(userRole)
      case EUserRole.RetailerInsights:
        return isRetailerInsights(userRole)
      case EUserRole.AdvertiserAdmin:
        return isAdvertiserAdmin(userRole)
      case EUserRole.AdvertiserCampaigns:
        return isAdvertiserCampaign(userRole)
      case EUserRole.AdvertiserInsights:
        return isAdvertiserInsights(userRole)
      /**
       * IMPORTANT: This role should only be used for
       * /organisations
       */
      case EUserRole.None:
        return isNone(userRole)
      default:
        return false
    }
  }
  return false
}

const isSuperAdmin = (userRole: EUserRole): boolean => {
  return userRole === EUserRole.SuperAdmin
}

const isRetailerAdmin = (userRole: EUserRole): boolean => {
  return isSuperAdmin(userRole) || userRole === EUserRole.RetailerAdmin
}

const isRetailerInventory = (userRole: EUserRole): boolean => {
  return isRetailerAdmin(userRole) || userRole === EUserRole.RetailerInventory
}
const isRetailerInsights = (userRole: EUserRole): boolean => {
  return (
    isRetailerInventory(userRole) || userRole === EUserRole.RetailerInsights
  )
}

const isAdvertiserAdmin = (userRole: EUserRole): boolean => {
  return isSuperAdmin(userRole) || userRole === EUserRole.AdvertiserAdmin
}

const isAdvertiserCampaign = (userRole: EUserRole): boolean => {
  return (
    isAdvertiserAdmin(userRole) || userRole === EUserRole.AdvertiserCampaigns
  )
}
const isAdvertiserInsights = (userRole: EUserRole): boolean => {
  return (
    isAdvertiserCampaign(userRole) || userRole === EUserRole.AdvertiserInsights
  )
}

const isNone = (userRole: EUserRole): boolean => {
  return (
    isAdvertiserInsights(userRole) ||
    isRetailerInsights(userRole) ||
    userRole === EUserRole.None
  )
}
