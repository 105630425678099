import styled from 'styled-components'
import Accordion from 'react-bootstrap/Accordion'
import { Background, TBackgroundMixin, TPaddingMixin } from '../../styles'

export const StyledAccordionBody = styled(Accordion.Body)<
  TPaddingMixin & TBackgroundMixin
>`
  padding: ${(props: TPaddingMixin) =>
    props.padding || 'var(--gutter-2x) var(--gutter-4x)'};
  ${(props: TBackgroundMixin) => Background({ ...props })};

  &&.faq-accordion-body {
    color: var(--font-color-placeholder);
  }
`
StyledAccordionBody.displayName = 'StyledAccordionBody'
