import { css, CSSProperties } from 'styled-components'

export type TSizeMixin = Pick<
  CSSProperties,
  | 'height'
  | 'maxHeight'
  | 'maxWidth'
  | 'width'
  | 'minWidth'
  | 'minHeight'
  | 'boxSizing'
>

export const Size = ({
  height,
  maxHeight,
  maxWidth,
  width,
  minWidth,
  minHeight,
  boxSizing,
}: TSizeMixin) => css`
  block-size: ${height};
  inline-size: ${width};
  max-block-size: ${maxHeight};
  max-inline-size: ${maxWidth};
  min-block-size: ${minHeight};
  min-inline-size: ${minWidth};
  box-sizing: ${boxSizing};

  @supports not (block-size: ${height}) {
    height: ${height};
    maxheight: ${maxHeight};
    min-height: ${minHeight};
  }

  @supports not (inline-size: ${width}) {
    max-width: ${maxWidth};
    width: ${width};
    min-width: ${minWidth};
  }
`
