import styled from 'styled-components'
import Form from 'react-bootstrap/Form'

import { IThemeProps } from '../../../../models/interfaces'
import {
  Action,
  Border,
  Display,
  TActionMixin,
  TBorderMixin,
  TDisplayMixin,
} from '../../styles/mixins'
import { TFormFieldError } from '../../../../models/types'

export const StyledCheckboxInput = styled(Form.Check.Input)<
  TDisplayMixin & TActionMixin & TFormFieldError
>`
  && {
    margin: auto;
    cursor: pointer;
    ${(props: TDisplayMixin) => Display({ ...props })};

    ${(props: IThemeProps & TFormFieldError & TActionMixin) =>
      Action({
        width: 'var(--icon-size-sm)',
        height: 'var(--icon-size-sm)',

        border: {
          width: '1px',
          style: 'solid',
          color: props.error
            ? 'var(--border-color-error)'
            : 'var(--border-color-default)',
        },

        backgroundColor:
          props.theme?.colors?.background?.primary ||
          'var(--background-color-default)',

        disableAction: {
          opacity: '1',
          border: {
            style: 'hidden !important',
          },
        },
      })};

    &:checked {
      background-color: ${(props: IThemeProps) =>
        props.theme?.colors?.background?.primary ||
        'var(--background-color-default)'};
      color: ${(props: IThemeProps) =>
        props.theme?.colors?.font?.active || 'var(--font-color-active)'};

      ${(props: TBorderMixin & TFormFieldError) =>
        Border({
          border: {
            width: '1px',
            style: 'solid',
            color: props.error
              ? 'var(--border-color-error)'
              : 'var(--border-color-default)',
          },
          ...props,
        })}
    }

    &:checked[type='checkbox'] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3cpath fill='none' stroke='%23ff7900' stroke-linecap='round' stroke-linejoin='round' stroke-width='3' d='M6 10l3 3l6-6'/%3e%3c/svg%3e");
    }

    &:checked[type='radio'] {
      background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 20 20'%3e%3ccircle cx='10' cy='10' r='5' fill='%23ff7900'/%3e%3c/svg%3e");
    }
  }
`
