import React from 'react'
import ReactDOM from 'react-dom'
import App from './App'
import SuspenseErrorBoundary from './components/SuspenseErrorBoundary'
import { SetupApp } from './components/SetupApp'

ReactDOM.render(
  <React.StrictMode>
    <SetupApp>
      <SuspenseErrorBoundary>
        <App />
      </SuspenseErrorBoundary>
    </SetupApp>
  </React.StrictMode>,
  document.getElementById('root')
)
