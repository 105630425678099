import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import {
  ERetailerReportPageType,
  IError,
  IReportingChartResponse,
  ITotalItems,
} from '@unfoldrtech/portal-mic'
import { coreAPI } from '../services/coreAPI'
import {
  generateCorrelationId,
  getTZOffset,
  getTimezone,
} from '../utils/helpers'
import {
  TReportingFilters,
  TPPRetailerId,
  TPPAdvertiserId,
} from '../models/types'
import { IReportPageTypes } from '../models/interfaces'

export default function useGetRetailerReportCharts({
  retailerId,
  advertiserId,
  retailerReportPageType: reportPageType = ERetailerReportPageType.Brands,
  campaignIds,
  adGroupIds,
  categoryIds,
  keywordIds,
  pageIds,
  positionIds,
  productIds,
  channelType,
  inventoryType,
  sortBy,
  sortOrder,
  pageLimit,
  pageOffset,
  timeWindow,
  endDate,
  startDate,
  tzOffset,
  tzZone,
  aggregationType,
  enabled = true,
}: {
  enabled?: boolean
} & TReportingFilters &
  TPPRetailerId &
  TPPAdvertiserId &
  IReportPageTypes) {
  const queryKeyName = `getRetailerReportCharts_${retailerId}_${reportPageType}_${
    channelType || ''
  }_${inventoryType || ''}`

  const brandId = advertiserId > 0 ? advertiserId : undefined

  return useQuery<
    AxiosResponse<ITotalItems & IReportingChartResponse>,
    AxiosError<IError>
  >(
    [queryKeyName],
    () => {
      return coreAPI
        .retailerReporting()
        .getRetailerReportCharts(
          retailerId,
          reportPageType,
          generateCorrelationId(),
          brandId,
          campaignIds,
          adGroupIds,
          categoryIds,
          keywordIds,
          pageIds,
          positionIds,
          productIds,
          channelType,
          sortBy,
          pageLimit,
          pageOffset,
          timeWindow,
          startDate,
          endDate,
          sortOrder,
          inventoryType,
          tzOffset || getTZOffset(endDate),
          tzZone || getTimezone(),
          aggregationType
        )
    },
    { suspense: true, enabled }
  )
}
