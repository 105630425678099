import { EDealType } from '@unfoldrtech/portal-mic'
import _ from 'lodash'

export type TGetDirectDealsFormSectionValidityParams = {
  dealType: EDealType
  sections: Record<string, boolean> & {
    detailsExclusive: boolean
    detailsStandard: boolean
  }
}

export const getDirectDealFormSectionsValidity = ({
  dealType,
  sections,
}: TGetDirectDealsFormSectionValidityParams) => {
  let computedObject: Record<string, boolean> = {}

  if (dealType === EDealType.StandardDeal) {
    computedObject = _.omit(sections, ['detailsExclusive'])
  }

  if (dealType === EDealType.ExclusiveDeal) {
    computedObject = _.omit(sections, ['detailsStandard'])
  }

  return Object.values(computedObject).find((v) => v === false) === undefined
}
