import { PayloadAction } from '@reduxjs/toolkit'
import { ICampaign, EMatchType } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedKeyword } from '../utils'

const setAdGroupKeywordMatchType = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    matchType: EMatchType
    keywordId: number
    adGroupId: number
  }>
) => {
  const selectedKeyword = getSelectedKeyword(state, action)

  if (selectedKeyword) {
    selectedKeyword.matchType = action.payload.matchType
  }
}

export default setAdGroupKeywordMatchType
