import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconUpload = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 18 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M4.33 5.667 8.996 1l4.667 4.667H11.07v4.666a.519.519 0 0 1-.518.519H7.44a.519.519 0 0 1-.519-.519V5.667H4.33Z"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M16.556 11.889v2.074c0 .572-.464 1.037-1.038 1.037H2.037A1.037 1.037 0 0 1 1 13.963v-2.074"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)
export default SvgIconUpload
