import {
  // ICampaign,
  ICampaignCreate,
} from '@unfoldrtech/portal-mic'
import { ICampaign } from '../../../models/interfaces/ICampaignState'
import { transformCampaign } from '../utils'

export const selectCampaign = ({
  campaign: campaignState,
}: {
  campaign: ICampaign
}): ICampaignCreate => transformCampaign(campaignState)
