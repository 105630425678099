import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconAdvertisers = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 19"
    {...props}
  >
    <path
      d="M6.51 12.75H4.35C2.362 12.75.75 11.071.75 9c0-2.071 1.612-3.75 3.6-3.75h2.16v7.5zm0 0c4.225 0 8.355 1.303 11.872 3.744l1.088.756V.75l-1.088.756C14.865 3.947 10.735 5.25 6.51 5.25v7.5zM22.35 7.5v3M6.51 12.75c0 1.984.78 3.882 2.16 5.25"
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgIconAdvertisers
