import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import {
  IPermittedCategoriesResponse,
  IKeywordsResponse,
  IPermittedChannelsResponse,
  IPermittedInventoryResponse,
  IProductsResponse,
  ITotalItems,
  IError,
  EParamAdvertiserTargetType,
  IPermittedPositionsResponse,
  EParamChannelType,
} from '@unfoldrtech/portal-mic'
import { coreAPI } from '../services/coreAPI'

import { generateCorrelationId } from '../utils/helpers'

export default function useGetAdvertiserTargets({
  advertiserId,
  targetType,
  retailerId,
  searchQuery,
  categoryId,
  subLevel,
  channelType,
  pageLimit,
  pageOffset,
  enabled = true,
  isSuspense = true,
}: {
  advertiserId: number
  targetType: EParamAdvertiserTargetType
  retailerId: number
  searchQuery?: string
  categoryId?: number
  subLevel?: number
  channelType?: EParamChannelType
  pageOffset?: number
  pageLimit?: number
  enabled?: boolean
  isSuspense?: boolean
}) {
  return useQuery<
    AxiosResponse<
      ITotalItems &
        (
          | IPermittedCategoriesResponse
          | IProductsResponse
          | IKeywordsResponse
          | IPermittedChannelsResponse
          | IPermittedInventoryResponse
          | IPermittedPositionsResponse
        )
    >,
    AxiosError<IError>
  >(
    [
      `getAdvertiserTargets_${targetType}_${searchQuery || ''}_${
        categoryId || ''
      }_${subLevel || ''}_${retailerId || ''}_${advertiserId || ''}_${
        channelType || ''
      }`,
    ],
    () =>
      coreAPI
        .advertiserCampaigns()
        .getAdvertiserTargets(
          advertiserId,
          retailerId,
          targetType,
          generateCorrelationId(),
          pageLimit,
          pageOffset,
          searchQuery,
          categoryId,
          subLevel,
          channelType
        ),
    { suspense: isSuspense, enabled }
  )
}
