import { css } from 'styled-components'
import { TFontFamilyMixin } from './fontStyle'

export type TFontFaceMixin = {
  fontFamily: TFontFamilyMixin
  url: any
  format: "'woff2'" | "'opentype'" | "'ttf'"
  fontWeight?: number
  style?: 'normal' | 'bold' | 'italic'
}

export const FontFace = ({
  fontFamily,
  url,
  format,
  fontWeight,
  style,
}: TFontFaceMixin) => css`
  @font-face {
    font-display: block;
    font-family: ${fontFamily};
    src: local(${fontFamily}), url(${url}) format(${format});
    font-weight: ${fontWeight || 'normal'};
    font-style: ${style} || normal;
  }
`
