import styled from 'styled-components'
import { IThemeProps } from '../../../models/interfaces'
import { TContainerProps } from '../styles/mixins'
import { SVGContainer } from './SVGContainer'

export const CheckIconContainer = styled(SVGContainer)<TContainerProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  margin-left: -45px;
  margin-right: var(--margin-default);
  border-radius: var(--gutter-6x);
  font-size: var(--font-size-ps);
  width: var(--icon-size-no);
  height: var(--icon-size-no);

  background: ${(props: IThemeProps & TContainerProps) =>
    props.background ||
    props.theme?.colors?.background?.secondary ||
    'var(--background-color-secondary)'};
  color: ${(props: IThemeProps & TContainerProps) =>
    props.color ||
    props.theme?.colors?.font?.secondary ||
    'var(--font-color-secondary)'};
`
