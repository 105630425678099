import { IProduct } from '@unfoldrtech/portal-mic'

export const getBrandsFromProducts = ({
  products,
}: {
  products: IProduct[]
}): string[] => {
  const brands = products.reduce((acc, product) => {
    if (product.brand && !acc.includes(product.brand)) {
      acc.push(product.brand)
    }
    return acc
  }, [] as string[])

  return brands
}
