import { PayloadAction } from '@reduxjs/toolkit'
import { TReportingFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'

const setCampaignIds = (
  state: TReportingFilters,
  action: PayloadAction<{
    campaignIds?: number[]
  }>
) => {
  state.campaignIds = action.payload.campaignIds
}

export default setCampaignIds
