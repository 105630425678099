import styled from 'styled-components'
import Navbar from 'react-bootstrap/Navbar'

import { FontPreset } from '../../styles/mixins'

export const StyledNavbarBrand = styled(Navbar.Brand)`
  padding: 0;
  &&& {
    ${FontPreset('nav title')};
  }
`
