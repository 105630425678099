import React from 'react'
import styled from 'styled-components'
import Form from 'react-bootstrap/Form'

import { FormSelectProps, FormControlProps } from 'react-bootstrap'
import { TFormFieldError } from '../../../../models/types'
import {
  Action,
  Border,
  TSizeMixin,
  TFontStyleMixin,
} from '../../styles/mixins'
import { Container } from '../../containers'

export type TBootstrapSelectProps = FormControlProps &
  TSizeMixin &
  FormSelectProps &
  TFormFieldError &
  TFontStyleMixin

export const Select = styled(Form.Select)<TSizeMixin & TFontStyleMixin>`
  padding: 0 var(--padding-default);

  ${(props) =>
    Action({
      width: '100%',
      height: '100%',
      cursor: 'pointer',
      border: {
        style: 'hidden',
      },

      backgroundColor:
        props.theme?.colors?.background?.primary ||
        'var(--background-color-default)',

      fontFamily: 'OpenSans-SemiBold',
      fontSize: props.fontSize || 'var(--font-size-pa)',
      textTransform: 'capitalize',
      color: props.theme?.colors?.font?.primary || 'var(--font-color-default)',
    })};

  &.input-error {
    ${Border({
      borderBottom: {
        width: '4px',
        style: 'solid',
        color: 'var(--border-color-error)',
        radius: '2px',
      },
    })};
  }
`

const unwrapSizeProps = (props: TSizeMixin) => ({
  ...props,
})

export const StyledBootstrapSelect = React.forwardRef(
  (
    {
      error,
      dataTestId,
      ...props
    }: TBootstrapSelectProps & { dataTestId?: string },
    ref
  ) => {
    const selectRef = ref || null
    const sizeProps = unwrapSizeProps(props)
    return (
      <Container
        border={{
          width: '1px',
          style: 'solid',
          color: 'var(--border-color-default)',
          radius: '2px',
        }}
        {...sizeProps}
      >
        <Select
          {...props}
          data-testid={dataTestId}
          ref={selectRef}
          className={error ? 'input-error' : ''}
        />
      </Container>
    )
  }
)
