import {
  ECreativeStatus,
  IAdData,
  IAdTemplate,
  ICreative,
} from '@unfoldrtech/portal-mic'

export class CCreative implements ICreative {
  id: number

  advertiserId: number

  title: string

  description?: string

  template: IAdTemplate

  data: IAdData

  status?: ECreativeStatus

  updatedAt: string

  updatedBy: string

  constructor(
    id?: number,
    advertiser_id?: number,
    title?: string,
    description?: string,
    template?: IAdTemplate,
    data?: IAdData,
    status?: ECreativeStatus,
    updated_at?: string,
    updated_by?: string
  ) {
    this.id = id as number
    this.advertiserId = advertiser_id as number
    this.title = title as string
    this.description = description
    this.template = template as IAdTemplate
    this.data = data as IAdData
    this.status = status
    this.updatedAt = updated_at as string
    this.updatedBy = updated_by as string
  }
}
