import {
  EStatus,
  EDayTimeTargetDay,
  EDayTimeTargetTime,
  EDayTimeTargetCriteria,
} from '@unfoldrtech/portal-mic'
import {
  IDOOHAdGroupUpdate,
  IDOOHAdGroupTargetingUpdate,
  IDOOHAdGroupCreativeUpdate,
} from '@unfoldrtech/portal-mic/dist/src/models/dooh'

export class CDOOHAdGroupUpdate implements IDOOHAdGroupUpdate {
  id: number

  title: string

  status?: EStatus

  days?: EDayTimeTargetDay[] | null

  times?: EDayTimeTargetTime[] | null

  criteria?: EDayTimeTargetCriteria

  targeting: IDOOHAdGroupTargetingUpdate

  creativesList?: Array<IDOOHAdGroupCreativeUpdate>

  constructor(
    id: number,
    title: string,
    targeting: IDOOHAdGroupTargetingUpdate,
    creativesList?: Array<IDOOHAdGroupCreativeUpdate>,
    status?: EStatus,
    days?: EDayTimeTargetDay[] | null,
    times?: EDayTimeTargetTime[] | null,
    criteria?: EDayTimeTargetCriteria
  ) {
    this.id = id
    this.title = title
    this.targeting = targeting
    this.creativesList = creativesList
    this.status = status
    this.days = days
    this.times = times
    this.criteria = criteria
  }
}
