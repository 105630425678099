import { useEffect, RefObject } from 'react'

export const useClickAwayListener = (
  ref: RefObject<HTMLElement>,
  callback: () => void
) => {
  useEffect(() => {
    const listener = (event: MouseEvent | null) => {
      if (!ref.current || ref.current.contains(event?.target as Node)) {
        return
      }
      callback()
    }

    document.addEventListener('mousedown', listener)

    return () => {
      document.removeEventListener('mousedown', listener)
    }
  }, [ref, callback])
}
