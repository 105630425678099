import styled from 'styled-components'

import { FontStyle } from '../styles/mixins'
import { Text } from './Text'

export const SelectedText = styled(Text)`
  ${(props) =>
    FontStyle({
      fontFamily: 'GalanoGrotesque-Medium',
      fontSize: 'var(--font-size-ps)',
      color: props.theme?.colors?.font?.active || 'var(--font-color-active)',
      opacity: '0.5',
    })}
`
