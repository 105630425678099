import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedDoohAdGroup } from '../utils'
import { IDOOHAdGroup, IDOOHCampaign } from '../../../models'

const removeDoohCampaignAdGroupCreatives = (
  state: WritableDraft<IDOOHCampaign>,
  action: PayloadAction<{ adGroupId: number; creativeIds: number[] }>
) => {
  const selectedAdGroup =
    getSelectedDoohAdGroup(state, action.payload.adGroupId) ||
    ({} as IDOOHAdGroup)

  for (let index = 0; index < action.payload.creativeIds.length; index += 1) {
    if (selectedAdGroup) {
      const creativeIndex = selectedAdGroup.creativesList?.findIndex(
        (creative) => creative.id === action.payload.creativeIds[index]
      )
      if (creativeIndex !== undefined && creativeIndex > -1) {
        selectedAdGroup.creativesList?.splice(creativeIndex, 1)
      }
    }
  }
}

export default removeDoohCampaignAdGroupCreatives
