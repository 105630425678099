import { EInventoryType } from '@unfoldrtech/portal-mic'
import React from 'react'
import { EDOOHInventoryType } from '../../../models'
import I18NText from '../../I18NText'
import { isScreenPlacementWithName } from '../../../utils/helpers/isScreenPlacementWithName'

export function CurrentInventoryPageText({
  inventoryType,
}: {
  inventoryType?: EInventoryType | EDOOHInventoryType | string
}) {
  if (inventoryType === EInventoryType.Category) {
    return (
      <>
        <I18NText id="categories" />
      </>
    )
  }
  if (inventoryType === EInventoryType.Keyword) {
    return (
      <>
        <I18NText id="keywords" />
      </>
    )
  }
  if (inventoryType === EInventoryType.PagePosition) {
    return (
      <>
        <I18NText id="positions" />
      </>
    )
  }
  if (inventoryType === EDOOHInventoryType.Screen) {
    return (
      <>
        <I18NText id="screen" />
      </>
    )
  }
  if (inventoryType && isScreenPlacementWithName(inventoryType)) {
    return <>{inventoryType}</>
  }
  return <> </>
}
