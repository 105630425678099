import { EUserRole } from '@unfoldrtech/portal-mic'
import React, { lazy } from 'react'
import { Route } from 'react-router-dom'
import { lazyRetry } from '../../utils/lazyRetry'
import AuthenticatedRoute from './AuthenticatedRoute'
import { PortalRoutes } from './PortalRoutes'

const Advertiser = lazy(() => import('../../pages/Advertiser'))
const AdvertiserOverview = lazy(() => import('../../pages/Advertiser/Overview'))

const Settings = lazy(() => lazyRetry(() => import('../../pages/Settings')))

const UserProfile = lazy(() =>
  lazyRetry(() => import('../../pages/Settings/Routes/UserProfile'))
)
const UserPassword = lazy(() =>
  lazyRetry(() => import('../../pages/Settings/Routes/UserPassword'))
)
const UserAndRoles = lazy(() =>
  lazyRetry(() => import('../../pages/Settings/Routes/UsersAndRoles'))
)

const NotFound = lazy(() => lazyRetry(() => import('../../pages/NotFound')))

const SettingsRoutes: React.FC = () => {
  return (
    <>
      <PortalRoutes>
        <Route
          path="advertiser/:orgId"
          element={
            <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
              <Advertiser />
            </AuthenticatedRoute>
          }
        >
          <Route
            path="retailer/:retailerId"
            element={
              <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
                <AdvertiserOverview />
              </AuthenticatedRoute>
            }
          >
            <Route
              path="settings"
              element={
                <AuthenticatedRoute
                  authRole={[
                    EUserRole.AdvertiserInsights,
                    EUserRole.RetailerInsights,
                  ]}
                >
                  <Settings />
                </AuthenticatedRoute>
              }
            >
              <Route
                path="profile"
                element={
                  <AuthenticatedRoute
                    authRole={[
                      EUserRole.AdvertiserInsights,
                      EUserRole.RetailerInsights,
                    ]}
                  >
                    <UserProfile />
                  </AuthenticatedRoute>
                }
                /* exact */
              />
              <Route
                path="password"
                element={
                  <AuthenticatedRoute
                    authRole={[
                      EUserRole.AdvertiserInsights,
                      EUserRole.RetailerInsights,
                    ]}
                  >
                    <UserPassword />
                  </AuthenticatedRoute>
                }
                /* exact */
              />
              <Route
                path="admin"
                element={
                  <AuthenticatedRoute
                    authRole={[
                      EUserRole.RetailerAdmin,
                      EUserRole.AdvertiserAdmin,
                    ]}
                  >
                    <UserAndRoles />
                  </AuthenticatedRoute>
                }
                /* exact */
              />

              <Route path="*" element={<NotFound />} />
            </Route>
          </Route>
        </Route>
      </PortalRoutes>
    </>
  )
}

export default SettingsRoutes
