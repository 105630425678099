import dayjs from 'dayjs'

export const getTicks = (
  start: string | number,
  end: string | number,
  numTicks: number
) => {
  const startTimestamp = dayjs(start).valueOf()
  const endTimestamp = dayjs(end).valueOf()

  const diffDays = dayjs(endTimestamp).diff(startTimestamp, 'milliseconds')

  const current = startTimestamp
  const interval = Math.round(diffDays / (numTicks - 1))

  const ticks = [startTimestamp]

  for (let index = 1; index < numTicks - 1; index += 1) {
    const tick = current + interval * index
    ticks.push(tick)
  }

  ticks.push(endTimestamp)
  return ticks
}
