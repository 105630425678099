import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconCategoriesPage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <g
      clipPath="url(#icon-categories-page_svg__a)"
      stroke="currentColor"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m11.582 7.57-5.5-3.499 5.5-3.5 5.508 3.5-5.508 3.5z" />
      <path d="M11.582 13.746V7.57l5.508-3.499v6.175l-5.508 3.5zm-5.5-9.675v6.175l5.5 3.5V7.57l-2.75-1.753-2.75-1.746zm8.25-1.753-5.5 3.499" />
      <path d="m17.09 17.245-5.508-3.5 5.508-3.498 5.5 3.499-5.5 3.5z" />
      <path d="M17.09 23.428v-6.183l5.5-3.5v6.184l-5.5 3.5zm-5.508-9.682v6.183l5.508 3.5v-6.184L14.332 15.5l-2.75-1.753zM19.84 12l-5.508 3.5m-8.25 1.745-5.508-3.5 5.508-3.498 5.5 3.499-5.5 3.5z" />
      <path d="M6.082 23.428v-6.183l5.5-3.5v6.184l-5.5 3.5zM.574 13.746v6.183l5.508 3.5v-6.184L3.324 15.5l-2.75-1.753zM8.832 12l-5.508 3.5" />
    </g>
    <defs>
      <clipPath id="icon-categories-page_svg__a">
        <path fill="#fff" d="M0 0h23.164v24H0z" />
      </clipPath>
    </defs>
  </svg>
)

export default SvgIconCategoriesPage
