import { IDimensions } from '@unfoldrtech/portal-mic'
import React from 'react'
import { CSSProperties } from 'styled-components'
import I18NText from '../../I18NText'
import { TFontPresets } from '../styles'
import { Text } from './Text'

export function CurrentDimensionsText({
  dimensions,
  showSuffix,
  preset,
  opacity,
}: Pick<CSSProperties, 'opacity'> & {
  dimensions?: IDimensions
  showSuffix?: boolean
  preset?: TFontPresets
}) {
  if (dimensions !== undefined) {
    return (
      <>
        <Text preset={preset} opacity={opacity}>
          {`${dimensions.width} x ${dimensions.height} `}
          <I18NText id="pixel.short" />
          {showSuffix && (
            <>
              {' '}
              <I18NText id="width.x.height.shortcut" />
            </>
          )}
        </Text>
      </>
    )
  }
  return <> </>
}
