import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import { generateCorrelationId } from '../../utils/helpers'
import { IUserProfile } from '../../models/core'
import { coreAPIV3 } from '../../services/coreAPIV3'

export const useGetUserProfile = ({
  enabled = true,
}: {
  enabled?: boolean
}) => {
  return useQuery<AxiosResponse<IUserProfile>, AxiosError<IError>>(
    ['getUserProfile'],
    () => coreAPIV3.users().getUserProfile(generateCorrelationId()),
    { suspense: true, enabled }
  )
}
