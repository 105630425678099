import React from 'react'
import { EPlatformType } from '@unfoldrtech/portal-mic'
import I18NText from '../../I18NText'

interface ICurrentPlatformTypeTextProps {
  platformType?: EPlatformType
}

export function CurrentPlatformTypeText({
  platformType,
}: ICurrentPlatformTypeTextProps) {
  if (platformType === EPlatformType.WebMobile) {
    return (
      <>
        <I18NText id="web.mobile" />
      </>
    )
  }
  if (platformType === EPlatformType.WebDesktop) {
    return (
      <>
        <I18NText id="web.desktop" />
      </>
    )
  }
  if (platformType === EPlatformType.AppMobile) {
    return (
      <>
        <I18NText id="app.mobile" />
      </>
    )
  }
  return <> </>
}
