import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconToastSuccess = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="m18.573 1.563-1.615.178a5.62 5.62 0 0 0-3.35 1.611l-7.341 7.341 3.174 3.175 7.342-7.34a5.614 5.614 0 0 0 1.61-3.35l.18-1.615zM3.979 8.97l-2.47-.358c-.168-.024-.302-.114-.355-.237-.053-.123-.016-.263.096-.367l.142-.133c.984-.92 2.533-1.366 4.003-1.153l.855.123L3.979 8.97zm7.424 7.024.409 2.46c.027.168.12.3.244.351.124.05.263.011.365-.103l.13-.145c.899-1.002 1.314-2.56 1.07-4.025l-.14-.853-2.078 2.315zm-5.639-1.881c1.033 1.033.396 1.6.13 1.865-.551.552-3.329 1.333-3.329 1.333s.781-2.78 1.333-3.33c.242-.244.772-.962 1.866.132z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgIconToastSuccess
