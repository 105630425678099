export const isValidCustomPackshotResolution = (
  image: { width: number; height: number },
  validationImage: { width: number; height: number }
): boolean => {
  const imageWidth = image.width
  const imageHeight = image.height
  const validationWidth = validationImage.width
  const validationHeight = validationImage.height
  return imageWidth === validationWidth && imageHeight === validationHeight
}
