import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconSearch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 18"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M4.125 5.62a.375.375 0 1 0 0 .75.375.375 0 0 0 0-.75m3.75 0a.375.375 0 1 0 0 .75.375.375 0 0 0 0-.75" />
      <path d="M21.75 3.75v-1.5a1.5 1.5 0 0 0-1.5-1.5h-18a1.5 1.5 0 0 0-1.5 1.5v6a1.5 1.5 0 0 0 1.5 1.5H7.5" />
      <path d="M15.75 15a5.25 5.25 0 1 0 0-10.5 5.25 5.25 0 0 0 0 10.5zm7.5 2.248-3.788-3.788" />
    </g>
  </svg>
)

export default SvgIconSearch
