import { createContext, Dispatch, SetStateAction } from 'react'
import { IAdData } from '@unfoldrtech/portal-mic'
import { TAdDataContext } from '../types'

export const defaultAdDataContext: TAdDataContext = [
  <IAdData>{},
  <Dispatch<SetStateAction<IAdData>>>{},
]

export const AdDataContext = createContext<TAdDataContext>(defaultAdDataContext)
