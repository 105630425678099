import { EOrgType } from '@unfoldrtech/portal-mic'
import { useContext } from 'react'
import { AdvertiserContext, AppContext, RetailerContext } from '../../../models'

export const useGetRetailerAndAdvertiserIds = () => {
  const [appContext] = useContext(AppContext)
  const retailerContext = useContext(RetailerContext)
  const [advertiserContext] = useContext(AdvertiserContext)

  const orgType = appContext.userOrg!.type
  let retailerId: number = -1
  let advertiserId: number = -1

  switch (orgType) {
    case EOrgType.Advertiser:
      retailerId = retailerContext.id || -1
      advertiserId = appContext.userOrg?.id || -1
      break
    case EOrgType.Retailer:
      retailerId = appContext.userOrg?.id || -1
      advertiserId = advertiserContext?.id
      break
    default:
      break
  }

  return { retailerId, advertiserId }
}
