import { PayloadAction } from '@reduxjs/toolkit'
import { ICampaign, IProduct, IAdGroup } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedAdGroup } from '../utils'

const addAdGroupProducts = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    products: Array<IProduct>
    adGroupId: number
  }>
) => {
  const selectedAdGroup =
    getSelectedAdGroup(state, action.payload.adGroupId) || ({} as IAdGroup)
  if (selectedAdGroup.productList === undefined) {
    selectedAdGroup.productList = []
  }

  selectedAdGroup.productList = action.payload.products
}

export default addAdGroupProducts
