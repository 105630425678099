import {
  EInventoryType,
  // IAdGroup
} from '@unfoldrtech/portal-mic'
import { transformCategoryTarget } from './transformCategoryTarget'
import { transformKeywordTarget } from './transformKeywordTarget'
import { transformPositionTarget } from './transformPositionTarget'
import { IAdGroup } from '../../../models/interfaces/ICampaignState'

// The function below is called a thunk and allows us to perform async logic. It
// can be dispatched like a regular action: `dispatch(incrementAsync(10))`. This
// will call the thunk with the `dispatch` function as the first argument. Async
// code can then be executed and other actions can be dispatched
// export const incrementAsync = (amount: number) => (dispatch: Dispatch) => {
//   setTimeout(() => {
//     dispatch(incrementByAmount(amount))
//   }, 1000)
// }

// TODO: MIC update
export function getAdGroupTargetList(adGroup: IAdGroup) {
  if (adGroup.inventoryType === EInventoryType.Category) {
    return {
      categoriesList:
        adGroup.categoriesList?.map(transformCategoryTarget) || [],
      positionIdList: adGroup.positionsList?.map((pos) => pos.id) || [],
    }
  }
  if (adGroup.inventoryType === EInventoryType.Keyword) {
    return {
      keywordsList: adGroup.keywordsList?.map(transformKeywordTarget) || [],
      positionIdList: adGroup.positionsList?.map((pos) => pos.id) || [],
    }
  }
  return {
    positionsList: adGroup.positionsList?.map(transformPositionTarget) || [],
    audienceIdList: adGroup.audiencesList?.map((audienceId) => audienceId.id),
  }
}
