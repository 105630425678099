import styled from 'styled-components'
import Accordion from 'react-bootstrap/Accordion'

import {
  TruncatePreset,
  Sticky,
  TStickyProps,
  TMiscMixin,
  TPaddingMixin,
  Padding,
  Background,
  TBackgroundMixin,
  TFontStyleMixin,
  FontStyle,
} from '../../styles/mixins'
import { IThemeProps } from '../../../../models/interfaces'
import { Action } from '../../styles/mixins/action'

export const StyledAccordionHeader = styled(Accordion.Header).withConfig({
  shouldForwardProp: (prop) => !['backgroundColor'].includes(prop as string),
})<
  TStickyProps &
    TMiscMixin &
    TPaddingMixin &
    TBackgroundMixin & {
      collapsed?: TFontStyleMixin
      active?: TFontStyleMixin
    }
>`
  ${(props: TStickyProps) => Sticky({ zIndex: 20, ...props })};
  ${(props: TMiscMixin) =>
    Action({
      cursor: props.cursor || 'cursor',
    })}

  & .accordion-button {
    padding-left: var(--gutter-4x);
    padding-right: var(--gutter-4x);
    ${(props: TPaddingMixin) => Padding({ ...props })};

    background: ${(props) =>
      props.theme?.colors?.background?.active ||
      'var(--background-color-active)'};
    ${(props: TBackgroundMixin) => Background({ ...props })};

    ${(props: { collapsed?: TFontStyleMixin }) =>
      FontStyle({ ...props.collapsed })};

    ${TruncatePreset()};
  }

  & .accordion-button.collapsed::after {
    ${(props: IThemeProps & { collapsed?: TFontStyleMixin }) =>
      `background-image: ${
        props.collapsed?.color
          ? `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23${props.collapsed.color.substring(
              1
            )}'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")`
          : ''
      };`}
  }

  & .accordion-button:not(.collapsed) {
    background: ${(props) =>
      props.theme?.colors?.background?.primary ||
      'var(--background-color-default)'};
    color: ${(props) =>
      props.theme?.colors?.font?.primary || 'var(--font-color-default)'};
    ${(props: { active?: TFontStyleMixin }) => FontStyle({ ...props.active })};
  }

  & .accordion-button:not(.collapsed)::after {
    background-image: ${(props: IThemeProps & { active?: TFontStyleMixin }) =>
      `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23${
        props.active?.color?.substring(1) ||
        props.theme?.colors?.font?.primary?.substring(1) ||
        'ffffff'
      }'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")`};
  }

  &&.faq-accordion-header {
    & .accordion-button {
      color: var(--font-color-placeholder);
    }

    & .accordion-button:focus {
      box-shadow: none;
    }

    & .accordion-button:not(.collapsed) {
      box-shadow: none;
      color: var(--font-color-dark);
    }
  }
`
StyledAccordionHeader.displayName = 'StyledAccordionHeader'
