import { ICampaign } from '@unfoldrtech/portal-mic'

export const selectCampaignDetails = ({
  campaign: campaignState,
}: {
  campaign: ICampaign
}) => ({
  channelType: campaignState.channelType,
  title: campaignState.title,
  id: campaignState.id,
  dealType: campaignState.dealType,
})
