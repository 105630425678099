import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconDisplayadSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      stroke="currentColor"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m16 14.5.75 4.5m-1.5 0H19M16 11.5h7.5m-7.5 3h6a1.5 1.5 0 0 0 1.5-1.5V2.5A1.5 1.5 0 0 0 22 1H5.5A1.5 1.5 0 0 0 4 2.5M1 13a1.5 1.5 0 0 1 1.5-1.5H7A1.5 1.5 0 0 1 8.5 13v9A1.5 1.5 0 0 1 7 23.5H2.5A1.5 1.5 0 0 1 1 22v-9zm0 7.5h7.5" />
      <path d="M4 8.5V7a1.5 1.5 0 0 1 1.5-1.5h6A1.5 1.5 0 0 1 13 7v9a1.5 1.5 0 0 1-1.5 1.5m0-3H13m-9-6h9" />
    </g>
  </svg>
)

export default SvgIconDisplayadSmall
