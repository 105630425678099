import React, { useContext, useEffect, useState } from 'react'
import { useForm, SubmitHandler, Controller } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { useLocation, useNavigate } from 'react-router-dom'
import { useTheme } from 'styled-components'
import { v4 as uuidv4 } from 'uuid'
import { IError, IOrgTheme } from '@unfoldrtech/portal-mic'
import { MultiFactorError } from 'firebase/auth'
import {
  FloatingInputWrapper,
  StyledInput,
  Container,
  PageLinkButton,
  SVGContainer,
  StyledSuffix,
  StyledForm,
  Text,
  IconVisible,
  IconInvisible,
  WebsiteButton,
} from '../components/Global'
import I18NText from '../components/I18NText'
import firebaseAuth from '../services/firebaseAuth'
import LogoTagline from '../components/LogoTagline'
import FooterCopyright from '../components/FooterCopyright'
import { TLocationState } from '../models/types'
import { ErrorToast } from '../components/ToastCard/ErrorToast'
import { useFeatureFlagClient } from '../hooks/useFeatureFlagClient'
import { AppContext } from '../models/contexts'

type TLoginForm = {
  email: string
  password: string
}

function Login() {
  const intl = useIntl()
  const navigate = useNavigate()
  const location = useLocation()

  const [appContext] = useContext(AppContext)

  const theme: IOrgTheme = useTheme()
  const featureFlagClient = useFeatureFlagClient()

  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showToast, setShowToast] = useState(false)
  const [isPasswordShow, setIsPasswordShow] = useState(false)
  const [isLoggingIn, setIsLoggingIn] = useState(false)
  const [hasErrorMessage, setHasErrorMessage] = useState(false)

  const {
    control,
    handleSubmit,
    formState: { errors, isValid, isDirty, isSubmitted },
  } = useForm<TLoginForm>()

  const onSubmit: SubmitHandler<TLoginForm> = async (data) => {
    setIsLoggingIn(true)
    try {
      await firebaseAuth.login({
        email: data.email.trim(),
        password: data.password,
      })
    } catch (err) {
      const errorFromLoginPage = { ...(err as IError) } as MultiFactorError
      if (
        errorFromLoginPage.code?.includes('auth/multi-factor-auth-required')
      ) {
        // The user is a multi-factor user.
        navigate('/mfa', {
          state: {
            errorFromLoginPage,
          },
        })
      }
      setShowToast(true)
    }
  }

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value)
  }

  const handlePasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPassword(event.target.value)
  }

  useEffect(() => {
    if (showToast) {
      setIsLoggingIn(false)
    }
  }, [showToast])

  useEffect(() => {
    if (!appContext.isLoggedIn) {
      // Init Feature Flag context
      const ffContext = featureFlagClient?.getContext()

      if (!ffContext?.anonymous) {
        featureFlagClient?.identify({
          kind: 'user',
          anonymous: true,
          key: uuidv4(),
        })
      }
    }
    return () => {
      setIsLoggingIn(false)
    }
  }, [appContext.isLoggedIn])

  useEffect(() => {
    if ((location.state as TLocationState)?.error) {
      setHasErrorMessage(!!(location.state as TLocationState)?.translationKey)
    }
  }, [location])

  return (
    <>
      <Container
        height="100vh"
        display="flex"
        flexDirection="column"
        overflow="auto"
        backgroundColor={
          theme.colors?.background?.dark || 'var(--background-color-dark)'
        }
      >
        <LogoTagline />
        <Container
          width="100%"
          flex="1"
          display="flex"
          justifyContent="center"
          paddingTop="var(--gutter-5x)"
        >
          <Container width="440px">
            <Container width="100%" textAlign="center">
              <I18NText
                id="login.signin"
                preset="h2"
                color={
                  theme.colors?.font?.secondary || 'var(--font-color-secondary)'
                }
                data-testid="login-title"
              />
            </Container>
            <Container>
              <StyledForm
                id="login-form"
                onSubmit={handleSubmit(onSubmit)}
                noValidate
              >
                <Container paddingTop="var(--gutter-4x)">
                  <Controller
                    name="email"
                    control={control}
                    defaultValue={email}
                    render={({ field }) => (
                      <FloatingInputWrapper
                        error={errors.email}
                        placeholder={
                          email?.length
                            ? intl.formatMessage({
                                id: 'login.email',
                              })
                            : ''
                        }
                      >
                        <StyledInput
                          autoFocus
                          id="email"
                          placeholder={intl.formatMessage({
                            id: 'login.email',
                          })}
                          type="email"
                          value={email}
                          field={field}
                          onChange={handleEmailChange}
                          error={errors.email}
                          containerSize={{
                            width: '100%',
                          }}
                          data-testid="login-email-input"
                        />
                      </FloatingInputWrapper>
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: intl.formatMessage({
                          id: 'error.required',
                        }),
                      },
                      pattern: {
                        message: intl.formatMessage({
                          id: 'login.errors.invalid.email',
                        }),
                        value:
                          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
                      },
                    }}
                  />
                </Container>
                <Container paddingTop="var(--margin-default)">
                  <Controller
                    name="password"
                    control={control}
                    defaultValue={password}
                    render={({ field }) => (
                      <FloatingInputWrapper
                        error={errors.password}
                        placeholder={
                          password?.length
                            ? intl.formatMessage({
                                id: 'login.password',
                              })
                            : ''
                        }
                      >
                        <StyledInput
                          id="password"
                          placeholder={intl.formatMessage({
                            id: 'login.password',
                          })}
                          type={isPasswordShow ? 'text' : 'password'}
                          value={password}
                          field={field}
                          data-testid="login-password-input"
                          onChange={handlePasswordChange}
                          error={errors.password}
                          containerSize={{
                            width: '100%',
                          }}
                          suffix={
                            <StyledSuffix>
                              <SVGContainer
                                preset="paragraph opensans"
                                cursor="pointer"
                                onClick={() =>
                                  setIsPasswordShow(!isPasswordShow)
                                }
                              >
                                {isPasswordShow ? (
                                  <IconInvisible />
                                ) : (
                                  <IconVisible />
                                )}
                              </SVGContainer>
                            </StyledSuffix>
                          }
                        />
                      </FloatingInputWrapper>
                    )}
                    rules={{
                      required: {
                        value: true,
                        message: intl.formatMessage({
                          id: 'error.required',
                        }),
                      },
                    }}
                  />
                </Container>
                <Container
                  paddingTop="var(--gutter-4x)"
                  display="flex"
                  alignItems="center"
                >
                  <WebsiteButton
                    type="submit"
                    data-testid="signin-button"
                    disabled={!isDirty || (isSubmitted && !isValid)}
                    isLoading={isLoggingIn}
                  >
                    <I18NText id="login.signin" />
                  </WebsiteButton>
                  <Container flex="1" display="flex" justifyContent="center">
                    <PageLinkButton
                      onClick={() => navigate('/forgot-password')}
                      backgroundColor={
                        theme.colors?.background?.dark ||
                        'var(--background-color-dark)'
                      }
                    >
                      <I18NText
                        preset="paragraph medium"
                        id="forgot.password.label"
                      />
                      <Text>?</Text>
                    </PageLinkButton>
                  </Container>
                </Container>
              </StyledForm>
            </Container>
          </Container>
        </Container>
        <FooterCopyright />
        <Container preset="paragraph opensans">
          <ErrorToast
            show={showToast}
            onClose={(show) => setShowToast(show)}
            position="top-center"
            translationKey="login.errors.invalid.retry"
            color="var(--font-color-error)"
          />
        </Container>
        <Container preset="error">
          <ErrorToast
            show={hasErrorMessage}
            onClose={async () => {
              setHasErrorMessage(false)
            }}
            dataTestId="error-message"
            translationKey={
              (location.state as TLocationState)?.translationKey || 'error'
            }
          />
        </Container>
      </Container>
    </>
  )
}

export default Login
