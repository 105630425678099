import React from 'react'
import styled from 'styled-components'
import { TFontPresets } from '../styles'
import { PageLinkButton } from './PageLinkButton'
import { Text } from '../fonts/Text'

export type TLinkButtonProps = {
  disabled?: boolean
  text: string
  onClick?: () => void
  preset?: TFontPresets
}

const StyledLinkButton = styled(PageLinkButton)`
  background-color: transparent;

  &[disabled] {
    background-color: transparent;
  }

  &:focus {
    background-color: transparent;
  }
`
export const LinkButton = ({
  disabled = false,
  text,
  onClick,
  preset = 'url',
  ...props
}: TLinkButtonProps) => {
  return (
    <StyledLinkButton
      variant={preset === 'url' ? 'link' : undefined}
      preset={preset}
      disabled={disabled}
      onClick={onClick}
      height="auto"
      fontWeight="bold"
      {...props}
    >
      <Text fontSize="var(--font-size-pa)">{text}</Text>
    </StyledLinkButton>
  )
}
