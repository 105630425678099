import { createSlice } from '@reduxjs/toolkit'
import { EStatus, IProduct } from '@unfoldrtech/portal-mic'
import { ICampaign, IAdGroup } from '../../models/interfaces/ICampaignState'
import reducers from './reducers'

const INITIAL_CAMPAIGN_STATE: ICampaign = <ICampaign>{
  adGroupList: [] as Array<IAdGroup>,
  productList: [] as Array<IProduct>,
  status: EStatus.Inactive,
}

export const campaignSlice = createSlice({
  name: 'campaign',
  initialState: INITIAL_CAMPAIGN_STATE,
  reducers: {
    // Redux Toolkit allows us to write "mutating" logic in reducers. It
    // doesn't actually mutate the state because it uses the immer library,
    // which detects changes to a "draft state" and produces a brand new
    // immutable state based off those changes
    resetCampaign: () => INITIAL_CAMPAIGN_STATE,
    ...reducers,
  },
})

export const {
  resetCampaign,
  setCampaignDetails,
  setCampaignSettings,
  setCampaignStatus,
  addCampaignProduct,
  setCampaignProducts,
  removeCampaignProduct,
  addCampaignAdGroup,
  addCampaignProducts,
  removeCampaignAdGroup,
  setCampaignAdGroupCreative,
  removeCampaignAdGroupCreative,
  removeCampaignAdGroupCreatives,
  setCampaignAdGroup,
  addAdGroupProduct,
  addAdGroupPosition,
  addAdGroupProducts,
  addAdGroupAudience,
  setAdGroupDayTimeTarget,
  resetAdGroupDayTimeTarget,
  removeAdGroupProduct,
  removeAdGroupPosition,
  setAdGroupProducts,
  setAdGroupKeywordBidPrice,
  setAdGroupKeywordMatchType,
  setAdGroupKeywordTargetList,
  setAdGroupKeyword,
  removeAdGroupKeywordTarget,
  removeAdGroupCategoryTarget,
  setAdGroupCategoryBidPrice,
  setAdGroupPositionBidPrice,
  insertAdGroupCategoryTargetList,
} = campaignSlice.actions

export const campaignsReducer = campaignSlice.reducer
