import styled, { CSSProperties } from 'styled-components'

import {
  Alignment,
  FontPreset,
  FontStyle,
  Sticky,
  TAlignmentMixin,
  TFontStyleMixin,
  TruncatePreset,
  TStickyProps,
  Padding,
  Border,
  TSizeMixin,
  Size,
  TBorderPropertiesMixin,
  TPaddingMixin,
} from '../styles/mixins'

export const StyledTableHeaderCell = styled.th<
  TFontStyleMixin &
    TAlignmentMixin &
    TStickyProps &
    TSizeMixin &
    TBorderPropertiesMixin &
    TPaddingMixin &
    Pick<CSSProperties, 'background' | 'backgroundColor'>
>`
  &&& {
    box-shadow: none;
    background: ${(props) => props.background || ''};
    background-color: ${(props) =>
      props.backgroundColor || 'var(--background-color-table-header)'};

    ${(props) =>
      Padding({
        padding: props.padding || '0 var(--gutter-2x)',
        paddingLeft: props.paddingLeft || '',
      })}

    ${FontPreset('paragraph')};

    ${(props) =>
      Border({
        border: {
          borderBottomWidth: props.borderBottomWidth || '1px',
          borderBottomStyle: props.borderBottomStyle || 'solid',
          borderBottomColor:
            props.borderBottomColor || 'var(--border-color-default)',
        },
      })};

    ${(props: TFontStyleMixin) =>
      FontStyle({
        lh: '1',
        ...props,
      })};

    ${(props: TAlignmentMixin) =>
      Alignment({
        ...props,
      })};

    ${(props: TStickyProps) =>
      Sticky({
        position: props.position || 'initial',
        zIndex: props.zIndex || 'auto',
        top: props.top || '',
        left: props.left || '',
        right: props.right || '',
        bottom: props.bottom || '',
      })};

    ${(props: TSizeMixin) =>
      Size({
        width: props.width || '',
      })};

    ${TruncatePreset('100%')};
  }
`
