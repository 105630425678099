import styled from 'styled-components'
import Toast from 'react-bootstrap/Toast'
import {
  Border,
  Size,
  Padding,
  TBorderMixin,
  TPaddingMixin,
  TSizeMixin,
} from '../../styles/mixins'

export const StyledToast = styled(Toast)<
  TBorderMixin & TSizeMixin & TPaddingMixin
>`
  ${(props: TBorderMixin) =>
    Border({
      border: props.border,
    })}
  ${(props: TSizeMixin) =>
    Size({
      maxWidth: props.maxWidth,
      width: props.width,
      height: props.height,
    })}

  .toast-body {
    ${(props: TPaddingMixin) =>
      Padding({
        padding: props.padding,
      })}
  }
`
