import { PayloadAction } from '@reduxjs/toolkit'
import { IAdGroup, ICampaign, IProduct } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedAdGroup } from '../utils'

const addAdGroupProduct = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    product: IProduct
    adGroupId: number
  }>
) => {
  const selectedAdGroup =
    getSelectedAdGroup(state, action.payload.adGroupId) || ({} as IAdGroup)
  if (selectedAdGroup.productList === undefined) {
    selectedAdGroup.productList = []
  }

  const isExists = selectedAdGroup?.productList?.findIndex(
    (p) => p.id === action.payload.product.id
  )

  if (isExists === -1) {
    selectedAdGroup?.productList?.push(action.payload.product)
  }

  if (isExists > -1) {
    selectedAdGroup.productList.splice(isExists, 1, action.payload.product)
  }
}
export default addAdGroupProduct
