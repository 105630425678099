import styled from 'styled-components'

import { Action } from '../styles/mixins'
import { StyledStatusBadge } from './bootstrap/StyledStatusBadge'

export const AcceptedBadge = styled(StyledStatusBadge)`
  ${Action({
    background: 'var(--badge-color-active) !important',
    color: 'var(--font-color-light) !important',
  })}
`
