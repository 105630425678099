/* eslint-disable no-console */
import React, { useState, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import * as serviceWorker from '../../serviceWorkerRegistration'
import { Container } from '../Global'
import { ToastCard } from '../ToastCard'
import I18NText from '../I18NText'

const ServiceWorkerWrapper = () => {
  const navigate = useNavigate()
  const location = useLocation()

  const [showReload] = useState(false)
  const [waitingWorker, setWaitingWorker] = useState<ServiceWorker | null>(null)

  const onSWUpdate = (registration: ServiceWorkerRegistration) => {
    setWaitingWorker(registration.waiting)
  }

  const reloadPage = () => {
    // setShowReload(false)
    if (waitingWorker) {
      waitingWorker.postMessage({ type: 'SKIP_WAITING' })
      navigate(0)
    } else {
      console.debug('No waiting sw found')
    }
  }

  useEffect(() => {
    ;(async () => {
      const registration = await navigator?.serviceWorker?.getRegistration()
      if (registration) {
        if (registration.waiting && registration.active) {
          /**
           * The page has been loaded when there's already a waiting and active SW.
           * This would happen if skipWaiting() isn't being called, and there are
           * still old tabs open.
           */
          onSWUpdate(registration)
        } else {
          await registration.update()
        }
      } else {
        console.debug('No sw registration found')
      }
    })()
  }, [location.pathname])

  /**
   * PORT-499
   *  Reload the page automatically if a new version is detected
   *  Do not show a toast message to reload page
   */
  useEffect(() => {
    // setShowReload(true)
    reloadPage()
  }, [waitingWorker])

  /**
   * If you want your app to work offline and load faster, you can change
   * unregister() to register() below. Note this comes with some pitfalls.
   * Learn more about service workers: https://cra.link/PWA
   * serviceWorkerRegistration.register()
   */
  useEffect(() => {
    serviceWorker.register({ onUpdate: onSWUpdate })
    // return () => unlisten()
  }, [])

  return (
    <ToastCard
      show={showReload}
      autohide={false}
      onClose={reloadPage}
      position="bottom-center"
    >
      <Container display="flex" justifyContent="center" alignItems="center">
        <Container alignSelf="center">
          <I18NText id="new.version.description" />
        </Container>
        <Container
          cursor="pointer"
          onClick={reloadPage}
          textDecoration="underline"
        >
          <I18NText id="refresh" />
        </Container>
      </Container>
    </ToastCard>
  )
}

/**
 * Checks for existence of a new service worker on every route change
 * Needs to be here as service worker requires access to window.location
 */
export default ServiceWorkerWrapper
