import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconReviewads = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="m7.138 18.365-4.556.002c-.72 0-1.303-.583-1.303-1.302l-.006-14.32c0-.72.581-1.303 1.301-1.304l9.225-.004c.346 0 .677.137.92.381l2.495 2.492a1.3 1.3 0 0 1 .381.92v1.414"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M5.106 5.723a.326.326 0 1 1 .012.651.326.326 0 0 1-.012-.651m5.247 2.193a.649.649 0 0 0-1.074.007l-1.692 2.505-1.084-.837a.648.648 0 0 0-.945.163l-1.62 3.485 3.254-.06"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M14.47 18.915a4.557 4.557 0 1 0-.165-9.112 4.557 4.557 0 0 0 .164 9.112z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="m21 20.746-3.334-3.217"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgIconReviewads
