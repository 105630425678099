import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError, ITotalItems } from '@unfoldrtech/portal-mic'
import { IReportingChartResponse } from '@unfoldrtech/portal-mic/dist/src/openapi/core/sdk/api'
import { coreAPI } from '../services/coreAPI'
import {
  generateCorrelationId,
  getTZOffset,
  getTimezone,
} from '../utils/helpers'
import {
  TCampaignsReportChartFilters,
  TPPAdvertiserId,
  TPPRetailerId,
} from '../models/types'
import { IReportPageTypes } from '../models/interfaces'

export default function useGetV2AdvertiserReportingCampaignChart({
  advertiserId,
  retailerId,
  channelType,
  aggregationType,
  timeWindow,
  startDate,
  endDate,
  tzOffset,
  tzZone,
  enabled = true,
  suspense = true,
}: {
  enabled?: boolean
  suspense?: boolean
} & TCampaignsReportChartFilters &
  TPPRetailerId &
  TPPAdvertiserId &
  IReportPageTypes) {
  const queryKeyName = `getAdvertiserReportingCampaignChart_${advertiserId}_${retailerId}_${
    channelType || ''
  }`

  return useQuery<
    AxiosResponse<ITotalItems & IReportingChartResponse>,
    AxiosError<IError>
  >(
    [queryKeyName, { start: startDate, end: endDate }],
    ({ queryKey }) => {
      const filters = queryKey[1] || {}
      const { start, end } = filters as { start: string; end: string }

      return coreAPI
        .v2AdvertiserReporting()
        .getAdvertiserCampaignsReportChart(
          advertiserId,
          generateCorrelationId(),
          retailerId,
          channelType,
          timeWindow,
          start,
          end,
          tzOffset || getTZOffset(endDate),
          tzZone || getTimezone(),
          aggregationType
        )
    },
    { suspense, enabled }
  )
}
