import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError, ITotalItems } from '@unfoldrtech/portal-mic'
import {
  EReportColumnHeader,
  IReportingTableResponse,
} from '@unfoldrtech/portal-mic/dist/src/openapi/core/sdk/api'
import { coreAPI } from '../services/coreAPI'
import {
  generateCorrelationId,
  getTZOffset,
  getTimezone,
} from '../utils/helpers'
import {
  TCreativesReportTableFilters,
  TPPAdvertiserId,
  TPPRetailerId,
} from '../models/types'
import { IReportPageTypes } from '../models/interfaces'

export default function useGetV2RetailerReportingCreativesTable({
  advertiserId,
  retailerId,
  campaignIds,
  adGroupIds,
  sortBy,
  timeWindow,
  startDate,
  endDate,
  sortOrder,
  tzOffset,
  tzZone,
  enabled = true,
  suspense = true,
}: {
  enabled?: boolean
  suspense?: boolean
} & TCreativesReportTableFilters &
  TPPRetailerId &
  TPPAdvertiserId &
  IReportPageTypes) {
  const queryKeyName = `getRetailerReportingCreativesTable_${retailerId}_${advertiserId}`

  return useQuery<
    AxiosResponse<ITotalItems & IReportingTableResponse>,
    AxiosError<IError>
  >(
    [queryKeyName, { start: startDate, end: endDate }],
    ({ queryKey }) => {
      const filters = queryKey[1] || {}
      const { start, end } = filters as { start: string; end: string }

      return coreAPI
        .v2RetailerReporting()
        .getRetailerCreativesReportTable(
          retailerId,
          generateCorrelationId(),
          advertiserId,
          campaignIds,
          adGroupIds,
          sortBy as EReportColumnHeader,
          sortOrder,
          timeWindow,
          start,
          end,
          tzOffset || getTZOffset(endDate),
          tzZone || getTimezone()
        )
    },
    { suspense, enabled }
  )
}
