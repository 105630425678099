import styled from 'styled-components'
import { StyledAccordionHeader } from './StyledAccordionHeader'
import { IThemeProps } from '../../../../models/interfaces'
import { TMiscMixin } from '../../styles'
import { Action } from '../../styles/mixins/action'

export const StyledAccordionHeaderSecondary = styled(
  StyledAccordionHeader
)<TMiscMixin>`
  & .accordion-button {
    ${(props: TMiscMixin) =>
      Action({
        cursor: props.cursor || 'pointer',
      })}
  }
  /* & .accordion-button {
    background: ${(props: IThemeProps) =>
    props.theme?.colors?.background?.primary ||
    'var(--background-color-default)'};
  } */
  & .accordion-button:not(.collapsed) {
    background: ${(props: IThemeProps) =>
      props.theme?.colors?.background?.secondary ||
      'var(--background-color-default)'};
    color: ${(props: IThemeProps) =>
      props.theme?.colors?.font?.secondary || 'var(--font-color-secondary)'};
  }
  & .accordion-button:not(.collapsed)::after {
    background-image: ${(props: IThemeProps) =>
      `url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23${
        props.theme?.colors?.font?.secondary?.substring(1) || 'ffffff'
      }'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e")`};
  }
`
