import styled from 'styled-components'

import { FontStyle, TFontStyleMixin } from '../styles/mixins'

export const StyledSuffix = styled.span<TFontStyleMixin>`
  font-size: var(--font-size-pa);
  margin: auto var(--margin-default);
  line-height: calc(var(--padding-default) * 2);

  ${(props: TFontStyleMixin) => FontStyle({ ...props })};
`
