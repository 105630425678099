import {
  IDOOHAdGroupTarget,
  IDOOHAdGroupTargeting,
  IDOOHCampaign,
} from '../../../models'
import { IDOOHAdGroupCustom } from '../../../models/interfaces/IDOOHAdGroupWithLocation'

export const selectDoohCampaignAdGroups = ({
  doohCampaign: campaignState,
}: {
  doohCampaign: IDOOHCampaign & {
    adGroupsList: (IDOOHAdGroupCustom & {
      targeting: IDOOHAdGroupTargeting & {
        screenPosition: IDOOHAdGroupTarget & {
          baseFloorPrice: number
          audienceFloorPrice?: number
          bidPrice: number
        }
        audiencesList: (IDOOHAdGroupTarget & {
          description: string
        })[]
      }
    })[]
  }
}): (IDOOHAdGroupCustom & {
  targeting: IDOOHAdGroupTargeting & {
    screenPosition: IDOOHAdGroupTarget & {
      baseFloorPrice: number
      audienceFloorPrice?: number
      bidPrice: number
    }
    audiencesList: (IDOOHAdGroupTarget & {
      description: string
    })[]
  }
})[] => campaignState.adGroupsList || []
