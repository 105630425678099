import {
  IProductFilterQuery,
  IProductFilterQueryValueRange,
} from '../../models'

export const unwrapPillTitles = ({
  query,
  currencySymbol,
}: {
  query: IProductFilterQuery
  currencySymbol?: string
}) => {
  switch (query.key) {
    case 'brands':
    case 'categories':
      return (query.value as string[]).join(', \n')
    case 'price': {
      const { from, to } = query.value as IProductFilterQueryValueRange
      return `${currencySymbol} ${from} - ${currencySymbol} ${to}`
    }
    case 'stock':
      return query.value ? 'in stock' : 'out of stock'
    default:
      return ''
  }
}
