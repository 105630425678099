import styled, { CSSProperties } from 'styled-components'
import {
  Alignment,
  Border,
  FontPreset,
  FontStyle,
  Sticky,
  TAlignmentMixin,
  TFontStyleMixin,
  TruncatePreset,
  TSizeMixin,
  TStickyProps,
  TBorderMixin,
  Size,
  TFontPresetMixin,
  Padding,
  TPaddingMixin,
} from '../styles/mixins'
// import { TExtraPaddingProps } from '../../../models'

export const VirtualTableStyles = styled.div<{
  table?: CSSProperties
  thead?: TStickyProps &
    TBorderMixin &
    TSizeMixin &
    Pick<CSSProperties, 'background' | 'backgroundColor'>
  tr?: TBorderMixin &
    TSizeMixin &
    Pick<CSSProperties, 'background' | 'backgroundColor'>
  th?: TFontStyleMixin &
    TAlignmentMixin &
    TStickyProps &
    TPaddingMixin &
    Pick<CSSProperties, 'background' | 'backgroundColor'>
  td?: TStickyProps &
    TFontStyleMixin &
    TAlignmentMixin &
    TFontPresetMixin &
    TPaddingMixin &
    Pick<CSSProperties, 'background' | 'backgroundColor'> & { active?: boolean }
}>`
  display: block;
  height: 100%;
  max-width: 100%;
  max-height: 100%;
  position: relative;

  /* This will make the table scrollable when it gets too small */
  .table-wrap {
    display: block;
    height: 100%;
    max-width: 100%;
    max-height: 100%;
    overflow-x: auto;
    overflow-y: hidden;
  }

  .table {
    display: inline-block;
    width: 100%;
    max-width: 100%;
    height: 100%;
    max-height: 100%;
  }

  .thead {
    align-items: center;

    ${(props) => Border({ ...props.thead })};
    background: ${(props) =>
      props.thead?.background || 'var(--background-color-table-header)'};

    ${(props) =>
      Size({
        width: props.thead?.width || '100%',
        height: 'var(--row-height-default)',
        maxHeight: 'var(--row-height-default)',
        ...props.thead,
      })};

    ${(props) => Sticky({ ...props.thead })};

    & > .th:first-child {
      padding-left: var(--gutter-2x) !important;
    }

    & > .th:last-child {
      padding-right: var(--gutter-1x) !important;
    }
  }

  .th {
    box-shadow: none;
    background: ${(props) => props.th?.background || ''};
    background-color: ${(props) =>
      props.th?.backgroundColor || 'var(--background-color-table-header)'};

    ${FontPreset('paragraph')};

    ${Border({
      borderBottom: {
        color: 'var(--border-color-default)',
      },
    })};

    ${(props) =>
      FontStyle({
        lh: '1',
        ...props.th,
      })};

    ${(props) =>
      Alignment({
        ...props.th,
      })};

    ${(props) =>
      Sticky({
        position: props.th?.position || 'initial',
        zIndex: props.th?.zIndex || 'auto',
        top: props.th?.top || '',
        left: props.th?.left || '',
        right: props.th?.right || '',
        bottom: props.th?.bottom || '',
      })};

    ${(props) =>
      Padding({
        ...props.th,
      })};

    ${TruncatePreset('100%')};
  }

  .tr {
    align-items: center;
    background: ${(props) => props.tr?.background || ''};
    background-color: ${(props) => props.tr?.backgroundColor || ''};

    ${(props) =>
      Size({
        height: 'var(--row-height-default)',
        maxHeight: 'var(--row-height-default)',
        ...props.tr,
      })};
    ${(props) => Border({ ...props.tr })};

    & > .td:first-child {
      padding-left: var(--gutter-2x);
    }

    & > .td:last-child {
      padding-right: var(--gutter-1x);
    }
  }

  .td {
    background: ${(props) => props.td?.background || ''};
    background-color: ${(props) => props.td?.backgroundColor || ''};

    ${(props) => FontPreset(props.td?.preset || 'paragraph small opensans')};
    ${(props) =>
      FontStyle({
        ...props.td,
      })}
    ${(props) =>
      Alignment({
        ...props.td,
      })};
    ${(props) =>
      Sticky({
        position: props.td?.position || 'initial',
        zIndex: props.td?.zIndex || 'auto',
        top: props.td?.top || '',
        left: props.td?.left || '',
        right: props.td?.right || '',
        bottom: props.td?.bottom || '',
      })};
    ${(props) =>
      Padding({
        ...props.td,
      })};
    ${TruncatePreset('100%')};
  }
`
