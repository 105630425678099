import styled from 'styled-components'
import Table from 'react-bootstrap/Table'
import {
  TSizeMixin,
  Size,
  TTableMixin,
  TableMixin,
  Padding,
} from '../styles/mixins'
import { TExtraPaddingProps } from '../../../models'

export const StyledTable = styled(Table).withConfig({
  shouldForwardProp: (prop) =>
    !['maxWidth', 'minWidth', 'firstChildPaddingLeft'].includes(prop as string),
})<TSizeMixin & TTableMixin & TExtraPaddingProps>`
  margin: 0;
  table-layout: fixed;

  tr > td:first-child,
  && tr > th:first-child {
    ${(props: TExtraPaddingProps) =>
      Padding({
        paddingLeft: props.firstChildPaddingLeft ?? 'var(--gutter-3x)',
      })}
  }

  tr > td:last-child,
  && tr > th:last-child {
    ${(props: TExtraPaddingProps) =>
      Padding({
        paddingRight: props.lastChildPaddingRight ?? 'var(--gutter-3x)',
      })}
  }

  ${(props: TTableMixin) => TableMixin({ ...props })}

  ${(props: TSizeMixin) => Size({ ...props })};
`
