import React from 'react'
import dayjs from 'dayjs'
import { TooltipProps } from 'recharts'
import styled from 'styled-components'
import { NOT_AVAILABLE } from '../../utils/constants'
import { Container, Text } from '../Global'
import { sanitizeDataTestId } from '../../utils/sanitizeDataTestId'
import { ValueType, NameType } from '../../models/interfaces'

const TooltipContainer = styled(Container)`
  font-family: OpenSans;
  font-size: var(--font-size-ps);
  text-transform: capitalize;
  border-style: hidden;
  border-radius: 2px;
  box-shadow: 0 1px 40px 0 rgba(0, 0, 0, 0.09), 0 1px 1px 0 rgba(0, 0, 0, 0.06);
  background-color: var(--background-color-light);
  padding: var(--gutter-1x);
`

interface IStyledTooltipProps extends TooltipProps<ValueType, NameType> {
  customLabelFormat: string
}

export function StyledTooltip({
  active,
  payload,
  customLabelFormat,
  label,
}: IStyledTooltipProps) {
  if (active) {
    return (
      <TooltipContainer data-testid="tooltip-label">
        <Container marginBottom="var(--gutter-mini)">
          <Text>{dayjs(label).format(customLabelFormat)}</Text>
        </Container>
        <Container data-testid="tooltip-item-list">
          {payload?.map((py) => {
            let value: string
            if (py.value === -1) {
              value = NOT_AVAILABLE
            } else {
              value = `${py.value} ${py.unit}`
            }
            return (
              <Container key={py.dataKey}>
                <Text
                  data-testid={sanitizeDataTestId(
                    `tooltip-item-${py.dataKey}-value`
                  )}
                  color={py.color}
                >{`${py.name}: ${value}`}</Text>
              </Container>
            )
          })}
        </Container>
      </TooltipContainer>
    )
  }

  return null
}
