import React from 'react'
import { TAdPreviewProps } from '../../models/types/TAdPreviewProps'
import { updateAdDataDestinationURL } from '../../utils/helpers'

function CallToAction({ adData, template }: TAdPreviewProps) {
  return (
    /**
     * This button will be rendered natively by the vendors on their website
     * Hence, a native button is used instead of styled button
     */
    <button
      style={{
        height: `${parseInt(template.data.cta.fontSize || '', 10) + 28}px`,
        minWidth: '40px',
        border: 'none',
        backgroundColor: adData.styling.colors?.ctaBackground,
        color: adData.styling.colors?.cta,
        fontFamily: template.data.cta.fontFamily,
        fontSize: template.data.cta.fontSize,
      }}
      onClick={() => {
        window.open(
          updateAdDataDestinationURL({
            adData,
            destinationURLPrefix: template.data.destinationURLPrefix,
          }).destinationURL
        )
      }}
      data-testid="ad-preview-cta-button"
    >
      {adData.cta.title}
    </button>
  )
}

export default CallToAction
