import styled from 'styled-components'
import { Container } from './Container'

export const HoverContainer = styled(Container)<{ isHidden?: boolean }>`
  > .hover-items {
    opacity: 0;
  }

  :hover {
    > .hover-items {
      opacity: 1;
    }
  }
`
