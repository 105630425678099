import styled from 'styled-components'

import { FontPreset, FontStyle, TFontStyleMixin } from '../styles/mixins'
import { Text } from './Text'

export const Paragraph = styled(Text)<TFontStyleMixin>`
  ${FontPreset('paragraph')};
  ${(props: TFontStyleMixin) =>
    FontStyle({
      ...props,
    })}
`
