import {
  EMediaType,
  IDOOHAdGroupData,
  IDOOHCreativeData1,
  IDOOHCreativeWithAdGroups,
} from '@unfoldrtech/portal-mic/dist/src/openapi/core/dooh'

export class CDOOHCreativeWithAdGroups implements IDOOHCreativeWithAdGroups {
  id: number

  advertiserId: number

  title: string

  mediaType: EMediaType

  data: IDOOHCreativeData1

  updatedAt: string

  updatedBy: string

  adgroupsList: Array<IDOOHAdGroupData>

  constructor(
    id: number,
    advertiserId: number,
    title: string,
    mediaType: EMediaType,
    data: IDOOHCreativeData1,
    updatedAt: string,
    updatedBy: string,
    adgroupsList: Array<IDOOHAdGroupData>
  ) {
    this.id = id
    this.advertiserId = advertiserId
    this.title = title
    this.mediaType = mediaType
    this.data = data
    this.updatedAt = updatedAt
    this.updatedBy = updatedBy
    this.adgroupsList = adgroupsList
  }
}
