import { EUserRole, IOrg, IUserProfile } from '@unfoldrtech/portal-mic'
import {
  IFeatureFlagCommonContext,
  IFeatureFlagContext,
  IFeatureFlagOrgContext,
  IFeatureFlagUserContext,
} from '../interfaces'

export class CFeatureFlagContext implements IFeatureFlagContext {
  [kind: string]: 'multi' | IFeatureFlagCommonContext

  kind: 'multi'

  user: IFeatureFlagUserContext

  advertiser!: IFeatureFlagOrgContext

  retailer!: IFeatureFlagOrgContext

  constructor({
    user,
    advertiser,
    retailer,
  }: {
    user: Pick<
      IUserProfile,
      'id' | 'email' | 'firstName' | 'lastName' | 'locale'
    > & { role: EUserRole }

    advertiser: Pick<IOrg, 'id' | 'name' | 'category' | 'region'>

    retailer: Pick<IOrg, 'id' | 'name' | 'category' | 'region'>
  }) {
    this.kind = 'multi'
    this.user = {
      ...user,
      key: `user-${user.id}`,
      kind: 'user',
    }
    if (advertiser?.id) {
      this.advertiser = {
        ...advertiser,
        key: `advertiser-${advertiser.id}`,
        kind: 'advertiser',
      }
    }
    if (retailer?.id) {
      this.retailer = {
        ...retailer,
        key: `retailer-${retailer.id}`,
        kind: 'retailer',
      }
    }
  }
}
