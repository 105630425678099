import { IOrgTheme } from '@unfoldrtech/portal-mic'

export const PORTAL_DEFAULT_THEME: IOrgTheme = {
  colors: {
    border: {
      primary: 'rgb(255,255,255)',
      //   secondary: 'rgb(255,255,255)',
      //   dashed: 'rgba(0, 0, 0, 0.1)',
      //   active: '#c3c3c3',
      //   light: '#ffffff',
      //   dark: '#000000',
      //   error: '#e13c3c',
      //   filterBottom: 'rgba(128, 128, 128, 0.2)',
      //   filterBoxShadow: 'rgba(0, 0, 0, 0.07)',
    },
    background: {
      // primary: '#000000',
      // secondary: 'rgb(0,0,0)',
      // light: '#ffffff',
      dark: '#000000',
      //   disabled: 'rgba(255, 255, 255, 0.5)',
      //   active: '#fafafa',
      //   textarea: 'rgba(235, 235, 235, 0.4)',
      //   confirm: '#ffdc55',
      //   toggle: '#cdcdcd',
      //   tableHeader: '#fafafa',
    },
    button: {
      primary: '#000000',
      //   secondary: '#ffffff',
      //   disabled: 'rgba(0, 0, 0, 0.5)',
    },
    font: {
      primary: '#ffffff',
      secondary: '#2bfeda',
      //   light: '#ffffff',
      //   action: '#000000',
      //   active: 'rgba(0, 0, 0, 0.5)',
      //   url: '#00ade6',
      placeholder: 'rgba(0, 0, 0, 0.5)',
      error: '#e13c3c',
    },
    badge: {
      //   active: '#3bc887',
      //   inactive: '#e13c3c',
      //   disabled: '#ececec',
    },
    chart: {
      grid: {
        // primary: '#c8c8c8',
      },
    },
  },
  sizes: {
    font: {
      h1: '40px',
      h2: '32px',
      h3: '24px',
      h5: '18px',
      pa: '14px',
      ps: '12px',
      xs: '10px',
      sm: '16px',
      no: '24px',
      lg: '36px',
      xl: '48px',
    },
    icon: {
      xs: '8px',
      sm: '16px',
      no: '24px',
      lg: '36px',
      xl: '48px',
    },
    button: {
      xs: '38px',
      sm: '48px',
      no: '56px',
      lg: '68px',
      xl: '76px',
    },
    logo: {
      xs: '48px',
      sm: '56px',
      no: '64px',
      lg: '72px',
      xl: '80px',
    },
  },
  spacing: {
    gutter: 8,
    letter: 0.86,
    row: {
      height: 72,
    },
  },
}
