import { useState, useEffect } from 'react'

export default function useShowToast(condition: boolean) {
  const [showToast, setShowToast] = useState<boolean>(false)
  useEffect(() => {
    setShowToast(condition)
  }, [condition])

  return [showToast, setShowToast] as const
}
