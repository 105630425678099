import React, { useMemo, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { IReportFiltersProps } from '../../models/interfaces'
import { TReportDateRange } from '../../models/types'
import {
  dayjsFormat,
  getDateRanges,
  getEndOfDay,
  getStartOfDay,
  getTodayInMs,
} from '../../utils/helpers'
import {
  FilterContainer,
  Container,
  StyledRangePicker,
  StyledAntDropdown,
  StyledAntMenu,
  IconArrowDownSmall,
  SVGContainer,
} from '../Global'
import I18NText from '../I18NText'
import {
  selectReportingFilters,
  setTimeProperties,
  setTimeWindow,
} from '../../store/reportingFilters'
import { getAggregationType } from '../../utils/helpers/getAggregationType'
import { EParamTimeWindow } from '../../models'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'
import { PORTAL_FF_REPORTING_EXPORT_EXCEL } from '../../utils/constants'

function Filters({
  onDateChangeFn,
  onExportData,
  isDateRangeFilterDisabled,
  isExportDisabled,
}: IReportFiltersProps) {
  const intl = useIntl()
  const dispatch = useDispatch()

  const featureFlags = useFeatureFlags()

  const { startDate, endDate } = useSelector(selectReportingFilters)

  const [dateRanges, setDateRanges] = useState<TReportDateRange>(
    getDateRanges()
  )

  let selectedDateRange: Array<Dayjs> = [dayjs(startDate), dayjs(endDate)]

  const isDateDisabled = useMemo(
    () => (date: dayjs.Dayjs) => {
      const endOfDay = getEndOfDay(getTodayInMs())

      if (date && endOfDay && date.valueOf() > endOfDay.valueOf()) {
        return true
      }

      return false
    },
    [dateRanges]
  )

  const onDateChange = (startDayjs: Dayjs, endDayjs: Dayjs) => {
    const mappedStartDate = getStartOfDay(startDayjs).toJSON()
    const mappedEndDate = getEndOfDay(endDayjs).toJSON()
    dispatch(setTimeWindow({ timeWindow: EParamTimeWindow.Custom }))
    dispatch(
      setTimeProperties({
        startDate: mappedStartDate,
        endDate: mappedEndDate,
        aggregationType: getAggregationType(mappedStartDate, mappedEndDate),
      })
    )
  }

  // Makes the changes backwards compatible with v1 reporting pages
  // New reporting page versions should ideally not pass date change function
  const onDateChangeCallback = onDateChangeFn ?? onDateChange

  return (
    <>
      <Container display="flex">
        <FilterContainer display="flex" alignItems="center">
          <StyledRangePicker
            border={{ style: 'hidden' }}
            height="100%"
            placeholder={[
              intl.formatMessage({
                id: 'from',
              }),
              intl.formatMessage({
                id: 'to',
              }),
            ]}
            disabledDate={isDateDisabled}
            value={[dayjs(startDate), dayjs(endDate)]}
            onChange={(dateRangeValues) => {
              if (dateRangeValues && dateRangeValues[0] && dateRangeValues[1]) {
                selectedDateRange = [dateRangeValues[0], dateRangeValues[1]]
              }
            }}
            onOpenChange={(isOpen) => {
              if (!isOpen) {
                setTimeout(() => {
                  onDateChangeCallback(
                    selectedDateRange[0],
                    selectedDateRange[1]
                  )
                }, 300)
              }
              if (isOpen) {
                setDateRanges(getDateRanges())
              }
            }}
            ranges={dateRanges}
            disabled={isDateRangeFilterDisabled}
            format={dayjsFormat}
            data-testid="range-picker"
          />
        </FilterContainer>
      </Container>
      <Container padding="0 0 0 var(--margin-default)">
        <FilterContainer>
          <StyledAntDropdown
            overlay={
              <StyledAntMenu>
                {Boolean(featureFlags[PORTAL_FF_REPORTING_EXPORT_EXCEL]) && (
                  <StyledAntMenu.Item
                    onClick={() => {
                      if (onExportData) {
                        onExportData({ startDate, endDate }, 'xlsx')
                      }
                    }}
                  >
                    <I18NText id="export.data.xlsx" />
                  </StyledAntMenu.Item>
                )}
                <StyledAntMenu.Item
                  onClick={() => {
                    if (onExportData) {
                      onExportData({ startDate, endDate }, 'csv')
                    }
                  }}
                >
                  <I18NText id="export.data.csv" />
                </StyledAntMenu.Item>
              </StyledAntMenu>
            }
            disabled={isExportDisabled}
          >
            <Container
              preset="paragraph opensans semibold"
              padding="0 var(--padding-default)"
              height="100%"
              display="flex"
              alignItems="center"
            >
              <I18NText id="export" />
              <SVGContainer
                preset="paragraph small"
                margin="0 0 0 var(--margin-default)"
              >
                <IconArrowDownSmall />
              </SVGContainer>
            </Container>
          </StyledAntDropdown>
        </FilterContainer>
      </Container>
    </>
  )
}

export default Filters
