import { PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'

import { getSelectedAdGroup } from '../utils'
import { ICampaign, IAdGroup } from '../../../models/interfaces/ICampaignState'

const addAdGroupAudience = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    audiences: {
      id: number
      name: string
      size: number
    }[]
    adGroupId: number
  }>
) => {
  const selectedAdGroup =
    getSelectedAdGroup(state, action.payload.adGroupId) || ({} as IAdGroup)

  selectedAdGroup.audiencesList = action.payload.audiences
}

export default addAdGroupAudience
