import { PayloadAction } from '@reduxjs/toolkit'
import { ICampaign, IAdGroupKeyword } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedAdGroup } from '../utils'

const setAdGroupKeywordTargetList = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    keywordTargetList: Array<IAdGroupKeyword>
    adGroupId: number
  }>
) => {
  const selectedAdGroup = getSelectedAdGroup(state, action.payload.adGroupId)
  if (selectedAdGroup) {
    selectedAdGroup.keywordsList = action.payload.keywordTargetList
  }
}

export default setAdGroupKeywordTargetList
