import { EOrientation, IDOOHCreativeData } from '@unfoldrtech/portal-mic'

export class CDOOHCreativeDataCreate implements IDOOHCreativeData {
  url: string

  resolution: string

  orientation: EOrientation

  aspectRatio: string

  constructor(url: string, width: number, height: number) {
    this.url = url
    this.resolution = `${width} x ${height}`
    this.orientation =
      width >= height ? EOrientation.Landscape : EOrientation.Portrait
    this.aspectRatio = (width / height).toString()
  }
}
