import { PayloadAction } from '@reduxjs/toolkit'
import { IRefreshTable } from '../../../models/interfaces'

const setRefreshTable = (
  state: IRefreshTable,
  action: PayloadAction<IRefreshTable>
) => {
  state.refreshTable = action.payload.refreshTable
}

export default setRefreshTable
