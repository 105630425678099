import styled from 'styled-components'

import { FontPreset, FontStyle, TFontStyleMixin } from '../styles/mixins'

export const ParagraphSmall = styled.div<TFontStyleMixin>`
  ${FontPreset('paragraph small')};
  ${(props: TFontStyleMixin) =>
    FontStyle({
      ...props,
    })}
`
