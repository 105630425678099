import styled from 'styled-components'
import Form from 'react-bootstrap/Form'

import {
  Background,
  Border,
  FontPreset,
  FontStyle,
  Size,
  TBackgroundMixin,
  TBorderMixin,
  TFontPresetMixin,
  TFontStyleMixin,
  TSizeMixin,
  TPaddingMixin,
} from '../../styles/mixins'

export const StyledTextArea = styled(Form.Control)<
  TBackgroundMixin &
    TSizeMixin &
    TFontStyleMixin &
    TFontPresetMixin &
    TBorderMixin &
    TPaddingMixin
>`
  ${FontPreset('paragraph small opensans')}
  border: none;
  resize: none;
  width: 100%;
  height: 100%;
  text-transform: unset;
  background: transparent;
  padding: ${(props: TPaddingMixin) =>
    props.padding || 'var(--margin-default) 0 0 var(--margin-default)'};
  ${(props: TBackgroundMixin) => Background({ ...props })};
  ${(props: TSizeMixin) => Size({ ...props })};
  ${(props: TFontPresetMixin) => FontPreset(props.preset)};
  ${(props: TFontStyleMixin) => FontStyle({ ...props })};
  ${(props: TBorderMixin) => Border({ ...props })};
`
