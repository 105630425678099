import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconList = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M.75 2.25a1.5 1.5 0 0 1 1.5-1.5h19.5a1.5 1.5 0 0 1 1.5 1.5v19.5a1.5 1.5 0 0 1-1.5 1.5H2.25a1.5 1.5 0 0 1-1.5-1.5V2.25z" />
      <path d="m12 4.5-4.5 6-3-3m9.75.75h4.5M12 13.5l-4.5 6-3-3m9.75.75h4.5" />
    </g>
  </svg>
)

export default SvgIconList
