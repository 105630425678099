import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconArrowBack = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 14 10"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M13 4.75H1.25M5 8.5 1.25 4.75 5 1" />
    </g>
  </svg>
)

export default SvgIconArrowBack
