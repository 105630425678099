import { EInventoryType } from '@unfoldrtech/portal-mic'
import React from 'react'
import I18NText from '../../I18NText'

export function CurrentInventoryTypeText({
  inventoryType,
}: {
  inventoryType?: EInventoryType
}) {
  if (inventoryType === EInventoryType.Category) {
    return (
      <>
        <I18NText id="categories" />
      </>
    )
  }
  if (inventoryType === EInventoryType.Keyword) {
    return (
      <>
        <I18NText id="keywords" />
      </>
    )
  }
  return <> </>
}
