import { PayloadAction } from '@reduxjs/toolkit'
import { IAdGroup, ICampaign } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedAdGroup } from '../utils'

const removeAdGroupProduct = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{ productId: number; adGroupId: number }>
) => {
  const selectedAdGroup =
    getSelectedAdGroup(state, action.payload.adGroupId) || ({} as IAdGroup)

  if (selectedAdGroup.productList === undefined) {
    selectedAdGroup.productList = []
  }
  const index = selectedAdGroup.productList.findIndex(
    (product) => product.id === action.payload.productId
  )
  if (index !== undefined && index > -1) {
    selectedAdGroup.productList.splice(index, 1)
  }
}

export default removeAdGroupProduct
