import { useQuery, UseQueryResult } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import { EChannelTypeAllAndDooh } from '@unfoldrtech/portal-mic/dist/src/openapi/core'

import { coreAPI } from '../services/coreAPI'
import { generateCorrelationId } from '../utils/helpers'

interface Params {
  advertiserId: number
  retailerId: number
  enabled?: boolean
}

export default function useGetAdvertiserChannelTargets({
  advertiserId,
  retailerId,
  enabled = true,
}: Params): UseQueryResult<
  AxiosResponse<EChannelTypeAllAndDooh[]>,
  AxiosError<IError>
> {
  return useQuery(
    [`getAdvertiserChannelTargets_${advertiserId}_${retailerId}`],
    () =>
      coreAPI
        .v2AdvertiserCampaigns()
        .getAdvertiserPermittedChannels(
          advertiserId,
          retailerId,
          generateCorrelationId()
        ),
    { enabled, suspense: true }
  )
}
