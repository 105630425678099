import { EParamChannelType } from '@unfoldrtech/portal-mic'
import { EParamChannelTypeGlobal } from '../../models/core'

export function convertEParamChannelTypeToEParamChannelTypeGlobal(
  channelType?: EParamChannelType
) {
  switch (channelType) {
    case EParamChannelType.SponsoredProduct:
      return EParamChannelTypeGlobal.SponsoredProduct
    case EParamChannelType.Da:
      return EParamChannelTypeGlobal.DisplayAd
    case EParamChannelType.Dooh:
      return EParamChannelTypeGlobal.DigitalOutOfHome
    default:
      break
  }
}
