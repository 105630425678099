import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { ThemeProvider } from 'styled-components'
import { AppContext } from '../../models/contexts'
import { sanitizeTheme } from '../../utils/sanitizeTheme'
import { ORG_DEFAULT_THEME, PORTAL_DEFAULT_THEME } from '../Global'

function StyledTheme({ children }: { children?: ReactNode | ReactNode[] }) {
  const [appContext] = useContext(AppContext)
  const [theme, setTheme] = useState(ORG_DEFAULT_THEME)

  useEffect(() => {
    if (appContext.userOrg?.data.theme) {
      setTheme(appContext.userOrg.data.theme)
    } else if (appContext.isLoggedIn) {
      setTheme(ORG_DEFAULT_THEME)
    } else {
      setTheme(PORTAL_DEFAULT_THEME)
    }
  }, [appContext.isLoggedIn, appContext.userOrg])

  return (
    <>
      <ThemeProvider theme={sanitizeTheme(theme)}>{children}</ThemeProvider>
    </>
  )
}

export default StyledTheme
