import dayjs from 'dayjs'
import { dayjsFormat } from './dayjsFormat'
import { getEndOfDay } from './getEndOfDay'
import { getStartOfDay } from './getStartOfDay'

export const getLabelFormat = (
  start: string | number,
  end: string | number
): string => {
  const DAY_IN_HOURS = 24
  const diff = dayjs(getEndOfDay(end)).diff(getStartOfDay(start), 'hour')

  if (diff <= DAY_IN_HOURS) {
    return dayjsFormat
  }
  if (diff > DAY_IN_HOURS && diff <= 7 * DAY_IN_HOURS) {
    return dayjsFormat
  }
  return dayjsFormat
}
