import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconLaunch = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 80 80"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="m77.927 2.729-7.847.869a27.295 27.295 0 0 0-16.273 7.825L18.145 47.081l15.419 15.426 35.669-35.658a27.27 27.27 0 0 0 7.822-16.277l.872-7.843z"
      stroke="#00ADE6"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M18.146 47.082 4.975 42.69a2.72 2.72 0 0 1-1.066-4.512l.844-.844A21.817 21.817 0 0 1 27.08 32.06l4.564 1.52-13.498 13.502z"
      stroke="#00ADE6"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="M18.146 47.082 4.975 42.69a2.72 2.72 0 0 1-1.066-4.512l.844-.844A21.817 21.817 0 0 1 27.08 32.06l4.564 1.52-13.498 13.502zm15.417 15.426 4.393 13.171a2.718 2.718 0 0 0 1.96 1.793 2.717 2.717 0 0 0 2.552-.727l.848-.84a21.823 21.823 0 0 0 5.269-22.331l-1.513-4.546-13.509 13.48z"
      stroke="#00ADE6"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      clipRule="evenodd"
      d="m33.563 62.508 4.393 13.171a2.718 2.718 0 0 0 1.96 1.793 2.717 2.717 0 0 0 2.552-.727l.848-.84a21.823 21.823 0 0 0 5.269-22.331l-1.513-4.546-13.509 13.48z"
      stroke="#00ADE6"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M24.396 64.44a8.15 8.15 0 0 1-2.397 5.786c-3.192 3.193-19.272 7.713-19.272 7.713s4.52-16.091 7.712-19.273a8.153 8.153 0 0 1 5.251-2.382"
      stroke="#00ADE6"
      strokeWidth={4}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgIconLaunch
