import { IDimensions } from '@unfoldrtech/portal-mic'
import { TOrientationOptions } from '../../models'

export interface IScaledDimensions extends IDimensions {
  scale: number
  orientation: TOrientationOptions
}

export const getScaledDimensions = (
  containerDimensions: IDimensions,
  itemDimensions: IDimensions
): IScaledDimensions => {
  const parsedContainerDimensions = {
    width: +containerDimensions.width.split('px')[0],
    height: +containerDimensions.height.split('px')[0],
  }

  const parsedItemDimensions = {
    width: +itemDimensions.width.split('px')[0],
    height: +itemDimensions.height.split('px')[0],
  }

  const delta = {
    width: parsedContainerDimensions.width - parsedItemDimensions.width,
    height: parsedContainerDimensions.height - parsedItemDimensions.height,
  }

  let scaleDeterminant: keyof IDimensions = 'width'
  let noneScaleDeterminant: keyof IDimensions = 'height'
  let orientation: TOrientationOptions = 'landscape'

  if (delta.height < delta.width) {
    scaleDeterminant = 'height'
    noneScaleDeterminant = 'width'
  }

  let scale = 1

  if (delta.height < 0 || delta.width < 0) {
    scale =
      parsedContainerDimensions[scaleDeterminant] /
      parsedItemDimensions[scaleDeterminant]
  }

  const computedDimensions = {
    width: parsedItemDimensions.width * scale,
    height: parsedItemDimensions.height * scale,
  }

  const isNonScaledDeterminantLargerThanContainer =
    computedDimensions[noneScaleDeterminant] >
    parsedContainerDimensions[noneScaleDeterminant]

  if (isNonScaledDeterminantLargerThanContainer) {
    const newScale =
      parsedContainerDimensions[noneScaleDeterminant] /
      computedDimensions[noneScaleDeterminant]

    computedDimensions.height *= newScale
    computedDimensions.width *= newScale
    scale *= newScale
  }

  if (computedDimensions.height > computedDimensions.width) {
    orientation = 'portrait'
  }

  const scaledDimensions: IScaledDimensions = {
    width: `${computedDimensions.width}px`,
    height: `${computedDimensions.height}px`,
    scale,
    orientation,
  }

  return scaledDimensions
}
