import React from 'react'
import { Container, StyledImage } from '../../../Global'

export const TableDataCellImage = ({ dataValue }: { dataValue?: string }) => {
  return (
    <Container display="flex" alignItems="center">
      <Container flex="1">&nbsp;</Container>
      <StyledImage src={dataValue as string} width="var(--icon-size-xl)" />
      <Container flex="1">&nbsp;</Container>
    </Container>
  )
}
