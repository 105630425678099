import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconCircleCheck = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 14"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.5 7.25 5.75 9.5l4.5-4.5"
      stroke="currentColor"
      strokeWidth={1.5}
    />
    <path
      clipRule="evenodd"
      d="M7 12.625a5.625 5.625 0 1 0 0-11.25 5.625 5.625 0 0 0 0 11.25z"
      stroke="currentColor"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgIconCircleCheck
