import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconAdCreate = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      clipRule="evenodd"
      d="M3.934 2.467C3.934 1.657 4.59 1 5.4 1h16.134C22.344 1 23 1.657 23 2.467V18.6c0 .81-.657 1.467-1.466 1.467H5.4c-.81 0-1.466-.657-1.466-1.467V2.467z"
      stroke="#00ADE6"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.067 23h-17.6C1.657 23 1 22.343 1 21.533v-17.6M14.2 5.4v8.8M9.8 9.8h8.8"
      stroke="#00ADE6"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgIconAdCreate
