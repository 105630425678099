/**
 * This function rounds numbers to given decimal digits unaffected by floating point precision errors.
 * @param n - The number to round
 * @param digits - The precision to round
 * @returns The rounded number
 *
 * ```ts
 * const roundedNumber = roundTo(1.005, 2)
 * console.log(roundedNumber) // 1.01
 * ```
 */
export function roundTo(n: number, digits: number = 0): number {
  const multiplicator = 10 ** digits
  const number = parseFloat((n * multiplicator).toFixed(11))
  return Math.round(number) / multiplicator
}
