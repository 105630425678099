import { css, CSSProperties } from 'styled-components'

export type TPaddingMixin = Pick<
  CSSProperties,
  'padding' | 'paddingTop' | 'paddingRight' | 'paddingBottom' | 'paddingLeft'
>

export const Padding = ({
  padding,
  paddingTop,
  paddingRight,
  paddingBottom,
  paddingLeft,
}: TPaddingMixin) => css`
  padding: ${padding};
  padding-top: ${paddingTop};
  padding-right: ${paddingRight};
  padding-bottom: ${paddingBottom};
  padding-left: ${paddingLeft};
`
