import { IError } from '@unfoldrtech/portal-mic'
import { AxiosResponse, AxiosError } from 'axios'
import { useMutation } from 'react-query'
import { coreAPI } from '../services/coreAPI'
import firebaseAuth from '../services/firebaseAuth'
import { generateCorrelationId } from '../utils/helpers'

export default function useLogout() {
  return useMutation<AxiosResponse<void>, AxiosError<IError>>([`logout`], () =>
    coreAPI
      .misc({ accessToken: firebaseAuth.getIdToken() })
      .logout(generateCorrelationId())
  )
}
