import React, { ReactNode, Suspense, useContext, useEffect } from 'react'
import { IError } from '@unfoldrtech/portal-mic'
import { AxiosError } from 'axios'
import { ErrorBoundary } from 'react-error-boundary'
import { QueryErrorResetBoundary } from 'react-query'
import { useNavigate } from 'react-router-dom'
import firebaseAuth from '../../services/firebaseAuth'
import useLogout from '../../hooks/useLogout'
import Loading from '../Loading'
import I18NText from '../I18NText'
import { Container } from '../Global'
import { PageLinkButton } from '../Global/buttons'
import { ToastCard } from '../ToastCard'
import { ErrorToast } from '../ToastCard/ErrorToast'
import { TTranslationKey } from '../../models/types'
import { AppContext, RetailerContext } from '../../models/contexts'
import { getHomePageURL } from '../../utils/helpers/getHomePageURL'
import { WarningToast } from '../ToastCard/WarningToast'

const ExpiredTokenToast = ({
  onClose,
}: {
  onClose: () => void | Promise<void>
}) => {
  return (
    <ToastCard
      display="none"
      delay={5}
      onClose={async () => {
        await onClose()
      }}
    >
      <Container justifyContent="center" display="flex">
        <Container
          margin="0 var(--gutter-default) 0 0"
          alignSelf="center"
          flex="1"
        >
          <I18NText id="error.tokenExpired" />
        </Container>
      </Container>
    </ToastCard>
  )
}

const ForbiddenToast = ({ onClick }: { onClick: () => void }) => {
  return (
    <ToastCard autohide={false}>
      <Container justifyContent="center" display="flex">
        <Container
          margin="0 var(--gutter-default) 0 0"
          alignSelf="center"
          flex="1"
        >
          <I18NText id="error.not.authorised" />
        </Container>
        <Container>&nbsp;</Container>
        <PageLinkButton
          variant="link"
          onClick={() => onClick()}
          backgroundColor="transparent"
        >
          <I18NText id="logout" color="var(--font-color-dark)" />
        </PageLinkButton>
      </Container>
    </ToastCard>
  )
}

function SuspenseErrorBoundary({
  children,
}: {
  children: ReactNode | ReactNode[]
}) {
  const navigate = useNavigate()

  const [appContext] = useContext(AppContext)
  const retailerContext = useContext(RetailerContext)

  const { mutate: logout, isSuccess, isError, isLoading } = useLogout()

  const onCloseExpiredToastHandler = async () => {
    await firebaseAuth.getIdToken(true)
  }

  const onClickForbiddenToastHandler = () => {
    logout()
  }

  const onClickNotAuthenticatedHandler = () => {
    logout()
  }

  const onClickUnknownHandler = () => {
    const homeURL = getHomePageURL({ appContext, retailerContext })
    navigate(homeURL)
  }

  useEffect(() => {
    if (isSuccess) {
      ;(async () => {
        await firebaseAuth.logout()
      })()
    }
  }, [isSuccess])

  useEffect(() => {
    if (isError) {
      ;(async () => {
        await firebaseAuth.logout()
      })()
    }
  }, [isError])

  return (
    <>
      <QueryErrorResetBoundary>
        {({ reset }) => (
          <ErrorBoundary
            fallbackRender={({ error, resetErrorBoundary }) => {
              if (
                (error as AxiosError<IError>).response?.status === 400 &&
                (error as AxiosError<IError>).response?.data?.message ===
                  'error.user.request.mfa'
              ) {
                navigate('/mfa')
                resetErrorBoundary()
              }
              const translationKey = (error as AxiosError<IError>).response
                ?.data.errorKey as unknown as TTranslationKey

              return (
                <Container>
                  {(error as AxiosError<IError>).response?.status === 498 && (
                    <ExpiredTokenToast
                      onClose={async () => {
                        resetErrorBoundary()
                        await onCloseExpiredToastHandler()
                      }}
                    />
                  )}
                  {(error as AxiosError<IError>).response?.status === 403 && (
                    <ForbiddenToast
                      onClick={() => {
                        resetErrorBoundary()
                        onClickForbiddenToastHandler()
                      }}
                    />
                  )}
                  {(error as AxiosError<IError>).response?.status === 401 && (
                    <WarningToast
                      onClick={() => {
                        resetErrorBoundary()
                        onClickNotAuthenticatedHandler()
                      }}
                      autohide={false}
                      translationKey="error.user.inactivity"
                    />
                  )}
                  {!(
                    (error as AxiosError<IError>).response?.status === 401 ||
                    (error as AxiosError<IError>).response?.status === 403 ||
                    (error as AxiosError<IError>).response?.status === 498
                  ) && (
                    <ErrorToast
                      onClose={() => {
                        resetErrorBoundary()
                        onClickUnknownHandler()
                      }}
                      translationKey={translationKey}
                    />
                  )}
                </Container>
              )
            }}
            onReset={reset}
          >
            <Suspense
              fallback={
                <Container textAlign="center" flex="1" width="100%">
                  <I18NText id="loadingSuspense" />
                </Container>
              }
            >
              {children}
            </Suspense>
          </ErrorBoundary>
        )}
      </QueryErrorResetBoundary>
      <Loading show={isLoading} />
    </>
  )
}

export default SuspenseErrorBoundary
