import React from 'react'
import Form from 'react-bootstrap/Form'
import styled, { CSSProperties } from 'styled-components'
import { IInputProps, IThemeProps } from '../../../models/interfaces'
import {
  Alignment,
  Border,
  FontPreset,
  FontStyle,
  Size,
  TAlignmentMixin,
  TFontStyleMixin,
  TruncatePreset,
  TSizeMixin,
} from '../styles/mixins'

const FormInput = React.forwardRef((props: IInputProps, ref) => (
  <Form.Control {...props} ref={ref} />
))

export const StyledFormInput = styled(FormInput).withConfig({
  shouldForwardProp: (prop) =>
    ![
      'borderLeft',
      'borderRight',
      'borderTop',
      'borderBottom',
      'border',
      'backgroundColor',
      'textAlign',
      'maxWidth',
      'minWidth',
      'paddingLeft',
      'paddingRight',
      'textTransform',
    ].includes(prop as string),
})<
  IInputProps &
    TSizeMixin &
    TFontStyleMixin &
    TAlignmentMixin &
    Pick<CSSProperties, 'padding' | 'paddingLeft' | 'paddingRight'>
>`
  && {
    background: inherit;
    padding: ${(props) => props.padding || ''};
    padding-left: ${(props) => props.paddingLeft || 'var(--gutter-4x)'};
    padding-right: ${(props) => props.paddingRight || 'var(--gutter-4x)'};

    ${FontPreset('paragraph medium')}

    ${(props: TFontStyleMixin) =>
      FontStyle({
        textTransform: 'none',
        ...props,
      })};
    ${Border({
      border: {
        style: 'hidden',
      },
    })};
    ${(props: TSizeMixin) =>
      Size({
        width: '100%',
        ...props,
      })};
    ${(props: TAlignmentMixin) =>
      Alignment({
        ...props,
      })};

    // Hide Increment/Decrement buttons for Number Inputs
    &::-webkit-outer-spin-button,
    ::-webkit-inner-spin-button {
      -webkit-appearance: none;
      margin: 0;
    }

    // Hide auto complete background color from browser
    &:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
      -webkit-background-clip: text;
    }

    &::placeholder {
      opacity: 0.5;
      color: ${(props: IThemeProps) =>
        props.theme?.colors?.font?.primary || 'var(--font-color-default)'};
      ${TruncatePreset('100%')};
    }

    &[disabled] {
      background-color: unset;
    }
  }
`
