import { css } from 'styled-components'
import { IThemeProps } from '../../../../models/interfaces'
import { FontStyle } from './fontStyle'

export type TFontPresets =
  | 'h1'
  | 'h2'
  | 'h3'
  | 'h3 regular'
  | 'h5'
  | 'nav title'
  | 'nav items'
  | 'paragraph'
  | 'paragraph medium'
  | 'paragraph regular'
  | 'paragraph small'
  | 'paragraph opensans'
  | 'paragraph opensans bold'
  | 'paragraph opensans semibold'
  | 'paragraph small opensans'
  | 'paragraph small opensans semibold'
  | 'button'
  | 'placeholder'
  | 'error'
  | 'url'
  // v2 - Archivo
  | 'h1-header'
  | 'h2-web-header'
  | 'h2-header'
  | 'h3-header'
  | 'h3-header-alt'
  | 'nav-title'
  | 'formfield-lg'
  | 'cta-lg'
  | 'h5-header'
  | 'subtitle-black'
  | 'subtitle-semibold'
  | 'subtitle'
  | 'cta-pagelink'
  | 'cta-sm'
  | 'paragraph-semibold'
  | 'nav-items'
  | 'formfield'
  | 'paragraph-regular'
  | 'formfield-sm'
  | 'success'
  | 'error'
  | 'label'
  | 'paragraph-sm'
  | 'table-title'
export type TFontPresetMixin = {
  preset?: TFontPresets
}

export const FontPreset = (preset?: TFontPresets) => css<IThemeProps>`
  ${(props: IThemeProps) =>
    preset === 'h1' &&
    FontStyle({
      fontFamily: 'GalanoGrotesque-SemiBold',
      fontSize: 'var(--font-size-h1)',
      lh: 'calc(var(--font-size-h1) + 2px)',
      color: props?.theme?.colors?.font?.primary || 'var(--font-color-default)',
    })};
  ${(props: IThemeProps) =>
    preset === 'h2' &&
    FontStyle({
      fontFamily: 'GalanoGrotesque-SemiBold',
      fontSize: 'var(--font-size-h2)',
      lh: 'calc(var(--font-size-h2) + 2px)',
      color: props?.theme?.colors?.font?.primary || 'var(--font-color-default)',
    })};
  ${(props: IThemeProps) =>
    preset === 'h3' &&
    FontStyle({
      fontFamily: 'GalanoGrotesque-SemiBold',
      fontSize: 'var(--font-size-h3)',
      lh: 'calc(var(--font-size-h3) + var(--gutter-default))',
      color: props?.theme?.colors?.font?.primary || 'var(--font-color-default)',
    })};
  ${(props: IThemeProps) =>
    preset === 'h3 regular' &&
    FontStyle({
      fontFamily: 'GalanoGrotesque-Regular',
      fontSize: 'var(--font-size-h3)',
      lh: 'calc(var(--font-size-h3) + var(--gutter-default))',
      color: props?.theme?.colors?.font?.primary || 'var(--font-color-default)',
    })};
  ${(props: IThemeProps) =>
    preset === 'h5' &&
    FontStyle({
      fontFamily: 'GalanoGrotesque-SemiBold',
      fontSize: 'var(--font-size-h5)',
      lh: 'calc(var(--font-size-h5) + 6px)',
      color: props?.theme?.colors?.font?.primary || 'var(--font-color-default)',
    })};
  ${(props: IThemeProps) =>
    preset === 'paragraph' &&
    FontStyle({
      fontFamily: 'GalanoGrotesque-SemiBold',
      fontSize: 'var(--font-size-pa)',
      lh: '0',
      color: props?.theme?.colors?.font?.primary || 'var(--font-color-default)',
    })};
  ${(props: IThemeProps) =>
    preset === 'paragraph medium' &&
    FontStyle({
      fontFamily: 'GalanoGrotesque-Medium',
      fontSize: 'var(--font-size-pa)',
      lh: 'calc(var(--padding-default) * 2)',
      color: props?.theme?.colors?.font?.primary || 'var(--font-color-default)',
      //
    })};
  ${(props: IThemeProps) =>
    preset === 'paragraph regular' &&
    FontStyle({
      fontFamily: 'GalanoGrotesque-Regular',
      fontSize: 'var(--font-size-pa)',
      lh: '0',
      color: props?.theme?.colors?.font?.primary || 'var(--font-color-default)',
    })};
  ${(props: IThemeProps) =>
    preset === 'paragraph opensans' &&
    FontStyle({
      fontFamily: 'OpenSans',
      fontSize: 'var(--font-size-pa)',
      lh: 'var(--padding-default)',
      color: props?.theme?.colors?.font?.primary || 'var(--font-color-default)',
    })};
  ${(props: IThemeProps) =>
    preset === 'paragraph opensans bold' &&
    FontStyle({
      fontFamily: 'OpenSans-Bold',
      fontSize: 'var(--font-size-pa)',
      lh: 'var(--padding-default)',
      color: props?.theme?.colors?.font?.primary || 'var(--font-color-default)',
    })};
  ${(props: IThemeProps) =>
    preset === 'paragraph opensans semibold' &&
    FontStyle({
      fontFamily: 'OpenSans-SemiBold',
      fontSize: 'var(--font-size-pa)',
      lh: 'var(--padding-default)',
      color: props?.theme?.colors?.font?.primary || 'var(--font-color-default)',
    })};
  ${(props: IThemeProps) =>
    preset === 'paragraph small opensans' &&
    FontStyle({
      fontFamily: 'OpenSans',
      fontSize: 'var(--font-size-ps)',
      lh: 'calc(var(--font-size-ps) + var(--gutter-default))',
      color: props?.theme?.colors?.font?.primary || 'var(--font-color-default)',
    })};
  ${(props: IThemeProps) =>
    preset === 'paragraph small opensans semibold' &&
    FontStyle({
      fontFamily: 'OpenSans-SemiBold',
      fontSize: 'var(--font-size-ps)',
      lh: 'calc(var(--font-size-ps) + var(--gutter-default))',
      color: props?.theme?.colors?.font?.primary || 'var(--font-color-default)',
    })};
  ${(props: IThemeProps) =>
    preset === 'paragraph small' &&
    FontStyle({
      fontFamily: 'GalanoGrotesque-SemiBold',
      fontSize: 'var(--font-size-ps)',
      lh: 'var(--padding-default)',
      color: props?.theme?.colors?.font?.primary || 'var(--font-color-default)',
    })};
  ${(props: IThemeProps) =>
    preset === 'nav title' &&
    FontStyle({
      fontFamily: 'GalanoGrotesque-SemiBold',
      fontSize: '20px',
      lh: 'calc(var(--padding-default) * 2)',
      color:
        props?.theme?.colors?.font?.secondary || 'var(--font-color-secondary)',
    })};
  ${(props: IThemeProps) =>
    preset === 'nav items' &&
    FontStyle({
      fontFamily: 'GalanoGrotesque-Medium',
      fontSize: 'var(--font-size-pa)',
      lh: 'calc(var(--padding-default) * 2)',
      color:
        props?.theme?.colors?.font?.secondary || 'var(--font-color-secondary)',
    })};
  ${(props: IThemeProps) =>
    preset === 'error' &&
    FontStyle({
      fontFamily: 'OpenSans-SemiBold',
      fontSize: 'var(--font-size-ps)',
      color: props?.theme?.colors?.font?.error || 'var(--font-color-error)',
      textTransform: 'none',
    })};
  ${(props: IThemeProps) =>
    preset === 'button' &&
    FontStyle({
      fontFamily: 'GalanoGrotesque-Bold',
      fontSize: 'var(--font-size-ps)',
      lh: 'calc(var(--padding-default) * 2)',
      color: props?.theme?.colors?.font?.action || 'var(--font-color-action)',
    })};
  ${(props: IThemeProps) =>
    preset === 'placeholder' &&
    FontStyle({
      fontFamily: 'OpenSans-SemiBold',
      fontSize: 'var(--font-size-ps)',
      color: props?.theme?.colors?.font?.primary
        ? `${props?.theme?.colors?.font?.primary}80`
        : 'var(--font-color-placeholder)',
    })};
  ${preset === 'url' &&
  FontStyle({
    fontFamily: 'OpenSans',
    fontSize: 'var(--font-size-ps)',
    color: 'var(--font-color-url)',
    lineHeight: 'initial',
    textTransform: 'none',
  })};

  // v2 - Archivo
  ${preset === 'h1-header' &&
  FontStyle({
    fontFamily: 'Archivo-SemiBold',
    fontSize: 'var(--font-size-h1)',
    lineHeight: 1.2,
    letterSpacing: 'normal',
  })}

  ${preset === 'h2-web-header' &&
  FontStyle({
    fontFamily: 'Archivo-Black',
    fontSize: 'var(--font-size-h2)',
    lineHeight: 1.25,
    letterSpacing: 'normal',
  })}

  ${preset === 'h2-header' &&
  FontStyle({
    fontFamily: 'Archivo-SemiBold',
    fontSize: 'var(--font-size-h2)',
    lineHeight: 1.25,
    letterSpacing: 'normal',
  })}

  ${preset === 'h3-header' &&
  FontStyle({
    fontFamily: 'Archivo-SemiBold',
    fontSize: 'var(--font-size-h3)',
    lineHeight: 1.17,
    letterSpacing: 'normal',
  })}

  ${preset === 'h3-header-alt' &&
  FontStyle({
    fontFamily: 'Archivo-Light',
    fontSize: 'var(--font-size-h3)',
    lineHeight: 1.17,
    letterSpacing: 'normal',
  })}

  ${preset === 'nav-title' &&
  FontStyle({
    fontFamily: 'Archivo-SemiBold',
    fontSize: '20px',
    lineHeight: 1.4,
    letterSpacing: 'normal',
  })}

  ${preset === 'formfield-lg' &&
  FontStyle({
    fontFamily: 'Archivo-Regular',
    fontSize: '20px',
    lineHeight: 1.2,
    letterSpacing: 'normal',
  })}

  ${preset === 'cta-lg' &&
  FontStyle({
    fontFamily: 'Archivo-Black',
    fontSize: 'var(--font-size-h5)',
    lineHeight: 1.11,
    letterSpacing: 'normal',
  })}

  ${preset === 'h5-header' &&
  FontStyle({
    fontFamily: 'Archivo-SemiBold',
    fontSize: 'var(--font-size-h5)',
    lineHeight: 1.33,
    letterSpacing: 'normal',
  })}

  ${preset === 'subtitle-black' &&
  FontStyle({
    fontFamily: 'Archivo-Black',
    fontSize: 'var(--font-size-sm)',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    // textAlign: 'left',
  })}

  ${preset === 'subtitle-semibold' &&
  FontStyle({
    fontFamily: 'Archivo-SemiBold',
    fontSize: 'var(--font-size-sm)',
    lineHeight: 1.5,
    letterSpacing: 'normal',
    // textAlign: 'left',
  })}

  ${preset === 'subtitle' &&
  FontStyle({
    fontFamily: 'Archivo-Regular',
    fontSize: 'var(--font-size-sm)',
    lineHeight: 1.5,
    letterSpacing: 'normal',
  })}

  ${preset === 'cta-pagelink' &&
  FontStyle({
    fontFamily: 'Archivo-Light',
    fontSize: 'var(--font-size-sm)',
    lineHeight: 1.75,
    letterSpacing: 'normal',
    // textAlign: 'left',
  })}

  ${preset === 'cta-sm' &&
  FontStyle({
    fontFamily: 'Archivo-Black',
    fontSize: 'var(--font-size-pa)',
    lineHeight: 1.43,
    letterSpacing: 'normal',
  })}

  ${preset === 'paragraph-semibold' &&
  FontStyle({
    fontFamily: 'Archivo-SemiBold',
    fontSize: 'var(--font-size-pa)',
    lineHeight: 1.71,
    letterSpacing: 'normal',
  })}

  ${preset === 'nav-items' &&
  FontStyle({
    fontFamily: 'Archivo-Medium',
    fontSize: 'var(--font-size-pa)',
    lineHeight: 1.71,
    letterSpacing: 'normal',
    // textAlign: 'left',
  })}

  ${preset === 'formfield' &&
  FontStyle({
    fontFamily: 'Archivo-Regular',
    fontSize: 'var(--font-size-pa)',
    lineHeight: 1.43,
    letterSpacing: 'normal',
  })}

  ${preset === 'paragraph-regular' &&
  FontStyle({
    fontFamily: 'Archivo-Regular',
    fontSize: 'var(--font-size-pa)',
    lineHeight: 1.71,
    letterSpacing: 'normal',
  })}

  ${preset === 'formfield-sm' &&
  FontStyle({
    fontFamily: 'Archivo-Regular',
    fontSize: 'var(--font-size-ps)',
    lineHeight: 1.33,
    letterSpacing: 'normal',
  })}

  ${preset === 'success' &&
  FontStyle({
    fontFamily: 'Archivo-SemiBold',
    fontSize: 'var(--font-size-xs)',
    lineHeight: 1.4,
    letterSpacing: 'normal',
  })}

  ${preset === 'error' &&
  FontStyle({
    fontFamily: 'Archivo-SemiBold',
    fontSize: 'var(--font-size-xs)',
    lineHeight: 1.4,
    letterSpacing: 'normal',
  })}

  ${preset === 'label' &&
  FontStyle({
    fontFamily: 'Archivo-Regular',
    fontSize: 'var(--font-size-xs)',
    lineHeight: 1.2,
    letterSpacing: 'normal',
  })}

  ${preset === 'paragraph-sm' &&
  FontStyle({
    fontFamily: 'Archivo-Regular',
    fontSize: 'var(--font-size-xs)',
    lineHeight: 1.4,
    letterSpacing: 'normal',
  })}

  ${preset === 'table-title' &&
  FontStyle({
    fontFamily: 'Archivo-Bold',
    fontSize: 'var(--font-size-ps)',
    lineHeight: 1.2,
    letterSpacing: 'normal',
  })}
`
