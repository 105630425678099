import { PayloadAction } from '@reduxjs/toolkit'
import { IAdGroup, ICampaign, IProduct } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedAdGroup } from '../utils'

const setAdGroupProducts = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    products: Array<IProduct>
    adGroupId: number
  }>
) => {
  const selectedAdGroup =
    getSelectedAdGroup(state, action.payload.adGroupId) || ({} as IAdGroup)

  selectedAdGroup.productList = action.payload.products
}

export default setAdGroupProducts
