import { css, CSSProperties } from 'styled-components'

export type TGridMixin = Pick<
  CSSProperties,
  | 'gridGap'
  | 'gridTemplateColumns'
  | 'gridTemplateRows'
  | 'gridTemplateAreas'
  | 'gridArea'
  | 'gridAutoFlow'
>
export const Grid = ({
  gridGap,
  gridTemplateColumns,
  gridTemplateRows,
  gridTemplateAreas,
  gridArea,
  gridAutoFlow,
}: TGridMixin) => css`
  display: grid;
  grid-gap: ${gridGap};
  grid-template-columns: ${gridTemplateColumns};
  grid-template-rows: ${gridTemplateRows};
  grid-template-areas: ${gridTemplateAreas};
  grid-area: ${gridArea};
  grid-auto-flow: ${gridAutoFlow};
`
