import { EOrgType } from '@unfoldrtech/portal-mic'
import useGetAdvertiserReportingDAPositionsTableV3 from '../../../../hooks/use-get-advertiser-reporting-da-positions-table-v3'
import useGetAdvertiserReportingDAPositionsChartV3 from '../../../../hooks/use-get-advertiser-reporting-da-positions-chart-v3'
import useGetRetailerReportingDAPositionsTableV3 from '../../../../hooks/use-get-retailer-reporting-da-positions-table-v3'
import useGetRetailerReportingDAPositionsChartV3 from '../../../../hooks/use-get-retailer-reporting-da-positions-chart-v3'

export const getTableAndChartDAPositionsFn = ({
  orgType = EOrgType.Advertiser,
}: {
  orgType?: EOrgType
}) => {
  let tableFn
  let chartFn
  if (orgType === EOrgType.Advertiser) {
    tableFn = useGetAdvertiserReportingDAPositionsTableV3
    chartFn = useGetAdvertiserReportingDAPositionsChartV3
  }
  if (orgType === EOrgType.Retailer) {
    tableFn = useGetRetailerReportingDAPositionsTableV3
    chartFn = useGetRetailerReportingDAPositionsChartV3
  }

  return { tableFn, chartFn }
}
