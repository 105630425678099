import { IDOOHCampaign } from '../../../models'

export const selectDoohCampaignSettings = ({
  doohCampaign: campaignState,
}: {
  doohCampaign: IDOOHCampaign
}): Pick<
  IDOOHCampaign,
  | 'startDate'
  | 'endDate'
  | 'budget'
  | 'auctionStrategy'
  | 'pricingStrategy'
  | 'pacing'
> => {
  return {
    startDate: campaignState.startDate,
    endDate: campaignState.endDate,
    budget: campaignState.budget,
    auctionStrategy: campaignState.auctionStrategy,
    pricingStrategy: campaignState.pricingStrategy,
    pacing: campaignState.pacing,
  }
}
