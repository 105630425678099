import { PayloadAction } from '@reduxjs/toolkit'
import { EChannelType, EDealType, ICampaign } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'

const setCampaignDetails = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    channelType: EChannelType
    title: string
    dealType: EDealType
  }>
) => {
  state.channelType = action.payload.channelType
  state.title = action.payload.title
  state.dealType = action.payload.dealType
}

export default setCampaignDetails
