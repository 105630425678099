import {
  EAdvertiserReportPageType,
  EOrgType,
  ERetailerReportPageType,
} from '@unfoldrtech/portal-mic'
import useGetReportCharts from '../../hooks/useGetAdvertiserReportCharts'
import useGetReportTable from '../../hooks/useGetAdvertiserReportTable'
import useGetV2AdvertiserReportingCampaignChart from '../../hooks/useGetV2AdvertiserReportingCampaignChart'
import useGetV2AdvertiserReportingCampaignTable from '../../hooks/useGetV2AdvertiserReportingCampaignTable'
import useGetV2RetailerReportingCampaignTable from '../../hooks/useGetV2RetailerReportingCampaignTable'
import useGetV2RetailerReportingCampaignChart from '../../hooks/useGetV2RetailerReportingCampaignChart'
import useGetV2AdvertiserReportingAdGroupsTable from '../../hooks/useGetV2AdvertiserReportingAdGroupsTable'
import useGetV2AdvertiserReportingAdGroupsChart from '../../hooks/useGetV2AdvertiserReportingAdGroupsChart'
import useGetV2AdvertiserReportingCreativesTable from '../../hooks/useGetV2AdvertiserReportingCreativesTable'
import useGetV2AdvertiserReportingCreativesChart from '../../hooks/useGetV2AdvertiserReportingCreativesChart'
import useGetV2RetailerReportingAdGroupsTable from '../../hooks/useGetV2RetailerReportingAdGroupsTable'
import useGetV2RetailerReportingAdGroupsChart from '../../hooks/useGetV2RetailerReportingAdGroupsChart'
import useGetV2RetailerReportingCreativesTable from '../../hooks/useGetV2RetailerReportingCreativesTable'
import useGetV2RetailerReportingCreativesChart from '../../hooks/useGetV2RetailerReportingCreativesChart'
import useGetRetailerReportTable from '../../hooks/useGetRetailerReportTable'
import useGetRetailerReportCharts from '../../hooks/useGetRetailerReportCharts'

export const getTableAndChartFn = ({
  advertiserReportPageType = EAdvertiserReportPageType.Campaigns,
  retailerReportPageType = ERetailerReportPageType.Brands,
  orgType = EOrgType.Advertiser,
}: {
  advertiserReportPageType?: EAdvertiserReportPageType
  retailerReportPageType?: ERetailerReportPageType
  orgType?: EOrgType
}) => {
  let tableFn
  let chartFn
  if (orgType === EOrgType.Advertiser) {
    tableFn = useGetReportTable
    chartFn = useGetReportCharts
  }
  if (orgType === EOrgType.Retailer) {
    tableFn = useGetRetailerReportTable
    chartFn = useGetRetailerReportCharts
  }

  if (orgType === EOrgType.Advertiser) {
    if (advertiserReportPageType === EAdvertiserReportPageType.Campaigns) {
      tableFn = useGetV2AdvertiserReportingCampaignTable
      chartFn = useGetV2AdvertiserReportingCampaignChart
    }
    if (advertiserReportPageType === EAdvertiserReportPageType.Adgroups) {
      tableFn = useGetV2AdvertiserReportingAdGroupsTable
      chartFn = useGetV2AdvertiserReportingAdGroupsChart
    }
    if (advertiserReportPageType === EAdvertiserReportPageType.Creatives) {
      tableFn = useGetV2AdvertiserReportingCreativesTable
      chartFn = useGetV2AdvertiserReportingCreativesChart
    }
  }

  if (orgType === EOrgType.Retailer) {
    if (retailerReportPageType === ERetailerReportPageType.Campaigns) {
      tableFn = useGetV2RetailerReportingCampaignTable
      chartFn = useGetV2RetailerReportingCampaignChart
    }
    if (retailerReportPageType === ERetailerReportPageType.Adgroups) {
      tableFn = useGetV2RetailerReportingAdGroupsTable
      chartFn = useGetV2RetailerReportingAdGroupsChart
    }
    if (retailerReportPageType === ERetailerReportPageType.Creatives) {
      tableFn = useGetV2RetailerReportingCreativesTable
      chartFn = useGetV2RetailerReportingCreativesChart
    }
  }

  return { tableFn, chartFn }
}
