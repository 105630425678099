import { PayloadAction } from '@reduxjs/toolkit'
import { ICampaign } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedCategory } from '../utils'

const setAdGroupCategoryBidPrice = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    bidPrice: number
    categoryTargetId: number
    adGroupId: number
  }>
) => {
  const selectedCategoryTarget = getSelectedCategory(state, action)

  if (selectedCategoryTarget) {
    selectedCategoryTarget.bidPrice = action.payload.bidPrice
  }
}

export default setAdGroupCategoryBidPrice
