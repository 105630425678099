import { PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'

import { IDOOHCampaign } from '../../../models'
import { getSelectedDoohAdGroup } from '../utils'

const resetAdGroupDayTimeTarget = (
  state: WritableDraft<IDOOHCampaign>,
  action: PayloadAction<{
    adGroupId: number
  }>
) => {
  const selectedAdGroup = getSelectedDoohAdGroup(
    state,
    action.payload.adGroupId
  )

  if (!selectedAdGroup) return

  selectedAdGroup.criteria = undefined
  selectedAdGroup.days = undefined
  selectedAdGroup.times = undefined
}

export default resetAdGroupDayTimeTarget
