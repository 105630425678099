import { EOrgType } from '@unfoldrtech/portal-mic'
import { IAppContext } from '../../models/interfaces'
import { TRetailerContext } from '../../models/types'

export const getHomePageURL = ({
  appContext,
  retailerContext,
}: {
  appContext: IAppContext
  retailerContext?: TRetailerContext
}) => {
  let orgType: string | undefined
  let homeURL: string | undefined

  let urlSuffix = ''

  const organisationId = appContext.userClaims?.orgId
  const organisationType = appContext.userClaims?.orgType

  switch (organisationType) {
    case EOrgType.Retailer:
      orgType = 'retailer'
      break
    case EOrgType.Advertiser:
      if (retailerContext?.id) {
        orgType = 'advertiser'
        urlSuffix = `/retailer/${retailerContext.id}`
      }
      break
    default:
    // throw new Error('unknown org type')
  }

  if (orgType && organisationId) {
    homeURL = `/${orgType}/${organisationId}${urlSuffix}`
  }

  return homeURL || '/organisations'
}
