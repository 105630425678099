import { css } from 'styled-components'
import { Alignment } from './alignment'
import { Background } from './background'
import { Border } from './border'
import { Display } from './display'
import { FontStyle } from './fontStyle'
import { TAllMixins } from './misc'
import { Size } from './size'

export type TActionMixin = TAllMixins & {
  hoverAction?: TAllMixins
  disableAction?: TAllMixins
  focusAction?: TAllMixins
  activeAction?: TAllMixins
}

export const ActionStyles = (props: TActionMixin) => css<TActionMixin>`
  cursor: ${props.cursor};
  opacity: ${props.opacity};
  pointer-events: ${props.pointerEvents};
  ${Border({ ...props })};
  ${Size({ ...props })};
  ${FontStyle({ ...props })};
  ${Background({ ...props })};
  ${Display({ ...props })};
  ${Alignment({ ...props })};
`
export const Action = (props: TActionMixin) => css<TActionMixin>`
  ${ActionStyles(props)};
  &:hover {
    ${props.hoverAction && ActionStyles(props.hoverAction)}
  }
  &:focus {
    ${props.focusAction && ActionStyles(props.focusAction)}
  }
  &.active {
    ${props.activeAction && ActionStyles(props.activeAction)}
  }
  &[disabled] {
    ${props.disableAction && ActionStyles(props.disableAction)}
  }
`
