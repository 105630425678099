import { PayloadAction } from '@reduxjs/toolkit'
import { TReportingFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'

const setStartDate = (
  state: TReportingFilters,
  action: PayloadAction<{
    startDate: string
  }>
) => {
  state.startDate = action.payload.startDate
}

export default setStartDate
