import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconCategories = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M8.69 19.19a1.503 1.503 0 0 1-2.121 0l-5.38-5.38a1.5 1.5 0 0 1 .001-2.12l10.5-10.5c.282-.282.663-.44 1.06-.44h5.379a1.5 1.5 0 0 1 1.5 1.5v5.38c0 .398-.158.78-.44 1.06" />
      <path d="M15.879 4.12a.375.375 0 1 0 0 .75.375.375 0 0 0 0-.75m-3 16.13a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5zm7.5 3a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5zm0-7.5a2.25 2.25 0 1 0 0-4.5 2.25 2.25 0 0 0 0 4.5zm-5.575 1.091 3.65-2.19m-3.487 4.179 3.325 1.33" />
    </g>
  </svg>
)

export default SvgIconCategories
