/* eslint-disable react/style-prop-object */
import React from 'react'
import { FormattedNumber } from 'react-intl'
import { TDataTest } from '../../../models/types'
import { NOT_AVAILABLE } from '../../../utils/constants/notAvailable'
import { Text } from './Text'
import useGetCurrentCurrency from '../../../hooks/useGetCurrentCurrency'

export function CurrencyText({
  value,
  dataTestid,
}: {
  value?: number
  dataTestid?: TDataTest
} & Intl.NumberFormatOptions) {
  const { text: currencyText } = useGetCurrentCurrency()

  return (
    <>
      {value !== undefined && value >= 0 ? (
        <FormattedNumber
          style="currency"
          value={value}
          currency={currencyText}
          maximumFractionDigits={2}
          data-testid={`formatted-number-${dataTestid}`}
        />
      ) : (
        <Text data-testid={`formatted-number-${dataTestid}`}>
          {NOT_AVAILABLE.toLowerCase()}
        </Text>
      )}
    </>
  )
}
