import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconArrowRight = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 18 10"
    {...props}
  >
    <path
      d="M1 4.75h15.75M13 8.5l3.75-3.75L13 1"
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgIconArrowRight
