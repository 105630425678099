import { PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'

import { getSelectedDoohAdGroup } from '../utils'
import { EDOOHTargetType, IDOOHCampaign } from '../../../models'

const setAdGroupAudience = (
  state: WritableDraft<IDOOHCampaign>,
  action: PayloadAction<{
    audiences: {
      id: number
      targetId: number
      name: string
      description: string
    }[]
    adGroupId: number
  }>
) => {
  const selectedAdGroup = getSelectedDoohAdGroup(
    state,
    action.payload.adGroupId
  )

  if (selectedAdGroup) {
    const { audiences } = action.payload

    selectedAdGroup.targeting.targetType =
      audiences.length > 0
        ? EDOOHTargetType.AudienceAndPosition
        : EDOOHTargetType.PositionOnly
    selectedAdGroup.targeting.audiencesList = action.payload.audiences
  }
}

export default setAdGroupAudience
