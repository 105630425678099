import React, { useEffect, useState } from 'react'
import { User } from 'firebase/auth'
import firebaseAuth from './services/firebaseAuth'

import I18NText from './components/I18NText'
import { AppContext, defaultAppContext } from './models/contexts'
import { IAppContext } from './models/interfaces'
import { Container } from './components/Global'
import InitAppContext from './components/InitAppContext'
import AppRouter from './components/AppRouter'

const App: React.FC = () => {
  const [isLoading, setIsLoading] = useState(true)
  const [appContext, setAppContext] = useState<IAppContext>(
    defaultAppContext[0]
  )

  const authObserver = (user: User | null) => {
    if (user) {
      setAppContext({
        ...appContext,
        isLoggedIn: true,
      })
    } else {
      setAppContext({
        isLoggedIn: false,
      })
    }
    setIsLoading(false)
  }

  // Listen to the Firebase Auth state and set the local state.
  useEffect(() => {
    const unregisterAuthObserver = firebaseAuth.onAuthStateChanged(authObserver)
    return () => unregisterAuthObserver() // Make sure we un-register Firebase observers when the component unmounts.
  }, [])

  if (!isLoading) {
    return (
      <>
        <AppContext.Provider value={[appContext, setAppContext]}>
          <InitAppContext>
            <AppRouter />
          </InitAppContext>
        </AppContext.Provider>
      </>
    )
  }

  return (
    <>
      <Container textAlign="center">
        <I18NText id="initialising" />
      </Container>
    </>
  )
}

export default App
