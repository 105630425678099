import { PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import { ICampaign } from '@unfoldrtech/portal-mic'

export function getSelectedPosition(
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    positionTargetId: number
    adGroupId: number
  }>
) {
  const selectedAdGroup = state.adGroupList.find(
    (adGroup) => adGroup.id === action.payload.adGroupId
  )

  const positionsList = selectedAdGroup?.positionsList || []
  const positionTargetIndex = positionsList.findIndex(
    (pos) => pos.id === action.payload.positionTargetId
  )
  const selectedPosition =
    positionTargetIndex !== -1 ? positionsList[positionTargetIndex] : undefined

  return selectedPosition
}
