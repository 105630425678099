import { PayloadAction } from '@reduxjs/toolkit'
import { EDealType } from '@unfoldrtech/portal-mic'
import { TReportingFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'

const setDealType = (
  state: TReportingFilters,
  action: PayloadAction<{
    dealType?: EDealType
  }>
) => {
  state.dealType = action.payload.dealType
}

export default setDealType
