import styled from 'styled-components'

import {
  FontPreset,
  FontStyle,
  TFontPresetMixin,
  TFontPresets,
  TFontStyleMixin,
} from '../styles/mixins'

export const Text = styled.span<TFontStyleMixin & { preset?: TFontPresets }>`
  ${({ preset }: TFontPresetMixin) => preset && FontPreset(preset)}

  ${(props: TFontStyleMixin) =>
    FontStyle({
      ...props,
    })};
`
