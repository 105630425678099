import styled, { CSSProperties } from 'styled-components'
import { TSizeMixin, TStickyProps, Sticky, Size } from '../styles/mixins'

export const StyledTableHeaderRow = styled.thead<
  TStickyProps &
    TSizeMixin &
    Pick<CSSProperties, 'background' | 'backgroundColor' | 'boxShadow'>
>`
  background: ${(props) =>
    props.background || 'var(--background-color-default)'};

  ${(props: TSizeMixin) => Size({ width: '100%', ...props })};
  ${(props: TStickyProps) => Sticky({ ...props })};
  box-shadow: ${(props) => props.boxShadow};
`
