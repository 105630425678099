import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import { IModalProps } from '../../models/interfaces'
import { StyledModal } from '../Global'

function Loading({ show, backdrop = 'static', keyboard = false }: IModalProps) {
  return (
    <>
      <StyledModal
        centered
        onHide={() => {}}
        border="none"
        backgroundColor="unset"
        textAlign="center"
        backdrop={backdrop}
        keyboard={keyboard}
        show={show}
      >
        <StyledModal.Body>
          <Spinner animation="border" variant="light" />
        </StyledModal.Body>
      </StyledModal>
    </>
  )
}

export default Loading
