import React from 'react'
import { NumberText } from '../../../Global'

export const TableDataCellFrequency = ({
  dataValue,
}: {
  dataValue?: number
}) => {
  return <NumberText value={Number(dataValue)} maximumFractionDigits={1} />
}
