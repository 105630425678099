import { css, CSSProperties } from 'styled-components'

export type TBackgroundMixin = Pick<
  CSSProperties,
  'backgroundColor' | 'backgroundImage' | 'background' | 'backgroundSize'
>

export const Background = ({
  background,
  backgroundColor,
  backgroundImage,
}: TBackgroundMixin) => css`
  background-color: ${backgroundColor};
  background-image: ${backgroundImage};
  background: ${background};
`
