import Accordion, { AccordionProps } from 'react-bootstrap/Accordion'
import styled from 'styled-components'
import {
  Action,
  Background,
  FontPreset,
  Margin,
  Padding,
  TActionMixin,
  TBackgroundMixin,
  TContainerProps,
  TFontPresetMixin,
  TMarginMixin,
  TPaddingMixin,
  Truncate,
  TTruncateMixin,
} from '../../styles/mixins'

export const StyledAccordion = styled(Accordion)<
  TContainerProps & AccordionProps
>`
  ${(props: TBackgroundMixin) => Background({ ...props })};

  ${(props: TPaddingMixin) => Padding({ ...props })};

  ${(props: TMarginMixin) => Margin({ ...props })};

  ${(props: TFontPresetMixin) => FontPreset(props.preset)};

  ${(props: TActionMixin) => Action({ ...props })}

  ${(props: TTruncateMixin) => Truncate({ ...props })}
`
