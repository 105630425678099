import { EAggregationType } from '@unfoldrtech/portal-mic'
import dayjs from 'dayjs'

export function getAggregationType(
  start: string,
  end: string
): EAggregationType {
  const diff = Math.abs(dayjs(start).diff(end, 'hours'))
  if (diff <= 24) {
    return EAggregationType.Daily
  }
  return EAggregationType.Daily
}
