import { PayloadAction } from '@reduxjs/toolkit'
import { IAdGroup, ICampaign } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedAdGroup } from '../utils'

const removeAdGroupPosition = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{ positionId: number; adGroupId: number }>
) => {
  const selectedAdGroup =
    getSelectedAdGroup(state, action.payload.adGroupId) || ({} as IAdGroup)

  if (selectedAdGroup.positionsList === undefined) {
    selectedAdGroup.positionsList = []
  }
  const index = selectedAdGroup.positionsList.findIndex(
    (position) => position.id === action.payload.positionId
  )
  if (index !== undefined && index > -1) {
    selectedAdGroup.positionsList.splice(index, 1)
  }
}

export default removeAdGroupPosition
