import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import { WritableDraft } from 'immer/dist/internal'
import { IDOOHCampaign } from '../../../models'

const removeDoohCampaignAdGroup = (
  state: WritableDraft<IDOOHCampaign>,
  action: PayloadAction<{ id: number }>
) => {
  const index = state.adGroupsList.findIndex(
    (adGroup) => adGroup.id === action.payload.id
  )
  state.adGroupsList.splice(index, 1)
}

export default removeDoohCampaignAdGroup
