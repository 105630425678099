import { createSlice } from '@reduxjs/toolkit'
import { IRefreshTable } from '../../models/interfaces'
import reducers from './reducers'

const INITIAL_REFRESH_TABLE_STATE: IRefreshTable = <IRefreshTable>{
  refreshTable: false,
}

export const refreshTableSlice = createSlice({
  name: 'refreshTable',
  initialState: INITIAL_REFRESH_TABLE_STATE,
  reducers: {
    resetRefreshTable: () => INITIAL_REFRESH_TABLE_STATE,
    ...reducers,
  },
})
export const { setRefreshTable } = refreshTableSlice.actions

export const refreshTableReducer = refreshTableSlice.reducer
