import { EMatchType } from '@unfoldrtech/portal-mic'
import React from 'react'
import I18NText from '../../I18NText'

export function CurrentMatchTypeText({
  matchType,
}: {
  matchType?: EMatchType
}) {
  if (matchType === EMatchType.Broad) {
    return (
      <>
        <I18NText id="keyword.matchType.broad" />
      </>
    )
  }
  if (matchType === EMatchType.Exact) {
    return (
      <>
        <I18NText id="keyword.matchType.exact" />
      </>
    )
  }
  if (matchType === EMatchType.Negative) {
    return (
      <>
        <I18NText id="keyword.matchType.negative" />
      </>
    )
  }
  return <> </>
}
