import { IProduct } from '@unfoldrtech/portal-mic'

export const getCategoriesFromProducts = ({
  products = [],
}: {
  products: IProduct[]
}) => {
  return Array.from(
    products.reduce((acc, product) => {
      if (product.categoryParentTitle) {
        acc.add(product.categoryParentTitle)
        if (product.categoryTitle) {
          acc.add(`${product.categoryParentTitle} / ${product.categoryTitle}`)
        }
      } else if (product.categoryTitle) {
        acc.add(product.categoryTitle)
      }
      return acc
    }, new Set<string>())
  ).sort((a, b) => {
    const aIsParent = !a.includes('/')
    const bIsParent = !b.includes('/')

    if (aIsParent && bIsParent) {
      return a.localeCompare(b)
    }
    if (aIsParent) {
      return -1
    }
    if (bIsParent) {
      return 1
    }
    return a.localeCompare(b)
  })
}
