import styled from 'styled-components'

import {
  Background,
  Border,
  FontStyle,
  Margin,
  Padding,
  TBackgroundMixin,
  TBorderMixin,
  TFontStyleMixin,
  TMarginMixin,
  TPaddingMixin,
} from '../styles/mixins'

export const StyledPrefix = styled.span<
  TFontStyleMixin &
    TBorderMixin &
    TPaddingMixin &
    TBackgroundMixin &
    TMarginMixin
>`
  font-size: var(--font-size-pa);
  line-height: calc(var(--padding-default) * 2);
  margin: auto 0 auto var(--margin-default);

  ${(props: TBorderMixin) => Border({ ...props })};
  ${(props: TPaddingMixin) => Padding({ ...props })};
  ${(props: TMarginMixin) => Margin({ ...props })};
  ${(props: TBackgroundMixin) => Background({ ...props })};
  ${(props: TFontStyleMixin) => FontStyle({ ...props })};
`
