import React, { ReactNode } from 'react'
import { Dropdown } from 'antd'
import { DropdownButtonProps } from 'antd/lib/dropdown'
import styled from 'styled-components'
import { Action } from '../../styles'

export const AntDropdown = styled(Dropdown)`
  && {
    ${Action({
      disableAction: {
        cursor: 'not-allowed',
        opacity: 0.5,
      },
    })}
  }
`

export const StyledAntDropdown = ({
  children,
  trigger = ['click'],
  ...props
}: {
  children: ReactNode | ReactNode[]
} & DropdownButtonProps) => {
  return (
    <AntDropdown trigger={trigger} {...props}>
      {children}
    </AntDropdown>
  )
}
