import styled from 'styled-components'
import Accordion from 'react-bootstrap/Accordion'
import {
  Background,
  Border,
  Margin,
  TBackgroundMixin,
  TBorderMixin,
  TMarginMixin,
} from '../../styles/mixins'

export const StyledAccordionItem = styled(Accordion.Item).withConfig({
  shouldForwardProp: (prop) =>
    ![
      'borderLeft',
      'borderRight',
      'borderTop',
      'borderBottom',
      'marginBottom',
      'border',
      'cursor',
      'backgroundColor',
    ].includes(prop as string),
})<TBorderMixin & TBackgroundMixin & TMarginMixin>`
  && {
    border-left: none;
    border-right: none;
    cursor: ${(props) => props.cursor || ''};

    ${(props: TBackgroundMixin) => Background({ ...props })};
    ${(props: TMarginMixin) => Margin({ ...props })};
    ${(props: TBorderMixin) => Border({ ...props })};
  }
`
