import {
  EStatus,
  // IAdGroup,
  IAdGroupCreate,
  IAdGroupUpdate,
} from '@unfoldrtech/portal-mic'
import { IAdGroup } from '../../../models/interfaces/ICampaignState'
import { getAdGroupTargetList } from './getAdGroupTargetList'
import { updateAdDataDestinationURL } from '../../../utils/helpers'
import { CTransformAdGroupUpdate } from '../../../models'

export function transformAdGroup(adGroup: IAdGroup): IAdGroupCreate {
  return {
    inventoryType: adGroup.inventoryType,
    targeting: getAdGroupTargetList(adGroup),
    title: adGroup.title,
    criteria: adGroup.criteria,
    days: adGroup.days,
    times: adGroup.times,
    productIdList: adGroup.productList?.map((p) => p.id) || [],
    creativesList: adGroup.creativesList?.map((creative) => ({
      ...creative,
      data: updateAdDataDestinationURL({
        destinationURLPrefix: creative.template.data.destinationURLPrefix,
        adData: creative.data,
      }),
    })),
  }
}

export function transformAdGroupUpdate(adGroup: IAdGroup): IAdGroupUpdate {
  return new CTransformAdGroupUpdate(
    getAdGroupTargetList(adGroup),
    adGroup.title,
    (
      adGroup.productList?.filter(
        (product) => product.status !== EStatus.Disabled // Similar behaviour as src/pages/Advertiser/Routes/Analytics/CampaignSettings.tsx line 242
      ) || []
    ).map((p) => p.id),
    adGroup.criteria,
    adGroup.days,
    adGroup.times
  )
}
