import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconSponsoredproductSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    {...props}
  >
    <g
      stroke="currentColor"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M19.007 14.5h-2.032a1.342 1.342 0 0 0-.5 2.587l2.062.825a1.34 1.34 0 0 1-.5 2.588h-2.03m1.5-6v-.75m0 7.5v-.75" />
      <path d="M17.507 23.5a6 6 0 0 0 0-12 6 6 0 0 0 0 12z" />
      <path d="M8.132 17.5H4.57a1.442 1.442 0 0 1-1.382-1.136l-2.138-9A1.484 1.484 0 0 1 2.432 5.5h18.149a1.484 1.484 0 0 1 1.383 1.864l-.3 1.256M4.007 5.5l4.5-4.5m10.5 4.498L14.507 1" />
    </g>
  </svg>
)

export default SvgIconSponsoredproductSmall
