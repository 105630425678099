import styled from 'styled-components'
import {
  Background,
  Padding,
  Size,
  TBackgroundMixin,
  TPaddingMixin,
  TSizeMixin,
  TTruncateMixin,
  Truncate,
} from '../styles'

export const UnorderedList = styled.ul<
  TBackgroundMixin & TPaddingMixin & TSizeMixin & TTruncateMixin
>`
  list-style: none;
  margin: 0;
  padding: 0;
  ${(props: TBackgroundMixin) => Background({ ...props })};
  ${(props: TPaddingMixin) => Padding({ ...props })};
  ${(props: TSizeMixin) => Size({ ...props })};
  ${(props: TTruncateMixin) => Truncate({ ...props })};
`
