import styled from 'styled-components'

import { Action } from '../styles/mixins'
import { StyledStatusBadge } from './bootstrap/StyledStatusBadge'

export const DisabledBadge = styled(StyledStatusBadge)`
  ${Action({
    background: 'var(--badge-color-disabled) !important',
    color: 'var(--font-color-dark) !important',
    opacity: '0.6',
  })}
`
