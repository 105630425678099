import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { EEntityType, IEntity, IError } from '@unfoldrtech/portal-mic'
import { coreAPI } from '../services/coreAPI'

import { generateCorrelationId } from '../utils/helpers'

export default function useUpdateEntityStatus({
  advertiserId,
  retailerId,
}: {
  advertiserId: number
  retailerId: number
}) {
  return useMutation<
    AxiosResponse<void>,
    AxiosError<IError>,
    { id: number; entityType: EEntityType; entity: IEntity }
  >(
    [`updateEntityStatus`],
    (params: { id: number; entityType: EEntityType; entity: IEntity }) =>
      coreAPI
        .v2AdvertiserCampaigns()
        .updateEntityStatus(
          advertiserId,
          params.entityType,
          retailerId,
          params.id,
          generateCorrelationId(),
          params.entity
        )
  )
}
