import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconSettings = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M10.54 2.434a1.956 1.956 0 0 0 2.908 0l.946-1.038A1.957 1.957 0 0 1 16.598.9a1.956 1.956 0 0 1 1.206 1.909l-.071 1.4a1.956 1.956 0 0 0 2.051 2.054l1.401-.071a1.958 1.958 0 0 1 1.409 3.41l-1.042.94a1.959 1.959 0 0 0 0 2.909l1.042.94a1.96 1.96 0 0 1-1.412 3.41l-1.401-.071a1.956 1.956 0 0 0-2.055 2.056l.07 1.4a1.96 1.96 0 0 1-3.402 1.41l-.941-1.041a1.958 1.958 0 0 0-2.908 0l-.944 1.04a1.958 1.958 0 0 1-3.407-1.407l.073-1.4a1.961 1.961 0 0 0-2.057-2.056l-1.399.07a1.957 1.957 0 0 1-1.417-3.406l1.042-.94a1.961 1.961 0 0 0 0-2.909l-1.042-.945a1.961 1.961 0 0 1-.494-2.2 1.961 1.961 0 0 1 1.904-1.206l1.399.07a1.96 1.96 0 0 0 2.059-2.06l-.068-1.4a1.96 1.96 0 0 1 3.407-1.41l.939 1.038z" />
      <path d="M11.994 16.5a4.5 4.5 0 1 0 0-9 4.5 4.5 0 0 0 0 9z" />
    </g>
  </svg>
)

export default SvgIconSettings
