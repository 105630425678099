import Nav from 'react-bootstrap/Nav'
import styled from 'styled-components'
import { IThemeProps } from '../../../models/interfaces'
import { FontPreset, Action, TruncatePreset } from '../styles/mixins'

export const NavLinkButton = styled(Nav.Link).attrs(() => ({
  className: 'nav-link',
}))`
  &&& {
    padding: 0;
    margin-right: var(--gutter-4x);

    ${FontPreset('nav items')};

    ${(props: IThemeProps) =>
      Action({
        opacity: '0.5',

        activeAction: {
          opacity: '1',
          borderBottom: {
            width: '3px',
            style: 'solid',
            color:
              props.theme?.colors?.border?.active ||
              'var(--border-color-active)',
          },
        },
      })};
    ${TruncatePreset('100%')};
  }
`
