import React from 'react'
import styled, { CSSProperties } from 'styled-components'
import BootstrapImage, { ImageProps } from 'react-bootstrap/Image'

import { useIntl } from 'react-intl'
import { Action, Truncate, TSizeMixin } from '../../styles/mixins'

export const Image = styled(BootstrapImage).withConfig({
  shouldForwardProp: (prop) =>
    !['maxWidth', 'minWidth', 'maxHeight', 'minHeight', 'objectFit'].includes(
      prop as string
    ),
})<
  TSizeMixin &
    Pick<CSSProperties, 'opacity' | 'display' | 'cursor' | 'objectFit'>
>`
  display: ${(props) => props.display || 'block'};
  object-fit: ${(props) => props.objectFit};
  ${(
    props: TSizeMixin &
      Pick<CSSProperties, 'opacity' | 'display' | 'cursor' | 'objectFit'>
  ) => Action({ cursor: props.cursor || 'pointer', ...props })};
  ${(props) => props.opacity || ''}
  ${Truncate({
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    whiteSpace: 'break-spaces',
  })}
`

export function StyledImage({
  size,
  src,
  height,
  width,
  alt = 'image',
  ...rest
}: {
  src?: string
  size?: string
  alt?: string
} & ImageProps &
  TSizeMixin &
  Pick<CSSProperties, 'opacity' | 'display' | 'cursor' | 'objectFit'>) {
  const intl = useIntl()

  const imageWidth = size || width
  const imageHeight = size || height
  const imageSrc = src || '/images/placeholder_image.png'

  const altText = intl.formatMessage({ id: 'error.not.available' })

  return (
    <>
      <Image
        src={imageSrc}
        width={imageWidth}
        height={imageHeight}
        alt={altText || alt}
        {...rest}
      />
    </>
  )
}
