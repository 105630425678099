import React, { lazy } from 'react'
import { EUserRole } from '@unfoldrtech/portal-mic'
import { Route } from 'react-router-dom'
import { lazyRetry } from '../../utils/lazyRetry'
import { PortalRoutes } from './PortalRoutes'
import AuthenticatedRoute from './AuthenticatedRoute'
import { PORTAL_FF_DA, PORTAL_FF_DOOH_FAQ_VIEW } from '../../utils/constants'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'

const FAQ = lazy(() => lazyRetry(() => import('../../pages/Faq')))

const Advertiser = lazy(() => import('../../pages/Advertiser'))

const AdvertiserOverview = lazy(() => import('../../pages/Advertiser/Overview'))

const SponsoredProducts = lazy(() =>
  lazyRetry(() => import('../../pages/Faq/Routes/Advertiser/SponsoredProducts'))
)

const Generic = lazy(() =>
  lazyRetry(() => import('../../pages/Faq/Routes/Advertiser/Generic'))
)

const DisplayAds = lazy(() =>
  lazyRetry(() => import('../../pages/Faq/Routes/Advertiser/DisplayAds'))
)

const DigitalOutOfHome = lazy(() =>
  lazyRetry(() => import('../../pages/Faq/Routes/Advertiser/DigitalOutOfHome'))
)

const AdvertiserFaqRoutes = () => {
  const featureFlags = useFeatureFlags()

  return (
    <PortalRoutes>
      <Route
        path="advertiser/:orgId"
        element={
          <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
            <Advertiser />
          </AuthenticatedRoute>
        }
      >
        <Route
          path="retailer/:retailerId"
          element={
            <AuthenticatedRoute authRole={EUserRole.AdvertiserInsights}>
              <AdvertiserOverview />
            </AuthenticatedRoute>
          }
        >
          <Route
            path="faq"
            element={
              <AuthenticatedRoute
                authRole={[
                  EUserRole.AdvertiserInsights,
                  EUserRole.RetailerInsights,
                  EUserRole.None,
                ]}
              >
                <FAQ />
              </AuthenticatedRoute>
            }
          >
            <Route
              path="generic"
              element={
                <AuthenticatedRoute
                  authRole={[
                    EUserRole.AdvertiserInsights,
                    EUserRole.RetailerInsights,
                    EUserRole.None,
                  ]}
                >
                  <Generic />
                </AuthenticatedRoute>
              }
            />
            <Route
              path="sponsored-products"
              element={
                <AuthenticatedRoute
                  authRole={[
                    EUserRole.AdvertiserInsights,
                    EUserRole.RetailerInsights,
                    EUserRole.None,
                  ]}
                >
                  <SponsoredProducts />
                </AuthenticatedRoute>
              }
            />
            {featureFlags[PORTAL_FF_DA] && (
              <Route
                path="display-ads"
                element={
                  <AuthenticatedRoute
                    authRole={[
                      EUserRole.AdvertiserInsights,
                      EUserRole.RetailerInsights,
                      EUserRole.None,
                    ]}
                  >
                    <DisplayAds />
                  </AuthenticatedRoute>
                }
              />
            )}
            {featureFlags[PORTAL_FF_DOOH_FAQ_VIEW] && (
              <Route
                path="digital-out-of-home"
                element={
                  <AuthenticatedRoute
                    authRole={[
                      EUserRole.AdvertiserInsights,
                      EUserRole.RetailerInsights,
                      EUserRole.None,
                    ]}
                  >
                    <DigitalOutOfHome />
                  </AuthenticatedRoute>
                }
              />
            )}
          </Route>
        </Route>
      </Route>
    </PortalRoutes>
  )
}

export default AdvertiserFaqRoutes
