import styled, { CSSProperties } from 'styled-components'
import { StyledButton } from './StyledButton'
import { FontPreset, Action, TFontPresets } from '../styles/mixins'
import { IThemeProps } from '../../../models/interfaces'

interface IPageLinkButtonProps
  extends Pick<CSSProperties, 'backgroundColor' | 'height'> {
  preset?: TFontPresets
}

export const PageLinkButton = styled(StyledButton).withConfig({
  shouldForwardProp: (prop) => !['backgroundColor'].includes(prop as string),
})<IPageLinkButtonProps>`
  padding-left: 0;
  padding-right: 0;

  ${(props: { preset?: TFontPresets }) =>
    FontPreset(props.preset ? props.preset : 'paragraph medium')};

  ${(props: IThemeProps & IPageLinkButtonProps) =>
    Action({
      height: props.height || 'var(--button-size-no)',

      backgroundColor:
        props.backgroundColor ||
        props.theme?.colors?.background?.primary ||
        'var(--background-color-default)',

      hoverAction: {
        backgroundColor:
          props.backgroundColor ||
          props.theme?.colors?.background?.primary ||
          'var(--background-color-default)',

        color: props.theme?.colors?.font?.url || 'var(--font-color-url)',
      },

      activeAction: {
        color: props.theme?.colors?.font?.active || 'var(--font-color-active)',

        backgroundColor:
          props.theme?.colors?.background?.primary ||
          'var(--background-color-default)',
      },

      disableAction: {
        color:
          props.theme?.colors?.font?.primary || 'var(--font-color-default)',

        backgroundColor:
          props.backgroundColor ||
          props.theme?.colors?.background?.disabled ||
          'var(--background-color-disabled)',
      },
    })}

  &&.sidebar-nav-button:disabled {
    background-color: transparent;
  }

  &&:focus {
    box-shadow: none;
  }
`
