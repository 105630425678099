import { TReportDateRange } from '../../models/types'
import { DAY_IN_MS } from '../constants'
import { getEndOfDay } from './getEndOfDay'
import { getStartOfDay } from './getStartOfDay'
import { getTodayInMs } from './getTodayInMs'
import { getYesterdayInMs } from './getYesterdayInMs'

export const getDateRanges = (): TReportDateRange => {
  const endOfDayYesterday = getEndOfDay(getYesterdayInMs())

  const dateRanges: TReportDateRange = {
    today: [getStartOfDay(), getEndOfDay(getTodayInMs())],
    yesterday: [getStartOfDay(getYesterdayInMs()), endOfDayYesterday],
    /*     'last-week': [
      getStartOfDay(getYesterdayInMs() - 6 * DAY_IN_MS),
      endOfDayYesterday,
    ], */
    'last-7-days': [
      getStartOfDay(getYesterdayInMs() - 6 * DAY_IN_MS),
      endOfDayYesterday,
    ],
    'last-28-days': [
      getStartOfDay(getYesterdayInMs() - 27 * DAY_IN_MS),
      endOfDayYesterday,
    ],
    'last-90-days': [
      getStartOfDay(getYesterdayInMs() - 89 * DAY_IN_MS),
      endOfDayYesterday,
    ],
    /*     'this-year': [
      getStartOfDay(getYesterdayInMs() - 364 * DAY_IN_MS),
      endOfDayYesterday,
    ], */
    'last-12-months': [
      getStartOfDay(getYesterdayInMs() - 364 * DAY_IN_MS),
      endOfDayYesterday,
    ],
    /*     'last-calendar-year': [
      getStartOfDay(getYesterdayInMs() - 364 * DAY_IN_MS),
      endOfDayYesterday,
    ], */
  }
  return dateRanges
}
