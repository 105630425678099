import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconCross = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 17 17"
    {...props}
  >
    <path
      d="m.75 15.75 15-15m-15 0 15 15"
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgIconCross
