import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconNotification = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 20 24"
    {...props}
  >
    <path
      d="M7.75 21.75a2.086 2.086 0 0 0 4.005 0M9.75 3V.75m0 2.25a7.5 7.5 0 0 1 7.5 7.5c0 7.046 1.5 8.25 1.5 8.25h-18s1.5-1.916 1.5-8.25A7.5 7.5 0 0 1 9.75 3z"
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgIconNotification
