import {
  ECreativeStatus,
  IAdData,
  ICreativeUpdateWithData,
} from '@unfoldrtech/portal-mic'

export class CCreativeUpdate implements ICreativeUpdateWithData {
  id: number

  title: string

  data: IAdData

  status?: ECreativeStatus

  constructor(
    id: number,
    title: string,
    data: IAdData,
    status?: ECreativeStatus
  ) {
    this.id = id
    this.title = title
    this.data = data
    this.status = status
  }
}
