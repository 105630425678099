import { PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import {
  EDayTimeTargetCriteria,
  EDayTimeTargetDay,
  EDayTimeTargetTime,
} from '@unfoldrtech/portal-mic'

import { getSelectedAdGroup } from '../utils'
import { ICampaign } from '../../../models/interfaces/ICampaignState'

function sanitizeValue(
  newValue?: EDayTimeTargetDay[] | EDayTimeTargetTime[] | null,
  defaultValue?: EDayTimeTargetDay[] | EDayTimeTargetTime[] | null
) {
  if (!newValue) return defaultValue
  if (newValue.length <= 0) return null
  return newValue
}

const setAdGroupDayTimeTarget = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    criteria?: EDayTimeTargetCriteria
    days?: EDayTimeTargetDay[] | null
    times?: EDayTimeTargetTime[] | null
    adGroupId: number
  }>
) => {
  const { criteria, days, times, adGroupId } = action.payload
  const selectedAdGroup = getSelectedAdGroup(state, adGroupId)

  if (!selectedAdGroup) return

  selectedAdGroup.criteria = criteria || selectedAdGroup.criteria
  selectedAdGroup.days = sanitizeValue(
    days,
    selectedAdGroup.days
  ) as EDayTimeTargetDay[]
  selectedAdGroup.times = sanitizeValue(
    times,
    selectedAdGroup.times
  ) as EDayTimeTargetTime[]
}

export default setAdGroupDayTimeTarget
