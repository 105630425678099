import styled from 'styled-components'
import { Container } from './Container'
import { TContainerProps } from '../styles/mixins'
import { IThemeProps } from '../../../models/interfaces'

export const SidebarCardContainer = styled(Container)<TContainerProps>`
  border-radius: 4px;
  box-shadow: 0 24px 24px -16px rgba(0, 0, 0, 0.3);
  color: ${(props: IThemeProps) => props.theme?.colors?.font?.secondary};
  background-color: ${(props: IThemeProps) =>
    props.theme?.colors?.background?.secondary};
`
