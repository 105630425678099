import { PayloadAction } from '@reduxjs/toolkit'
import { EReportColumnHeader } from '@unfoldrtech/portal-mic'
import { TReportingFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'

const setSortBy = (
  state: TReportingFilters,
  action: PayloadAction<{
    sortBy?: EReportColumnHeader
  }>
) => {
  state.sortBy = action.payload.sortBy
}

export default setSortBy
