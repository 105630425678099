import {
  Action,
  combineReducers,
  configureStore,
  ThunkAction,
} from '@reduxjs/toolkit'
import * as Sentry from '@sentry/react'

import { campaignsReducer } from './campaigns'
import { doohCampaignReducer } from './doohCampaign'
import { campaignValidityReducer } from './campaignValidity'
import { refreshTableReducer } from './refreshTable'
import { reportingFiltersReducer } from './reportingFilters'

const sentryReduxEnhancer = Sentry.createReduxEnhancer({})

const store = configureStore({
  reducer: combineReducers({
    campaign: campaignsReducer,
    doohCampaign: doohCampaignReducer,
    campaignValidity: campaignValidityReducer,
    reportingFilters: reportingFiltersReducer,
    refreshTable: refreshTableReducer,
  }),
  enhancers: [sentryReduxEnhancer],
})

export default store

export type TStore = ReturnType<typeof store.getState>
export type TAppThunk = ThunkAction<void, TStore, null, Action<string>>
