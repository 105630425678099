import styled from 'styled-components'
import Form from 'react-bootstrap/Form'

import {
  Display,
  TDisplayMixin,
  TAlignmentMixin,
  Alignment,
  TMarginMixin,
  Margin,
} from '../styles/mixins'

export const Label = styled(Form.Label)<
  TDisplayMixin & TAlignmentMixin & TMarginMixin
>`
  margin: 0;
  :hover {
    cursor: pointer;
  }

  ${(props: TDisplayMixin) => Display({ ...props })}
  ${(props: TAlignmentMixin) => Alignment({ ...props })};
  ${(props: TMarginMixin) => Margin({ ...props }) || 0};
`
