/* eslint-disable react/style-prop-object */
import React from 'react'
import { FormattedNumber } from 'react-intl'
import { NOT_AVAILABLE } from '../../../utils/constants/notAvailable'
import { Text } from './Text'

export function PercentageText({ value = 0 }: { value?: number }) {
  // TODO: Set default format based on locale
  return (
    <>
      {value !== -1 ? (
        <FormattedNumber
          style="percent"
          value={value}
          maximumFractionDigits={2}
        />
      ) : (
        <Text>{NOT_AVAILABLE.toLowerCase()}</Text>
      )}
    </>
  )
}
