import styled from 'styled-components'
import Tooltip from 'react-bootstrap/Tooltip'

import {
  FontPreset,
  FontStyle,
  Size,
  Truncate,
  Alignment,
  TAlignmentMixin,
  TFontStyleMixin,
  TSizeMixin,
  TTruncateMixin,
  TPaddingMixin,
  Padding,
  TBackgroundMixin,
  Background,
  Border,
  TBorderMixin,
} from '../styles/mixins'
import { IThemeProps } from '../../../models/interfaces'

export const TooltipText = styled(Tooltip).withConfig({
  shouldForwardProp: (prop) =>
    ![
      'maxWidth',
      'whiteSpace',
      'textAlign',
      'lineHeight',
      'backgroundColor',
      'borderRadius',
    ].includes(prop as string),
})<
  TFontStyleMixin &
    TSizeMixin &
    TTruncateMixin &
    TAlignmentMixin &
    TPaddingMixin &
    TBackgroundMixin &
    TBorderMixin
>`
  && .tooltip-inner {
    ${FontPreset('paragraph-semibold')}
    ${(props: TFontStyleMixin & IThemeProps) =>
      FontStyle({
        ...props,
        lineHeight: props.lh || props.lineHeight || 'unset',
      })}
    ${(props: TSizeMixin) =>
      Size({
        maxWidth: props.maxWidth,
      })}
    ${(props: TTruncateMixin) =>
      Truncate({
        whiteSpace: props.whiteSpace,
      })}
    ${(props: TAlignmentMixin) =>
      Alignment({
        textAlign: props.textAlign,
      })}
    ${(props: TPaddingMixin) =>
      Padding({
        padding: props.padding,
      })}
    ${(props: TBackgroundMixin) =>
      Background({
        backgroundColor: props.backgroundColor,
      })}
      ${(props: TBorderMixin) =>
      Border({
        border: { borderRadius: props.border?.borderRadius },
      })}
  }

  && .tooltip-arrow::before {
    ${(props: TBackgroundMixin) =>
      Border({
        border: { borderTopColor: props.backgroundColor },
      })};
  }
`
