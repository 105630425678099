import { ICampaign, ICampaignCreate } from '@unfoldrtech/portal-mic'
import { transformAdGroup } from './transformAdGroup'

export const transformCampaign = (campaign: ICampaign): ICampaignCreate => {
  const campaignToSave = <ICampaignCreate>{
    title: campaign.title,
    startDate: campaign.startDate,
    endDate: campaign.endDate || undefined,
    auctionStrategy: campaign.auctionStrategy,
    pricingStrategy: campaign.pricingStrategy,
    budget: Number(campaign.budget),
    channelType: campaign.channelType,
    pacing: campaign.pacing,
    productIdList: campaign.productList?.map((p) => p.id) || [],
    adGroupList: [],
    status: campaign.status,
    frequency: campaign.frequency,
  }

  campaign.adGroupList?.forEach((adGroup, index) => {
    campaignToSave.adGroupList[index] = transformAdGroup(adGroup)
  })

  return campaignToSave
}
