import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import { generateCorrelationId } from '../../utils/helpers'
import { IRegisterConsentCreate } from '../../models/core'
import { coreAPIV3 } from '../../services/coreAPIV3'

export const useRegisterUserConsent = () => {
  return useMutation<
    AxiosResponse<void>,
    AxiosError<IError>,
    {
      payload: IRegisterConsentCreate
    }
  >([`registerUserConsent`], (params) =>
    coreAPIV3
      .misc()
      .registerUserConsent(generateCorrelationId(), params.payload)
  )
}
