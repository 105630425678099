import styled from 'styled-components'
import Form from 'react-bootstrap/Form'

import { IThemeProps } from '../../../../models/interfaces'
import { Action, TruncatePreset } from '../../styles/mixins'

export const SelectFilter = styled(Form.Select)`
  padding: 0 var(--padding-default);
  box-shadow: 0 2px 3px 0 var(--border-color-filter-box-shadow);

  ${(props: IThemeProps) =>
    Action({
      cursor: 'pointer',
      height: 'var(--button-size-lg)',

      backgroundColor:
        props.theme?.colors?.background?.primary ||
        'var(--background-color-default)',

      fontFamily: 'OpenSans-SemiBold',
      fontSize: 'var(--font-size-pa)',
      textTransform: 'uppercase',
      color: props.theme?.colors?.font?.primary || 'var(--font-color-default)',

      border: {
        radius: '2px',
        width: '1px',
        style: 'solid',
        color: 'var(--border-color-default)',
      },
      borderBottom: {
        radius: '0px',
        width: '3px',
        style: 'solid',
        color: 'var(--border-color-filter-bottom)',
      },
    })};

  ${TruncatePreset('260px')};
`
