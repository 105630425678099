import { PayloadAction } from '@reduxjs/toolkit'
import { EParamChannelType } from '@unfoldrtech/portal-mic'
import { TReportingFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'

const setChannelType = (
  state: TReportingFilters,
  action: PayloadAction<{
    channelType?: EParamChannelType
  }>
) => {
  state.channelType = action.payload.channelType
}

export default setChannelType
