import { PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'

import { ICampaign } from '../../../models'
import { getSelectedAdGroup } from '../utils'

const resetAdGroupDayTimeTarget = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    adGroupId: number
  }>
) => {
  const selectedAdGroup = getSelectedAdGroup(state, action.payload.adGroupId)

  if (!selectedAdGroup) return

  selectedAdGroup.criteria = undefined
  selectedAdGroup.days = undefined
  selectedAdGroup.times = undefined
}

export default resetAdGroupDayTimeTarget
