import React, { lazy } from 'react'
import { EUserRole } from '@unfoldrtech/portal-mic'
import { Route } from 'react-router-dom'

import { PortalRoutes } from './PortalRoutes'
import AuthenticatedRoute from './AuthenticatedRoute'
import { lazyRetry } from '../../utils/lazyRetry'
import Unsubscribe from '../../pages/Unsubscribe'

const Organisations = lazy(() =>
  lazyRetry(() => import('../../pages/Organisations'))
)

const MiscRoutes = () => {
  return (
    <>
      <PortalRoutes>
        <Route
          path="organisations"
          element={
            <AuthenticatedRoute
              authRole={[
                EUserRole.AdvertiserInsights,
                EUserRole.RetailerInsights,
                EUserRole.None,
              ]}
            >
              <Organisations />
            </AuthenticatedRoute>
          }
          /* exact */
        />
        <Route
          path="unsubscribe"
          element={
            <AuthenticatedRoute
              authRole={[
                EUserRole.AdvertiserInsights,
                EUserRole.RetailerInsights,
                EUserRole.None,
              ]}
            >
              <Unsubscribe />
            </AuthenticatedRoute>
          }
          /* exact */
        />
      </PortalRoutes>
    </>
  )
}

export default MiscRoutes
