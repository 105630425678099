import { IDOOHCampaign } from '../../../models'

export const selectDoohCampaignDetails = ({
  doohCampaign: campaignState,
}: {
  doohCampaign: IDOOHCampaign
}) => ({
  channelType: campaignState.channelType,
  title: campaignState.title,
  id: campaignState.id,
})
