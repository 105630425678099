import {
  EChannelType,
  // IAdGroup,
  // ICampaign
} from '@unfoldrtech/portal-mic'

// TODO: MIC update
import { ICampaign, IAdGroup } from '../../../models/interfaces/ICampaignState'
import { TPlacement } from '../../../models/types/TPlacement'

export const selectCampaignAdGroups = ({
  campaign: campaignState,
}: {
  campaign: ICampaign
}) =>
  (campaignState.adGroupList as Array<
    IAdGroup & { placement?: TPlacement; channelType?: EChannelType }
  >) || []
