import { EPageType } from '@unfoldrtech/portal-mic'
import React from 'react'

import { SVGContainer } from '../containers'
import { IconCategoriesPage, IconHome, IconSearch } from '../icons'

interface CurrentPageTypeIconProps {
  pageType?: EPageType
}

export function CurrentPageTypeIcon({ pageType }: CurrentPageTypeIconProps) {
  if (pageType === EPageType.Categories) {
    return (
      <SVGContainer>
        <IconCategoriesPage />
      </SVGContainer>
    )
  }
  if (pageType === EPageType.Home) {
    return (
      <SVGContainer>
        <IconHome />
      </SVGContainer>
    )
  }
  if (pageType === EPageType.Search) {
    return (
      <SVGContainer>
        <IconSearch />
      </SVGContainer>
    )
  }

  return <> </>
}
