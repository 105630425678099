import { EPlatformType } from '@unfoldrtech/portal-mic'
import React from 'react'

import { SVGContainer } from '../containers'
import { IconWeb } from '../icons'
import SvgIconApp from '../icons/IconApp'

interface CurrentPlatformIconProps {
  platformType: EPlatformType
}

export function CurrentPlatformIcon({
  platformType,
}: CurrentPlatformIconProps) {
  if (platformType === EPlatformType.AppMobile) {
    return (
      <SVGContainer>
        <SvgIconApp />
      </SVGContainer>
    )
  }
  if (platformType === EPlatformType.WebDesktop) {
    return (
      <SVGContainer>
        <IconWeb />
      </SVGContainer>
    )
  }
  if (platformType === EPlatformType.WebMobile) {
    return (
      <SVGContainer>
        <IconWeb />
      </SVGContainer>
    )
  }

  return <> </>
}
