import { ECurrency } from '@unfoldrtech/portal-mic'
import React from 'react'
import I18NText from '../../I18NText'

export function CurrencyOptionText({ currency }: { currency?: ECurrency }) {
  if (currency === ECurrency.Eur) {
    return (
      <>
        <I18NText id="currency.option.eur" />
      </>
    )
  }
  if (currency === ECurrency.Usd) {
    return (
      <>
        <I18NText id="currency.option.usd" />
      </>
    )
  }
  if (currency === ECurrency.Gbp) {
    return (
      <>
        <I18NText id="currency.option.gbp" />
      </>
    )
  }
  if (currency === ECurrency.Aud) {
    return (
      <>
        <I18NText id="currency.option.aud" />
      </>
    )
  }
  if (currency === ECurrency.Cad) {
    return (
      <>
        <I18NText id="currency.option.cad" />
      </>
    )
  }
  if (currency === ECurrency.Cny) {
    return (
      <>
        <I18NText id="currency.option.cny" />
      </>
    )
  }
  if (currency === ECurrency.Inr) {
    return (
      <>
        <I18NText id="currency.option.inr" />
      </>
    )
  }
  if (currency === ECurrency.Dkk) {
    return (
      <>
        <I18NText id="currency.option.dkk" />
      </>
    )
  }
  if (currency === ECurrency.Nok) {
    return (
      <>
        <I18NText id="currency.option.nok" />
      </>
    )
  }
  if (currency === ECurrency.Sek) {
    return (
      <>
        <I18NText id="currency.option.sek" />
      </>
    )
  }
  return null
}
