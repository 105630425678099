import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconWebArrow = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 19 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path d="M0 8h16m-6 6 6-6-6-6" stroke="#fff" strokeWidth={4} />
  </svg>
)

export default SvgIconWebArrow
