import React, { useEffect, useState } from 'react'
import { TreeSelect, TreeNodeProps } from 'antd'
import styled from 'styled-components'
import { useIntl } from 'react-intl'
import { IconZoom } from '../../icons'
import { SVGContainer, Container } from '../../containers'
import { TTranslationKey } from '../../../../models/types'

const CheckBoxSelect = styled(TreeSelect)`
  && input {
    height: var(--button-size-sm);
  }
`
const { SHOW_ALL } = TreeSelect

export type TKey = string | number

export interface ITreeData {
  id?: TKey
  pId?: TKey
  value?: TKey
  title?: string
  isLeaf?: boolean
  disableCheckbox?: boolean
  checkable?: boolean
  disabled?: boolean
  selectable?: boolean
  checked?: boolean
  switcherIcon?: React.ReactNode | React.ReactNode[]
}

export const createTreeNode = ({
  id,
  pId,
  value,
  title,
  isLeaf,
  disableCheckbox,
  checkable,
  disabled,
  selectable,
  switcherIcon,
}: ITreeData): ITreeData => {
  return {
    id,
    pId,
    title,
    value: value || id,
    isLeaf: isLeaf || Boolean(pId),
    disableCheckbox,
    checkable,
    disabled,
    selectable,
    switcherIcon,
  }
}

export interface ITreeNodeProps extends TreeNodeProps {}

export function StyledTreeSelect({
  treeData,
  placeholder,
  isClearInput,
  treeCheckStrictly,
  onChange,
  onLoadData,
}: {
  treeData: Array<ITreeData>
  placeholder: TTranslationKey
  isClearInput?: boolean
  treeCheckStrictly?: boolean
  onChange?: (value: Array<ITreeData>) => void
  onLoadData?: ({ node }: { node: ITreeData }) => Promise<unknown>
}) {
  const intl = useIntl()
  const [selectedValue, setSelectedValue] = useState<Array<ITreeData>>()

  const loadData = ({ node }: { node: ITreeData }) => {
    if (onLoadData) {
      return onLoadData({ node })
    }

    return new Promise((resolve) => {
      resolve({ node })
    })
  }

  const onSelect = (itemList: Array<ITreeData>) => {
    setSelectedValue(itemList)
    if (onChange) {
      onChange(itemList)
    }
  }

  useEffect(() => {
    if (isClearInput) {
      setSelectedValue([])
    }
  }, [isClearInput])

  return (
    <>
      <Container display="flex">
        <SVGContainer
          preset="h5"
          alignSelf="center"
          padding="0 0 0 var(--gutter-4x)"
        >
          <IconZoom />
        </SVGContainer>
        <CheckBoxSelect
          treeDataSimpleMode
          style={{ width: '100%' }}
          dropdownStyle={{ maxHeight: 400, overflow: 'auto' }}
          value={selectedValue}
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          // maybe overwritten from props in future
          treeCheckable={true}
          showCheckedStrategy={SHOW_ALL}
          bordered={false}
          treeNodeFilterProp="title"
          // values from props
          treeCheckStrictly={treeCheckStrictly}
          placeholder={intl.formatMessage({
            id: placeholder,
          })}
          loadData={(node) => loadData({ node: node as ITreeData })}
          onChange={(v) => onSelect(v as Array<ITreeData>)}
          treeData={treeData}
        />
      </Container>
    </>
  )
}
