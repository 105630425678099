/* eslint-disable no-console */
import React from 'react'

export const VersionInfo: React.FC = () => {
  const version = process.env.REACT_APP_VERSION

  console.info(
    `version: ${version || 'No version information present at build time'}`
  )

  return <></>
}
