import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconPage = (props: SVGProps<SVGSVGElement>) => (
  <svg width="1em" height="1em" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path
      d="M1.25 2.636c0-.602.49-1.09 1.091-1.09h13.091a1.09 1.09 0 0 1 1.091 1.09v13.091c0 .602-.489 1.091-1.09 1.091H2.34a1.09 1.09 0 0 1-1.09-1.09V2.635zm0 3.273h15.273m-4.363 0v10.909"
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgIconPage
