import { createSlice, PayloadAction } from '@reduxjs/toolkit'
import { ICampaignValidity } from '../../models/interfaces'

export const campaignValiditySlice = createSlice({
  name: 'campaignValidity',
  initialState: <ICampaignValidity>{},
  reducers: {
    resetValidity: () => <ICampaignValidity>{},
    setStartPageValidity: (
      state,
      action: PayloadAction<{
        isValid: boolean
      }>
    ) => {
      state.start = action.payload.isValid
    },
    setSPSettingsPageValidity: (
      state,
      action: PayloadAction<{
        isValid: boolean
      }>
    ) => {
      state.spSettings = action.payload.isValid
    },
    setProductsPageValidity: (
      state,
      action: PayloadAction<{
        isValid: boolean
      }>
    ) => {
      state.products = action.payload.isValid
    },
    setAdGroupsPageValidity: (
      state,
      action: PayloadAction<{
        eventKey: string
        isValid: boolean
      }>
    ) => {
      if (state.adGroups === undefined) {
        state.adGroups = {}
      }
      state.adGroups[action.payload.eventKey] = action.payload.isValid
    },
    removeAdGroupsPageValidity: (
      state,
      action: PayloadAction<{
        eventKey: string
      }>
    ) => {
      if (state.adGroups) {
        delete state.adGroups[action.payload.eventKey]
      }
    },
    removeAdGroupCreativePageValidity: (
      state,
      action: PayloadAction<{
        eventKey: string
      }>
    ) => {
      if (state.daCreatives) {
        delete state.daCreatives[action.payload.eventKey]
      }
    },
    setReviewPageValidity: (
      state,
      action: PayloadAction<{
        isValid: boolean
      }>
    ) => {
      state.review = action.payload.isValid
    },
    setDASettingsPageValidity: (
      state,
      action: PayloadAction<{
        isValid: boolean
      }>
    ) => {
      state.daSettings = action.payload.isValid
    },
    setDACreativesPageValidity: (
      state,
      action: PayloadAction<{
        eventKey: string
        isValid: boolean
      }>
    ) => {
      if (state.daCreatives === undefined) {
        state.daCreatives = {}
      }
      state.daCreatives[action.payload.eventKey] = action.payload.isValid
    },
    setDOOHSettingsPageValidity: (
      state,
      action: PayloadAction<{
        isValid: boolean
      }>
    ) => {
      state.isDoohSettingsCompleted = action.payload.isValid
    },
    setDOOHCreativesPageValidity: (
      state,
      action: PayloadAction<{
        eventKey: string
        isValid: boolean
      }>
    ) => {
      if (state.doohCreatives === undefined) {
        state.doohCreatives = {}
      }
      state.doohCreatives[action.payload.eventKey] = action.payload.isValid
    },
    removeAdGroupDOOHCreativePageValidity: (
      state,
      action: PayloadAction<{
        eventKey: string
      }>
    ) => {
      if (state.doohCreatives) {
        delete state.doohCreatives[action.payload.eventKey]
      }
    },
  },
})

export const {
  resetValidity,
  setStartPageValidity,
  setSPSettingsPageValidity,
  setDASettingsPageValidity,
  setDOOHSettingsPageValidity,
  setProductsPageValidity,
  setAdGroupsPageValidity,
  removeAdGroupsPageValidity,
  removeAdGroupCreativePageValidity,
  setReviewPageValidity,
  setDACreativesPageValidity,
  setDOOHCreativesPageValidity,
  removeAdGroupDOOHCreativePageValidity,
} = campaignValiditySlice.actions

// The function below is called a selector and allows us to select a value from
// the state. Selectors can also be defined inline where they're used instead of
// in the slice file. For example: `useSelector((state) => state.counter.value)`
export const selectCampaignValidity = ({
  campaignValidity,
}: {
  campaignValidity: ICampaignValidity
}) => campaignValidity

export const campaignValidityReducer = campaignValiditySlice.reducer
