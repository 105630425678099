import React from 'react'
import FooterCopyright from '../FooterCopyright'
import LogoTagline from '../LogoTagline'
import { UserConsentModal } from '../UserConsentModal'
import { Container } from '../Global'

interface IUserConsentProps {
  onConsent: () => void
}

export const UserConsent = ({ onConsent }: IUserConsentProps) => {
  return (
    <Container
      height="100vh"
      display="flex"
      flexDirection="column"
      overflow="auto"
      backgroundColor="var(--background-color-dark)"
    >
      <LogoTagline />
      <Container
        width="100%"
        flex="1"
        display="flex"
        justifyContent="center"
        paddingTop="var(--gutter-5x)"
      >
        <UserConsentModal onConsent={onConsent} />
      </Container>
      <FooterCopyright />
    </Container>
  )
}
