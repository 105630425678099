import React, { FC } from 'react'
import { withLDProvider as withFeatureFlagProvider } from 'launchdarkly-react-client-sdk'
import { NOT_AVAILABLE } from '../../utils/constants'

const clientKey = process.env.REACT_APP_FEATURE_FLAG_CLIENT_KEY || NOT_AVAILABLE

const FeatureFlagProvider: FC = ({ children }) => {
  return <>{children}</>
}

export default withFeatureFlagProvider({
  clientSideID: clientKey,
  reactOptions: {
    useCamelCaseFlagKeys: false,
  },
})(FeatureFlagProvider)
