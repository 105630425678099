import styled from 'styled-components'
import { IThemeProps } from '../../../models/interfaces'
import { CheckIconContainer } from './CheckIconContainer'

export const SidebarCheckContainer = styled(CheckIconContainer)<{
  backgroundColor?: string
}>`
  font-size: var(--font-size-pa);
  width: var(--font-size-h2);
  height: var(--font-size-h2);
  margin: 0 var(--margin-default) 0 calc(var(--gutter-6x) * -1);
  border: 1px solid
    ${(props: IThemeProps) =>
      props.theme?.colors?.font?.primary || 'var(--font-color-default)'};
  background-color: ${(props) =>
    props.backgroundColor ||
    props.theme?.colors?.background?.primary ||
    'var(--background-color-default)'};
  color: ${(props: IThemeProps) =>
    props.theme?.colors?.font?.primary || 'var(--font-color-default)'};
`
