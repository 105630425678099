const loadVideo = (url: string): Promise<HTMLVideoElement> =>
  new Promise((resolve, reject) => {
    try {
      const video = document.createElement('video')
      video.preload = 'metadata'

      video.addEventListener(
        'loadedmetadata',
        function loadMetaData() {
          video.height = this.videoHeight
          video.width = this.videoWidth

          // send back result
          resolve(video)
        },
        false
      )

      video.src = url
    } catch (e) {
      reject(e)
    }
  })

export const getVideoSpecification = async (url: string) => {
  const video = await loadVideo(url)
  return video
}
