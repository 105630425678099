import styled, { CSSProperties } from 'styled-components'
import FloatingLabel from 'react-bootstrap/FloatingLabel'

import {
  FontPreset,
  FontStyle,
  TFontStyleMixin,
  TruncatePreset,
} from '../styles/mixins'

export const FloatingLabelText = styled(FloatingLabel).withConfig({
  shouldForwardProp: (prop) =>
    !['paddingLeft', 'paddingRight'].includes(prop as string),
})<TFontStyleMixin & Pick<CSSProperties, 'paddingLeft' | 'paddingRight'>>`
  display: flex;

  &.input-error {
    label {
      ${(props) =>
        FontStyle({
          color:
            props.theme?.colors?.font?.error || 'var(--border-color-error)',
        })}
    }
  }

  label {
    padding-top: 0;
    padding-left: ${(props) => props.paddingLeft || 'var(--gutter-4x)'};
    padding-right: ${(props) => props.paddingRight || 'var(--gutter-4x)'};

    ${FontPreset('placeholder')};

    ${(props: TFontStyleMixin) =>
      FontStyle({
        ...props,
      })}
    ${TruncatePreset('100%')};
  }
`
