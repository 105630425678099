import styled from 'styled-components'

import {
  FontPreset,
  FontStyle,
  TFontStyleMixin,
  Truncate,
  TTruncateMixin,
} from '../styles/mixins'

export const ParagraphSmallOpenSans = styled.div<
  TFontStyleMixin & TTruncateMixin
>`
  ${FontPreset('paragraph small opensans')};

  ${(props: TFontStyleMixin) =>
    FontStyle({
      ...props,
    })}
  ${(props: TTruncateMixin) => Truncate({ ...props })}
`
