import styled from 'styled-components'
import { Container } from './Container'

export const CenterContainer = styled(Container)`
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  height: ${(props) => props.height || '100vh'};
  flex-direction: column;
`
