import { PayloadAction } from '@reduxjs/toolkit'
import { EParamInventoryType } from '@unfoldrtech/portal-mic'
import { TReportingFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'

const setInventoryType = (
  state: TReportingFilters,
  action: PayloadAction<{
    inventoryType?: EParamInventoryType
  }>
) => {
  state.inventoryType = action.payload.inventoryType
}

export default setInventoryType
