import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconHome = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 22"
    {...props}
  >
    <path
      d="M3.75 12.878v8.25h6v-6a1.5 1.5 0 0 1 1.5-1.5h1.5a1.5 1.5 0 0 1 1.5 1.5v6h6v-8.25m-19.5-1.5L10.94 1.19a1.5 1.5 0 0 1 2.121 0L23.25 11.38M16.5 4.628v-1.5h3.75v5.25"
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgIconHome
