import React, { useState, useEffect, useMemo } from 'react'
import {
  EMetricAggregateType,
  EOrgType,
  EReportColumnHeader,
  EReportColumnType,
  IReportGraph,
} from '@unfoldrtech/portal-mic'
import { schemeTableau10 } from 'd3-scale-chromatic'
import { FormatNumberOptions, useIntl } from 'react-intl'
import { useLocation } from 'react-router-dom'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { XAxisProps, YAxisProps } from 'recharts'

import { IReportChartProps, IReportFiltersProps } from '../../models/interfaces'
import { Container, GridContainer, StyledImage } from '../Global'
import { BigNumber, LineChart } from '../Widgets'
import { TChartMetricData } from '../../models/types'
import { selectReportingFilters } from '../../store/reportingFilters'
import MetricsSelector from './MetricsSelector'
import Loading from '../Loading'
import { getLabelFormat } from '../../utils/helpers'
import { sanitizeDataTestId } from '../../utils/sanitizeDataTestId'
import {
  NOT_AVAILABLE,
  SHARE_OF_VOICE_PROD_DEPLOYMENT_DATE,
} from '../../utils/constants'
import useGetCurrentCurrency from '../../hooks/useGetCurrentCurrency'

const lineColors = schemeTableau10
const bigNumberWidgetWidth = '214px'

function ChartWrapper(chartProps: IReportFiltersProps & IReportChartProps) {
  const { symbol: currencySymbol, text: currencyText } = useGetCurrentCurrency()

  const intl = useIntl()
  const location = useLocation()

  const [selectedMetrics, setSelectedMetrics] = useState<Array<string>>([])
  const [pinnedMetrics, setPinnedMetrics] = useState<Array<string>>([])
  const [pinnedMetricIndices, setPinnedMetricIndices] = useState<Array<number>>(
    []
  )
  const [chartData, setChartData] = useState<Array<TChartMetricData>>([])

  // Ensuring that other params updates refresh the chart
  const [chartParamsUpdated, setChartParamsUpdated] = useState<boolean>()

  const {
    channelType,
    inventoryType,
    startDate,
    endDate,
    sortBy,
    sortOrder,
    timeWindow,
    campaignIds,
    adGroupIds,
    aggregationType,
  } = useSelector(selectReportingFilters)

  const {
    orgType,
    retailerId,
    advertiserId,
    advertiserReportPageType,
    retailerReportPageType,
    getChartDataFn,
    defaultMetric,
  } = chartProps

  const {
    data: chartDataResponseObj,
    refetch,
    isFetching,
    isFetchedAfterMount,
    dataUpdatedAt,
  } = getChartDataFn({
    retailerId,
    advertiserId,
    advertiserReportPageType,
    retailerReportPageType,
    channelType,
    inventoryType,
    startDate,
    endDate,
    sortBy,
    sortOrder,
    timeWindow,
    campaignIds,
    adGroupIds,
    aggregationType,
    enabled: false,
  })

  const chartDataResponse: IReportGraph =
    chartDataResponseObj?.data?.chart || ({} as IReportGraph)

  const metrics =
    chartDataResponse.metrics?.map((metric) => {
      const translatedHeader = intl.formatMessage({
        id: `report.table.header.${orgType?.toLowerCase()}.${metric}`,
      })
      return translatedHeader
    }) || []

  // param will never be a string, this is a compatibility adaptation for V3
  const onToggleSelectedMetric = (metricIndex: number | string) => {
    const metric = chartDataResponse.metrics?.find(
      (_m, index) => index === metricIndex
    )
    let sanitizedSelectedMetrics: Array<string> = JSON.parse(
      JSON.stringify(selectedMetrics)
    )
    if (metric && selectedMetrics.includes(metric)) {
      sanitizedSelectedMetrics = sanitizedSelectedMetrics.filter(
        (selectedMetric) => selectedMetric !== metric
      )
    } else {
      if (selectedMetrics.length === 2) {
        sanitizedSelectedMetrics.shift()
      }
      if (metric) {
        sanitizedSelectedMetrics.push(metric)
      }
    }
    setSelectedMetrics(sanitizedSelectedMetrics)
  }

  // param will never be a string, this is a compatibility adaptation for V3
  const onUnpinMetric = (metricIndex: number | string) => {
    const metric = chartDataResponse.metrics?.find(
      (_m, index) => index === metricIndex
    )
    const pinnedIndices = pinnedMetrics.filter((met) => met !== metric)
    setPinnedMetrics(pinnedIndices)
    if (metric && selectedMetrics.includes(metric)) {
      onToggleSelectedMetric(metricIndex)
    }
  }

  // param will never be a string, this is a compatibility adaptation for V3
  const onPinMetric = (metricIndex: number | string) => {
    const metric = chartDataResponse.metrics?.find(
      (_m, index) => index === metricIndex
    )
    if (metric && !pinnedMetrics.includes(metric)) {
      setPinnedMetrics([...pinnedMetrics, metric])
      onToggleSelectedMetric(metricIndex)
    }
  }

  const transformChartData = () => {
    if (selectedMetrics.length) {
      const transformedChartData: Array<TChartMetricData> = []
      chartDataResponse.data?.forEach((valueList, valueIndex) => {
        const chartDataObj: TChartMetricData = {
          date: dayjs(valueList[0]).valueOf(),
        }
        selectedMetrics?.forEach((metric) => {
          const metricIndex = chartDataResponse.metrics?.findIndex(
            (met) => met === metric
          )
          if (metricIndex !== -1) {
            const metricName = metrics[metricIndex]
            const value = valueList[metricIndex + 1]
            const timestamp = chartDataResponse.data[valueIndex][0]

            let numberValue =
              value !== undefined && value !== null ? Number(value) : undefined

            if (
              numberValue &&
              chartDataResponse.types?.length &&
              chartDataResponse.types[metricIndex] ===
                EReportColumnType.Percentage
            ) {
              numberValue *= 100
            }

            // Don't show share of voice data from before share of voice went live
            if (
              chartDataResponse.metrics[metricIndex] ===
                EReportColumnHeader.ShareOfVoice &&
              dayjs(timestamp) < SHARE_OF_VOICE_PROD_DEPLOYMENT_DATE
            ) {
              chartDataObj[metricName] = NOT_AVAILABLE
              return chartDataObj
            }

            // Round to max 2 decimals
            const formattedValue =
              numberValue || numberValue === 0
                ? Math.round(numberValue * 100) / 100
                : (undefined as unknown as number)

            chartDataObj[metricName] =
              formattedValue !== -1 ? formattedValue : 0
          } else {
            selectedMetrics.splice(selectedMetrics.indexOf(metric), 1)
            pinnedMetrics.splice(pinnedMetrics.indexOf(metric), 1)
            pinnedMetricIndices.splice(metricIndex, 1)
          }
        })
        transformedChartData.push(chartDataObj)
      })
      return transformedChartData
    }
    return []
  }

  const getFormattedMetricValue = (
    value: number | string,
    index: number,
    applyStyle?: boolean
  ) => {
    let formattedValue: string | number = value

    if (
      chartDataResponse.types?.length &&
      chartDataResponse.types[index] === EReportColumnType.Currency
    ) {
      // TODO: Based on locale
      const formatOptions: FormatNumberOptions = {
        currency: currencyText,
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }
      if (applyStyle) {
        formatOptions.style = 'currency'
      }
      formattedValue = intl.formatNumber(Number(value), formatOptions)
    } else if (
      chartDataResponse.types?.length &&
      chartDataResponse.types[index] === EReportColumnType.Percentage
    ) {
      // Don't show share of voice data from before share of voice went live
      if (
        chartDataResponse.metrics[index] === EReportColumnHeader.ShareOfVoice &&
        dayjs(chartDataResponse.data[chartDataResponse.data.length - 1][0]) <
          SHARE_OF_VOICE_PROD_DEPLOYMENT_DATE
      ) {
        return NOT_AVAILABLE
      }
      const formatOptions: FormatNumberOptions = {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      }
      if (applyStyle) {
        formatOptions.style = 'percent'
      }
      formattedValue = intl.formatNumber(Number(value), formatOptions)
    } else if (
      chartDataResponse.types?.length &&
      chartDataResponse.types[index] === EReportColumnType.Number
    ) {
      formattedValue = intl.formatNumber(Number(value), {
        maximumFractionDigits: 2,
        minimumFractionDigits: 0,
      })
    }
    return value !== -1 ? formattedValue : NOT_AVAILABLE
  }

  const getTicks = (
    start: string | number,
    end: string | number,
    numTicks: number
  ) => {
    const startTimestamp = dayjs(start).valueOf()
    const endTimestamp = dayjs(end).valueOf()

    const diffDays = dayjs(endTimestamp).diff(startTimestamp, 'milliseconds')

    const current = startTimestamp
    const interval = Math.round(diffDays / (numTicks - 1))

    const ticks = [startTimestamp]

    for (let index = 1; index < numTicks - 1; index += 1) {
      const tick = current + interval * index
      ticks.push(tick)
    }

    ticks.push(endTimestamp)
    return ticks
  }

  const getMetricUnit = (type: EReportColumnType): string => {
    let unit = ''
    switch (type) {
      /* case EReportColumnType.ChannelType:
        break
        case EReportColumnType.InventoryType:
        break
        case EReportColumnType.Status:
        break
        case EReportColumnType.String:
        break
        case EReportColumnType.Date:
        break
        case EReportColumnType.Number:
        break
      */
      case EReportColumnType.Currency:
        unit = `(${currencySymbol})`
        break
      case EReportColumnType.Percentage:
        unit = '(%)'
        break
      default:
        break
    }

    return unit
  }

  const yAxiiProps: Record<string | number, YAxisProps> = useMemo(() => {
    const axiiProps: Record<number, YAxisProps> = {}

    selectedMetrics.forEach((/* metricIndex, index */) => {
      /* const metric = metrics[metricIndex]
      const values = chartData
        .filter((point) => point[metric] !== undefined)
        .map((point) => Number(point[metric]))

      const minValue = Math.min(...values)
      const maxValue = Math.max(...values)
      const min = minValue
      const max = minValue + 5 < maxValue ? maxValue : min + 5

      axiiProps[index] = {
        min,
        max,
        domain: [min, max],
      } */
    })

    return axiiProps
  }, [chartData])

  const xAxisProps: XAxisProps = useMemo(() => {
    const axisProps: XAxisProps = {
      dataKey: 'date',
      type: 'number',
      name: 'Time',
    }
    if (chartData.length) {
      const start = chartData[0].date
      const end = chartData.slice(-1)[0].date
      const numTicks = 2
      axisProps.domain = [start, end]
      axisProps.tickFormatter = (time) =>
        dayjs(time).format(getLabelFormat(start, end))
      axisProps.ticks = getTicks(start, end, numTicks)
    }

    return axisProps
  }, [chartData])

  function getDefaultMetricIndex() {
    const defaultSelectedMetric = chartDataResponse.metrics?.findIndex(
      (metric) => metric === defaultMetric
    )
    const defaultMetricIndex =
      defaultSelectedMetric > -1 ? defaultSelectedMetric : 0
    return defaultMetricIndex
  }

  // file deepcode ignore DuplicateIfBody: not duplicate
  useEffect(() => {
    if (channelType) {
      setChartParamsUpdated(true)
    } else if (
      channelType === undefined &&
      location.pathname.endsWith('campaigns-all')
    ) {
      /**
       * If location contains 'adgroups-all', channel type is undefined
       */
      setChartParamsUpdated(true)
    }
  }, [channelType])

  useEffect(() => {
    if (inventoryType) {
      setChartParamsUpdated(true)
    } else if (
      inventoryType === undefined &&
      location.pathname.endsWith('adgroups-all')
    ) {
      /**
       * If location contains 'adgroups-all', inventory type is undefined
       */
      setChartParamsUpdated(true)
    }
  }, [inventoryType])

  useEffect(() => {
    if (retailerReportPageType) {
      setChartParamsUpdated(true)
    }
  }, [retailerReportPageType])

  useEffect(() => {
    if (advertiserReportPageType) {
      setChartParamsUpdated(true)
    }
  }, [advertiserReportPageType])

  useEffect(() => {
    if (startDate && endDate) {
      setChartParamsUpdated(true)
    }
  }, [startDate, endDate, aggregationType])

  useEffect(() => {
    if (chartParamsUpdated) {
      refetch()
      setChartParamsUpdated(false)
    }
  }, [chartParamsUpdated])

  useEffect(() => {
    /**
     * Select a default metric if
     *   - chart data has been refetched
     *   - & no metrics are pinned
     */
    if (isFetchedAfterMount && !pinnedMetrics.length) {
      const defaultMetricIndex = getDefaultMetricIndex()
      onPinMetric(defaultMetricIndex)
    }

    /**
     * Sanitise the pinned metrics & selected metrics if
     *   - chart data has been refetched
     *   - & some metrics are pinned
     */
    if (
      isFetchedAfterMount &&
      pinnedMetrics.length &&
      chartDataResponse &&
      chartDataResponse.metrics
    ) {
      const sanitizedPinnedMetrics = pinnedMetrics.filter((metric) => {
        const metricIndex = chartDataResponse.metrics?.findIndex(
          (met) => met === metric
        )
        if (metricIndex > -1) {
          return chartDataResponse.metrics.length > metricIndex
        }
        // we filter out pinned metrics that are not available anymore
        return false
      })
      if (pinnedMetrics.length === 0) {
        const defaultMetricIndex = getDefaultMetricIndex()
        const metric = chartDataResponse.metrics.find(
          (_m, index) => index === defaultMetricIndex
        )
        if (metric) {
          pinnedMetrics.push(metric)
        }
      }
      setPinnedMetrics(sanitizedPinnedMetrics)

      // selected metrics
      const sanitizedSelectedMetrics = selectedMetrics.filter(
        (metricIndex) => sanitizedPinnedMetrics.indexOf(metricIndex) > -1
      )
      if (sanitizedSelectedMetrics.length === 0) {
        sanitizedSelectedMetrics.push(pinnedMetrics[0])
      }
      setSelectedMetrics(sanitizedSelectedMetrics)
    }
  }, [isFetchedAfterMount])

  useEffect(() => {
    if (dataUpdatedAt || selectedMetrics.length > 0) {
      setChartData(transformChartData())
    }
  }, [dataUpdatedAt, selectedMetrics])

  useEffect(() => {
    const pinnedIndeces: Array<number> = []
    pinnedMetrics.forEach((metric) => {
      const metricIndex = chartDataResponse.metrics?.findIndex(
        (met) => met === metric
      )
      pinnedIndeces.push(metricIndex)
    })
    setPinnedMetricIndices(pinnedIndeces)
  }, [pinnedMetrics])

  return (
    <>
      {Boolean(chartDataResponse?.metrics?.length) &&
      Boolean(chartDataResponse?.aggregates?.length) &&
      Boolean(chartDataResponse?.data?.length) ? (
        <>
          <GridContainer
            gridGap="var(--margin-default)"
            gridTemplateColumns={`repeat(4, minmax(${bigNumberWidgetWidth}, 1fr))`}
            padding="var(--margin-default) 0"
            width="100%"
            minHeight="116px"
          >
            {pinnedMetricIndices.map((index) => {
              const metricName = chartDataResponse.metrics?.[index]
              const translatedMetricName = metrics[index]
              const { value, type } = chartDataResponse.aggregates[index] || {
                value: 0,
                type: EMetricAggregateType.Avg,
              }
              const borderBottomColor = lineColors[index % lineColors.length]
              const formattedValue: string | number = getFormattedMetricValue(
                value as number,
                index,
                true
              )

              const metric = chartDataResponse.metrics?.find(
                (_m, i) => index === i
              )

              return (
                <Container
                  data-testid={sanitizeDataTestId(`metric-${metricName}`)}
                  key={`${translatedMetricName}-${value}-${type}-${borderBottomColor}`}
                >
                  <BigNumber
                    id={index}
                    translatedMetricName={translatedMetricName}
                    metricName={metricName}
                    value={formattedValue}
                    description={type as string}
                    borderBottomColor={borderBottomColor}
                    onClick={onToggleSelectedMetric}
                    onRemove={onUnpinMetric}
                    isSelected={!!metric && selectedMetrics.includes(metric)}
                  />
                </Container>
              )
            })}
            {pinnedMetricIndices.length < 4 && (
              <Container>
                <MetricsSelector
                  metrics={metrics}
                  pinnedMetricIndices={pinnedMetricIndices}
                  onChange={onPinMetric}
                />
              </Container>
            )}
          </GridContainer>

          <Container width="100%" height="300px">
            <LineChart
              chartData={chartData}
              metrics={selectedMetrics.map((metric) => {
                const metricIndex = chartDataResponse.metrics?.findIndex(
                  (met) => met === metric
                )
                return metrics[metricIndex]
              })}
              xAxisProps={xAxisProps}
              yAxiiProps={yAxiiProps}
              tooltipProps={{
                labelFormatter: (label) =>
                  dayjs(label).format(
                    getLabelFormat(
                      chartData[0].date,
                      chartData.slice(-1)[0].date
                    )
                  ),
              }}
              metricColors={selectedMetrics.map((metric) => {
                const metricIndex = chartDataResponse.metrics?.findIndex(
                  (met) => met === metric
                )
                return lineColors[metricIndex]
              })}
              metricUnits={selectedMetrics.map((metric) => {
                const metricIndex = chartDataResponse.metrics?.findIndex(
                  (met) => met === metric
                )
                return getMetricUnit(chartDataResponse.types[metricIndex])
              })}
            />
          </Container>
        </>
      ) : (
        <StyledImage
          fluid
          data-testid="chart-placeholder"
          src={
            orgType === EOrgType.Retailer
              ? `${process.env.REACT_APP_CDN_URL}/images/f33ff4f9-cd01-49f5-ac76-77e8abed0473.png`
              : `${process.env.REACT_APP_CDN_URL}/images/13529de4-79e5-491d-83a7-1cf5b08b8d7b.png`
          }
          alt="home_placeholder"
          cursor="default"
        />
      )}
      <Loading show={isFetching} />
    </>
  )
}

export default ChartWrapper
