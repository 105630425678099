import styled from 'styled-components'

import { Border, TBorderMixin } from '../styles/mixins'
import { StyledTableRow } from './StyledTableRow'

export const StyledTableIconRow = styled(StyledTableRow)<
  TBorderMixin & { active?: boolean }
>`
  cursor: pointer;
  ${(props: TBorderMixin) => Border({ ...props })};

  .icon-arrow {
    svg {
      display: ${(props) => (props.active ? 'inline-block' : 'none')};
    }
  }

  :hover {
    & :first-child {
      opacity: 1;
    }
    .icon-arrow {
      svg {
        display: inline-block;
      }
    }
  }

  & :first-child {
    opacity: ${(props) => (props.active ? '1' : '')};
  }
`
