import { VIDEO_DURATION_MARGIN } from './constants'

export const isValidDuration = (
  duration: number,
  validationDuration: { min: number; max: number }
): boolean => {
  return (
    duration >= validationDuration.min - VIDEO_DURATION_MARGIN &&
    duration <= validationDuration.max + VIDEO_DURATION_MARGIN
  )
}
