import { initializeApp } from 'firebase/app'
import {
  getAuth,
  User,
  Auth,
  signInWithEmailAndPassword,
  signInWithCustomToken,
  onAuthStateChanged,
  getIdToken,
  getIdTokenResult,
  signOut,
  verifyPasswordResetCode,
  confirmPasswordReset,
  updatePassword,
  reauthenticateWithCredential,
  EmailAuthProvider,
  checkActionCode,
  setPersistence,
  browserSessionPersistence,
  browserLocalPersistence,
  inMemoryPersistence,
  indexedDBLocalPersistence,
  Persistence,
  RecaptchaVerifier,
  multiFactor,
  PhoneAuthProvider,
  PhoneMultiFactorGenerator,
  MultiFactorResolver,
  getMultiFactorResolver,
  MultiFactorInfo,
  MultiFactorSession,
  PhoneInfoOptions,
  MultiFactorError,
} from 'firebase/auth'
import { TFirebaseAuthPersistance } from '../models/types'

const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGE_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
  tenantId: process.env.REACT_APP_FIREBASE_TENANT_ID,
}

const envPersistance = process.env
  .REACT_APP_FIREBASE_AUTH_PERSISTANCE as TFirebaseAuthPersistance

let persistance: Persistence

if (envPersistance === 'LOCAL_INDEX_DB') {
  persistance = indexedDBLocalPersistence
} else if (envPersistance === 'LOCAL') {
  persistance = browserLocalPersistence
} else if (envPersistance === 'SESSION') {
  persistance = browserSessionPersistence
} else if (envPersistance === 'NONE') {
  persistance = inMemoryPersistence
}

export class Firebase {
  private auth: Auth

  constructor() {
    const app = initializeApp(firebaseConfig)
    this.auth = getAuth(app)
    this.auth.tenantId = firebaseConfig.tenantId || null

    if (persistance) {
      setPersistence(this.auth, persistance)
    }
  }

  getInstance() {
    return this.auth
  }

  async login({ email, password }: { email: string; password: string }) {
    const userCredential = await signInWithEmailAndPassword(
      this.auth,
      email,
      password
    )
    /* if (!userCredential.user.emailVerified) {
      await sendEmailVerification(userCredential.user)
    } */
    return userCredential
  }

  async reauthenticateWithCredential({
    currentPassword,
  }: {
    currentPassword: string
  }) {
    const currentUser = this.getCurrentUser()
    if (currentUser?.email) {
      const credential = EmailAuthProvider.credential(
        currentUser.email,
        currentPassword
      )
      return reauthenticateWithCredential(currentUser, credential)
    }
  }

  /**
   * Checks a password reset code sent to the user by email or other out-of-band mechanism.
   *
   * @returns the user's email address if valid.
   *
   * @param actionCode - A verification code sent to the user.
   *
   */
  async verifyPasswordResetCode({ actionCode }: { actionCode: string }) {
    return verifyPasswordResetCode(this.auth, actionCode)
  }

  /**
   * Completes the password reset process, given a confirmation code and new password.
   *
   * @param actionCode - A confirmation code sent to the user.
   * @param newPassword - The new password.
   *
   */
  async confirmPasswordReset({
    actionCode,
    newPassword,
  }: {
    actionCode: string
    newPassword: string
  }) {
    return confirmPasswordReset(this.auth, actionCode, newPassword)
  }

  async updatePassword({ newPassword }: { newPassword: string }) {
    const currentUser = this.getCurrentUser()
    if (currentUser) {
      return updatePassword(currentUser, newPassword)
    }

    throw new Error('Could not update password, because could not find user')
  }

  /* async register(name: string, email: string, password: string) {
    await this.auth.createUserWithEmailAndPassword(email, password)
    await this.auth.currentUser?.updateProfile({
      displayName: name,
    })
    return this.auth.currentUser
  } */

  getCurrentUser() {
    return this.auth.currentUser
  }

  onAuthStateChanged(cb: (user: User | null) => void) {
    return onAuthStateChanged(this.auth, cb)
  }

  async loginCustomToken(customToken: string) {
    return signInWithCustomToken(this.auth, customToken)
  }

  async getIdToken(forceRefresh: boolean = false) {
    const currentUser = this.getCurrentUser()
    if (currentUser) {
      return getIdToken(currentUser, forceRefresh)
    }

    throw new Error('Could not get id token, because could not find user')
  }

  async getIdTokenResult(forceRefresh: boolean = true) {
    const currentUser = this.getCurrentUser()
    if (currentUser) {
      const idTokenResult = await getIdTokenResult(currentUser, forceRefresh)
      return idTokenResult
    }
    throw new Error(
      'Could not get id token result, because could not find user'
    )
  }

  async checkActionCode({ actionCode }: { actionCode: string }) {
    return checkActionCode(this.auth, actionCode)
  }

  async logout() {
    return signOut(this.auth)
  }

  // ####################################################
  // ###########             MFA              ###########
  // ####################################################
  // Used in multi-factor enrollment.
  public verificationId: string | null = null

  startEnrollMultiFactor = async (
    phoneNumber: string | null,
    recaptchaId: string = 'recaptcha'
  ) => {
    /**
     * Pass the input id if setting invisible recaptcha,
     * otherwise pass the container id where recaptcha will
     * be launch.
     */
    const recaptchaVerifier = new RecaptchaVerifier(
      recaptchaId,
      { size: 'invisible' },
      this.auth
    )
    if (this.auth.currentUser) {
      this.verificationId = await multiFactor(this.auth.currentUser)
        .getSession()
        .then((multiFactorSession) => {
          // Specify the phone number and pass the MFA session.
          const phoneInfoOptions = {
            phoneNumber,
            session: multiFactorSession,
          }
          const phoneAuthProvider = new PhoneAuthProvider(this.auth)
          // Send SMS verification code.
          return phoneAuthProvider.verifyPhoneNumber(
            phoneInfoOptions,
            recaptchaVerifier
          )
        })
        .catch((error) => {
          throw error
        })
    }
  }

  finishEnrollMultiFactor = async (verificationCode: string) => {
    if (this.verificationId && this.auth.currentUser) {
      // User will insert the verification code. Then:
      const cred = PhoneAuthProvider.credential(
        this.verificationId,
        verificationCode
      )
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)
      // Complete enrollment.
      await multiFactor(this.auth.currentUser)
        .enroll(multiFactorAssertion, this.auth.currentUser.email)
        .catch((error) => {
          throw error
        })
      this.verificationId = null
    }
  }

  public multiFactorResolver: MultiFactorResolver | null = null

  getMfaResolver = (error: MultiFactorError) => {
    this.multiFactorResolver = getMultiFactorResolver(this.auth, error)
    return this.multiFactorResolver
  }

  startMfaSignin = async (
    multiFactorHint: MultiFactorInfo,
    session: MultiFactorSession,
    recaptchaId: string = 'recaptcha'
  ) => {
    const recaptchaVerifier = new RecaptchaVerifier(
      recaptchaId,
      { size: 'invisible' },
      this.auth
    )

    if (multiFactorHint.factorId === PhoneMultiFactorGenerator.FACTOR_ID) {
      const phoneInfoOptions: PhoneInfoOptions = {
        multiFactorHint,
        session,
      }
      const phoneAuthProvider = new PhoneAuthProvider(this.auth)
      // Send SMS verification code
      this.verificationId = await phoneAuthProvider
        .verifyPhoneNumber(phoneInfoOptions, recaptchaVerifier)
        .catch((error) => {
          throw error
        })
    } else {
      // Should never arrive here.
      // Only phone number second factors are supported.
    }
  }

  finishMfaSignIn = async (verificationCode: string) => {
    // Get the SMS verification code sent to the user.
    if (this.verificationId && this.multiFactorResolver) {
      const cred = PhoneAuthProvider.credential(
        this.verificationId,
        verificationCode
      )
      const multiFactorAssertion = PhoneMultiFactorGenerator.assertion(cred)

      // Complete sign-in.
      await this.multiFactorResolver
        .resolveSignIn(multiFactorAssertion)
        .then(() => {
          // We can use userCredential as a prop to get the user info if desired.
          // User successfully signed in with the second factor phone number.
        })
        .catch((error: any) => {
          throw error
        })
    }

    this.multiFactorResolver = null
    this.verificationId = null
  }

  // Not in use, might come in handy later.
  unEnrollMultiFactor = async () => {
    // Check if the user is enrolled in MFA.
    const checkMfaEnrollment = multiFactor(
      this.auth.currentUser!
    ).enrolledFactors
    // Unenroll all the MFA entries in the current user by uid.
    for (let i = 0; i < checkMfaEnrollment.length; i += 1) {
      // eslint-disable-next-line no-await-in-loop
      await multiFactor(this.auth.currentUser!).unenroll(
        checkMfaEnrollment[i].uid
      )
    }
  }

  // ####################################################
  // ###########          END MFA             ###########
  // ####################################################
}

export default new Firebase()
