import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import { ICampaign, IAdGroup } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedAdGroup } from '../utils'

const removeCampaignAdGroupCreatives = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{ adGroupId: number }>
) => {
  const selectedAdGroup =
    getSelectedAdGroup(state, action.payload.adGroupId) || ({} as IAdGroup)

  if (selectedAdGroup) {
    selectedAdGroup.creativesList = []
  }
}

export default removeCampaignAdGroupCreatives
