import { PayloadAction } from '@reduxjs/toolkit'
import { ICampaign, IProduct } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'

const setCampaignProducts = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<Array<IProduct>>
) => {
  state.productList = action.payload
}

export default setCampaignProducts
