import styled, { CSSProperties } from 'styled-components'
import Badge from 'react-bootstrap/Badge'

import { IThemeProps } from '../../../../models/interfaces'
import {
  Action,
  FontPreset,
  TruncatePreset,
  TSizeMixin,
  Size,
} from '../../styles/mixins'

export const StyledBadge = styled(Badge).withConfig({
  shouldForwardProp: (prop) =>
    !['backgroundColor', 'marginLeft', 'marginRight', 'maxWidth'].includes(
      prop as string
    ),
})<
  TSizeMixin &
    Pick<
      CSSProperties,
      | 'pointerEvents'
      | 'backgroundColor'
      | 'color'
      | 'margin'
      | 'marginLeft'
      | 'marginRight'
      | 'cursor'
    >
>`
  padding-left: var(--margin-default);
  padding-right: var(--gutter-mini);

  margin: ${(props) => props.margin || ''};
  margin-left: ${(props) => props.marginLeft || ''};
  margin-right: ${(props) => props.marginRight || ''};
  pointer-events: ${(props) => props.pointerEvents || ''};

  ${FontPreset('paragraph small opensans semibold')};

  ${(
    props: IThemeProps &
      Pick<
        CSSProperties,
        'pointerEvents' | 'backgroundColor' | 'color' | 'height' | 'cursor'
      >
  ) =>
    Action({
      cursor: props.cursor ? props.cursor : 'pointer',
      verticalAlign: 'middle',
      textTransform: 'unset',
      height: props.height ? props.height : 'var(--font-size-h3)',
      lineHeight: '1.3',

      border: {
        style: 'hidden',
      },

      backgroundColor: props.backgroundColor
        ? `${props.backgroundColor} !important`
        : '',
      color: props.color ? `${props.color} !important` : '',

      hoverAction: {
        backgroundColor: props.theme?.colors?.background?.secondary
          ? `${props.theme?.colors?.background?.secondary} !important`
          : 'var(--background-color-secondary) !important',
        color: props.theme?.colors?.font?.secondary
          ? `${props.theme?.colors?.font?.secondary} !important`
          : 'var(--font-color-secondary) !important',
      },
    })};

  ${(props: TSizeMixin) => Size({ ...props })}

  ${TruncatePreset(`120px`)};
`
