import styled from 'styled-components'
import { IThemeProps } from '../../../models/interfaces'
import { Container } from './Container'

/**
 *  Content is shown on hover
 */
export const HoverItemsContainer = styled(Container)<{ disabled?: boolean }>`
  opacity: ${(props) => (props.disabled ? '0.5' : '')};

  > .hover-items {
    display: none;
  }

  &:hover {
    box-shadow: ${(props) =>
      props.disabled ? '' : '0 24px 24px -16px rgba(0, 0, 0, 0.3)'};
    cursor: ${(props) => (props.disabled ? 'not-allowed' : 'pointer')};
    background: ${(props: { disabled?: boolean } & IThemeProps) =>
      props.disabled
        ? ''
        : props.theme?.colors?.background?.secondary ||
          'var(--background-color-secondary)'};

    div,
    span,
    button {
      color: ${(props: { disabled?: boolean } & IThemeProps) =>
        props.disabled
          ? ''
          : props.theme?.colors?.font?.secondary ||
            'var(--font-color-secondary)'};
    }

    > .hover-items {
      display: ${(props) => (props.disabled ? '' : 'inline-block')};
    }
  }
`
