import { EDatasetKey, EParamChannelTypeGlobal } from '../../../../models/core'

export function getProductEDatasetKeyFromChannelTypeGlobal(
  channelType?: EParamChannelTypeGlobal
) {
  switch (channelType) {
    case EParamChannelTypeGlobal.SponsoredProduct:
      return EDatasetKey.ProductSp
    case EParamChannelTypeGlobal.DisplayAd:
      return EDatasetKey.ProductDa
    default: // eventually we should add generic and throw error if other
      return EDatasetKey.ProductSp
  }
}
