import { EDOOHTargetType, IDOOHAdGroupTargeting } from '../../models'
import { LocationType } from '../../pages/Advertiser/Routes/NewCampaign/DigitalOutOfHome/AdGroups/getLocationTypeIcon'

export const getLocationTypeByTargetType = (
  targeting: IDOOHAdGroupTargeting
): LocationType | undefined => {
  if (
    [
      EDOOHTargetType.PositionOnly,
      EDOOHTargetType.AudienceAndPosition,
    ].includes(targeting.targetType)
  ) {
    return LocationType.ALL_STORES
  }

  if (targeting.targetType === EDOOHTargetType.LocationAndPosition) {
    if (
      'storeLocationsList' in targeting.locationsList &&
      targeting.locationsList.storeLocationsList.length
    ) {
      return LocationType.STORES
    }
    if (
      'cityLocationsList' in targeting.locationsList &&
      targeting.locationsList.cityLocationsList.length
    ) {
      return LocationType.CITIES
    }
  }
}
