import styled, { CSSProperties } from 'styled-components'
import { ToastContainer } from 'react-bootstrap'
import { Display } from '../../styles/mixins'

export const StyledToastContainer = styled(ToastContainer)<
  Pick<CSSProperties, 'display'>
>`
  z-index: 10000;
  ${(props) =>
    Display({
      display: props.display,
    })}
`
