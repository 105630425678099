import { IAdData } from '@unfoldrtech/portal-mic'

export const updateAdDataDestinationURL = ({
  destinationURLPrefix,
  adData,
}: {
  destinationURLPrefix: string
  adData: IAdData
}): IAdData => {
  return adData.destinationURL.startsWith(destinationURLPrefix)
    ? adData
    : {
        ...adData,
        destinationURL: `${destinationURLPrefix}${adData.destinationURL}`,
      }
}
