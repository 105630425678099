import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconArrowUpSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 12 8"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="m1 7 5-5 5 5"
      fill="none"
      fillRule="evenodd"
    />
  </svg>
)

export default SvgIconArrowUpSmall
