import { IOrgTheme } from '@unfoldrtech/portal-mic'

export const ORG_DEFAULT_THEME: IOrgTheme = {
  colors: {
    border: {
      primary: 'rgba(0, 0, 0, 0.1)',
      secondary: 'rgb(255,255,255)',
      dashed: 'rgba(0, 0, 0, 0.1)',
      active: '#c3c3c3',
      light: '#ffffff',
      dark: '#bebebe',
      error: '#e13c3c',
      filterBottom: 'rgba(128, 128, 128, 0.2)',
      filterBoxShadow: 'rgba(0, 0, 0, 0.07)',
    },
    background: {
      primary: '#ffffff',
      secondary: 'rgb(0,0,0)',
      light: '#ffffff',
      dark: '#000000',
      disabled: '#f5f5f5',
      active: '#e5e5e5',
      textarea: 'rgba(235, 235, 235, 0.4)',
      confirm: '#ffdc55',
      toggle: '#cdcdcd',
      tableHeader: '#fafafa',
    },
    button: {
      primary: '#000000',
      secondary: '#ffffff',
      disabled: 'rgba(0, 0, 0, 0.5)',
    },
    font: {
      primary: '#000000',
      secondary: '#ffffff',
      light: '#ffffff',
      dark: '#000000',
      action: '#ffffff',
      active: 'rgba(0, 0, 0, 0.5)',
      url: '#00ade6',
      placeholder: '#7d7d7d',
      error: '#e13c3c',
    },
    badge: {
      active: '#3bc887',
      inactive: '#e13c3c',
      disabled: '#ececec',
      pending: '#ff912d',
    },
    chart: {
      grid: {
        primary: '#c8c8c8',
      },
    },
  },
}
