import { createGlobalStyle } from 'styled-components'

import Galano_Grotesque_Bold from '../../../assets/fonts/galano/Galano_Grotesque_Bold.woff2'
import Galano_Grotesque_SemiBold from '../../../assets/fonts/galano/Galano_Grotesque_SemiBold.woff2'
import Galano_Grotesque_Medium from '../../../assets/fonts/galano/Galano_Grotesque_Medium.woff2'
import Galano_Grotesque_Regular from '../../../assets/fonts/galano/Galano_Grotesque_Regular.woff2'
import OpenSans from '../../../assets/fonts/opensans/OpenSans-Regular.woff2'
import OpenSans_Bold from '../../../assets/fonts/opensans/OpenSans-Bold.woff2'
import OpenSans_SemiBold from '../../../assets/fonts/opensans/OpenSans-SemiBold.woff2'
import OpenSans_Italic from '../../../assets/fonts/opensans/OpenSans-Italic.woff2'
import OpenSans_SemiBold_Italic from '../../../assets/fonts/opensans/OpenSans-SemiBoldItalic.woff2'

import Archivo_Regular from '../../../assets/fonts/archivo/Archivo-Regular.woff2'
import Archivo_Light from '../../../assets/fonts/archivo/Archivo-Light.woff2'
import Archivo_Medium from '../../../assets/fonts/archivo/Archivo-Medium.woff2'
import Archivo_SemiBold from '../../../assets/fonts/archivo/Archivo-SemiBold.woff2'
import Archivo_Bold from '../../../assets/fonts/archivo/Archivo-Bold.woff2'
import Archivo_Black from '../../../assets/fonts/archivo/Archivo-Black.woff2'

import 'bootstrap/dist/css/bootstrap.min.css'

import { FontFace } from './mixins'

export const GlobalFonts = createGlobalStyle`
    // Galano
    ${FontFace({
      fontFamily: 'GalanoGrotesque-Bold',
      url: Galano_Grotesque_Bold,
      format: "'woff2'",
    })}
    ${FontFace({
      fontFamily: 'GalanoGrotesque-SemiBold',
      url: Galano_Grotesque_SemiBold,
      format: "'woff2'",
    })}
    ${FontFace({
      fontFamily: 'GalanoGrotesque-Medium',
      url: Galano_Grotesque_Medium,
      format: "'woff2'",
    })}
    ${FontFace({
      fontFamily: 'GalanoGrotesque-Regular',
      url: Galano_Grotesque_Regular,
      format: "'woff2'",
    })}

    // OpenSans
    ${FontFace({
      fontFamily: 'OpenSans',
      url: OpenSans,
      format: "'woff2'",
    })}
    ${FontFace({
      fontFamily: 'OpenSans-Bold',
      url: OpenSans_Bold,
      format: "'woff2'",
    })}
    ${FontFace({
      fontFamily: 'OpenSans-SemiBold',
      url: OpenSans_SemiBold,
      format: "'woff2'",
    })}
    ${FontFace({
      fontFamily: 'OpenSans-Italic',
      url: OpenSans_Italic,
      format: "'woff2'",
    })}
    ${FontFace({
      fontFamily: 'OpenSans-SemiBold-Italic',
      url: OpenSans_SemiBold_Italic,
      format: "'woff2'",
    })} 

    // Archivo
    ${FontFace({
      fontFamily: 'Archivo-Regular',
      url: Archivo_Regular,
      format: "'woff2'",
    })}
    ${FontFace({
      fontFamily: 'Archivo-Light',
      url: Archivo_Light,
      format: "'woff2'",
      fontWeight: 300,
    })}
    ${FontFace({
      fontFamily: 'Archivo-Medium',
      url: Archivo_Medium,
      format: "'woff2'",
      fontWeight: 500,
    })}
    ${FontFace({
      fontFamily: 'Archivo-SemiBold',
      url: Archivo_SemiBold,
      format: "'woff2'",
      fontWeight: 600,
    })}
    ${FontFace({
      fontFamily: 'Archivo-Bold',
      url: Archivo_Bold,
      format: "'woff2'",
      fontWeight: 700,
    })}
    ${FontFace({
      fontFamily: 'Archivo-Black',
      url: Archivo_Bold,
      format: "'woff2'",
      fontWeight: 700,
    })}
    ${FontFace({
      fontFamily: 'Archivo-Black',
      url: Archivo_Black,
      format: "'woff2'",
      fontWeight: 900,
    })}

  }
`
