import { createContext, Dispatch, SetStateAction } from 'react'
import { IAdTemplate } from '@unfoldrtech/portal-mic'
import { TAdTemplateContext } from '../types'

export const defaultAdTemplateContext: TAdTemplateContext = [
  <IAdTemplate>{},
  <Dispatch<SetStateAction<IAdTemplate>>>{},
]

export const AdTemplateContext = createContext<TAdTemplateContext>(
  defaultAdTemplateContext
)
