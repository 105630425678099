import { PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import { ICampaign } from '@unfoldrtech/portal-mic'

export function getSelectedCategory(
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    categoryTargetId: number
    adGroupId: number
  }>
) {
  const adGroupIndex = state.adGroupList.findIndex(
    (adGroup) => adGroup.id === action.payload.adGroupId
  )
  const selectedAdGroup = state.adGroupList[adGroupIndex]

  let categoryTargetIndex = selectedAdGroup.categoriesList?.findIndex(
    (ct) => ct.id === action.payload.categoryTargetId
  )

  if (categoryTargetIndex === undefined) {
    categoryTargetIndex = -1
  }

  const selectedCategory = selectedAdGroup.categoriesList
    ? selectedAdGroup.categoriesList[categoryTargetIndex]
    : undefined
  return selectedCategory
}
