import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconArrowSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 10 10"
    {...props}
  >
    <path
      d="M9 5H1m4 4 4-4-4-4"
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgIconArrowSmall
