import React from 'react'
import { FormattedDate } from 'react-intl'
import I18NText from '../../I18NText'

export function DateText({
  value,
  ...props
}: { value?: string | number | Date } & Intl.DateTimeFormatOptions) {
  if (value === undefined || value === null) {
    return (
      <>
        <I18NText id="no.date" opacity="0.5" />
      </>
    )
  }
  // TODO: Set default format based on locale
  return (
    <>
      <FormattedDate
        year="numeric"
        month="short"
        day="numeric"
        value={new Date(value)}
        {...props}
      />
    </>
  )
}
