import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconPlus = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 10 10"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M3.75 10V6.25H0v-2.5h3.75V0h2.5v3.75H10v2.5H6.25V10h-2.5z"
      fill="currentColor"
    />
  </svg>
)

export default SvgIconPlus
