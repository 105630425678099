/**
 * This function groups array elements based on a common property.
 * @param array - Array of items to be grouped
 * @param property - The property to be grouped by
 * @returns A new object grouped by the given property
 *
 * ```ts
 * const inventory = [
  { name: 'asparagus', type: 'vegetables', quantity: 5 },
  { name: 'bananas',  type: 'fruit', quantity: 0 },
  { name: 'goat', type: 'meat', quantity: 23 },
  { name: 'cherries', type: 'fruit', quantity: 5 },
  { name: 'fish', type: 'meat', quantity: 22 }
];
 * const result = groupBy(inventory, "type");
* //Result is:
  {
    vegetables: [
      { name: 'asparagus', type: 'vegetables', quantity: 5 },
    ],
    fruit: [
      { name: "bananas", type: "fruit", quantity: 0 },
      { name: "cherries", type: "fruit", quantity: 5 }
    ],
    meat: [
      { name: "goat", type: "meat", quantity: 23 },
      { name: "fish", type: "meat", quantity: 22 }
    ]
  }
 ```
 */
export function groupBy<T, K extends keyof any>(
  array: T[],
  getKey: (i: T) => K
) {
  const result = array.reduce((accumulator, item) => {
    const groups = JSON.parse(JSON.stringify(accumulator))

    if (!groups[getKey(item)]) {
      groups[getKey(item)] = []
    }
    groups[getKey(item)].push(item)

    return groups
  }, {} as Record<K, T[]>)

  return result
}
