import setDoohCampaignDetails from './setDoohCampaignDetails'
import setDoohCampaignSettings from './setDoohCampaignSettings'
import setDoohCampaignAdGroup from './setDoohCampaignAdGroup'
import removeDoohCampaignAdGroup from './removeDoohCampaignAdGroup'
import setDoohCampaignAdGroupCreative from './setDoohCampaignAdGroupCreative'
import removeDoohCampaignAdGroupCreatives from './removeDoohCampaignAdGroupCreatives'
import setAdGroupDayTimeTarget from './set-ad-group-day-time-target.reducer'
import resetAdGroupDayTimeTarget from './reset-ad-group-day-time-target.reducer'
import setAdGroupAudience from './set-ad-group-audience.reducer'

export default {
  setDoohCampaignDetails,
  setDoohCampaignSettings,
  setDoohCampaignAdGroup,
  setDoohCampaignAdGroupCreative,
  setAdGroupDayTimeTarget,
  setAdGroupAudience,
  removeDoohCampaignAdGroup,
  removeDoohCampaignAdGroupCreatives,
  resetAdGroupDayTimeTarget,
}
