import { PayloadAction } from '@reduxjs/toolkit'
import { ICampaign, IProduct } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'

const addCampaignProduct = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<IProduct>
) => {
  const isExists = state.productList.findIndex(
    (p) => p.id === action.payload.id
  )

  if (isExists === -1) {
    state.productList.push(action.payload)
  }

  if (isExists > -1) {
    state.productList.splice(isExists, 1, action.payload)
  }
}

export default addCampaignProduct
