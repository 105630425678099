import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { EUserRole } from '@unfoldrtech/portal-mic'
import { useLocation, useNavigate } from 'react-router-dom'

import { AppContext, RetailerContext } from '../../models/contexts'
import { TAppContext } from '../../models/types'
import { isUserAuthorised } from '../../utils/userAuthorisation'
import I18NText from '../I18NText'
import { Container } from '../Global'
import { getHomePageURL } from '../../utils/helpers/getHomePageURL'

const AuthenticatedRoute = ({
  authRole,
  children,
}: {
  authRole: EUserRole | Array<EUserRole>
  children: ReactNode | Array<ReactNode>
}) => {
  const authRoleList = Array.isArray(authRole) ? authRole : [authRole]

  const navigate = useNavigate()
  const location = useLocation()

  const [appContext] = useContext<TAppContext>(AppContext)
  const retailerContext = useContext(RetailerContext)

  const [isRouteAccessible, setIsRouteAccessible] = useState<
    boolean | undefined
  >()

  useEffect(() => {
    if (appContext.userProfile?.email) {
      let routeAccessibleFlag = false

      for (let index = 0; index < authRoleList.length; index += 1) {
        const accessRole = authRoleList[index]
        routeAccessibleFlag =
          routeAccessibleFlag ||
          isUserAuthorised(
            appContext.userClaims?.userRole || EUserRole.None,
            accessRole
          )
      }

      setIsRouteAccessible(routeAccessibleFlag)
    }
  }, [appContext.userClaims])

  useEffect(() => {
    /**
     * Navigate to home screen if user does not have permissions to access the requested route
     */
    if (isRouteAccessible === false) {
      const homePageURL = getHomePageURL({ appContext, retailerContext })
      navigate(homePageURL, {
        state: location.state,
      })
    }
  }, [isRouteAccessible])

  useEffect(() => {
    /**
     * Navigate to login screen if user is not logged in
     */
    if (!appContext.isLoggedIn) {
      navigate('/login', {
        state: {
          /* error: true,
          messageKey: 'error.redirect.not.logged.in',
          requestedRoute: location.pathname, */
          requestedRoute: location.pathname.includes('unsubscribe')
            ? location.pathname
            : undefined,
        },
      })
    }
  }, [appContext.isLoggedIn])

  /**
   * Permitted to view requested route/page
   */
  if (isRouteAccessible) {
    return <>{children}</>
  }

  /**
   * isRouteAccessible is undefined
   */
  return (
    <>
      <Container textAlign="center" flex="1" width="100%" display="inline">
        <I18NText id="three.dots" />
      </Container>
    </>
  )
}

export default AuthenticatedRoute
