import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconPieChart = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 23 23"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12.75.75v9h9a9 9 0 0 0-9-9z" />
      <path d="M9.75 3.75a9 9 0 1 0 9 9h-9v-9z" />
    </g>
  </svg>
)

export default SvgIconPieChart
