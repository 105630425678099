import styled from 'styled-components'
import { IThemeProps } from '../../../models/interfaces'
import I18NText from '../../I18NText'

export const CampaignStepText = styled(I18NText)<{
  opacity?: string
}>`
  font-family: GalanoGrotesque-Medium;
  font-size: var(icon-size-sm);
  text-transform: capitalize;

  color: ${(props: IThemeProps) =>
    props.theme?.colors?.font?.primary || 'var(--font-color-default)'};
  opacity: ${(props) => props.opacity || '0.5'};
`
