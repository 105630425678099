import { IProduct } from '@unfoldrtech/portal-mic'
import { TProductIdentifier } from '../../models'

export const getSearchedProducts = ({
  products = [],
  productSearchQuery,
  productIdentifier = 'externalId',
}: {
  products: IProduct[]
  productSearchQuery?: string
  productIdentifier?: TProductIdentifier
}) => {
  return (
    products.filter((product) => {
      return (
        product.name
          .toLowerCase()
          .includes(productSearchQuery?.toLowerCase() ?? '') ||
        (product[productIdentifier] ?? '')
          .toLowerCase()
          .includes(productSearchQuery?.toLowerCase() ?? '')
      )
    }) || []
  )
}
