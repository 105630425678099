import styled, { CSSProperties } from 'styled-components'

import { Border, Size, TBorderMixin, TSizeMixin } from '../styles/mixins'

export const StyledTableRow = styled.tr<
  TBorderMixin &
    TSizeMixin &
    Pick<CSSProperties, 'background' | 'backgroundColor' | 'verticalAlign'>
>`
  vertical-align: ${(props) => props.verticalAlign || 'middle'};

  background: ${(props) => props.background || ''};
  background-color: ${(props) => props.backgroundColor || ''};

  ${(props: TSizeMixin) =>
    Size({
      width: '100%',
      height: 'var(--row-height-default)',
      maxHeight: 'var(--row-height-default)',
      ...props,
    })};
  ${(props: TBorderMixin) => Border({ ...props })};
`
