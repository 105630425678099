import React from 'react'
import styled from 'styled-components'
import Dropdown from 'react-bootstrap/Dropdown'
import { DropdownItemProps } from 'react-bootstrap/DropdownItem'
import { Action, FontPreset, TruncatePreset } from '../../styles/mixins'

const DropdownItem = (props: DropdownItemProps) => <Dropdown.Item {...props} />

export const StyledDropdownItem = styled(DropdownItem)`
  ${FontPreset('paragraph small opensans')};
  ${TruncatePreset('100%')};
  ${Action({
    opacity: '0.5',

    hoverAction: {
      opacity: '1',
    },
  })}
`
