import { PayloadAction } from '@reduxjs/toolkit'
import { EAuctionStrategy, EPacing } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { EPricingStrategy, IDOOHCampaign } from '../../../models'

const setDoohCampaignSettings = (
  state: WritableDraft<IDOOHCampaign>,
  action: PayloadAction<{
    startDate: string
    endDate: string
    budget: number
    pacing: EPacing
    auctionStrategy: EAuctionStrategy
    pricingStrategy: EPricingStrategy
  }>
) => {
  state.startDate = action.payload.startDate
  state.endDate = action.payload.endDate
  state.budget = action.payload.budget
  state.pacing = action.payload.pacing
  state.auctionStrategy = action.payload.auctionStrategy
  state.pricingStrategy = action.payload.pricingStrategy
}

export default setDoohCampaignSettings
