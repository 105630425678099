import React, { lazy } from 'react'

import { EUserRole } from '@unfoldrtech/portal-mic'
import { Route } from 'react-router-dom'
import { PortalRoutes } from './PortalRoutes'
import AuthenticatedRoute from './AuthenticatedRoute'
import { lazyRetry } from '../../utils/lazyRetry'

const FAQ = lazy(() => lazyRetry(() => import('../../pages/Faq')))

const GenericRetailerFAQ = lazy(() =>
  lazyRetry(() => import('../../pages/Faq/Routes/Retailer/Generic'))
)

const RetailerSponsoredProductsFAQ = lazy(() =>
  lazyRetry(() => import('../../pages/Faq/Routes/Retailer/SponsoredProducts'))
)

const RetailerDisplayAdsFAQ = lazy(() =>
  lazyRetry(() => import('../../pages/Faq/Routes/Retailer/DisplayAds'))
)

const RetailerDigitalOutOfHomeFAQ = lazy(() =>
  lazyRetry(() => import('../../pages/Faq/Routes/Retailer/DigitalOutOfHome'))
)

const RetailerFAQRoutes = () => {
  return (
    <PortalRoutes>
      <Route
        path="/"
        element={
          <AuthenticatedRoute
            authRole={[EUserRole.RetailerInsights, EUserRole.None]}
          >
            <FAQ />
          </AuthenticatedRoute>
        }
      >
        <Route
          path="generic"
          element={
            <AuthenticatedRoute
              authRole={[
                EUserRole.AdvertiserInsights,
                EUserRole.RetailerInsights,
                EUserRole.None,
              ]}
            >
              <GenericRetailerFAQ />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="sponsored-products"
          element={
            <AuthenticatedRoute
              authRole={[
                EUserRole.AdvertiserInsights,
                EUserRole.RetailerInsights,
                EUserRole.None,
              ]}
            >
              <RetailerSponsoredProductsFAQ />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="display-ads"
          element={
            <AuthenticatedRoute
              authRole={[
                EUserRole.AdvertiserInsights,
                EUserRole.RetailerInsights,
                EUserRole.None,
              ]}
            >
              <RetailerDisplayAdsFAQ />
            </AuthenticatedRoute>
          }
        />

        <Route
          path="digital-out-of-home"
          element={
            <AuthenticatedRoute
              authRole={[
                EUserRole.AdvertiserInsights,
                EUserRole.RetailerInsights,
                EUserRole.None,
              ]}
            >
              <RetailerDigitalOutOfHomeFAQ />
            </AuthenticatedRoute>
          }
        />
      </Route>
    </PortalRoutes>
  )
}

export default RetailerFAQRoutes
