import { ComponentType } from 'react'

const RETRY_LAZY_SESSION_STORAGE_KEY = 'retry-lazy-attempts'
const MAX_REFRESH_ATTEMPTS = 1

type RetryLazySessionStorage = {
  refreshAttempts: number
  loading: boolean
}

export const lazyRetry = (
  componentImport: () => Promise<{ default: ComponentType<any> }>
) => {
  return new Promise<{ default: ComponentType<any> }>((resolve, reject) => {
    // Using sessionStorage because the data is not required once the window is closed
    const retryLazySessionStorageString = sessionStorage.getItem(
      RETRY_LAZY_SESSION_STORAGE_KEY
    )

    const retryLazySessionStorage: RetryLazySessionStorage =
      retryLazySessionStorageString
        ? (JSON.parse(retryLazySessionStorageString) as RetryLazySessionStorage)
        : { refreshAttempts: 0, loading: false }

    componentImport()
      .then((component) => {
        resolve(component)
      })
      .catch((error) => {
        if (
          error.name === 'ChunkLoadError' &&
          retryLazySessionStorage?.refreshAttempts < MAX_REFRESH_ATTEMPTS &&
          !retryLazySessionStorage.loading
        ) {
          sessionStorage.setItem(
            RETRY_LAZY_SESSION_STORAGE_KEY,
            JSON.stringify(<RetryLazySessionStorage>{
              refreshAttempts: retryLazySessionStorage.refreshAttempts,
              loading: true,
            })
          )
          setTimeout(() => {
            sessionStorage.setItem(
              RETRY_LAZY_SESSION_STORAGE_KEY,
              JSON.stringify(<RetryLazySessionStorage>{
                refreshAttempts: retryLazySessionStorage.refreshAttempts + 1,
                loading: false,
              })
            )
            return window.location.reload()
          }, 2000)
        } else {
          return reject(error)
        }
      })
  })
}
