import styled from 'styled-components'
import { TContainerProps, Size } from '../styles/mixins'
import { Container } from './Container'

export const GradientContainer = styled(Container)<TContainerProps>`
  position: absolute;
  bottom: 0;
  width: 800px;

  padding: var(--gutter-default) 0;
  background-image: linear-gradient(rgba(255, 255, 255, 0), rgb(255, 255, 255));

  ${(props: TContainerProps) => Size({ ...props })}
`
