import { EUserRole } from '@unfoldrtech/portal-mic'
import dayjs from 'dayjs'

import { IAppBarProps, INavItem } from '../../models/interfaces'
import { TQPOrgRegion, TQPOrgCategory } from '../../models/types'
import {
  IconHome,
  IconAdvertisers,
  IconList,
  IconPieChart,
  IconFallback,
  IconReviewads,
  IconImgFolder,
  IconDirectDeals,
} from '../../components/Global'

export const DAY_IN_MS = 86400 * 1000
export const TODAY_IN_MS = dayjs().valueOf()
export const YESTERDAY_IN_MS = TODAY_IN_MS - DAY_IN_MS
export const ORG_REGIONS: Array<TQPOrgRegion> = ['all', 'nl', 'global']
export const ORG_CATEGORIES: Array<TQPOrgCategory> = ['all', 'cola', 'fab']

export const NAV_ITEMS: Record<string, Array<INavItem>> = {
  RETAILER: [
    {
      title: 'retailer.nav.home',
      path: 'home',
      role: EUserRole.RetailerInsights,
      icon: IconHome,
    },
    {
      title: 'retailer.nav.advertisers',
      path: 'advertisers',
      role: EUserRole.RetailerAdmin,
      icon: IconAdvertisers,
    },
    {
      title: 'retailer.nav.inventory',
      path: 'inventory',
      role: EUserRole.RetailerInventory,
      icon: IconList,
    },
    {
      title: 'retailer.nav.analytics',
      path: 'analytics',
      role: EUserRole.RetailerInsights,
      icon: IconPieChart,
    },
    {
      title: 'retailer.nav.creatives',
      path: 'creatives',
      role: EUserRole.RetailerInsights,
      icon: IconFallback,
    },
    {
      title: 'retailer.nav.review.creatives',
      path: 'review-creatives',
      role: EUserRole.RetailerInsights,
      icon: IconReviewads,
    },
    {
      title: 'direct.deals',
      path: 'direct-deals',
      role: EUserRole.None,
      icon: IconDirectDeals,
    },
  ],
  ADVERTISER: [
    {
      title: 'advertiser.nav.all.campaigns',
      path: 'home',
      role: EUserRole.AdvertiserInsights,
      icon: IconHome,
    },
    {
      title: 'retailer.nav.analytics',
      path: 'analytics',
      role: EUserRole.AdvertiserInsights,
      icon: IconPieChart,
    },
    {
      title: 'advertiser.nav.creatives',
      path: 'creatives',
      role: EUserRole.AdvertiserInsights,
      icon: IconAdvertisers,
      disabledTooltipKey: 'coming.soon',
    },
    {
      title: 'retailer.nav.assetLibrary',
      path: 'asset-library',
      role: EUserRole.AdvertiserCampaigns,
      icon: IconImgFolder,
      isDisabled: true,
      disabledTooltipKey: 'coming.soon',
    },
    {
      title: 'direct.deals',
      path: 'direct-deals',
      role: EUserRole.None,
      icon: IconDirectDeals,
    },
  ],
}

export const NAVBAR_SETTINGS_PROPS: IAppBarProps = {
  title: 'settings',
  navLinkItems: [
    {
      title: 'settings.profile',
      path: 'profile',
    },
    {
      title: 'login.password',
      path: 'password',
    },
    {
      title: 'settings.users.and.roles',
      path: 'admin',
    },
    {
      title: 'settings.signing.keys',
      path: 'signing-keys',
      isHidden: true,
    },
    {
      title: 'settings.currency',
      path: 'currency',
      isHidden: true,
    },
    {
      title: 'settings.mfa',
      path: 'mfa',
      isHidden: true,
    },
    {
      title: 'settings.audience.modifiers',
      path: 'audience-modifiers',
      isHidden: true,
    },
  ],
}

export const RETAILER_NAVBAR_FAQ_PROPS: IAppBarProps = {
  title: 'faq',
  navLinkItems: [
    {
      title: 'faq.generic',
      path: 'generic',
    },
    {
      title: 'faq.sponsored.products',
      path: 'sponsored-products',
    },
    {
      title: 'faq.display.ads',
      path: 'display-ads',
    },
    {
      title: 'faq.digital.out.of.home',
      path: 'digital-out-of-home',
    },
  ],
}

export const ADVERTISER_NAVBAR_FAQ_PROPS: IAppBarProps = {
  title: 'faq',
  navLinkItems: [
    {
      title: 'faq.generic',
      path: 'generic',
    },
    {
      title: 'faq.sponsored.products',
      path: 'sponsored-products',
    },
    {
      title: 'faq.display.ads',
      path: 'display-ads',
    },
    {
      title: 'faq.digital.out.of.home',
      path: 'digital-out-of-home',
    },
  ],
}
