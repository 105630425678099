import React from 'react'
import { Container, SVGContainer } from '../containers'
import { StyledFormInput } from './StyledFormInput'
import { StyledSuffix } from '../elements'
import { IconZoom } from '../icons'
import { TMarginMixin, TSizeMixin } from '../styles'
import { TDataTest } from '../../../models/types'

export interface ISearchInputProps {
  onChange: (value: string) => void
  onBlur?: (e: React.FocusEvent<HTMLInputElement>) => void
  placeholder?: string
  containerSize?: TSizeMixin
  containerMargin?: TMarginMixin
}

export const SearchInput = ({
  onChange,
  onBlur,
  placeholder = 'Search',
  containerSize,
  containerMargin,
  dataTestId,
}: ISearchInputProps & TDataTest) => {
  const [value, setValue] = React.useState('')

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value: inputValue } = event.target
    setValue(inputValue)
    onChange(inputValue)
  }

  return (
    <Container
      display="flex"
      border={{
        width: '1px',
        style: 'solid',
        color: 'var(--border-color-default)',
        radius: '60px',
      }}
      height="40px"
      maxWidth="340px"
      {...containerSize}
      {...containerMargin}
    >
      <StyledFormInput
        placeholder={placeholder}
        value={value}
        onChange={handleChange}
        onBlur={onBlur}
        fontFamily="Archivo-Regular"
        paddingRight="var(--gutter-2x)"
        data-testid={dataTestId}
      />
      <StyledSuffix lineHeight={1.5}>
        <SVGContainer>
          <IconZoom />
        </SVGContainer>
      </StyledSuffix>
    </Container>
  )
}
