import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import {
  ERetailerReportPageType,
  IError,
  IReportingTableResponse,
  ITotalItems,
} from '@unfoldrtech/portal-mic'
import { coreAPI } from '../services/coreAPI'
import {
  generateCorrelationId,
  getTZOffset,
  getTimezone,
} from '../utils/helpers'
import {
  TPPAdvertiserId,
  TPPRetailerId,
  TReportingFilters,
} from '../models/types'
import { IReportPageTypes } from '../models/interfaces'

export default function useGetRetailerReportTable({
  retailerId,
  advertiserId,
  retailerReportPageType: reportPageType = ERetailerReportPageType.Brands,
  campaignIds,
  adGroupIds,
  categoryIds,
  keywordIds,
  pageIds,
  positionIds,
  productIds,
  channelType,
  inventoryType,
  sortBy,
  sortOrder,
  pageLimit,
  pageOffset,
  timeWindow,
  endDate,
  startDate,
  tzOffset,
  tzZone,
  enabled = true,
  suspense = true,
}: {
  enabled?: boolean
  suspense?: boolean
} & TReportingFilters &
  TPPRetailerId &
  TPPAdvertiserId &
  IReportPageTypes) {
  const queryKeyName = `getRetailerReportTable_${retailerId}_${reportPageType}_${
    channelType || ''
  }_${inventoryType || ''}`

  const brandId = advertiserId > 0 ? advertiserId : undefined

  return useQuery<
    AxiosResponse<ITotalItems & IReportingTableResponse>,
    AxiosError<IError>
  >(
    [queryKeyName, { start: startDate, end: endDate }],
    ({ queryKey }) => {
      const filters = queryKey[1] || {}
      const { start, end } = filters as { start: string; end: string }

      return coreAPI
        .retailerReporting()
        .getRetailerReportTable(
          retailerId,
          reportPageType,
          generateCorrelationId(),
          brandId,
          campaignIds,
          adGroupIds,
          categoryIds,
          keywordIds,
          pageIds,
          positionIds,
          productIds,
          channelType,
          sortBy,
          pageLimit,
          pageOffset,
          timeWindow,
          start,
          end,
          sortOrder,
          inventoryType,
          tzOffset || getTZOffset(endDate),
          tzZone || getTimezone()
        )
    },
    { suspense, enabled }
  )
}
