import { PayloadAction } from '@reduxjs/toolkit'
import { IAdGroup, ICampaign, ICreative } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedAdGroup } from '../utils'

const setCampaignAdGroupCreative = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    creative: ICreative
    adGroupId: number
  }>
) => {
  const selectedAdGroup =
    getSelectedAdGroup(state, action.payload.adGroupId) || ({} as IAdGroup)
  if (selectedAdGroup.creativesList === undefined) {
    selectedAdGroup.creativesList = []
  }
  const creativeIndex = selectedAdGroup.creativesList.findIndex(
    (creative) => creative.id === action.payload.creative.id
  )
  if (creativeIndex === -1) {
    selectedAdGroup.creativesList.push(action.payload.creative)
  } else {
    selectedAdGroup.creativesList[creativeIndex] = action.payload.creative
  }
}
export default setCampaignAdGroupCreative
