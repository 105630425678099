import { IPermittedOrganisations } from '@unfoldrtech/portal-mic'
import { createContext } from 'react'
import { TAdvertiserContext } from '../types'

export const defaultAdvertiserContext: TAdvertiserContext = [
  <IPermittedOrganisations>{},
]

export const AdvertiserContext = createContext<TAdvertiserContext>(
  defaultAdvertiserContext
)
