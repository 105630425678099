import { PayloadAction } from '@reduxjs/toolkit'
import { EParamSortOrder } from '@unfoldrtech/portal-mic'
import { TReportingFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'

const setSortOrder = (
  state: TReportingFilters,
  action: PayloadAction<{
    sortOrder?: EParamSortOrder
  }>
) => {
  state.sortOrder = action.payload.sortOrder
}

export default setSortOrder
