import { EPageType } from '@unfoldrtech/portal-mic'
import { TTranslationKey } from '../../models/types'

export function getPageTypeText(pageType: EPageType): TTranslationKey {
  switch (pageType) {
    case EPageType.Home:
      return 'homepage'
    case EPageType.Search:
      return 'search.page'
    case EPageType.Categories:
      return 'category.page'
    default:
      return 'notFound'
  }
}
