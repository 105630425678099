import { useIntl } from 'react-intl'
import { IPosition } from '@unfoldrtech/portal-mic'
import { getPageTypeText } from '../../../utils/helpers/getPageTypeText'

export const CurrentPositionsTooltipText = (positions: IPosition[]): string => {
  const intl = useIntl()

  return positions
    .map(
      (pos) =>
        `${intl.formatMessage({
          id: getPageTypeText(pos.page),
        })} > ${pos.title}`
    )
    .join('\n')
}
