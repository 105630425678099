import React from 'react'
import { FieldError } from 'react-hook-form'
import { useIntl } from 'react-intl'
import { CSSProperties } from 'styled-components'
import { StyledCheckbox, StyledCheckboxInput } from '../elements'
import { Container } from '../containers'
import { TDataTest, TTranslationKey } from '../../../models/types'
import { TBorderMixin, TFontStyleMixin } from '../styles/mixins'
import { CheckboxLabel, Text } from '../fonts'
import I18NText from '../../I18NText'
import { sanitizeDataTestId } from '../../../utils/sanitizeDataTestId'

export interface CheckBoxControlProps
  extends TFontStyleMixin,
    Pick<TBorderMixin, 'border'>,
    Pick<CSSProperties, 'textAlign'> {
  type: 'checkbox' | 'radio'
  onChange: (event: React.ChangeEvent<{ checked: unknown }>) => void
  label?: TTranslationKey
  text?: string
  isLabelLeft?: boolean
  checked?: boolean
  disabled?: boolean
  error?: FieldError
  showLabel?: boolean
}
export const CheckBox = ({
  label,
  text,
  onChange,
  type,
  isLabelLeft,
  checked,
  disabled,
  dataTestId = '',
  textAlign = 'center',
  error,
  border,
  showLabel = true,
  ...fontStyleProps
}: CheckBoxControlProps & TDataTest) => {
  const intl = useIntl()
  return (
    <>
      <StyledCheckbox>
        <Container display="flex" margin="auto" textAlign={textAlign}>
          {isLabelLeft && showLabel && (
            <CheckboxLabel
              data-testid={sanitizeDataTestId(`${dataTestId}-label`)}
            >
              {label ? (
                <I18NText id={label} {...fontStyleProps} />
              ) : (
                <Text {...fontStyleProps}>{text}</Text>
              )}
            </CheckboxLabel>
          )}
          <StyledCheckboxInput
            type={type}
            onChange={onChange}
            checked={checked}
            flex={isLabelLeft ? '1' : ''}
            disabled={disabled}
            data-testid={sanitizeDataTestId(dataTestId)}
            error={error}
            border={border}
          />
          {!isLabelLeft && showLabel && (
            <CheckboxLabel
              flex={isLabelLeft ? '' : '1'}
              data-testid={sanitizeDataTestId(`${dataTestId}-label`)}
            >
              {label ? (
                <I18NText id={label} {...fontStyleProps} />
              ) : (
                <Text {...fontStyleProps}>{text}</Text>
              )}
            </CheckboxLabel>
          )}
        </Container>
      </StyledCheckbox>
      {!!error && (
        <Container textAlign="left">
          <Text preset="error" data-testid="checkbox-error-message">
            {intl.formatMessage({
              id: error.message || 'error',
            })}
          </Text>
        </Container>
      )}
    </>
  )
}
