import {
  TDAProductsReportTableFilters,
  TSPProductsReportTableFilters,
} from '../../../models/types'

export const selectProductsTableReportingFilters = ({
  reportingFilters: reportingProductsTableFiltersState,
}: {
  reportingFilters:
    | TSPProductsReportTableFilters
    | TDAProductsReportTableFilters
}): TSPProductsReportTableFilters | TDAProductsReportTableFilters =>
  reportingProductsTableFiltersState
