import { PayloadAction } from '@reduxjs/toolkit'
import { ICampaign } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedKeyword } from '../utils'

const setAdGroupKeyword = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    keyword: string
    keywordId: number
    adGroupId: number
  }>
) => {
  const selectedKeyword = getSelectedKeyword(state, action)

  if (selectedKeyword) {
    selectedKeyword.keyword = action.payload.keyword
  }
}

export default setAdGroupKeyword
