import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconAlert = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M8.785 3.731 1.817 15.344c-.8 1.333.16 3.029 1.715 3.029h13.936c1.554 0 2.514-1.696 1.715-3.03L12.215 3.732c-.777-1.295-2.653-1.295-3.43 0z"
      stroke="#FF7900"
    />
    <path
      d="M10.5 12.621V6.873"
      stroke="#FF7900"
      strokeWidth={1.225}
      strokeLinecap="round"
    />
    <circle cx={10.5} cy={14.996} r={0.875} fill="#FF7900" />
  </svg>
)

export default SvgIconAlert
