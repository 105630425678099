/**
 * This helper function helps separate the last part of an URL from the rest of the path.
 * @param url - The url to be separated
 * @returns The first and last part of the path of the given URL
 *
 * ```ts
 * const url = "https://example.com/category/subcategory"
 * const { firstPart, lastPart } = separateUrlParts(url)
 * firstPart // "category"
 * lastPart // "subcategory"
 * ```
 */
export function separateUrlParts(url?: string): {
  firstPart: string
  lastPart: string
} {
  let path: string
  const result = {
    firstPart: '',
    lastPart: '',
  }
  if (!url) return result
  try {
    path = new URL(url.replace(/\/+$/, '')).pathname
  } catch (error) {
    return result
  }
  const urlParts = path.split('/')
  result.lastPart = urlParts[urlParts.length - 1]
  const lastPartIndex = path.lastIndexOf(result.lastPart)
  result.firstPart = path.substring(0, lastPartIndex)
  return result
}
