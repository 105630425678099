import React, { useState } from 'react'
import { IOrgTheme } from '@unfoldrtech/portal-mic'
import { useIntl } from 'react-intl'
import { useTheme } from 'styled-components'
import { IconCirclePlus, StyledAntSelect, SVGContainer } from '../../Global'
import { ISelectOption } from '../../../models/interfaces'
import { TGlobalMetricData, TGlobalMetrics } from '../models'

export const MetricsSelectorV3 = ({
  whitelistedMetricData,
  pinnedMetrics,
  onChange,
}: {
  whitelistedMetricData: Array<TGlobalMetricData>
  onChange: (n: TGlobalMetrics) => void
  pinnedMetrics: Array<string>
}) => {
  const intl = useIntl()
  const theme: IOrgTheme = useTheme()
  const [value, setValue] = useState<string>()
  return (
    <StyledAntSelect
      className="metric-selector"
      flex="1"
      padding="0 var(--padding-default) 0 var(--gutter-8x)"
      fontFamily="GalanoGrotesque-Regular"
      color="var(--font-color-default)"
      textTransform="capitalize"
      border={{
        width: '1px',
        color: theme.colors?.border?.dashed || 'var(--border-color-dashed)',
        style: 'dashed',
        radius: '4px',
      }}
      height="100%"
      value={value}
      onSelect={(v) => {
        setValue(
          intl.formatMessage({
            id: 'report.select.metric',
          })
        )
        onChange(`${v}` as TGlobalMetrics)
      }}
      options={whitelistedMetricData.map(
        (whitelistedMetric) =>
          ({
            label: whitelistedMetric.translatedMetricName,
            value: whitelistedMetric.metricName,
            disabled: pinnedMetrics.includes(whitelistedMetric.metricName),
          } as ISelectOption)
      )}
      placeholder={intl.formatMessage({
        id: 'report.select.metric',
      })}
      suffixIcon={
        <SVGContainer preset="paragraph small">
          <IconCirclePlus />
        </SVGContainer>
      }
      dataTestId="metric-selector"
    />
  )
}
