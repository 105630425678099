import styled from 'styled-components'

import { Action, Size, TSizeMixin } from '../styles/mixins'

export const StyledStatusCircle = styled.div<
  TSizeMixin & { active?: boolean; margin?: string }
>`
  margin: ${(props) => props.margin || 'auto 0'};
  ${(props: { active?: boolean }) =>
    Action({
      display: 'inline-block',
      height: 'var(--icon-size-xs)',
      width: 'var(--icon-size-xs)',
      background: props.active
        ? 'var(--badge-color-active)'
        : 'var(--badge-color-inactive)',
      border: {
        radius: 'var(--gutter-default)',
      },
    })};
  ${(props: TSizeMixin) => Size({ ...props })};
`
