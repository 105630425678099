import React, { useState, useEffect, ReactNode, useContext } from 'react'
import { EEntityType, EParamSortOrder, EStatus } from '@unfoldrtech/portal-mic'
import { useDispatch, useSelector } from 'react-redux'
import { TReportingFilters } from '../../../../models/types'
import { StyledImage } from '../../../Global'
import Loading from '../../../Loading'
import { selectDOOHLocationTableReportingFilters } from '../../../../store/reportingFilters'
import {
  selectRefreshTable,
  setRefreshTable,
} from '../../../../store/refreshTable'
import { ReportTableV3 } from '../../components/ReportTableV3'
import { getPlaceholderImage } from '../../helpers/get-placeholder-image'
import { TableDataCellName } from '../../components/table-data-cell/TableDataCellName'
import { TableDataCellStatus } from '../../components/table-data-cell/TableDataCellStatus'
import { TableDataCellLocationType } from '../../components/table-data-cell/TableDataCellLocationType'
import { TableDataCellAdGroupName } from '../../components/table-data-cell/TableDataCellAdGroupName'
import { TableDataCellImpressions } from '../../components/table-data-cell/TableDataCellImpressions'
import { TableDataCellPlayouts } from '../../components/table-data-cell/TableDataCellPlayouts'
import { TableDataCellCosts } from '../../components/table-data-cell/TableDataCellCosts'
import { TableDataCellCpm } from '../../components/table-data-cell/TableDataCellCpm'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import { getTableAndChartDOOHLocationsFn } from '../helpers/get-table-and-chart-dooh-locations-fn'
import {
  AppContext,
  AdGroupContext,
  CampaignContext,
  EDOOHReportLocationsColumnHeader,
  IDOOHReportLocationsTable,
  EDatasetKey,
} from '../../../../models'
import { TReportTableDataV3 } from '../../models/types/report-table-data-v3.type'
import { TDOOHLocationTableHeaders } from '../models/types/dooh-location-table-headers.type'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import { TGlobalTables } from '../../models'

export const ReportingDOOHLocationTableWrapper = ({
  onTableDataChange,
}: {
  onTableDataChange: (locationsTableData: IDOOHReportLocationsTable) => void
}) => {
  const dispatch = useDispatch()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)

  const { startDate, endDate, timeWindow, tzZone } = useSelector(
    selectDOOHLocationTableReportingFilters
  )
  const refreshTable = useSelector(selectRefreshTable)
  const whitelistData = useGetDatasetKeyTableWhitelist({
    key: EDatasetKey.LocationDooh,
  })
  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()

  const orgType = appContext.userOrg!.type
  const hookFn = getTableAndChartDOOHLocationsFn({
    orgType,
  })

  const getTableDataFn = hookFn.tableFn!

  const campaignId = campaign?.id || -1
  const adGroupId = adGroup?.id || -1

  const [sortBy, setSortBy] = useState<TDOOHLocationTableHeaders>()
  const [sortOrder, setSortOrder] = useState<EParamSortOrder>()
  const [tableData, setTableData] = useState<TReportTableDataV3>()
  const [tableParamsUpdated, setTableParamsUpdated] = useState<boolean>()

  const {
    data: tableDataResponseObj,
    refetch,
    isFetching,
  } = getTableDataFn({
    retailerId,
    advertiserId,
    campaignId,
    adGroupId,
    sortBy,
    timeWindow,
    startDate,
    endDate,
    sortOrder,
    tzZone,
    enabled: false,
  })

  const onSortByChange = (sortColumn?: TGlobalTables) => {
    setSortBy(sortColumn as TDOOHLocationTableHeaders)
  }

  const onSortOrderChange = (
    type: TReportingFilters['sortOrder'] = EParamSortOrder.Desc
  ) => {
    setSortOrder(type)
  }

  const tableDataResponse =
    tableDataResponseObj?.data.table || ({} as IDOOHReportLocationsTable)

  const processTableDataResponse = (
    tableDataRes: IDOOHReportLocationsTable
  ) => {
    const headers = whitelistData.map((wData) => wData.header)

    const processedTableData: TReportTableDataV3 = {
      columnIdList: headers,
      columnNameList: whitelistData.map((wData) => wData.translatedMetricName),
      columnNameTooltipList: whitelistData.map(
        (wData) => wData.translatedTooltipMetricName
      ),
      columnSpanList: headers.map((header) =>
        header === EDOOHReportLocationsColumnHeader.Name ? 2 : 1
      ),
      rowList: [],
    }

    tableDataRes.rows.forEach((rows) => {
      const dataRow: Array<ReactNode | ReactNode[]> = []
      headers.forEach((header) => {
        let dataCell: ReactNode | ReactNode[]
        const dataValue = rows[header as TDOOHLocationTableHeaders]

        switch (header) {
          case EDOOHReportLocationsColumnHeader.Name:
            dataCell = <TableDataCellName dataValue={dataValue?.toString()} />
            break
          case EDOOHReportLocationsColumnHeader.Status:
            dataCell = (
              <TableDataCellStatus
                dataValue={dataValue?.toString() as EStatus}
              />
            )
            break
          case EDOOHReportLocationsColumnHeader.Type:
            dataCell = (
              <TableDataCellLocationType dataValue={dataValue?.toString()} />
            )
            break
          case EDOOHReportLocationsColumnHeader.AdgroupName:
            dataCell = (
              <TableDataCellAdGroupName dataValue={dataValue?.toString()} />
            )
            break
          case EDOOHReportLocationsColumnHeader.Costs:
            dataCell = <TableDataCellCosts dataValue={Number(dataValue)} />
            break
          case EDOOHReportLocationsColumnHeader.Cpm:
            dataCell = <TableDataCellCpm dataValue={Number(dataValue)} />
            break
          case EDOOHReportLocationsColumnHeader.Impressions:
            dataCell = (
              <TableDataCellImpressions dataValue={Number(dataValue)} />
            )
            break
          case EDOOHReportLocationsColumnHeader.Playouts:
            dataCell = <TableDataCellPlayouts dataValue={Number(dataValue)} />
            break

          default:
            dataCell = undefined
            break
        }

        if (dataCell) {
          dataRow.push(dataCell)
        }
      })

      processedTableData.rowList.push(dataRow)
    })

    return processedTableData
  }

  useEffect(() => {
    if (startDate && endDate) {
      setTableParamsUpdated(true)
    }
  }, [startDate, endDate])

  useEffect(() => {
    if (sortBy || sortOrder) {
      setTableParamsUpdated(true)
    }
  }, [sortBy, sortOrder])

  useEffect(() => {
    if (tableParamsUpdated) {
      refetch()
      setTableParamsUpdated(false)
    }
  }, [tableParamsUpdated])

  useEffect(() => {
    if (refreshTable) {
      refetch()
      dispatch(setRefreshTable({ refreshTable: false }))
    }
  }, [refreshTable])

  useEffect(() => {
    if (Object.keys(tableDataResponse).length > 0) {
      setTableData(processTableDataResponse(tableDataResponse))
      onTableDataChange(tableDataResponse)
    }
  }, [tableDataResponse])

  return (
    <>
      {tableData?.rowList?.length ? (
        <>
          {tableData?.rowList && (
            <ReportTableV3
              {...tableData}
              sortBy={sortBy}
              sortOrder={sortOrder}
              setSortBy={onSortByChange}
              setSortOrder={onSortOrderChange}
            />
          )}
        </>
      ) : (
        <StyledImage
          fluid
          src={getPlaceholderImage(EEntityType.AdGroupLocations, orgType)}
          alt="home_placeholder"
          cursor="default"
        />
      )}
      <Loading show={isFetching} />
    </>
  )
}
