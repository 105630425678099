import { createContext, Dispatch, SetStateAction } from 'react'
import { IAppContext } from '../interfaces'
import { TAppContext } from '../types'

export const defaultAppContext: TAppContext = [
  <IAppContext>{},
  <Dispatch<SetStateAction<IAppContext>>>{},
]

export const AppContext = createContext<TAppContext>(defaultAppContext)
