import styled from 'styled-components'
import {
  TContainerProps,
  TFontPresetMixin,
  FontPreset,
  TActionMixin,
  Action,
  TTruncateMixin,
  Truncate,
  Border,
  Sticky,
  TStickyMixin,
  TMarginMixin,
  Margin,
  TPaddingMixin,
  Padding,
  Gap,
  TGapMixin,
} from '../styles/mixins'

export const Container = styled.div<TContainerProps>`
  background: ${(props) => props.background || ''};
  background-size: ${(props) => props.backgroundSize || ''};
  position: ${(props) => props.position || ''};
  grid-template-columns: ${(props) => props.gridTemplateColumns || ''};
  grid-template-rows: ${(props) => props.gridTemplateRows || ''};
  grid-template-areas: ${(props) => props.gridTemplateAreas || ''};
  grid-auto-flow: ${(props) => props.gridAutoFlow || ''};
  grid-area: ${(props) => props.gridArea || ''};
  transition: ${(props) => props.transition || ''};

  ${(props: TPaddingMixin) => Padding({ ...props })};

  ${(props: TMarginMixin) => Margin({ ...props })};

  ${(props: TFontPresetMixin) => FontPreset(props.preset)};

  ${(props: TActionMixin) => Action({ ...props })};

  ${(props: TTruncateMixin) => Truncate({ ...props })};

  ${(props: TStickyMixin) => (props.sticky ? Sticky({ ...props.sticky }) : '')};

  ${(props: TGapMixin) => Gap({ ...props })};

  &.input-error {
    ${Border({
      borderBottom: {
        width: '4px',
        style: 'solid',
        color: 'var(--border-color-error)',
        radius: '2px',
      },
    })};
  }
`
