import { PayloadAction } from '@reduxjs/toolkit'
import { EStatus, ICampaign } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'

const setCampaignStatus = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    status: EStatus
  }>
) => {
  state.status = action.payload.status
}

export default setCampaignStatus
