import { PayloadAction } from '@reduxjs/toolkit'
import {
  EAuctionStrategy,
  EPacing,
  EPricingStrategy,
  ICampaign,
  ICampaignFrequency,
} from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'

const setCampaignSettings = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    startDate: string
    endDate?: string | null
    budget: number
    auctionStrategy?: EAuctionStrategy
    pricingStrategy?: EPricingStrategy
    frequency?: ICampaignFrequency
    pacing?: EPacing
  }>
) => {
  state.startDate = action.payload.startDate
  if (action.payload.endDate !== undefined) {
    state.endDate = action.payload.endDate
  }
  state.budget = action.payload.budget
  state.auctionStrategy = action.payload.auctionStrategy
  state.pricingStrategy = action.payload.pricingStrategy
  state.frequency = action.payload.frequency || undefined
  if (action.payload.pacing) {
    state.pacing = action.payload.pacing
  }
}

export default setCampaignSettings
