import { IOrgTheme } from '@unfoldrtech/portal-mic'
import { ORG_DEFAULT_THEME } from '../components/Global'

/**
 *
 * IMPORTANT
 * Do Not Remove These Defaults
 *
 * TOFIX:
 * The following is required to ensure that
 * the styling of the app does not break when an organisation does not have a theme
 *
 * The defaults for background.primary and font.primary are required by the ThemeProvider
 *
 */
export function sanitizeTheme(orgTheme: IOrgTheme = ORG_DEFAULT_THEME) {
  const theme = orgTheme

  if (theme.colors === undefined) {
    theme.colors = {}
  }
  if (theme.colors.background === undefined) {
    theme.colors.background = {}
  }
  if (theme.colors.background.primary === undefined) {
    theme.colors.background.primary =
      ORG_DEFAULT_THEME.colors?.background?.primary
  }

  if (theme.colors.font === undefined) {
    theme.colors.font = {}
  }
  if (theme.colors.font.primary === undefined) {
    theme.colors.font.primary = ORG_DEFAULT_THEME.colors?.font?.primary
  }
  return theme
}
