import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconApp = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 22 24"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M12.75 13.5v6.267a2.733 2.733 0 0 1-2.732 2.733H3.483A2.734 2.734 0 0 1 .75 19.767V4.232A2.733 2.733 0 0 1 3.483 1.5H9.75M.75 18h12" />
      <path d="M19.5 4.5h-7.672a1.499 1.499 0 0 0-1.399 2.027l1.306 3.486a.75.75 0 0 0 .7.487h6.461a.748.748 0 0 0 .7-.487l1.308-3.488A1.498 1.498 0 0 0 19.499 4.5zm-7.586 0 1.5-3.75m6 3.75-1.5-3.75m-3.664 7.5v-1.5m3 1.5v-1.5" />
    </g>
  </svg>
)

export default SvgIconApp
