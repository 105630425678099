import styled from 'styled-components'
import { IThemeProps } from '../../../models/interfaces'
import { Action } from '../styles'
import { StyledButton } from './StyledButton'

export const WebsiteButton = styled(StyledButton)`
  ${(props: IThemeProps) =>
    Action({
      height: '100px',
      width: '200px',
      border: {
        style: 'solid',
        width: '4px',
        color:
          props.theme.colors?.border?.primary || 'var(--border-color-default)',
      },
      borderBottom: {
        width: '8px',
      },
      hoverAction: {
        border: {
          style: 'solid',
          width: '4px',
          color:
            props.theme.colors?.border?.primary ||
            'var(--border-color-default)',
        },
        borderBottom: {
          width: '4px',
        },
        opacity: '1',
      },
      disableAction: {
        border: {
          style: 'solid',
          width: '4px',
          color:
            props.theme.colors?.border?.primary ||
            'var(--border-color-default)',
        },
        borderBottom: {
          width: '8px',
        },
        opacity: '0.4',
      },
    })}
  }
`
