import { PayloadAction } from '@reduxjs/toolkit'
import { EAggregationType } from '@unfoldrtech/portal-mic'
import { TReportingFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'

const setAggregationType = (
  state: TReportingFilters,
  action: PayloadAction<{
    aggregationType: EAggregationType
  }>
) => {
  state.aggregationType = action.payload.aggregationType
}

export default setAggregationType
