import {
  Configuration,
  USERApi,
  RETAILERINVENTORYApi,
  RETAILERADMINApi,
  ADVERTISERADMINApi,
  ADVERTISERCAMPAIGNSApi,
  ConfigurationParameters,
  ADVERTISERREPORTINGApi,
  RETAILERREPORTINGApi,
  MISCApi,
} from '@unfoldrtech/portal-mic'
import {
  ADVERTISERREPORTINGApi as V2ADVERTISERREPORTINGApi,
  RETAILERREPORTINGApi as V2RETAILERREPORTINGApi,
  RETAILERADMINApi as V2RETAILERADMINApi,
  ADVERTISERCAMPAIGNSApi as V2ADVERTISERCAMPAIGNSApi,
} from '../models/core'
import firebaseAuth from './firebaseAuth'

const BE_HOST = process.env.REACT_APP_BE_HOST || 'http://localhost'
const BE_PORT = process.env.REACT_APP_BE_PORT || '2000'

const defaultBasePath = `${BE_HOST}:${BE_PORT}`

// We should rename Normal and V2 APIS to Legacy & Normal
// This change will affect a LOT of places, we need to do it in a clean PR.
export const coreAPI = {
  misc: (configParams?: ConfigurationParameters) =>
    new MISCApi(
      new Configuration({
        basePath: defaultBasePath,
        ...configParams,
      })
    ),
  user: (configParams?: ConfigurationParameters) =>
    new USERApi(
      new Configuration({
        accessToken: firebaseAuth.getIdToken(),
        basePath: defaultBasePath,
        ...configParams,
      })
    ),
  retailerInventory: (configParams?: ConfigurationParameters) =>
    new RETAILERINVENTORYApi(
      new Configuration({
        accessToken: firebaseAuth.getIdToken(),
        basePath: defaultBasePath,
        ...configParams,
      })
    ),
  retailerAdmin: (configParams?: ConfigurationParameters) =>
    new RETAILERADMINApi(
      new Configuration({
        accessToken: firebaseAuth.getIdToken(),
        basePath: defaultBasePath,
        ...configParams,
      })
    ),
  advertiserAdmin: (configParams?: ConfigurationParameters) =>
    new ADVERTISERADMINApi(
      new Configuration({
        accessToken: firebaseAuth.getIdToken(),
        basePath: defaultBasePath,
        ...configParams,
      })
    ),
  retailerReporting: (configParams?: ConfigurationParameters) =>
    new RETAILERREPORTINGApi(
      new Configuration({
        accessToken: firebaseAuth.getIdToken(),
        basePath: defaultBasePath,
        ...configParams,
      })
    ),
  advertiserCampaigns: (configParams?: ConfigurationParameters) =>
    new ADVERTISERCAMPAIGNSApi(
      new Configuration({
        accessToken: firebaseAuth.getIdToken(),
        basePath: defaultBasePath,
        ...configParams,
      })
    ),
  advertiserReporting: (configParams?: ConfigurationParameters) =>
    new ADVERTISERREPORTINGApi(
      new Configuration({
        accessToken: firebaseAuth.getIdToken(),
        basePath: defaultBasePath,
        ...configParams,
      })
    ),
  v2AdvertiserReporting: (configParams?: ConfigurationParameters) =>
    new V2ADVERTISERREPORTINGApi(
      new Configuration({
        accessToken: firebaseAuth.getIdToken(),
        basePath: defaultBasePath,
        ...configParams,
      })
    ),
  v2RetailerReporting: (configParams?: ConfigurationParameters) =>
    new V2RETAILERREPORTINGApi(
      new Configuration({
        accessToken: firebaseAuth.getIdToken(),
        basePath: defaultBasePath,
        ...configParams,
      })
    ),
  v2RetailerAdmin: (configParams?: ConfigurationParameters) =>
    new V2RETAILERADMINApi(
      new Configuration({
        accessToken: firebaseAuth.getIdToken(),
        basePath: defaultBasePath,
        ...configParams,
      })
    ),
  v2AdvertiserCampaigns: (configParams?: ConfigurationParameters) =>
    new V2ADVERTISERCAMPAIGNSApi(
      new Configuration({
        accessToken: firebaseAuth.getIdToken(),
        basePath: defaultBasePath,
        ...configParams,
      })
    ),
}
