import React from 'react'
import { EPageType } from '@unfoldrtech/portal-mic'
import I18NText from '../../I18NText'

interface ICurrentPageTypeTextProps {
  pageType?: EPageType
}

export function CurrentPageTypeText({ pageType }: ICurrentPageTypeTextProps) {
  if (pageType === EPageType.Categories) {
    return (
      <>
        <I18NText id="categories" />
      </>
    )
  }
  if (pageType === EPageType.Search) {
    return (
      <>
        <I18NText id="search" />
      </>
    )
  }
  if (pageType === EPageType.Home) {
    return (
      <>
        <I18NText id="homepage" />
      </>
    )
  }
  return <> </>
}
