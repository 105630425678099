import dayjs from 'dayjs'
import React from 'react'
import {
  NOT_AVAILABLE,
  SHARE_OF_VOICE_PROD_DEPLOYMENT_DATE,
} from '../../../../utils/constants'
import { PercentageText, Text } from '../../../Global'

export const TableDataCellShareOfVoice = ({
  dataValue,
  endDate,
}: {
  dataValue?: number
  endDate?: string
}) => {
  if (dayjs(endDate) < SHARE_OF_VOICE_PROD_DEPLOYMENT_DATE) {
    return <Text>{NOT_AVAILABLE}</Text>
  }
  return <PercentageText value={Number(dataValue)} />
}
