import { EEntityType, EOrgType } from '@unfoldrtech/portal-mic'

export const getPlaceholderImage = (
  entityType: EEntityType,
  orgType: EOrgType
) => {
  // Search terms
  if (
    entityType === EEntityType.AdGroupSearchTerms &&
    orgType === EOrgType.Retailer
  ) {
    return `${process.env.REACT_APP_CDN_URL}/images/d4f55087-96a2-44f5-96b7-b9b00401a9b6.png`
  }
  if (
    entityType === EEntityType.AdGroupSearchTerms &&
    orgType === EOrgType.Advertiser
  ) {
    return `${process.env.REACT_APP_CDN_URL}/images/2216d421-bef6-42a7-8169-e361a01413ee.png`
  }

  // Audiences
  if (
    entityType === EEntityType.AdGroupAudiences &&
    orgType === EOrgType.Retailer
  ) {
    return `${process.env.REACT_APP_CDN_URL}/images/01c2793a-a233-4598-9848-a8aa84975218.png`
  }
  if (
    entityType === EEntityType.AdGroupAudiences &&
    orgType === EOrgType.Advertiser
  ) {
    return `${process.env.REACT_APP_CDN_URL}/images/7e7f469e-c4b5-4cad-ae5f-b023f63c07e9.png`
  }

  // Defaults
  if (orgType === EOrgType.Retailer) {
    return `${process.env.REACT_APP_CDN_URL}/images/4e54ce6d-8d3b-44e5-bb3f-e6be8e837f19.png`
  }
  return `${process.env.REACT_APP_CDN_URL}/images/03905c58-c4a9-4cd3-84f7-e95c7fecb313.png`
}
