import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { EOrgType, IOrgAuthenticate } from '@unfoldrtech/portal-mic'
import * as Sentry from '@sentry/react'
import { logger } from '@sentry/utils'
import { useQueryClient } from 'react-query'
import {
  useNavigate,
  useLocation /* , useParams */,
  Location,
} from 'react-router-dom'
import { ThemeProvider } from 'styled-components'
import firebaseAuth from '../../services/firebaseAuth'
import { AppContext } from '../../models/contexts'
import {
  TAppContext,
  TLocationState,
  TTranslationKey,
} from '../../models/types'
import { AppTheme, Container, ORG_DEFAULT_THEME } from '../Global'
import { IAppContext, IParsedToken, IUserClaim } from '../../models/interfaces'
import I18NText from '../I18NText'
import useAuthenticateUser from '../../hooks/useAuthenticateUser'
import useLogout from '../../hooks/useLogout'
import Locale from './Locale'
import StyledTheme from './StyledTheme'
import { ErrorToast } from '../ToastCard/ErrorToast'
import { UserConsent } from '../UserConsent'
import { sanitizeTheme } from '../../utils/sanitizeTheme'

/**
 * @description If logged in -
 * Initialises user profie and user organisation in global app context
 * Also initalises the following configuration based on user preferences
 * - Locale/Intl
 * - Theme
 * @returns children wrapped in App, Theme and Intl Context - If logged in
 * @returns children - If not logged in
 */

function InitAppContext({ children }: { children?: ReactNode | ReactNode[] }) {
  const navigate = useNavigate()
  const location = useLocation()

  const queryClient = useQueryClient()

  const [appContext, setAppContext] = useContext<TAppContext>(AppContext)

  const [errorMessage, setErrorMessage] = useState<string>()

  const {
    error: errorUserAuthenticate,
    isError: isErrorUserAuthenticate,
    mutate: authenticateUser,
    // isFetching: isFetchingUserAuthenticate,
    // isSuccess: isSuccessUserAuthenticate,
    data: userAuthenticateResponse,
    reset: resetUserAuthenticate,
  } = useAuthenticateUser()

  const {
    mutate: logout,
    // isLoading: isLoadingLogout,
    isSuccess: isSuccessLogout,
    isError: isErrorLogout,
  } = useLogout()

  const userOrgProfile = userAuthenticateResponse?.data || {}

  /**
   * URL [.../<domain>/orgType/orgId/...]
   * use the org id and org type from URL/location.state, if they exist
   * otherwise authenticate against default org
   * */
  const getOrgAuthenticatePayload = (
    currentLocation: Location
  ): IOrgAuthenticate => {
    const orgAuthenticate: IOrgAuthenticate = {}

    const pathParts = currentLocation.pathname.split('/')

    const organisationType =
      pathParts.length > 1
        ? (pathParts[1] as 'advertiser' | 'retailer')
        : undefined
    const orgType: EOrgType =
      organisationType === 'advertiser'
        ? EOrgType.Advertiser
        : EOrgType.Retailer

    const secondPathPart = pathParts.length > 2 ? pathParts[2] : undefined
    const orgId = !Number.isNaN(Number(secondPathPart))
      ? Number(secondPathPart)
      : undefined

    if (orgId && orgType) {
      orgAuthenticate.organisation = {
        id: Number(orgId),
        type: orgType,
      }
    }
    return orgAuthenticate
  }

  useEffect(() => {
    // Clear react query cache
    queryClient.getQueryCache().clear()
    // Clear user from sentry scope
    Sentry.configureScope((scope) => scope.setUser(null))

    const orgAuthenticate = getOrgAuthenticatePayload(location)

    if (appContext.isLoggedIn) {
      authenticateUser(orgAuthenticate)
    }
  }, [appContext.isLoggedIn])

  useEffect(() => {
    if (appContext.isLoggedIn && userOrgProfile.userProfile) {
      ;(async () => {
        const { userProfile } = userOrgProfile
        const { userOrg } = userOrgProfile
        const { claims } = await firebaseAuth.getIdTokenResult()

        const authenticatedContextData: IAppContext = {
          ...appContext,
        }

        const userClaims = claims as IParsedToken & IUserClaim

        authenticatedContextData.userProfile = userProfile
        authenticatedContextData.userOrg = userOrg
        authenticatedContextData.userClaims = userClaims

        Sentry.configureScope((scope) =>
          scope.setUser({
            userId: userProfile?.id,
            // userFullName: `${userProfile?.lastName}, ${userProfile?.firstName}`,
          })
        )

        setAppContext(authenticatedContextData)
      })()
    }
  }, [userOrgProfile])

  useEffect(() => {
    if (appContext.isLoggedIn && appContext.userProfile) {
      /**
       * Navigate to /organisations if the current page is
       *    - /
       *    - login
       *    - reset password
       *    - register
       */
      if (
        location.pathname === '/' ||
        location.pathname.startsWith('/login') ||
        // location.pathname.startsWith('/forgot-password') ||
        location.pathname.startsWith('/reset-password') ||
        // location.pathname.startsWith('/organisations') ||
        location.pathname.startsWith('/register')
      ) {
        navigate((location.state as TLocationState)?.requestedRoute || '/mfa')
      }
    }
  }, [appContext.isLoggedIn, appContext.userProfile])

  useEffect(() => {
    if (isSuccessLogout) {
      ;(async () => {
        await firebaseAuth.logout()
      })()
    }
  }, [isSuccessLogout])

  useEffect(() => {
    if (isErrorLogout) {
      ;(async () => {
        await firebaseAuth.logout()
      })()
    }
  }, [isErrorLogout])

  useEffect(() => {
    if (isErrorUserAuthenticate) {
      const errorResponse =
        errorUserAuthenticate?.response?.data?.errorKey ||
        'error.authenticating.user'
      const message = errorResponse
      setErrorMessage(message)
    }
  }, [isErrorUserAuthenticate])

  useEffect(() => {
    if (errorMessage?.length) {
      logger.error(errorMessage)
    }
  }, [errorMessage])

  useEffect(() => {
    return () => {
      setErrorMessage('')
    }
  }, [])

  /**
   * Error while authenticating
   */
  if (isErrorUserAuthenticate) {
    return (
      <>
        <ErrorToast
          show={Boolean(errorMessage?.length)}
          onClose={async () => {
            logout()
            resetUserAuthenticate()
          }}
          translationKey={errorMessage as unknown as TTranslationKey}
        />
      </>
    )
  }

  /**
   * Logged in, but user profile in app context is not initialised
   */
  if (appContext.isLoggedIn && !appContext.userProfile) {
    return (
      <>
        <Container textAlign="center">
          <I18NText id="loadingSuspense" />
        </Container>
      </>
    )
  }

  /**
   * Logged in, user profile in app context is initialised, but user consent is not given
   */
  if (
    appContext.isLoggedIn &&
    !appContext.userProfile?.hasConsentTermsAndConditions
  ) {
    return (
      <>
        <Locale>
          <StyledTheme>
            <AppTheme />
            <UserConsent
              onConsent={() => {
                const orgAuthenticate = getOrgAuthenticatePayload(location)
                authenticateUser(orgAuthenticate)
              }}
            />
          </StyledTheme>
        </Locale>
      </>
    )
  }

  /**
   * Logged in and user profile in app context is initialised
   */
  if (appContext.isLoggedIn && appContext.userProfile) {
    return (
      <>
        <Locale>
          <StyledTheme>
            <AppTheme />
            {children}
          </StyledTheme>
        </Locale>
      </>
    )
  }

  if (location.pathname.includes('/mfa')) {
    return (
      <>
        <ThemeProvider theme={sanitizeTheme(ORG_DEFAULT_THEME)}>
          <AppTheme />
          {children}
        </ThemeProvider>
      </>
    )
  }

  return <>{children}</>
}

export default InitAppContext
