import { EOrgType } from '@unfoldrtech/portal-mic'
import useGetAdvertiserReportingDAAudiencesTableV3 from '../../../../hooks/use-get-advertiser-reporting-da-audiences-table-v3'
import useGetAdvertiserReportingDAAudiencesChartV3 from '../../../../hooks/use-get-advertiser-reporting-da-audiences-chart-v3'
import useGetRetailerReportingDAAudiencesTableV3 from '../../../../hooks/use-get-retailer-reporting-da-audiences-table-v3'
import useGetRetailerReportingDAAudiencesChartV3 from '../../../../hooks/use-get-retailer-reporting-da-audiences-chart-v3'

export const getTableAndChartDAAudiencesFn = ({
  orgType = EOrgType.Advertiser,
}: {
  orgType?: EOrgType
}) => {
  let tableFn
  let chartFn
  if (orgType === EOrgType.Advertiser) {
    tableFn = useGetAdvertiserReportingDAAudiencesTableV3
    chartFn = useGetAdvertiserReportingDAAudiencesChartV3
  }
  if (orgType === EOrgType.Retailer) {
    tableFn = useGetRetailerReportingDAAudiencesTableV3
    chartFn = useGetRetailerReportingDAAudiencesChartV3
  }

  return { tableFn, chartFn }
}
