import styled from 'styled-components'
import Form from 'react-bootstrap/Form'

import {
  Display,
  FontStyle,
  TDisplayMixin,
  TFontStyleMixin,
  TruncatePreset,
  Background,
  TBackgroundMixin,
  Border,
  TBorderMixin,
  Alignment,
  TAlignmentMixin,
} from '../styles/mixins'

export const CheckboxLabel = styled(Form.Check.Label)<
  TFontStyleMixin &
    TDisplayMixin &
    TBackgroundMixin &
    TBorderMixin &
    TAlignmentMixin
>`
  margin: 0 var(--margin-default);
  ${TruncatePreset('100%')};
  ${(props: TFontStyleMixin) => FontStyle({ ...props })};
  ${(props: TDisplayMixin) => Display({ ...props })};
  ${(props: TBackgroundMixin) => Background({ ...props })};
  ${(props: TBorderMixin) => Border({ ...props })};
  ${(props: TAlignmentMixin) => Alignment({ ...props })};
`
