import React, { useState, useEffect, ReactNode, useContext } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { EEntityType, EParamSortOrder, EStatus } from '@unfoldrtech/portal-mic'
import { TReportingFilters } from '../../../../models/types'
import { StyledImage } from '../../../Global'
import Loading from '../../../Loading'
import { selectDAAudienceTableReportingFilters } from '../../../../store/reportingFilters'
import {
  selectRefreshTable,
  setRefreshTable,
} from '../../../../store/refreshTable'
import { ReportTableV3 } from '../../components/ReportTableV3'
import { getPlaceholderImage } from '../../helpers/get-placeholder-image'
import { TableDataCellName } from '../../components/table-data-cell/TableDataCellName'
import { TableDataCellStatus } from '../../components/table-data-cell/TableDataCellStatus'
import { TableDataCellAdGroupName } from '../../components/table-data-cell/TableDataCellAdGroupName'
import { TableDataCellImpressions } from '../../components/table-data-cell/TableDataCellImpressions'
import { TableDataCellCosts } from '../../components/table-data-cell/TableDataCellCosts'
import { TableDataCellCpm } from '../../components/table-data-cell/TableDataCellCpm'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import {
  AppContext,
  AdGroupContext,
  CampaignContext,
  EDatasetKey,
  IDAReportAudiencesTable,
  EDAReportAudiencesColumnHeader,
} from '../../../../models'
import { TReportTableDataV3 } from '../../models/types/report-table-data-v3.type'
import { useGetDatasetKeyTableWhitelist } from '../../hooks/use-get-dataset-key-table-whitelist'
import { TDAAudienceTableHeaders } from '../models'
import { getTableAndChartDAAudiencesFn } from '../helpers/get-table-and-chart-da-audiences-fn'
import { TableDataCellPlacement } from '../../components/table-data-cell/TableDataCellPlacement'
import { TableDataCellCtr } from '../../components/table-data-cell/TableDataCellCtr'
import { TableDataCellUniqueReach } from '../../components/table-data-cell/TableDataCellUniqueReach'
import { TGlobalTables } from '../../models'
import { TableDataCellShareOfVoice } from '../../components/table-data-cell/TableDataCellShareOfVoice'
import { TableDataCellFrequency } from '../../components/table-data-cell/TableDataCellFrequency'

export const ReportingDAAudienceTableWrapper = ({
  onTableDataChange,
}: {
  onTableDataChange: (audiencesTableData: IDAReportAudiencesTable) => void
}) => {
  const dispatch = useDispatch()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)

  const { startDate, endDate, timeWindow, tzZone } = useSelector(
    selectDAAudienceTableReportingFilters
  )
  const refreshTable = useSelector(selectRefreshTable)
  const whitelistData = useGetDatasetKeyTableWhitelist({
    key: EDatasetKey.AudienceDa,
  })
  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()

  const orgType = appContext.userOrg!.type
  const hookFn = getTableAndChartDAAudiencesFn({
    orgType,
  })

  const getTableDataFn = hookFn.tableFn!

  const campaignId = campaign?.id || -1
  const adGroupId = adGroup?.id

  const [sortBy, setSortBy] = useState<TDAAudienceTableHeaders>()
  const [sortOrder, setSortOrder] = useState<EParamSortOrder>()
  const [tableData, setTableData] = useState<TReportTableDataV3>()
  const [tableParamsUpdated, setTableParamsUpdated] = useState<boolean>()

  const {
    data: tableDataResponseObj,
    refetch,
    isFetching,
  } = getTableDataFn({
    retailerId,
    advertiserId,
    campaignId,
    adGroupId,
    sortBy,
    timeWindow,
    startDate,
    endDate,
    sortOrder,
    tzZone,
    enabled: false,
  })

  const onSortByChange = (sortColumn?: TGlobalTables) => {
    setSortBy(sortColumn as TDAAudienceTableHeaders)
  }

  const onSortOrderChange = (
    type: TReportingFilters['sortOrder'] = EParamSortOrder.Desc
  ) => {
    setSortOrder(type)
  }

  const tableDataResponse =
    tableDataResponseObj?.data.table || ({} as IDAReportAudiencesTable)

  const processTableDataResponse = (tableDataRes: IDAReportAudiencesTable) => {
    const headers = whitelistData.map((wData) => wData.header)

    const processedTableData: TReportTableDataV3 = {
      columnIdList: headers,
      columnNameList: whitelistData.map((wData) => wData.translatedMetricName),
      columnNameTooltipList: whitelistData.map(
        (wData) => wData.translatedTooltipMetricName
      ),
      columnSpanList: headers.map((header) =>
        header === EDAReportAudiencesColumnHeader.Name ? 2 : 1
      ),
      rowList: [],
    }

    tableDataRes.rows.forEach((rows) => {
      const dataRow: Array<ReactNode | ReactNode[]> = []
      headers.forEach((header) => {
        let dataCell: ReactNode | ReactNode[]
        const dataValue = rows[header as TDAAudienceTableHeaders]

        switch (header) {
          case EDAReportAudiencesColumnHeader.Name:
            dataCell = <TableDataCellName dataValue={dataValue?.toString()} />
            break
          case EDAReportAudiencesColumnHeader.Status:
            dataCell = (
              <TableDataCellStatus
                dataValue={dataValue?.toString() as EStatus}
              />
            )
            break
          case EDAReportAudiencesColumnHeader.AdgroupName:
            dataCell = (
              <TableDataCellAdGroupName dataValue={dataValue?.toString()} />
            )
            break
          case EDAReportAudiencesColumnHeader.Placement:
            dataCell = (
              <TableDataCellPlacement dataValue={dataValue?.toString()} />
            )
            break
          case EDAReportAudiencesColumnHeader.Impressions:
            dataCell = (
              <TableDataCellImpressions dataValue={Number(dataValue)} />
            )
            break
          case EDAReportAudiencesColumnHeader.Clicks:
            dataCell = (
              <TableDataCellImpressions dataValue={Number(dataValue)} />
            )
            break
          case EDAReportAudiencesColumnHeader.Ctr:
            dataCell = <TableDataCellCtr dataValue={Number(dataValue)} />
            break
          case EDAReportAudiencesColumnHeader.UniqueReach:
            dataCell = (
              <TableDataCellUniqueReach dataValue={Number(dataValue)} />
            )
            break
          case EDAReportAudiencesColumnHeader.Frequency:
            dataCell = <TableDataCellFrequency dataValue={Number(dataValue)} />
            break
          case EDAReportAudiencesColumnHeader.Costs:
            dataCell = <TableDataCellCosts dataValue={Number(dataValue)} />
            break
          case EDAReportAudiencesColumnHeader.Cpm:
            dataCell = <TableDataCellCpm dataValue={Number(dataValue)} />
            break
          case EDAReportAudiencesColumnHeader.ShareOfVoice:
            dataCell = (
              <TableDataCellShareOfVoice dataValue={Number(dataValue)} />
            )
            break

          default:
            dataCell = undefined
            break
        }

        if (dataCell) {
          dataRow.push(dataCell)
        }
      })

      processedTableData.rowList.push(dataRow)
    })

    return processedTableData
  }

  useEffect(() => {
    if (startDate && endDate) {
      setTableParamsUpdated(true)
    }
  }, [startDate, endDate])

  useEffect(() => {
    if (sortBy || sortOrder) {
      setTableParamsUpdated(true)
    }
  }, [sortBy, sortOrder])

  useEffect(() => {
    if (tableParamsUpdated) {
      refetch()
      setTableParamsUpdated(false)
    }
  }, [tableParamsUpdated])

  useEffect(() => {
    if (refreshTable) {
      refetch()
      dispatch(setRefreshTable({ refreshTable: false }))
    }
  }, [refreshTable])

  useEffect(() => {
    if (Object.keys(tableDataResponse).length > 0) {
      setTableData(processTableDataResponse(tableDataResponse))
      onTableDataChange(tableDataResponse)
    }
  }, [tableDataResponse])

  return (
    <>
      {tableData?.rowList?.length ? (
        <>
          {tableData?.rowList && (
            <ReportTableV3
              {...tableData}
              sortBy={sortBy}
              sortOrder={sortOrder}
              setSortBy={onSortByChange}
              setSortOrder={onSortOrderChange}
            />
          )}
        </>
      ) : (
        <StyledImage
          fluid
          src={getPlaceholderImage(EEntityType.AdGroupAudiences, orgType)}
          alt="home_placeholder"
          cursor="default"
        />
      )}
      <Loading show={isFetching} />
    </>
  )
}
