// TOFIX: Build fails on CI for some reason
/* eslint-disable prettier/prettier */
import React, { ReactHTML } from 'react'
import { FormattedMessage } from 'react-intl'
import { TTextValues, TTranslationKey } from '../../models/types'
import { Text } from '../Global/fonts/Text'
import { TFontPresetMixin, TFontStyleMixin } from '../Global/styles/mixins'

interface IBase<T> {
  tag?: keyof ReactHTML | React.FC<T>
  shouldRender?: boolean
  id: TTranslationKey | string
  defaultMessage?: string
  values?: TTextValues
}

type TI18nText<T> = IBase<T> &
  TFontStyleMixin & {
    [K in keyof T]: T[K]
  }

const unwrapFontStyles = (props: TFontStyleMixin & TFontPresetMixin) => ({
  ...props,
})

/**
 * Component used to render an intl message having as a wrapper a given tag
 *
 * @param tag
 * @param shouldRender
 * @param dynamicProps
 * @constructor
 */
function I18NText<P extends object>({
  shouldRender = true,
  ...dynamicProps
}: TI18nText<P>) {
  if (!shouldRender) return null

  const { defaultMessage, id, values, ...parentProps } = dynamicProps
  const messageProps = { defaultMessage, id, values }
  const fontStyles = unwrapFontStyles(parentProps)

  return (
    <Text {...fontStyles}>
      <FormattedMessage {...messageProps} />
    </Text>
  )
}

export default I18NText
