import styled from 'styled-components'
import { IThemeProps } from '../../../models/interfaces'
import {
  TActionMixin,
  Action,
  TruncatePreset,
  TFontPresetMixin,
  FontPreset,
} from '../styles/mixins'
import { StyledButton } from './StyledButton'

export const FilterButton = styled(StyledButton)<
  TActionMixin & TFontPresetMixin
>`
  box-shadow: 0 2px 3px 0 var(--border-color-filter-box-shadow);

  ${(props: TActionMixin & IThemeProps) =>
    Action({
      fontFamily: 'OpenSans-SemiBold',
      lh: 'var(--button-size-sm)',
      fontSize: 'var(--icon-size-sm)',
      color: props.theme?.colors?.font?.primary || 'var(--font-color-default)',
      textTransform: 'capitalize',

      background:
        props.theme?.colors?.background?.primary ||
        'var(--background-color-default)',

      width: 'var(--button-size-lg)',
      height: 'var(--button-size-lg)',

      border: {
        radius: '2px',
        width: '1px',
        style: 'solid',
        color: 'var(--border-color-default)',
      },
      borderBottom: {
        width: '3px',
        style: 'solid',
        color: 'var(--border-color-filter-bottom)',
      },

      hoverAction: {
        color:
          props.theme?.colors?.font?.primary || 'var(--font-color-default)',

        background:
          props.theme?.colors?.background?.primary ||
          'var(--background-color-default)',

        border: {
          radius: '2px',
          width: '1px',
          style: 'solid',
          color: 'var(--border-color-default)',
        },
      },

      focusAction: {
        color:
          props.theme?.colors?.font?.primary || 'var(--font-color-default)',

        background:
          props.theme?.colors?.background?.primary ||
          'var(--background-color-default)',

        border: {
          radius: '2px',
          width: '1px',
          style: 'solid',
          color: 'var(--border-color-default)',
        },
      },

      disableAction: {
        color:
          props.theme?.colors?.font?.primary || 'var(--font-color-default)',

        background:
          props.theme?.colors?.background?.primary ||
          'var(--background-color-default)',
        border: {
          style: 'hidden',
        },
      },

      ...props,
    })};
  ${(props: TFontPresetMixin) => FontPreset(props.preset)};
  ${TruncatePreset('100%')};
`
