import React from 'react'
import RetailerRoutes from './RetailerRoutes'
import PublicRoutes from './PublicRoutes'
import SettingsRoutes from './SettingsRoutes'
import AdvertiserRoutes from './AdvertiserRoutes'
import AdvertiserAnalyticsRoutes from './AdvertiserAnalyticsRoutes'
import MiscRoutes from './MiscRoutes'
import AdvertiserFaqRoutes from './AdvertiserFaqRoutes'

function AppRouter() {
  return (
    <>
      <PublicRoutes />

      <MiscRoutes />

      <RetailerRoutes />

      <AdvertiserRoutes />
      <AdvertiserAnalyticsRoutes />

      <SettingsRoutes />
      <AdvertiserFaqRoutes />
    </>
  )
}

export default AppRouter
