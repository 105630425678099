import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconZoom = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 22 22"
    {...props}
  >
    <path
      d="M11.959 16.222A8.056 8.056 0 1 0 5.657 1.394a8.056 8.056 0 1 0 6.302 14.828zm2.545-1.727 6.248 6.249"
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgIconZoom
