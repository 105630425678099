import styled, { CSSProperties } from 'styled-components'

import {
  Alignment,
  FontPreset,
  FontStyle,
  Sticky,
  TAlignmentMixin,
  TFontPresetMixin,
  TFontStyleMixin,
  TruncatePreset,
  TStickyProps,
  Padding,
  TPaddingMixin,
} from '../styles/mixins'

export const StyledTableCell = styled.td<
  TStickyProps &
    TFontStyleMixin &
    TAlignmentMixin &
    TFontPresetMixin &
    TPaddingMixin &
    Pick<CSSProperties, 'background' | 'backgroundColor' | 'paddingLeft'> & {
      active?: boolean
    }
>`
  && {
    background: ${(props) => props.background || ''};
    background-color: ${(props) => props.backgroundColor || ''};

    ${(props) =>
      Padding({
        padding: props.padding || '0 var(--gutter-2x)',
        paddingLeft: props.paddingLeft || '',
      })}
    ${(props: TFontPresetMixin) =>
      FontPreset(props.preset || 'paragraph small opensans')};
    ${(props: TFontStyleMixin) =>
      FontStyle({
        ...props,
      })}
    ${(props: TAlignmentMixin) =>
      Alignment({
        ...props,
      })};
    ${(props: TStickyProps) =>
      Sticky({
        position: props.position || 'initial',
        zIndex: props.zIndex || 'auto',
        top: props.top || '',
        left: props.left || '',
        right: props.right || '',
        bottom: props.bottom || '',
      })};
    ${TruncatePreset('100%')};
  }
`
