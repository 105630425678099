import { PayloadAction } from '@reduxjs/toolkit'
import { EAggregationType } from '@unfoldrtech/portal-mic'
import { TReportingFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'

const setTimeProperties = (
  state: TReportingFilters,
  action: PayloadAction<{
    startDate: string
    endDate: string
    aggregationType: EAggregationType
  }>
) => {
  state.startDate = action.payload.startDate
  state.endDate = action.payload.endDate
  state.aggregationType = action.payload.aggregationType
}

export default setTimeProperties
