import { EInventoryType } from '@unfoldrtech/portal-mic'
import React from 'react'
import I18NText from '../../I18NText'

export function CurrentPlacementText({
  placement,
}: {
  placement?: EInventoryType
}) {
  if (placement === EInventoryType.Category) {
    return (
      <>
        <I18NText id="category.page" />
      </>
    )
  }
  if (placement === EInventoryType.Keyword) {
    return (
      <>
        <I18NText id="search.page" />
      </>
    )
  }
  if (placement === EInventoryType.PagePosition) {
    return (
      <>
        <I18NText id="homepage" />
      </>
    )
  }
  return <> </>
}
