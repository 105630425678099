import { PayloadAction } from '@reduxjs/toolkit'
import { ICampaign } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedPosition } from '../utils/getSelectedPosition'

const setAdGroupPositionBidPrice = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    bidPrice: number
    positionTargetId: number
    adGroupId: number
  }>
) => {
  const selectedPositionTarget = getSelectedPosition(state, action)

  if (selectedPositionTarget) {
    selectedPositionTarget.bidPrice = action.payload.bidPrice
  }
}

export default setAdGroupPositionBidPrice
