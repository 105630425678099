import React from 'react'
import styled, { CSSProperties } from 'styled-components'
import { IToastProps } from '../../models/interfaces'
import { TDataTest } from '../../models/types'
import {
  IconToastWarning,
  SVGContainer,
  Text,
  Container,
  EllipsisContainer,
  Action,
} from '../Global'
import { ToastCard } from '.'
import I18NText from '../I18NText'

export const ToastText = styled(Text)`
  ${Action({
    cursor: 'pointer',
    hoverAction: {
      opacity: 0.5,
    },
  })}
`

export function WarningToast({
  delay = 5000,
  autohide = true,
  onClose = () => {},
  position = 'top-center',
  dataTestId,
  display,
  show,
  translationKey = 'toast.message.default.warning',
  onClick,
}: IToastProps & TDataTest & Pick<CSSProperties, 'display'>) {
  return (
    <>
      <ToastCard
        delay={delay}
        autohide={autohide}
        onClose={onClose}
        position={position}
        dataTestId={dataTestId}
        display={display}
        border={{
          style: 'solid',
          width: '1px',
          color: 'var(--toast-color-warning)',
          radius: 'var(--gutter-5x)',
        }}
        height="var(--gutter-7x)"
        padding="var(--gutter-1x)"
        maxWidth="100%"
        width="auto"
        show={show}
      >
        <Container display="flex">
          <SVGContainer
            backgroundColor="var(--toast-color-warning)"
            marginRight="var(--gutter-2x)"
            border={{ radius: 'var(--gutter-5x)' }}
            width="var(--gutter-5x)"
            height="var(--gutter-5x)"
            padding="var(--gutter-1x)"
            textAlign="center"
            preset="formfield-lg"
          >
            <IconToastWarning />
          </SVGContainer>
          <EllipsisContainer padding="var(--gutter-1x) 0" display="flex">
            <Container
              marginRight="var(--gutter-5x)"
              data-testid="warning-toast-message-container"
            >
              <I18NText
                id={translationKey}
                preset="paragraph regular"
                color="var(--font-color-dark)"
              />
            </Container>
            {onClick && (
              <Container margin="0 var(--gutter-5x)">
                <ToastText
                  color="var(--toast-color-warning)"
                  onClick={onClick}
                  preset="cta-sm"
                >
                  <I18NText id="try.again" />
                </ToastText>
              </Container>
            )}
          </EllipsisContainer>
        </Container>
      </ToastCard>
    </>
  )
}
