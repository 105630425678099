import { PayloadAction } from '@reduxjs/toolkit'
import {
  EStatus,
  IAdGroup,
  IAdGroupPosition,
  ICampaign,
} from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedAdGroup } from '../utils'

const addAdGroupPosition = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    position: IAdGroupPosition
    adGroupId: number
  }>
) => {
  const selectedAdGroup =
    getSelectedAdGroup(state, action.payload.adGroupId) || ({} as IAdGroup)
  if (selectedAdGroup.positionsList === undefined) {
    selectedAdGroup.positionsList = []
  }
  const isExists =
    selectedAdGroup?.positionsList.find(
      (p) => p.id === action.payload.position.id && p.status === EStatus.Active
    ) || undefined
  if (isExists === undefined) {
    selectedAdGroup.positionsList.push(action.payload.position)
  }
}
export default addAdGroupPosition
