import { css, CSSProperties } from 'styled-components'

export type TBorderPropertiesMixin = Pick<
  CSSProperties,
  | 'borderWidth'
  | 'borderColor'
  | 'borderStyle'
  | 'borderRadius'
  | 'borderTopWidth'
  | 'borderTopColor'
  | 'borderTopStyle'
  | 'borderTopLeftRadius'
  | 'borderTopRightRadius'
  | 'borderBottomWidth'
  | 'borderBottomColor'
  | 'borderBottomStyle'
  | 'borderBottomLeftRadius'
  | 'borderBottomRightRadius'
  | 'borderLeftWidth'
  | 'borderLeftColor'
  | 'borderLeftStyle'
  | 'borderRightWidth'
  | 'borderRightColor'
  | 'borderRightStyle'
  | 'width'
  | 'color'
> & {
  radius?: string
  style?: 'solid' | 'dashed' | 'hidden' | 'hidden !important'
}

// TOFIX: Mixin should allow assigning only relevant properties
export type TBorderMixin = {
  borderRight?: TBorderPropertiesMixin
  borderLeft?: TBorderPropertiesMixin
  borderTop?: TBorderPropertiesMixin
  borderBottom?: TBorderPropertiesMixin
  border?: TBorderPropertiesMixin
}

export const Border = ({
  borderRight,
  borderLeft,
  borderTop,
  borderBottom,
  border,
}: TBorderMixin) => css`
  border-width: ${border?.width || border?.borderWidth};
  border-style: ${border?.style || border?.borderStyle};
  border-color: ${border?.color || border?.borderColor};
  border-radius: ${border?.radius || border?.borderRadius};

  border-right-width: ${borderRight?.width || border?.borderRightWidth};
  border-right-style: ${borderRight?.style || border?.borderRightStyle};
  border-right-color: ${borderRight?.color || border?.borderRightColor};
  /* border-right-radius: ${borderRight?.radius}; */

  border-left-width: ${borderLeft?.width || border?.borderLeftWidth};
  border-left-style: ${borderLeft?.style || border?.borderLeftStyle};
  border-left-color: ${borderLeft?.color || border?.borderLeftColor};
  /* border-left-radius: ${borderLeft?.radius}; */

  border-top-width: ${borderTop?.width || border?.borderTopWidth};
  border-top-style: ${borderTop?.style || border?.borderTopStyle};
  border-top-color: ${borderTop?.color || border?.borderTopColor};
  border-top-left-radius: ${border?.borderTopLeftRadius};
  border-top-right-radius: ${border?.borderTopRightRadius};
  /* border-top-radius: ${borderTop?.radius}; */

  border-bottom-width: ${borderBottom?.width || border?.borderBottomWidth};
  border-bottom-style: ${borderBottom?.style || border?.borderBottomStyle};
  border-bottom-color: ${borderBottom?.color || border?.borderBottomColor};
  border-bottom-left-radius: ${border?.borderBottomLeftRadius};
  border-bottom-right-radius: ${border?.borderBottomRightRadius};
  /* border-bottom-radius: ${borderBottom?.radius}; */
`
