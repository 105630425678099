export const isValidResolution = (
  video: { width: number; height: number },
  validationVideo: { width: number; height: number }
): boolean => {
  const videoWidth = video.width
  const videoHeight = video.height
  const validationWidth = validationVideo.width
  const validationHeight = validationVideo.height
  return (
    (videoWidth === validationWidth && videoHeight === validationHeight) ||
    (videoWidth > validationWidth &&
      videoHeight > validationHeight &&
      videoWidth / videoHeight === validationWidth / validationHeight) // video is bigger, but kept the aspect ratio
  )
}
