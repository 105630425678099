import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconAlignLeft = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 14 9"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M1 .5h12m-12 4h8m-8 4h12"
      stroke="currentColor"
      strokeLinecap="round"
    />
  </svg>
)

export default SvgIconAlignLeft
