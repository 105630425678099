import React from 'react'
import { CSSProperties } from 'styled-components'
import { sanitizeDataTestId } from '../../utils/sanitizeDataTestId'
import {
  EllipsisContainer,
  SVGContainer,
  Container,
  IconCircleCrossSmall,
  Text,
  IconButton,
} from '../Global'

export function BigNumber({
  id = -1,
  value = 0,
  translatedMetricName = '',
  metricName = '',
  description = '',
  borderBottomColor = 'var(--border-color-default)',
  isSelected = false,
  onClick = () => {},
  onRemove = () => {},
}: {
  id?: number
  value?: number | string
  translatedMetricName?: string
  metricName?: string
  description?: string
  isSelected?: boolean
  onClick?: (id: number) => void
  onRemove?: (id: number) => void
} & Pick<CSSProperties, 'borderBottomColor'>) {
  return (
    <Container
      width="100%"
      padding="var(--margin-default)"
      border={{
        width: '1px',
        style: 'solid',
        color: 'var(--border-color-default)',
        radius: '4px',
      }}
      borderBottom={{
        width: '3px',
        color: isSelected ? borderBottomColor : 'var(--border-color-default)',
        radius: '1.5px',
      }}
      /* style={{
        boxShadow: isSelected ? '0px 0px 8px 4px rgba(0,0,0,0.2)' : '',
      }} */
    >
      <Container display="flex" alignItems="center">
        <EllipsisContainer
          flex="1"
          preset="paragraph opensans"
          cursor="pointer"
          onClick={() => onClick(id)}
        >
          <Text
            data-testid={sanitizeDataTestId(`chart-metric-name-${metricName}`)}
          >
            {translatedMetricName}
          </Text>
        </EllipsisContainer>
        <SVGContainer opacity="0.5">
          <IconButton
            onClick={() => onRemove(id)}
            data-testid={sanitizeDataTestId(`chart-metric-cross-${metricName}`)}
          >
            <IconCircleCrossSmall />
          </IconButton>
        </SVGContainer>
      </Container>
      <Container
        display="flex"
        alignItems="center"
        cursor="pointer"
        onClick={() => onClick(id)}
      >
        <EllipsisContainer preset="h5" padding="0 var(--margin-default) 0 0">
          <Text
            data-testid={sanitizeDataTestId(`chart-metric-value-${metricName}`)}
          >
            {value}
          </Text>
        </EllipsisContainer>
        <EllipsisContainer preset="paragraph small opensans" opacity="0.5">
          <Text>{description}</Text>
        </EllipsisContainer>
      </Container>
    </Container>
  )
}
