import { css, CSSProperties } from 'styled-components'

export type TTruncateMixin = Pick<
  CSSProperties,
  | 'whiteSpace'
  | 'overflow'
  | 'textOverflow'
  | 'maxWidth'
  | 'overflowX'
  | 'overflowY'
> & {
  truncateWidth?: string
}

export const Truncate = ({
  maxWidth,
  truncateWidth,
  whiteSpace,
  overflow,
  overflowX,
  overflowY,
  textOverflow,
}: TTruncateMixin) => css`
  max-width: ${truncateWidth || maxWidth};
  white-space: ${whiteSpace};
  overflow: ${overflow};
  overflow-x: ${overflowX};
  overflow-y: ${overflowY};
  text-overflow: ${textOverflow};
`

export const TruncatePreset = (maxWidth?: string) =>
  Truncate({
    maxWidth,
    truncateWidth: maxWidth,
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
  })
