import { PayloadAction } from '@reduxjs/toolkit'
import { EParamTimeWindow } from '@unfoldrtech/portal-mic'
import { TReportingFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'

const setTimeWindow = (
  state: TReportingFilters,
  action: PayloadAction<{
    timeWindow?: EParamTimeWindow
  }>
) => {
  state.timeWindow = action.payload.timeWindow
}

export default setTimeWindow
