import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconDigitalOutOfHomeSmall = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 22 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      d="M4 32a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2v1H4v-1zM8 23h6v7H8z"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <rect
      width="20"
      height="22"
      rx="2"
      transform="matrix(1 0 0 -1 1 23)"
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      stroke="currentColor"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
      d="M4 20h14V4H4z"
    />
    <path d="m6 9 3-3M6 12l3-3" stroke="currentColor" strokeLinecap="round" />
  </svg>
)

export default SvgIconDigitalOutOfHomeSmall
