import { EChannelTypeAllAndDooh } from '@unfoldrtech/portal-mic/dist/src/openapi/core'
import { EChannelType, EParamChannelType } from '@unfoldrtech/portal-mic'

export function convertChannelTypeToParam(
  channelType: EChannelTypeAllAndDooh | EChannelType
) {
  switch (channelType) {
    case EChannelType.SponsoredProduct:
      return EParamChannelType.SponsoredProduct
    case EChannelType.DisplayAd:
      return EParamChannelType.Da
    case EChannelTypeAllAndDooh.Dooh:
      return EParamChannelType.Dooh
    default:
      break
  }
}
