import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import { ICampaign, IAdGroup } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedAdGroup } from '../utils'

const removeCampaignAdGroupCreative = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{ adGroupId: number; creativeId: number }>
) => {
  const selectedAdGroup =
    getSelectedAdGroup(state, action.payload.adGroupId) || ({} as IAdGroup)

  if (selectedAdGroup) {
    const index = selectedAdGroup.creativesList?.findIndex(
      (creative) => creative.id === action.payload.creativeId
    )
    if (index !== undefined && index > -1) {
      selectedAdGroup.creativesList?.splice(index, 1)
    }
  }
}

export default removeCampaignAdGroupCreative
