import { useMutation } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import { IError } from '@unfoldrtech/portal-mic'
import { generateCorrelationId } from '../../utils/helpers'
import { IUserProfile, IUserProfileUpdate } from '../../models/core'
import { coreAPIV3 } from '../../services/coreAPIV3'

export const usePatchUserProfile = () => {
  return useMutation<
    AxiosResponse<IUserProfile>,
    AxiosError<IError>,
    {
      payload: IUserProfileUpdate
    }
  >([`patchUserProfile`], (params) =>
    coreAPIV3.users().patchUserProfile(generateCorrelationId(), params.payload)
  )
}
