import React, { useState, useEffect, useContext } from 'react'
import { EOrgType } from '@unfoldrtech/portal-mic'
import { schemeTableau10 } from 'd3-scale-chromatic'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import dayjs from 'dayjs'
import { Container, GridContainer, StyledImage } from '../../../Global'
import { LineChart } from '../../../Widgets'
import { TChartMetricData } from '../../../../models/types'
import { selectDAPositionsChartReportingFilters } from '../../../../store/reportingFilters'
import Loading from '../../../Loading'
import { getLabelFormat } from '../../../../utils/helpers'
import { sanitizeDataTestId } from '../../../../utils/sanitizeDataTestId'
import useGetCurrentCurrency from '../../../../hooks/useGetCurrentCurrency'
import { MetricsSelectorV3 } from '../../components/MetricSelectorV3'
import { useGetRetailerAndAdvertiserIds } from '../../hooks/use-get-retailer-and-advertiser-ids'
import { getDefaultMetric } from '../../helpers/get-default-metric'
import { getTableAndChartDAPositionsFn } from '../helpers/get-table-and-chart-da-positions-fn'
import {
  AdGroupContext,
  AppContext,
  CampaignContext,
  EDatasetKey,
  IDAReportPositionsChartData,
} from '../../../../models'
import { MetricWidget } from '../../components/MetricWidget'
import { useGetChartXYProps } from '../../hooks/use-get-chart-x-y-props'
import { TDAPositionsMetrics } from '../models'
import { getFormatOptionCurrency } from '../../helpers/get-format-option-currency'
import { getFormatOptionNumber } from '../../helpers/get-format-option-number'
import { useGetDatasetKeyChartWhitelist } from '../../hooks/use-get-dataset-key-chart-whitelist'
import { TDatasetWhitelistKey, TGlobalMetrics } from '../../models'
import { getFormatOptionPercent } from '../../helpers'

const lineColors = schemeTableau10
const bigNumberWidgetWidth = '214px'

export const ReportingDAPositionsChartWrapper = () => {
  const intl = useIntl()

  const [appContext] = useContext(AppContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)
  const orgType = appContext.userOrg!.type

  const { symbol: currencySymbol, text: currencyText } = useGetCurrentCurrency()
  const { retailerId, advertiserId } = useGetRetailerAndAdvertiserIds()

  const defaultMetric = getDefaultMetric({ orgType })
  const hookFn = getTableAndChartDAPositionsFn({
    orgType,
  })
  const getChartDataFn = hookFn.chartFn!

  const campaignId = campaign?.id ?? -1
  const adGroupId = adGroup?.id

  const [selectedMetricNames, setSelectedMetricNames] = useState<
    Array<TDAPositionsMetrics>
  >([])
  const [pinnedMetricNames, setPinnedMetricNames] = useState<
    Array<TDAPositionsMetrics>
  >([])

  const [chartData, setChartData] = useState<Array<TChartMetricData>>([])
  const [chartParamsUpdated, setChartParamsUpdated] = useState<boolean>()

  const { startDate, endDate, timeWindow, aggregationType, tzZone } =
    useSelector(selectDAPositionsChartReportingFilters)
  const { xAxisProps, yAxisProps } = useGetChartXYProps(chartData)

  const {
    data: chartDataResponseObj,
    refetch,
    isFetching,
    isFetchedAfterMount,
    dataUpdatedAt,
  } = getChartDataFn({
    advertiserId,
    retailerId,
    campaignId,
    adGroupId,
    timeWindow,
    startDate,
    endDate,
    tzZone,
    enabled: false,
  })

  const whitelistData = useGetDatasetKeyChartWhitelist({
    key:
      (chartDataResponseObj?.data.chart?.datasetKey as TDatasetWhitelistKey) ||
      EDatasetKey.PositionDa,
  })

  const dataMetricWhitelisted = whitelistData.map((data) => {
    return data.metricName
  })

  const chartDataResponse: Array<IDAReportPositionsChartData> =
    chartDataResponseObj?.data?.chart?.data ?? []

  const chartDataMetrics = chartDataResponse.length
    ? (dataMetricWhitelisted as TDAPositionsMetrics[])
    : []

  const chartDataAggregates =
    chartDataResponseObj?.data?.chart?.summary.aggregates ?? {}

  const chartDataTypes =
    (chartDataResponseObj &&
      chartDataResponseObj?.data?.chart?.summary.types) ??
    {}

  const onToggleSelectedMetric = (metricName: TGlobalMetrics) => {
    let newSelectedMetrics: Array<TDAPositionsMetrics> = JSON.parse(
      JSON.stringify(selectedMetricNames)
    )

    if (selectedMetricNames.includes(metricName as TDAPositionsMetrics)) {
      newSelectedMetrics = newSelectedMetrics.filter(
        (selectedMetric) => selectedMetric !== metricName
      )
    } else {
      if (selectedMetricNames.length === 2) {
        newSelectedMetrics.shift()
      }
      newSelectedMetrics.push(metricName as TDAPositionsMetrics)
    }
    setSelectedMetricNames(newSelectedMetrics)
  }

  const onUnpinMetric = (metricName: TGlobalMetrics) => {
    const newPinnedMetrics = pinnedMetricNames.filter(
      (pinnedMetric) => pinnedMetric !== metricName
    )

    setPinnedMetricNames(newPinnedMetrics)

    if (selectedMetricNames.includes(metricName as TDAPositionsMetrics)) {
      onToggleSelectedMetric(metricName)
    }
  }

  const onPinMetric = (metricName: TGlobalMetrics) => {
    const metric = chartDataMetrics.find((dMetrics) => dMetrics === metricName)
    if (metric && !pinnedMetricNames.includes(metric)) {
      setPinnedMetricNames([...pinnedMetricNames, metric])
      onToggleSelectedMetric(metricName)
    }
  }

  const transformChartData = () => {
    if (selectedMetricNames.length) {
      const transformedChartData: Array<TChartMetricData> = []
      chartDataResponse.forEach((chartDataPoint) => {
        const chartDataObj: TChartMetricData = {
          date: dayjs(chartDataPoint.timestamp).valueOf(),
        }

        selectedMetricNames.forEach((metricName) => {
          const whitelisteMetric = whitelistData.find((wMetric) => {
            return wMetric.metricName === metricName
          })
          const metricValue =
            chartDataPoint[metricName as keyof IDAReportPositionsChartData]

          const sanitisedValue =
            metricValue &&
            !Number.isNaN(Number(metricValue)) &&
            Number(metricValue) > 0
              ? Number(metricValue)
              : 0

          // Round to max 2 decimals
          const formattedValue = Math.round(Number(sanitisedValue) * 100) / 100

          chartDataObj[whitelisteMetric?.translatedMetricName!] = formattedValue
        })
        transformedChartData.push(chartDataObj)
      })

      return transformedChartData
    }
    return []
  }

  const getFormattedMetricValue = (
    value: string | number,
    metric: TDAPositionsMetrics
  ) => {
    let formattedValue: string | number = value
    const whitelisteMetric = whitelistData.find((wMetric) => {
      return wMetric.metricName === metric
    })

    if (whitelisteMetric?.isCurrency) {
      formattedValue = intl.formatNumber(
        Number(value),
        getFormatOptionCurrency(currencyText)
      )
    } else if (whitelisteMetric?.isPercentage) {
      formattedValue = intl.formatNumber(
        Number(value),
        getFormatOptionPercent()
      )
    } else {
      formattedValue = intl.formatNumber(Number(value), getFormatOptionNumber())
    }

    return `${formattedValue}`
  }
  useEffect(() => {
    if (startDate && endDate) {
      setChartParamsUpdated(true)
    }
  }, [startDate, endDate, aggregationType])

  useEffect(() => {
    if (chartParamsUpdated) {
      refetch()
      setChartParamsUpdated(false)
    }
  }, [chartParamsUpdated])

  useEffect(() => {
    if (isFetchedAfterMount && !pinnedMetricNames.length) {
      onPinMetric(defaultMetric)
    }
  }, [isFetchedAfterMount])

  // This use effect takes care of maintaining the same pinned metrics as the previous page when switching
  useEffect(() => {
    if (
      isFetchedAfterMount &&
      pinnedMetricNames.length &&
      chartDataResponse?.length
    ) {
      // Some of the previous selected pinned metrics might not be available on this page
      // We cannot pin them if they are not valid for this page
      const validPreExistingPinnedMetrics = pinnedMetricNames.filter(
        (metric) => {
          const metricIndex = chartDataMetrics.findIndex(
            (met) => met === metric
          )
          if (metricIndex > -1) {
            return chartDataMetrics.length > metricIndex
          }
          return false
        }
      )

      // In the case that we had a previous pinned metric but that was a metric not valid on this page
      // we need to set the default metric.
      if (pinnedMetricNames.length === 0) {
        if (chartDataMetrics.length) {
          pinnedMetricNames.push(defaultMetric)
        }
      }

      setPinnedMetricNames(validPreExistingPinnedMetrics)

      const sanitizedSelectedMetrics = selectedMetricNames.filter(
        (metricIndex) => validPreExistingPinnedMetrics.indexOf(metricIndex) > -1
      )
      if (sanitizedSelectedMetrics.length === 0) {
        sanitizedSelectedMetrics.push(pinnedMetricNames[0])
      }
      setSelectedMetricNames(sanitizedSelectedMetrics)
    }
  }, [isFetchedAfterMount])

  useEffect(() => {
    if (dataUpdatedAt || selectedMetricNames.length > 0) {
      setChartData(transformChartData())
    }
  }, [dataUpdatedAt, selectedMetricNames])

  return (
    <>
      {Boolean(chartDataMetrics.length) &&
      Boolean(chartDataAggregates) &&
      Boolean(chartDataTypes) ? (
        <>
          <GridContainer
            gridGap="var(--margin-default)"
            gridTemplateColumns={`repeat(4, minmax(${bigNumberWidgetWidth}, 1fr))`}
            padding="var(--margin-default) 0"
            width="100%"
            minHeight="116px"
          >
            {pinnedMetricNames.map((metric) => {
              const whitelistedMetric = whitelistData.find((wMetric) => {
                return wMetric.metricName === metric
              })

              const { value, metricAggregationType } = {
                value: chartDataAggregates[metric],
                metricAggregationType: chartDataTypes[metric],
              }

              const formattedValue: string = getFormattedMetricValue(
                value as number,
                whitelistedMetric?.metricName! as TDAPositionsMetrics
              )

              const metricIndex = chartDataMetrics.findIndex(
                (met) => met === whitelistedMetric?.metricName
              )
              const borderBottomColor =
                lineColors[metricIndex % lineColors.length]

              return (
                <Container
                  data-testid={sanitizeDataTestId(
                    `metric-${whitelistedMetric?.metricName}`
                  )}
                  key={whitelistedMetric?.metricName}
                >
                  <MetricWidget
                    id={whitelistedMetric?.metricName!}
                    translatedMetricName={
                      whitelistedMetric?.translatedMetricName
                    }
                    metricName={whitelistedMetric?.metricName}
                    value={formattedValue}
                    description={metricAggregationType}
                    borderBottomColor={borderBottomColor}
                    onClick={onToggleSelectedMetric}
                    onRemove={onUnpinMetric}
                    isSelected={
                      !!whitelistedMetric?.metricName &&
                      selectedMetricNames.includes(
                        whitelistedMetric?.metricName as TDAPositionsMetrics
                      )
                    }
                  />
                </Container>
              )
            })}
            {pinnedMetricNames.length < 4 && (
              <Container>
                <MetricsSelectorV3
                  whitelistedMetricData={whitelistData}
                  pinnedMetrics={pinnedMetricNames}
                  onChange={onPinMetric}
                />
              </Container>
            )}
          </GridContainer>

          <Container width="100%" height="300px">
            <LineChart
              chartData={chartData}
              metrics={selectedMetricNames.map((selectedMetric) => {
                const whitelisteMetric = whitelistData.find((wMetric) => {
                  return wMetric.metricName === selectedMetric
                })
                return whitelisteMetric!.translatedMetricName
              })}
              xAxisProps={xAxisProps}
              yAxiiProps={yAxisProps}
              tooltipProps={{
                labelFormatter: (label) =>
                  dayjs(label).format(
                    getLabelFormat(chartData[0].date, chartData[1].date)
                  ),
              }}
              metricColors={selectedMetricNames.map((metric) => {
                const metricIndex = chartDataMetrics.findIndex(
                  (met) => met === metric
                )
                return lineColors[metricIndex]
              })}
              metricUnits={selectedMetricNames.map((metric) => {
                const whitelisteMetric = whitelistData.find((wMetric) => {
                  return wMetric.metricName === metric
                })
                if (whitelisteMetric?.isCurrency) {
                  return `(${currencySymbol})`
                }
                return ''
              })}
            />
          </Container>
        </>
      ) : (
        <StyledImage
          fluid
          data-testid="chart-placeholder"
          src={
            orgType === EOrgType.Retailer
              ? `${process.env.REACT_APP_CDN_URL}/images/f33ff4f9-cd01-49f5-ac76-77e8abed0473.png`
              : `${process.env.REACT_APP_CDN_URL}/images/13529de4-79e5-491d-83a7-1cf5b08b8d7b.png`
          }
          alt="home_placeholder"
          cursor="default"
        />
      )}
      <Loading show={isFetching} />
    </>
  )
}
