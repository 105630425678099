import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconWeb = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M20.25 11.25v3a1.5 1.5 0 0 1-1.5 1.5H5.25a1.5 1.5 0 0 1-1.5-1.5v-3M21.749 23.25H2.253a1.5 1.5 0 0 1-1.342-2.17l1.836-3.672A3 3 0 0 1 5.43 15.75h13.14c1.138 0 2.176.642 2.685 1.658l1.836 3.671a1.5 1.5 0 0 1-1.342 2.171zm-11.249-3h3M21.148.75H2.852a.753.753 0 0 0-.733.587L.75 6a2.25 2.25 0 1 0 4.5 0 2.25 2.25 0 1 0 4.5 0 2.25 2.25 0 1 0 4.5 0 2.25 2.25 0 1 0 4.5 0 2.25 2.25 0 1 0 4.5 0l-1.37-4.663a.75.75 0 0 0-.733-.587z" />
    </g>
  </svg>
)

export default SvgIconWeb
