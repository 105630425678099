import styled from 'styled-components'

import { FontPreset, FontStyle, TFontStyleMixin } from '../styles/mixins'
import { Text } from './Text'

export const ErrorText = styled(Text)<TFontStyleMixin>`
  ${FontPreset('error')};
  ${(props) =>
    FontStyle({
      ...props,
    })}
`
