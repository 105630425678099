import { PayloadAction } from '@reduxjs/toolkit'
import { ICampaign, IAdGroupCategory } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedAdGroup } from '../utils'

const insertAdGroupCategoryTargetList = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    categoryTargetList: Array<IAdGroupCategory>
    adGroupId: number
  }>
) => {
  const selectedAdGroup = getSelectedAdGroup(state, action.payload.adGroupId)

  if (selectedAdGroup) {
    if (selectedAdGroup.categoriesList === undefined) {
      selectedAdGroup.categoriesList = []
    }
    action.payload.categoryTargetList.forEach((ct) => {
      if (selectedAdGroup.categoriesList) {
        const catTargetIndex = selectedAdGroup.categoriesList?.findIndex(
          (cat) => ct.id === cat.id
        )
        if (catTargetIndex === -1) {
          selectedAdGroup.categoriesList.push(ct)
        } else {
          selectedAdGroup.categoriesList[catTargetIndex] = { ...ct }
        }
      }
    })
  }
}

export default insertAdGroupCategoryTargetList
