import React, { ReactElement, ReactNode } from 'react'
import { CSSProperties } from 'styled-components'
import { StyledFormInput } from './StyledFormInput'
import { Container } from '../containers'
import { IInputProps } from '../../../models/interfaces'
import {
  TAlignmentMixin,
  TBorderMixin,
  TFontStyleMixin,
  TSizeMixin,
} from '../styles/mixins'

export interface IStyledInputProps
  extends Omit<IInputProps, 'prefix' | 'suffix'> {
  fontStyle?: TFontStyleMixin
  inputSize?: TSizeMixin
  inputBorder?: TBorderMixin
  inputAlignment?: TAlignmentMixin
  inputPadding?: Pick<CSSProperties, 'padding' | 'paddingLeft' | 'paddingRight'>
  containerSize?: TSizeMixin
  containerBorder?: TBorderMixin
  containerAlignment?: TAlignmentMixin
  prefix?: ReactNode | ReactNode[]
  suffix?: ReactNode | ReactNode[]
}

export const StyledInput = React.forwardRef(
  (
    {
      onChange,
      field,
      error,
      prefix,
      suffix,
      fontStyle,
      inputSize,
      inputBorder,
      inputAlignment,
      inputPadding,
      containerSize,
      containerBorder,
      containerAlignment,
      ...props
    }: IStyledInputProps,
    ref
  ): ReactElement => {
    const inputRef = ref || field?.ref || null

    const handleOnWheel = () => {
      if (props.type === 'number') {
        return (document.activeElement as HTMLInputElement).blur()
      }
    }

    return (
      <Container
        display="flex"
        border={{
          width: '1px',
          style: 'solid',
          color: 'var(--border-color-default)',
          radius: '2px',
        }}
        height="var(--button-size-lg)"
        className={error ? 'input-error' : ''}
        backgroundColor={props.disabled ? 'var(--border-color-default)' : ''}
        opacity={props.disabled ? '0.5' : ''}
        {...containerBorder}
        {...containerSize}
        {...containerAlignment}
      >
        {prefix}
        <StyledFormInput
          {...field}
          {...fontStyle}
          {...inputSize}
          {...inputBorder}
          {...inputAlignment}
          {...inputPadding}
          {...props}
          ref={inputRef}
          onChange={(v: React.ChangeEvent<HTMLInputElement>) => {
            field?.onChange(v)
            if (onChange) {
              onChange(v)
            }
          }}
          onWheel={handleOnWheel}
        />
        {suffix}
      </Container>
    )
  }
)
