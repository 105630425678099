import { PayloadAction } from '@reduxjs/toolkit'
import { ICampaign } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedAdGroup } from '../utils'

const removeAdGroupCategoryTarget = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{ categoryTargetId: number; adGroupId: number }>
) => {
  const selectedAdGroup = getSelectedAdGroup(state, action.payload.adGroupId)

  const categoryTargetIndex = selectedAdGroup?.categoriesList?.findIndex(
    (ct) => ct.id === action.payload.categoryTargetId
  )

  if (categoryTargetIndex !== undefined && categoryTargetIndex > -1) {
    selectedAdGroup?.categoriesList?.splice(categoryTargetIndex, 1)
  }
}

export default removeAdGroupCategoryTarget
