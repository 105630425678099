import styled from 'styled-components'
import { TContainerProps } from '../styles/mixins'

export const StyledCircle = styled.div<TContainerProps>`
  border-radius: 48px;
  background-color: ${(props) =>
    props.backgroundColor ||
    props.theme?.colors?.font?.primary ||
    'var(--font-color-default)'};
  width: ${(props) => props.width || '6px'};
  height: ${(props) => props.height || '6px'};
`
