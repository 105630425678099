import { createSlice } from '@reduxjs/toolkit'
import { EParamSortOrder, EParamTimeWindow } from '@unfoldrtech/portal-mic'
import { TReportingFilters } from '../../models/types'
import { getEndDate, getStartDate } from '../../utils/helpers'
import { getAggregationType } from '../../utils/helpers/getAggregationType'
import reducers from './reducers'

const INITIAL_REPORTING_FILTERS_STATE: TReportingFilters = <TReportingFilters>{
  startDate: getStartDate(),
  endDate: getEndDate(),
  aggregationType: getAggregationType(getStartDate(), getEndDate()),
  timeWindow: EParamTimeWindow.Custom,
  sortOrder: EParamSortOrder.Asc,
}

export const reportingFiltersSlice = createSlice({
  name: 'reportingFilters',
  initialState: INITIAL_REPORTING_FILTERS_STATE,
  reducers: {
    resetReportingFilters: () => INITIAL_REPORTING_FILTERS_STATE,
    ...reducers,
  },
})
export const {
  resetReportingFilters,
  setAggregationType,
  setAdGroupIds,
  setCampaignIds,
  setChannelType,
  setInventoryType,
  setTimeProperties,
  setTimeWindow,
  setStartDate,
  setEndDate,
  setSortBy,
  setSortByV3,
  setSortOrder,
  setDealType,
} = reportingFiltersSlice.actions

export const reportingFiltersReducer = reportingFiltersSlice.reducer
