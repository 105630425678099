import React, { useContext, useEffect, useState } from 'react'
import {
  EAdvertiserReportPageType,
  EEntityType,
  EOrgType,
  EParamAdvertiserTargetType,
  EParamTimeWindow,
  ERetailerReportPageType,
  EUserRole,
  IPermittedInventoryResponse,
  IReportObjectCreative,
  IReportTable,
  ITotalItems,
  EParamChannelType,
  EParamInventoryType,
} from '@unfoldrtech/portal-mic'

import dayjs, { Dayjs } from 'dayjs'
import { useNavigate } from 'react-router-dom'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'

import ReportPageTemplate from '../ReportPageTemplate'
import Loading from '../Loading'

import {
  AdGroupContext,
  AdvertiserContext,
  AppContext,
  CampaignContext,
  RetailerContext,
} from '../../models/contexts'
import {
  TReportExportType,
  TReportingFilters,
  TTranslationKey,
} from '../../models/types'
import {
  IReportChartProps,
  IReportFiltersProps,
  IReportSwitchProps,
  IReportTableProps,
  V2IReportTable,
} from '../../models/interfaces'

import useGetAdvertiserChannelTargets from '../../hooks/useGetAdvertiserChannelTargets'
import useGetAdvertiserTargets from '../../hooks/useGetAdvertiserTargets'
import useUpdateEntityStatus from '../../hooks/useUpdateEntityStatus'

import {
  selectReportingFilters,
  setAdGroupIds,
  setCampaignIds,
  setChannelType,
  setDealType,
  setInventoryType,
  setSortBy,
  setSortOrder,
  setTimeProperties,
  setTimeWindow,
} from '../../store/reportingFilters'

import {
  convertChannelTypeToParam,
  convertPermittedInventory,
  getEndOfDay,
  getStartOfDay,
  getAggregationType,
  exportTableDataToFile,
} from '../../utils/helpers'

import { NOT_AVAILABLE, exportFileDateFormat } from '../../utils/constants'
import { getTableAndChartFn } from './getTableAndChartFn'

export type TCSVData = {
  headers: Array<string /* | { label: string; key: string } */>
  data: Array<Record<string, string | number | IReportObjectCreative>>
}

function ReportPageWrapper(
  props: {
    orgType?: EOrgType
    title?: TTranslationKey
    subTitle?: TTranslationKey
  } & Partial<
    IReportTableProps &
      IReportChartProps &
      IReportSwitchProps &
      IReportFiltersProps &
      TReportingFilters
  >
) {
  const hookFn = getTableAndChartFn({
    advertiserReportPageType: props.advertiserReportPageType,
    retailerReportPageType: props.retailerReportPageType,
    orgType: props.orgType,
  })

  const {
    orgType = EOrgType.Advertiser,
    title = 'retailer.advertisers.nav.overview',
    subTitle,
    advertiserReportPageType = EAdvertiserReportPageType.Campaigns,
    retailerReportPageType = ERetailerReportPageType.Brands,
    navigateURLPrefix = '',
    navigateDisabled = false,
    role = EUserRole.AdvertiserCampaigns,
    showSwitch = true,
    switchDisabled = false,
    switchDisabledTooltip = 'no.permissions',
    onUpdateEntityStatus = useUpdateEntityStatus,
    defaultMetric,
    entityType,
    virtual,
    ...filters
  } = props
  const getChartDataFn = hookFn.chartFn!
  const getTableDataFn = hookFn.tableFn!

  const intl = useIntl()
  const navigate = useNavigate()

  const [appContext] = useContext(AppContext)
  const retailerContext = useContext(RetailerContext)
  const [advertiserContext] = useContext(AdvertiserContext)
  const campaign = useContext(CampaignContext)
  const adGroup = useContext(AdGroupContext)

  const [reportingFlitersIsSetup, setReportingFlitersIsSetup] = useState(false)
  const [tableDataRes, setTableDataRes] = useState<
    IReportTable | V2IReportTable
  >({})

  const dispatch = useDispatch()

  const { channelType, startDate, endDate, timeWindow } = useSelector(
    selectReportingFilters
  )

  let retailerId = -1
  let advertiserId = -1
  let defaultSelectedMetric = defaultMetric

  if (defaultSelectedMetric === undefined) {
    defaultSelectedMetric =
      orgType === EOrgType.Advertiser ? 'revenue' : 'costs'
  }

  switch (orgType) {
    case EOrgType.Advertiser:
      retailerId = retailerContext.id || -1
      advertiserId = appContext.userOrg?.id ?? -1
      break
    case EOrgType.Retailer:
      retailerId = appContext.userOrg?.id ?? -1
      advertiserId = advertiserContext?.id
      break
    default:
      break
  }

  const {
    data: permittedChannelsResponse,
    isFetching: isFetchingChannelPermit,
  } = useGetAdvertiserChannelTargets({
    advertiserId,
    retailerId,
    enabled:
      orgType === EOrgType.Advertiser &&
      (entityType === EEntityType.Campaign ||
        filters.inventoryType === EParamInventoryType.Screen),
  })

  const {
    data: permittedInventoryResponse,
    isFetching: isFetchingInventoryPermit,
  } = useGetAdvertiserTargets({
    advertiserId,
    retailerId,
    targetType: EParamAdvertiserTargetType.Inventory,
    enabled:
      orgType === EOrgType.Advertiser &&
      advertiserReportPageType === EAdvertiserReportPageType.Adgroups,
  })

  const permittedChannels =
    permittedChannelsResponse?.data?.map((channel) =>
      convertChannelTypeToParam(channel)
    ) || []

  if (
    orgType === EOrgType.Advertiser &&
    entityType === EEntityType.Campaign &&
    channelType
  ) {
    if (!permittedChannels.includes(channelType)) {
      navigate('../../../analytics/campaigns-all')
    }
  }

  if (
    orgType === EOrgType.Advertiser &&
    advertiserReportPageType === EAdvertiserReportPageType.Adgroups
  ) {
    const permittedInventory =
      Array.from(
        new Set(
          (
            permittedInventoryResponse?.data as ITotalItems &
              IPermittedInventoryResponse
          )?.inventoryList?.map((inventory) =>
            convertPermittedInventory(inventory.inventoryType!)
          )
        )
      ) || []

    const isDOOHScreenDisabled =
      filters.inventoryType === EParamInventoryType.Screen &&
      !permittedChannels.includes(EParamChannelType.Dooh)

    if (
      isDOOHScreenDisabled ||
      (filters.inventoryType !== EParamInventoryType.Screen &&
        !permittedInventory.includes(
          entityType as
            | EEntityType.AdGroupCategory
            | EEntityType.AdGroupKeyword
            | EEntityType.AdGroupPosition
            | undefined
        ) &&
        entityType !== EEntityType.AdGroup)
    ) {
      navigate('../../../analytics/adgroups-all')
    }
  }

  let tableParamChannelType = channelType
  if (
    ((orgType === EOrgType.Retailer &&
      retailerReportPageType === ERetailerReportPageType.Creatives) ||
      (orgType === EOrgType.Advertiser &&
        advertiserReportPageType === EAdvertiserReportPageType.Creatives)) &&
    !tableParamChannelType
  ) {
    tableParamChannelType =
      filters.channelType ||
      (campaign.channelType && convertChannelTypeToParam(campaign.channelType))
  }

  const { data: tableDataResponse, isFetching: isFetchingTableData } =
    getTableDataFn({
      retailerId,
      advertiserId,
      advertiserReportPageType,
      retailerReportPageType,
      timeWindow,
      startDate,
      endDate,
      channelType: tableParamChannelType,
      enabled: false,
      ...filters,
    })

  const onDateChangeFn = (startDayjs: Dayjs, endDayjs: Dayjs) => {
    const mappedStartDate = getStartOfDay(startDayjs).toJSON()
    const mappedEndDate = getEndOfDay(endDayjs).toJSON()
    dispatch(setTimeWindow({ timeWindow: EParamTimeWindow.Custom }))
    dispatch(
      setTimeProperties({
        startDate: mappedStartDate,
        endDate: mappedEndDate,
        aggregationType: getAggregationType(mappedStartDate, mappedEndDate),
      })
    )
  }

  const getTranslatedTableHeaders = ({
    tableData,
    organisationType,
  }: {
    tableData: IReportTable | V2IReportTable
    organisationType: EOrgType
  }) => {
    const translatedHeaders: Array<{ label: string; key: string }> =
      tableData.header?.map((header) => {
        const translatedHeader = intl.formatMessage({
          id: `report.table.header.${organisationType?.toLowerCase()}.${header}`,
        })
        return { label: translatedHeader, key: header }
      }) || []

    return translatedHeaders
  }

  const onExportData = (
    { startDate: start, endDate: end }: TReportingFilters,
    type: TReportExportType
  ) => {
    const orgName = appContext.userOrg?.name ?? orgType ?? NOT_AVAILABLE
    const pageTitle = intl
      .formatMessage({ id: title })
      .replace(/[-]+/g, '')
      .replace(/[ ]+/g, '_')
    const sDate = dayjs(start).format(exportFileDateFormat)
    const eDate = dayjs(end).format(exportFileDateFormat)

    const fileName = `${orgName}_${pageTitle}_${sDate}_${eDate}`
    const translatedHeaders = getTranslatedTableHeaders({
      tableData: tableDataRes,
      organisationType: orgType,
    })

    exportTableDataToFile({
      tableData: tableDataRes,
      headers: translatedHeaders,
      fileName,
      fileType: type,
    })
  }

  useEffect(() => {
    const channel =
      convertChannelTypeToParam(campaign.channelType) ?? filters.channelType
    dispatch(setChannelType({ channelType: channel }))
    dispatch(setDealType({ dealType: campaign.dealType }))
    dispatch(setInventoryType({ inventoryType: filters.inventoryType }))
    dispatch(setSortBy({ sortBy: filters.sortBy }))
    if (filters.sortOrder) {
      dispatch(setSortOrder({ sortOrder: filters.sortOrder }))
    }
    dispatch(
      setCampaignIds({
        campaignIds: campaign?.id ? [campaign.id] : filters.campaignIds,
      })
    )
    dispatch(
      setAdGroupIds({
        adGroupIds: adGroup?.id ? [adGroup.id] : filters.adGroupIds,
      })
    )
    setReportingFlitersIsSetup(true)
  }, [
    filters.channelType,
    campaign.channelType,
    filters.inventoryType,
    filters.sortBy,
    filters.sortOrder,
    filters.campaignIds,
    filters.adGroupIds,
    campaign.id,
    adGroup.id,
  ])

  useEffect(() => {
    if (tableDataResponse?.data.table) {
      setTableDataRes(tableDataResponse.data.table)
    }
  }, [tableDataResponse])

  return (
    <>
      {reportingFlitersIsSetup && (
        <>
          <ReportPageTemplate
            title={title}
            subTitle={subTitle}
            chartProps={{
              orgType,
              retailerId,
              advertiserId,
              defaultMetric: defaultSelectedMetric,
              advertiserReportPageType,
              retailerReportPageType,
              getChartDataFn,
            }}
            tableProps={{
              orgType,
              retailerId,
              advertiserId,
              currentCampaignId: campaign?.id,
              currentAdGroupId: adGroup?.id,
              getTableDataFn,
              advertiserReportPageType,
              retailerReportPageType,
              navigateURLPrefix,
              navigateDisabled,
              role,
              userRole: appContext.userClaims?.userRole ?? EUserRole.None,
              showSwitch,
              switchDisabled,
              switchDisabledTooltip,
              entityType,
              onUpdateEntityStatus,
              virtual,
            }}
            filterProps={{
              onDateChangeFn,
              onExportData,
              isDateRangeFilterDisabled: !tableDataRes.data?.length,
            }}
          />
        </>
      )}
      <Loading
        show={
          isFetchingTableData ||
          isFetchingChannelPermit ||
          isFetchingInventoryPermit
        }
      />
    </>
  )
}

export default ReportPageWrapper
