import styled from 'styled-components'

import { FontPreset, FontStyle, TFontStyleMixin } from '../styles/mixins'
import { Text } from './Text'

export const TotalText = styled(Text)<TFontStyleMixin>`
  ${FontPreset('paragraph regular')}
  ${(props: TFontStyleMixin) =>
    FontStyle({
      opacity: '0.5',
      ...props,
    })}
`
