import React from 'react'
import I18NText from '../../I18NText'
import { EDAPacing } from '../../../models/core'

export function CurrentDAPacingText({ pacing }: { pacing?: EDAPacing }) {
  if (pacing === EDAPacing.Even) {
    return <I18NText id="advertiser.campaigns.new.settings.pacing.even" />
  }
  if (pacing === EDAPacing.Asap) {
    return <I18NText id="advertiser.campaigns.new.settings.pacing.asap" />
  }
  return <I18NText id="not.applicable" />
}
