import { ICampaign, ICampaignDetails } from '@unfoldrtech/portal-mic'

export const selectCampaignSettings = ({
  campaign: campaignState,
}: {
  campaign: ICampaign
}): Pick<
  ICampaignDetails,
  | 'startDate'
  | 'endDate'
  | 'budget'
  | 'auctionStrategy'
  | 'pricingStrategy'
  | 'frequency'
  | 'pacing'
  | 'channelType'
> => {
  return {
    startDate: campaignState.startDate,
    endDate: campaignState.endDate,
    budget: campaignState.budget,
    auctionStrategy: campaignState.auctionStrategy,
    pricingStrategy: campaignState.pricingStrategy,
    frequency: campaignState.frequency,
    pacing: campaignState.pacing,
    channelType: campaignState.channelType,
  }
}
