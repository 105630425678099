import { useMemo } from 'react'
import dayjs from 'dayjs'
import { XAxisProps, YAxisProps } from 'recharts'
import { TChartMetricData } from '../../../models'
import { getLabelFormat } from '../../../utils/helpers'
import { getTicks } from '../helpers/get-ticks'

export const useGetChartXYProps = (chartData: Array<TChartMetricData>) => {
  const yAxisProps: Record<string | number, YAxisProps> = useMemo(() => {
    return {}
  }, [chartData])

  const xAxisProps: XAxisProps = useMemo(() => {
    const axisProps: XAxisProps = {
      dataKey: 'date',
      type: 'number',
      name: 'Time',
    }
    if (chartData.length) {
      const start = chartData[0].date
      const end = chartData.slice(-1)[0].date
      const numTicks = 2
      axisProps.domain = [start, end]
      axisProps.tickFormatter = (time) =>
        dayjs(time).format(getLabelFormat(start, end))
      axisProps.ticks = getTicks(start, end, numTicks)
    }

    return axisProps
  }, [chartData])

  return { xAxisProps, yAxisProps }
}
