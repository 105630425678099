import React from 'react'
import { CSSProperties } from 'styled-components'
import { IToastProps } from '../../models/interfaces'
import { TDataTest } from '../../models/types'
import {
  EllipsisContainer,
  StyledToast,
  TBorderPropertiesMixin,
} from '../Global'
import { StyledToastContainer } from '../Global/elements/bootstrap/StyledToastContainer'
import I18NText from '../I18NText'

export function ToastCard({
  delay = 3000,
  autohide = true,
  onClose = () => {},
  position = 'top-center',
  dataTestId,
  display,
  border,
  height,
  padding,
  width,
  maxWidth,
  header,
  show,
  children,
}: IToastProps &
  TDataTest &
  Pick<
    CSSProperties,
    'display' | 'height' | 'padding' | 'width' | 'maxWidth'
  > & { border?: TBorderPropertiesMixin }) {
  return (
    <>
      <StyledToastContainer
        position={position}
        display={display}
        data-testid={dataTestId}
      >
        <StyledToast
          onClose={async () => {
            await onClose(false)
          }}
          border={border}
          padding={padding}
          height={height}
          width={width}
          maxWidth={maxWidth}
          show={show}
          delay={delay}
          autohide={autohide}
          header={header}
        >
          {Boolean(header) && Boolean(header?.length) && (
            <StyledToast.Header>
              <EllipsisContainer
                display="flex"
                flex="1"
                width="100%"
                textTransform="uppercase"
              >
                <I18NText id={header ?? ''} />
              </EllipsisContainer>
            </StyledToast.Header>
          )}
          <StyledToast.Body>
            <EllipsisContainer width="100%" whiteSpace="break-spaces">
              {children}
            </EllipsisContainer>
          </StyledToast.Body>
        </StyledToast>
      </StyledToastContainer>
      <></>
    </>
  )
}
