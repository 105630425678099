import styled from 'styled-components'

import {
  Background,
  FontPreset,
  FontStyle,
  TBackgroundMixin,
  TFontStyleMixin,
} from '../styles/mixins'
import { IThemeProps } from '../../../models/interfaces'
import { Text } from './Text'

export const BackgroundTotalText = styled(Text)<
  TFontStyleMixin & TBackgroundMixin
>`
  padding: 0 var(--gutter-default);
  border-radius: var(--margin-default);
  ${FontPreset('paragraph')};
  ${(props: TFontStyleMixin & IThemeProps) =>
    FontStyle({
      color:
        props.theme?.colors?.font?.secondary || 'var(--font-color-secondary)',
      ...props,
    })};
  ${(props: IThemeProps & TBackgroundMixin) =>
    Background({
      backgroundColor: props.theme?.colors?.background?.secondary,
      ...props,
    })};
`
