import Decimal from 'decimal.js'

export const getAudienceFloorPriceModifier = (
  floorPrice: number,
  audienceFloorPrice?: number
): number => {
  return audienceFloorPrice
    ? new Decimal(audienceFloorPrice)
        .minus(new Decimal(floorPrice))
        .toDecimalPlaces(2)
        .toNumber()
    : 0
}
