/* eslint-disable react/style-prop-object */
import React from 'react'
import { FormattedNumber } from 'react-intl'
import { NOT_AVAILABLE } from '../../../utils/constants/notAvailable'
import { Text } from './Text'

export function NumberText({
  value = 0,
  maximumFractionDigits = 2,
}: {
  value?: number
  maximumFractionDigits?: number
}) {
  // TODO: Set default format based on locale
  return (
    <>
      {value !== -1 ? (
        <FormattedNumber
          value={Number(value)}
          maximumFractionDigits={maximumFractionDigits}
        />
      ) : (
        <Text>{NOT_AVAILABLE.toLowerCase()}</Text>
      )}
    </>
  )
}
