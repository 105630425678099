import { IProduct } from '@unfoldrtech/portal-mic'
import {
  IProductFilterQuery,
  IProductFilterQueryValueRange,
} from '../../models'

export const getFilteredProducts = ({
  products = [],
  productsFiltersQuery,
}: {
  products: IProduct[]
  productsFiltersQuery?: IProductFilterQuery[]
}) => {
  if (!productsFiltersQuery || productsFiltersQuery.length === 0) {
    return products
  }

  const filterByBrand = (value: string[], product: IProduct) => {
    const brands = new Set(value.map((brand) => brand.toLowerCase()))
    return brands.has(product.brand?.toLowerCase() ?? '')
  }

  const filterByCategories = (value: string[], product: IProduct) => {
    const { categoryParentTitle, categoryTitle } = product
    const productCategory = categoryParentTitle
      ? `${categoryParentTitle} / ${categoryTitle}`.toLowerCase()
      : categoryTitle?.toLowerCase()

    return value.some((filterParam) => {
      const transformedFilterParam = filterParam.toLowerCase()
      const [filterMain, filterSub] = transformedFilterParam.split(' / ')
      if (filterSub) {
        return productCategory === transformedFilterParam
      }

      return categoryParentTitle
        ? categoryParentTitle.toLowerCase() === filterMain
        : categoryTitle?.toLowerCase() === filterMain
    })
  }

  const filterByPrice = (
    value: IProductFilterQueryValueRange,
    product: IProduct
  ) => {
    const { from, to } = value
    return product.price >= Number(from) && product.price <= Number(to)
  }

  const filterByStock = (value: boolean, product: IProduct) => {
    return product.inStock === value
  }

  return products.filter((product) => {
    return productsFiltersQuery.every((query) => {
      const { key, value } = query

      switch (key) {
        case 'brands':
          return filterByBrand(value as string[], product)

        case 'categories':
          return filterByCategories(value as string[], product)

        case 'price':
          return filterByPrice(value as IProductFilterQueryValueRange, product)

        case 'stock':
          return filterByStock(value as boolean, product)

        default:
          return true
      }
    })
  })
}
