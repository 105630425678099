import React, { useState, useEffect, ReactNode } from 'react'
import { useIntl } from 'react-intl'
import {
  EAdvertiserReportPageType,
  EChannelType,
  EEntityType,
  ECreativeChannelType,
  ECreativeStatus,
  EInventoryType,
  EMatchType,
  EOrgType,
  EPageType,
  EParamSortOrder,
  EReportColumnHeader,
  EReportColumnType,
  ERetailerReportPageType,
  EStatus,
  IEntity,
  IReportDACreative,
  IReportDOOHCreative,
  IReportObjectCreative,
  IReportTable,
  EDealType,
  ECampaignStatus,
} from '@unfoldrtech/portal-mic'
import { useNavigate, useLocation } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Column } from 'react-table'
import dayjs from 'dayjs'
import { IReportFiltersProps, IReportTableProps } from '../../models/interfaces'
import {
  TReportingFilters,
  TReportTableData,
  TTranslationKey,
  TVirtualTableData,
} from '../../models/types'
import {
  CurrencyText,
  CurrentChannelTypeText,
  CurrentInventoryPageText,
  DateText,
  PercentageText,
  CurrentStatusBadge,
  Text,
  PageLinkButton,
  TooltipWrapper,
  CurrentMatchTypeText,
  StyledImage,
  Container,
  StyledAntDropdown,
  IconArrowDownSmall,
  SVGContainer,
  HoverContainer,
  StyledAntMenu,
  StyledCircle,
  EllipsisContainer,
  NumberText,
  CreativeStatusBadge,
  StyledVirtualTable,
  IconArrowUpSmall,
  IconCirclePlay,
} from '../Global'
import Loading from '../Loading'
import { ReportTable } from '../Widgets'
import { isUserAuthorised } from '../../utils/userAuthorisation'
import { isScreenPlacementWithName } from '../../utils/helpers/isScreenPlacementWithName'
import I18NText from '../I18NText'
import {
  selectReportingFilters,
  setSortBy,
  setSortOrder,
} from '../../store/reportingFilters'
import { ErrorToast } from '../ToastCard/ErrorToast'
import AdPreview from '../AdPreview'
import { getPageTypeText } from '../../utils/helpers'
import { selectRefreshTable, setRefreshTable } from '../../store/refreshTable'
import {
  NOT_AVAILABLE,
  PORTAL_FF_DA,
  PORTAL_FF_DOOH_REPORTING_VIEW,
  PORTAL_FF_SP,
  SHARE_OF_VOICE_PROD_DEPLOYMENT_DATE,
} from '../../utils/constants'
import { EDeletedStatus, EDOOHInventoryType } from '../../models'
import { VideoPlayerModal } from '../../pages/Advertiser/components/VideoPlayerModal'
import { useFeatureFlags } from '../../hooks/useFeatureFlags'

export interface IReportDOOHObjectCreative {
  /**
   * Title of the creative
   * @type {string}
   * @memberof IReportDOOHObjectCreative
   */
  title?: string
  /**
   * Url of the creative
   * @type {string}
   * @memberof IReportDOOHObjectCreative
   */
  creativeUrl?: string
}

function TableWrapper(tableProps: IReportFiltersProps & IReportTableProps) {
  const {
    orgType,
    advertiserId,
    retailerId,
    currentCampaignId,
    currentAdGroupId,
    getTableDataFn,
    advertiserReportPageType,
    retailerReportPageType,
    navigateURLPrefix,
    navigateDisabled,
    role,
    userRole,
    showSwitch,
    switchDisabled,
    switchDisabledTooltip,
    entityType,
    onUpdateEntityStatus,
    isDateRangeFilterDisabled,
    virtual,
  } = tableProps

  const intl = useIntl()

  const navigate = useNavigate()
  const location = useLocation()
  const dispatch = useDispatch()
  const featureFlags = useFeatureFlags()
  const {
    channelType,
    inventoryType,
    startDate,
    endDate,
    sortBy,
    sortOrder,
    timeWindow,
    campaignIds,
    adGroupIds,
    dealType,
  } = useSelector(selectReportingFilters)
  const refreshTable = useSelector(selectRefreshTable)

  const [tableData, setTableData] = useState<TReportTableData>()
  const [showToast, setShowToast] = useState(false)
  const [videoPlayerUrlToast, setVideoPlayerUrlToast] = useState('')
  const [virtualTableColumns, setVirtualTableColumns] = useState<
    Array<Column<TVirtualTableData<object>>>
  >([])
  const [virtualTableData, setVirtualTableData] = useState<
    Array<TVirtualTableData<object>>
  >([])

  // Ensuring that other params updates refresh the table
  const [tableParamsUpdated, setTableParamsUpdated] = useState<boolean>()

  const {
    data: tableDataResponseObj,
    refetch,
    isFetching,
  } = getTableDataFn({
    advertiserId,
    retailerId,
    advertiserReportPageType,
    retailerReportPageType,
    channelType,
    inventoryType,
    startDate,
    endDate,
    sortBy,
    sortOrder,
    timeWindow,
    campaignIds,
    adGroupIds,
    enabled: false,
  })

  const {
    mutate: updateEntityStatus,
    error: errorMutate,
    isError: isMutateEntityError,
    isLoading: isMutateEntityStatusLoading,
    isSuccess: isMutateEntityStatusSuccess,
    reset: resetEntityStatusMutate,
  } = onUpdateEntityStatus({
    advertiserId,
    retailerId,
  })

  const onSortByChange = (sortColumn: TReportingFilters['sortBy']) => {
    dispatch(setSortBy({ sortBy: sortColumn }))
  }

  const onSortOrderChange = (
    type: TReportingFilters['sortOrder'] = EParamSortOrder.Desc
  ) => {
    dispatch(setSortOrder({ sortOrder: type }))
  }

  const onEntityStatusUpdateHandler = ({
    id,
    status,
    matchType,
  }: {
    id: number
    status: EStatus
    matchType?: EMatchType
  }) => {
    if (entityType) {
      resetEntityStatusMutate()

      updateEntityStatus({
        entityType,
        id,
        entity: {
          campaignId: currentCampaignId,
          adGroupId: currentAdGroupId,
          status,
          matchType,
        } as IEntity,
      })
    }
  }

  const getPlaceholderImage = () => {
    const isPortalSPFlagEnabled = featureFlags[PORTAL_FF_SP]
    const isPortalDAFlagEnabled = featureFlags[PORTAL_FF_DA]
    const isPortalDOOHReportingViewFlagEnabled =
      featureFlags[PORTAL_FF_DOOH_REPORTING_VIEW]
    const isPortalDOOHOnlyFlagEnabled =
      !isPortalSPFlagEnabled &&
      !isPortalDAFlagEnabled &&
      isPortalDOOHReportingViewFlagEnabled
    if (
      advertiserReportPageType === EAdvertiserReportPageType.KeywordsInsights &&
      orgType === EOrgType.Advertiser
    ) {
      return `${process.env.REACT_APP_CDN_URL}/images/2216d421-bef6-42a7-8169-e361a01413ee.png`
    }
    if (
      retailerReportPageType === ERetailerReportPageType.KeywordsInsights &&
      orgType === EOrgType.Retailer
    ) {
      return `${process.env.REACT_APP_CDN_URL}/images/d4f55087-96a2-44f5-96b7-b9b00401a9b6.png`
    }
    if (orgType === EOrgType.Retailer && isPortalDOOHOnlyFlagEnabled) {
      return `${process.env.REACT_APP_CDN_URL}/images/61b1e30c-3d71-4589-9ac6-36dd19af64e0.png`
    }
    if (orgType === EOrgType.Advertiser && isPortalDOOHOnlyFlagEnabled) {
      return `${process.env.REACT_APP_CDN_URL}/images/45f7bf69-819a-4677-930f-61cd9cec7e0a.png`
    }
    if (orgType === EOrgType.Retailer) {
      return `${process.env.REACT_APP_CDN_URL}/images/4e54ce6d-8d3b-44e5-bb3f-e6be8e837f19.png`
    }
    return `${process.env.REACT_APP_CDN_URL}/images/03905c58-c4a9-4cd3-84f7-e95c7fecb313.png`
  }

  const tableDataResponse =
    tableDataResponseObj?.data.table || ({} as IReportTable)

  /**
   *
   * @param tableDataRes API response containing headers and report table data along with data types.
   * These data types are used to render the correct data cell
   * @returns processedTableData which is used to render the table.
   * The processed data contains i18n table headers,
   * and the correct data cell (React Element) based on data type
   */
  const processTableDataResponse = (tableDataRes: IReportTable) => {
    const idColumnIndex =
      tableDataRes.header?.findIndex(
        (header) => header === EReportColumnHeader.Id
      ) || 0
    const statusColumnIndex =
      tableDataRes.header?.findIndex(
        (header) => header === EReportColumnHeader.Status
      ) || -1
    const channelTypeColumnIndex =
      tableDataRes.header?.findIndex(
        (header) => header === EReportColumnHeader.ChannelType
      ) || -1
    const dealTypeColumnIndex =
      tableDataRes.header?.findIndex(
        (header) => header === EReportColumnHeader.DealType
      ) || -1

    const columnIdList =
      tableDataRes.header?.filter((_, index) => index !== idColumnIndex) || []

    const processedTableData: TReportTableData = {
      columnIdList,
      columnNameList:
        columnIdList.map((header) => {
          if (header === 'name') {
            const pageType =
              (orgType === EOrgType.Retailer && retailerReportPageType) ||
              (orgType === EOrgType.Advertiser && advertiserReportPageType) ||
              ''
            let formattedHeader = `report.table.header.${orgType?.toLowerCase()}.name.${pageType}`
            if (
              orgType === EOrgType.Advertiser &&
              pageType === EAdvertiserReportPageType.Adgroups &&
              inventoryType
            ) {
              formattedHeader = formattedHeader.concat(
                `.${inventoryType.toLowerCase()}`
              )
            }

            return intl.formatMessage({
              id: formattedHeader,
            })
          }
          const translatedHeader = intl.formatMessage({
            id: `report.table.header.${orgType?.toLowerCase()}.${header}`,
          })
          return translatedHeader
        }) || [],
      columnNameTooltipList: columnIdList.map((columnId) => {
        const pageType =
          (orgType === EOrgType.Retailer && retailerReportPageType) ||
          (orgType === EOrgType.Advertiser && advertiserReportPageType) ||
          ''
        return intl.formatMessage({
          id: `report.table.header.${orgType?.toLowerCase()}.${
            columnId === 'name' ? `${pageType}` : columnId
          }.tooltip` as TTranslationKey,
        })
      }),
      columnSpanList: columnIdList.map((id) =>
        id === EReportColumnHeader.Name ? 2 : 1
      ),
      data: [],
    }
    /**
     * For each row
     * render table cell based on header type
     * ex:
     *  If header = status                                          => render status badge
     *  If header = channel type                                    => render channel type
     *  If header = inventory type                                  => render inventory type
     *  If header = conversions, roas, reach                        => render percentage
     *  If header = date                                            => render formatted date
     *  If header = budget, spend, costs, cpc/cpm, revenue, etc...  => render formatted number
     *  ...
     *  */
    tableDataRes.data?.forEach((rowData, rowIndex) => {
      const dataRow: Array<ReactNode | ReactNode[]> = []
      tableDataRes.types?.forEach((type, index) => {
        let dataCell: ReactNode | ReactNode[]
        const dataValue = rowData[index]

        if (index !== idColumnIndex) {
          switch (type) {
            case EReportColumnType.ChannelType:
              dataCell = (
                <CurrentChannelTypeText
                  channelType={dataValue as EChannelType}
                />
              )
              break
            case EReportColumnType.Currency:
              dataCell = <CurrencyText value={Number(dataValue)} />
              break
            case EReportColumnType.Date:
              dataCell = (
                <DateText value={dataValue as string | number | Date} />
              )
              break
            case EReportColumnType.InventoryType:
              dataCell = (
                <CurrentInventoryPageText
                  inventoryType={
                    dataValue as EInventoryType | EDOOHInventoryType
                  }
                />
              )
              break
            case EReportColumnType.Number:
              dataCell = (
                <NumberText
                  value={
                    tableDataRes?.header?.length &&
                    tableDataRes.header[index] === EReportColumnHeader.Carts &&
                    Number(dataValue) === 0 &&
                    rowData[channelTypeColumnIndex] !==
                      EChannelType.SponsoredProduct // Remove conditions and 0 after ADDP update (Sprint 44)
                      ? -1
                      : Number(dataValue)
                  }
                  maximumFractionDigits={
                    tableDataRes?.header?.length &&
                    tableDataRes.header[index] === EReportColumnHeader.AvgRank
                      ? 1
                      : 2
                  }
                />
              )
              break
            case EReportColumnType.Percentage: {
              if (
                tableDataRes.header?.[index] ===
                  EReportColumnHeader.ShareOfVoice &&
                dayjs(endDate) < SHARE_OF_VOICE_PROD_DEPLOYMENT_DATE
              ) {
                dataCell = NOT_AVAILABLE
              } else {
                dataCell = <PercentageText value={Number(dataValue)} />
              }
              break
            }
            case EReportColumnType.Status:
              {
                const isMutateNotAllowed = !isUserAuthorised(userRole, role)

                const campaignDealType =
                  (rowData[dealTypeColumnIndex] as EDealType) || dealType

                const isDirectDeal = [
                  EDealType.StandardDeal,
                  EDealType.ExclusiveDeal,
                ].includes(campaignDealType)

                const isSwitchDisabled =
                  (dataValue as EStatus) === EStatus.Disabled || isDirectDeal

                const isDeleted =
                  (dataValue as EDeletedStatus) === EDeletedStatus.Deleted

                const isDisabled =
                  isMutateNotAllowed ||
                  isSwitchDisabled ||
                  switchDisabled ||
                  (entityType === EEntityType.AdGroupCreative &&
                    !currentAdGroupId) ||
                  isDeleted

                let tooltipKey: TTranslationKey = 'no.permissions'

                if (isSwitchDisabled) {
                  tooltipKey = 'entity.disabled'
                } else if (switchDisabledTooltip) {
                  tooltipKey = switchDisabledTooltip
                }

                if (
                  advertiserReportPageType ===
                    EAdvertiserReportPageType.Keywords &&
                  orgType === EOrgType.Advertiser &&
                  isSwitchDisabled
                ) {
                  tooltipKey = 'entity.disabled.keyword'
                }

                if (!currentAdGroupId) {
                  tooltipKey = 'entity.unableToChange'
                }

                if (
                  [ECreativeStatus.Pending, ECreativeStatus.Rejected].includes(
                    dataValue as ECreativeStatus
                  ) &&
                  (advertiserReportPageType ===
                    EAdvertiserReportPageType.Creatives ||
                    retailerReportPageType ===
                      ERetailerReportPageType.Creatives)
                ) {
                  dataCell = (
                    <>
                      <CreativeStatusBadge
                        creativeStatus={dataValue as ECreativeStatus}
                      />
                    </>
                  )
                } else {
                  dataCell = (
                    <>
                      <TooltipWrapper
                        tooltipKey={tooltipKey}
                        showTooltip={
                          showSwitch &&
                          isDisabled &&
                          !isDirectDeal &&
                          !isDeleted
                        }
                      >
                        <StyledAntDropdown
                          overlay={
                            <StyledAntMenu
                              onClick={({ key }) => {
                                const status = key as EStatus
                                const id = Number(rowData[idColumnIndex])

                                let matchType: EMatchType | undefined
                                const matchTypeColumnIndex =
                                  tableDataRes.header?.findIndex(
                                    (header) =>
                                      header === EReportColumnHeader.MatchType
                                  ) || -1
                                if (matchTypeColumnIndex > -1) {
                                  matchType = rowData[
                                    matchTypeColumnIndex
                                  ] as EMatchType
                                }

                                onEntityStatusUpdateHandler({
                                  id,
                                  status,
                                  matchType,
                                })
                              }}
                            >
                              <StyledAntMenu.Item key={EStatus.Active}>
                                <Container display="flex" alignItems="center">
                                  <StyledCircle
                                    backgroundColor="var(--badge-color-active)"
                                    width="8px"
                                    height="8px"
                                  />
                                  <Container marginLeft="var(--gutter-default)">
                                    <I18NText
                                      fontFamily="OpenSans"
                                      size="var(--font-size-ps)"
                                      textTransform="capitalize"
                                      id="active"
                                      data-testid="table-status-active-option"
                                    />
                                  </Container>
                                </Container>
                              </StyledAntMenu.Item>
                              <StyledAntMenu.Item key={EStatus.Inactive} danger>
                                <Container display="flex" alignItems="center">
                                  <StyledCircle
                                    backgroundColor="var(--badge-color-inactive)"
                                    width="8px"
                                    height="8px"
                                  />
                                  <Container marginLeft="var(--gutter-default)">
                                    <I18NText
                                      fontFamily="OpenSans"
                                      size="var(--font-size-ps)"
                                      textTransform="capitalize"
                                      id="inactive"
                                      data-testid="table-status-inactive-option"
                                    />
                                  </Container>
                                </Container>
                              </StyledAntMenu.Item>
                            </StyledAntMenu>
                          }
                          disabled={isDisabled || !showSwitch}
                          overlayStyle={{ zIndex: 1000 }}
                          key={Number(Math.random() * 1000).toString()}
                        >
                          <HoverContainer
                            preset="paragraph opensans semibold"
                            display="flex"
                            alignItems="center"
                            height="100%"
                            width="100%"
                            cursor="pointer"
                            onClick={(e) => {
                              e.preventDefault()
                            }}
                            data-testid="report-table-status-dropdown"
                          >
                            <CurrentStatusBadge
                              status={
                                dataValue as
                                  | EStatus
                                  | ECampaignStatus
                                  | ECreativeStatus
                              }
                            />
                            {!isDisabled && showSwitch && (
                              <SVGContainer
                                preset="paragraph small"
                                margin="0 0 0 var(--margin-default)"
                                className="hover-items"
                              >
                                <IconArrowDownSmall />
                              </SVGContainer>
                            )}
                          </HoverContainer>
                        </StyledAntDropdown>
                      </TooltipWrapper>
                    </>
                  )
                }
              }
              break
            case EReportColumnType.MatchType:
              dataCell = (
                <CurrentMatchTypeText matchType={dataValue as EMatchType} />
              )
              break
            case EReportColumnType.Image:
              dataCell = (
                <Container display="flex" alignItems="center">
                  <Container flex="1">&nbsp;</Container>
                  <StyledImage
                    src={dataValue as string}
                    width="var(--icon-size-xl)"
                  />
                  <Container flex="1">&nbsp;</Container>
                </Container>
              )
              break
            case EReportColumnType.String:
              if (
                tableDataRes?.header?.length &&
                tableDataRes.header[index] === EReportColumnHeader.DealType
              ) {
                dataCell = (
                  <TooltipWrapper
                    showTooltip={true}
                    tooltipKey={`${
                      dataValue as EDealType
                    }.tooltip`.toLowerCase()}
                  >
                    <EllipsisContainer maxWidth="240px">
                      <I18NText id={(dataValue as EDealType).toLowerCase()} />
                    </EllipsisContainer>
                  </TooltipWrapper>
                )
              } else if (
                tableDataRes?.header?.length &&
                tableDataRes.header[index] === EReportColumnHeader.Name &&
                !navigateDisabled
              ) {
                // TOFIX: Maybe there is a better way to do this?
                const isAdvertiserOrg = orgType === EOrgType.Advertiser
                const retailerReportPageTypeForNavlink =
                  retailerReportPageType ===
                  ERetailerReportPageType.CampaignsAdvertiser
                    ? ERetailerReportPageType.Campaigns
                    : retailerReportPageType
                const reportPageType =
                  (isAdvertiserOrg
                    ? advertiserReportPageType
                    : retailerReportPageTypeForNavlink) || ''
                const id = rowData[idColumnIndex]
                const navLink = `../${navigateURLPrefix}${reportPageType}/${id}`
                const isDisabled =
                  (rowData[statusColumnIndex] as EStatus) === EStatus.Disabled

                dataCell = (
                  <>
                    <TooltipWrapper
                      showTooltip={true}
                      tooltip={
                        isAdvertiserOrg && isDisabled
                          ? intl.formatMessage({
                              id: 'entity.disabled',
                            })
                          : dataValue?.toString()
                      }
                    >
                      <EllipsisContainer maxWidth="240px">
                        <PageLinkButton
                          onClick={() => {
                            navigate(navLink, { state: rowData })
                          }}
                          disabled={isAdvertiserOrg && isDisabled}
                          data-testid={`report-table-row-${rowIndex}-cell-${index}-data`}
                        >
                          <Text>{dataValue}</Text>
                        </PageLinkButton>
                      </EllipsisContainer>
                    </TooltipWrapper>
                  </>
                )
              } else if (
                tableDataRes?.header?.length &&
                tableDataRes.header[index] === EReportColumnHeader.Placement &&
                typeof dataValue === 'string' &&
                !isScreenPlacementWithName(dataValue)
              ) {
                dataCell = (
                  <TooltipWrapper
                    showTooltip={true}
                    tooltipKey={
                      `${getPageTypeText(
                        dataValue as EPageType
                      )}.tooltip` as TTranslationKey
                    }
                  >
                    <EllipsisContainer maxWidth="240px">
                      <I18NText id={getPageTypeText(dataValue as EPageType)} />
                    </EllipsisContainer>
                  </TooltipWrapper>
                )
              } else {
                dataCell = (
                  <TooltipWrapper
                    showTooltip={true}
                    tooltip={dataValue?.toString() || NOT_AVAILABLE}
                  >
                    <EllipsisContainer maxWidth="240px">
                      <Text>{dataValue?.toString() || NOT_AVAILABLE}</Text>
                    </EllipsisContainer>
                  </TooltipWrapper>
                )
              }
              break
            case EReportColumnType.ObjectCreative:
              if (
                (dataValue as IReportObjectCreative).channelType ===
                ECreativeChannelType.DisplayAd
              ) {
                const creative = dataValue as IReportDACreative

                dataCell = (
                  <Container
                    maxWidth="264px"
                    maxHeight="264px"
                    display="flex"
                    flexDirection="column"
                  >
                    <Container
                      paddingTop="var(--gutter-1x)"
                      preset="table-title"
                    >
                      {creative.title}
                    </Container>
                    <Container display="flex" padding="var(--gutter-1x) 0">
                      <Container>
                        <AdPreview
                          height="150px"
                          width="200px"
                          adData={creative.data}
                          template={creative.template}
                        />
                      </Container>
                    </Container>
                  </Container>
                )
              } else if (
                (dataValue as IReportObjectCreative).channelType ===
                ECreativeChannelType.DigitalOutOfHome
              ) {
                const creative = dataValue as IReportDOOHCreative

                dataCell = (
                  <Container display="flex">
                    <TooltipWrapper
                      showTooltip={true}
                      tooltip={(dataValue as IReportDOOHObjectCreative).title}
                    >
                      <EllipsisContainer maxWidth="240px">
                        <Text>
                          {(dataValue as IReportDOOHObjectCreative).title}
                        </Text>
                      </EllipsisContainer>
                    </TooltipWrapper>
                    <SVGContainer
                      preset="paragraph small"
                      margin="0 0 0 var(--margin-default)"
                      className="hover-items"
                      cursor="pointer"
                    >
                      <IconCirclePlay
                        fontSize="var(--icon-size-sm)"
                        onClick={() => {
                          setVideoPlayerUrlToast(creative.data.url || '')
                        }}
                      />
                    </SVGContainer>
                  </Container>
                )
              }
              break

            default:
              dataCell = undefined
              break
          }
        }

        if (dataCell) {
          dataRow.push(dataCell)
        }
      })

      processedTableData.data.push(dataRow)
    })

    return processedTableData
  }

  useEffect(() => {
    if (startDate && endDate) {
      setTableParamsUpdated(true)
    }
  }, [startDate, endDate])

  useEffect(() => {
    if (sortBy || sortOrder) {
      setTableParamsUpdated(true)
    }
  }, [sortBy, sortOrder])

  // file deepcode ignore DuplicateIfBody: not duplicate
  useEffect(() => {
    if (channelType) {
      dispatch(setSortBy({ sortBy: undefined }))
      dispatch(setSortOrder({ sortOrder: EParamSortOrder.Desc }))
      setTableParamsUpdated(true)
    } else if (
      channelType === undefined &&
      location.pathname.endsWith('campaigns-all')
    ) {
      /**
       * If location contains 'campaigns-all', channel type is undefined
       */
      dispatch(setSortBy({ sortBy: undefined }))
      dispatch(setSortOrder({ sortOrder: EParamSortOrder.Desc }))
      setTableParamsUpdated(true)
    }
  }, [channelType])

  useEffect(() => {
    if (inventoryType) {
      dispatch(setSortBy({ sortBy: undefined }))
      dispatch(setSortOrder({ sortOrder: EParamSortOrder.Desc }))
      setTableParamsUpdated(true)
    } else if (
      inventoryType === undefined &&
      location.pathname.endsWith('adgroups-all')
    ) {
      /**
       * If location contains 'adgroups-all', inventory type is undefined
       */
      dispatch(setSortBy({ sortBy: undefined }))
      dispatch(setSortOrder({ sortOrder: EParamSortOrder.Desc }))
      setTableParamsUpdated(true)
    }
  }, [inventoryType])

  useEffect(() => {
    if (retailerReportPageType) {
      dispatch(setSortBy({ sortBy: undefined }))
      dispatch(setSortOrder({ sortOrder: EParamSortOrder.Desc }))
      setTableParamsUpdated(true)
    }
  }, [retailerReportPageType])

  useEffect(() => {
    if (advertiserReportPageType) {
      dispatch(setSortBy({ sortBy: undefined }))
      dispatch(setSortOrder({ sortOrder: EParamSortOrder.Desc }))
      setTableParamsUpdated(true)
    }
  }, [advertiserReportPageType])

  useEffect(() => {
    if (tableParamsUpdated) {
      refetch()
      setTableParamsUpdated(false)
    }
  }, [tableParamsUpdated])

  useEffect(() => {
    if (isMutateEntityStatusSuccess && !isFetching) {
      dispatch(setRefreshTable({ refreshTable: true }))
    }
  }, [isMutateEntityStatusSuccess])

  useEffect(() => {
    if (refreshTable) {
      refetch()
      dispatch(setRefreshTable({ refreshTable: false }))
    }
  }, [refreshTable])

  useEffect(() => {
    if (Object.keys(tableDataResponse).length > 0) {
      setTableData(processTableDataResponse(tableDataResponse))
    }
  }, [tableDataResponse])

  useEffect(() => {
    let virtualTableColumnsUpdate: Array<Column<TVirtualTableData<object>>> = []
    let virtualTableDataUpdate: Array<TVirtualTableData<object>> = []

    if (tableData) {
      virtualTableColumnsUpdate = tableData.columnNameList.map(
        (columnName, index) => {
          return {
            Header: (
              <Container
                onClick={() => {
                  /**
                   * If the same column is clicked, change sort type
                   * else change the sort column
                   */
                  if (sortBy === tableData.columnIdList[index]) {
                    onSortOrderChange(
                      sortOrder === EParamSortOrder.Asc
                        ? EParamSortOrder.Desc
                        : EParamSortOrder.Asc
                    )
                  } else {
                    onSortByChange(tableData.columnIdList[index])
                    onSortOrderChange(EParamSortOrder.Asc)
                  }
                }}
                cursor="pointer"
              >
                <TooltipWrapper
                  showTooltip={true}
                  tooltipKey={
                    `report.table.header.${orgType?.toLowerCase()}.${
                      tableData.columnIdList[index]
                    }.tooltip` as TTranslationKey
                  }
                >
                  <EllipsisContainer maxWidth="240px">
                    <Text>{columnName}</Text>
                    {sortBy === tableData.columnIdList[index] &&
                      sortOrder === 'asc' && (
                        <SVGContainer
                          paddingLeft="var(--gutter-mini)"
                          display="inline-table"
                        >
                          <IconArrowUpSmall />
                        </SVGContainer>
                      )}
                    {sortBy === tableData.columnIdList[index] &&
                      sortOrder === 'desc' && (
                        <SVGContainer
                          paddingLeft="var(--gutter-mini)"
                          display="inline-table"
                        >
                          <IconArrowDownSmall />
                        </SVGContainer>
                      )}
                  </EllipsisContainer>
                </TooltipWrapper>
              </Container>
            ),
            accessor: tableData.columnIdList[index],
            width:
              tableData.columnIdList[index] === 'search_text' ||
              tableData.columnIdList[index] === 'keyword' ||
              tableData.columnIdList[index] === 'unique_reach' ||
              tableData.columnIdList[index] === 'frequency' ||
              tableData.columnIdList[index] === 'impressions' ||
              tableData.columnIdList[index] === 'avg_rank' ||
              tableData.columnIdList[index] === 'carts' ||
              tableData.columnIdList[index] === 'purchases' ||
              tableData.columnIdList[index] === 'costs'
                ? '130px'
                : '90px',
          }
        }
      )
      virtualTableDataUpdate = tableData.data.map((row) => {
        let superObject = {}
        row.forEach((rowData, index) => {
          const accessor: string = tableData.columnIdList[index]
          superObject = { ...superObject, [`${accessor}`]: rowData }
        })
        return superObject
      })

      setVirtualTableColumns(virtualTableColumnsUpdate)
      setVirtualTableData(virtualTableDataUpdate)
    }
  }, [tableData])

  useEffect(() => {
    if (isMutateEntityError) {
      setShowToast(true)
    }
  }, [isMutateEntityError])

  return (
    <>
      {!isDateRangeFilterDisabled ? (
        <>
          {Boolean(virtualTableColumns.length) &&
            Boolean(virtualTableData.length) &&
            virtual && (
              <Container height="calc(100vh - var(--gutter-8x) - var(--gutter-10x) - var(--gutter-8x) - 1px)">
                <StyledVirtualTable
                  columns={virtualTableColumns}
                  data={virtualTableData}
                  isReport={true}
                />
              </Container>
            )}
          {tableData?.data &&
            tableData.columnIdList &&
            tableData.columnNameList &&
            !virtual && (
              <ReportTable
                {...tableProps}
                {...tableData}
                sortBy={sortBy}
                sortOrder={sortOrder}
                setSortBy={onSortByChange}
                setSortOrder={onSortOrderChange}
              />
            )}
        </>
      ) : (
        <StyledImage
          fluid
          src={getPlaceholderImage()}
          alt="home_placeholder"
          cursor="default"
        />
      )}
      <Loading show={isFetching || isMutateEntityStatusLoading} />
      <ErrorToast
        show={showToast}
        onClose={() => setShowToast(false)}
        translationKey={
          errorMutate?.response?.data.errorKey as unknown as TTranslationKey
        }
      />
      <VideoPlayerModal
        show={!!videoPlayerUrlToast}
        url={videoPlayerUrlToast}
        onClose={() => setVideoPlayerUrlToast('')}
      />
    </>
  )
}

export default TableWrapper
