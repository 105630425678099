import React, { useState } from 'react'
import { IOrgTheme, IReportGraph } from '@unfoldrtech/portal-mic'
import { useIntl } from 'react-intl'
import { useTheme } from 'styled-components'
import { IconCirclePlus, StyledAntSelect, SVGContainer } from '../Global'
import { ISelectOption } from '../../models/interfaces'

function MetricsSelector({
  metrics,
  pinnedMetricIndices,
  onChange,
}: {
  pinnedMetricIndices: Array<number>
  onChange: (n: number) => void
} & Pick<IReportGraph, 'metrics'>) {
  const intl = useIntl()
  const theme: IOrgTheme = useTheme()
  const [value, setValue] = useState<string>()
  return (
    <>
      <StyledAntSelect
        className="metric-selector"
        flex="1"
        padding="0 var(--padding-default) 0 var(--gutter-8x)"
        fontFamily="GalanoGrotesque-Regular"
        color="var(--font-color-default)"
        textTransform="capitalize"
        border={{
          width: '1px',
          color: theme.colors?.border?.dashed || 'var(--border-color-dashed)',
          style: 'dashed',
          radius: '4px',
        }}
        height="100%"
        // value={pinnedMetricIndices}
        value={value}
        onSelect={(v) => {
          if (!Number.isNaN(v)) {
            setValue(
              intl.formatMessage({
                id: 'report.select.metric',
              })
            )
            onChange(Number(v))
          }
        }}
        options={metrics.map(
          (m, index) =>
            ({
              label: m,
              value: index.toString(),
              disabled: pinnedMetricIndices.includes(index),
            } as ISelectOption)
        )}
        placeholder={intl.formatMessage({
          id: 'report.select.metric',
        })}
        suffixIcon={
          <SVGContainer preset="paragraph small">
            <IconCirclePlus />
          </SVGContainer>
        }
        dataTestId="metric-selector"
      />
    </>
  )
}

export default MetricsSelector
