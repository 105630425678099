import { css, CSSProperties } from 'styled-components'

export type TMarginMixin = Pick<
  CSSProperties,
  'margin' | 'marginTop' | 'marginRight' | 'marginBottom' | 'marginLeft'
>

export const Margin = ({
  margin,
  marginTop,
  marginRight,
  marginBottom,
  marginLeft,
}: TMarginMixin) => css`
  margin: ${margin};
  margin-top: ${marginTop};
  margin-right: ${marginRight};
  margin-bottom: ${marginBottom};
  margin-left: ${marginLeft};
`
