import React from 'react'
import { EDealType } from '../../../models/core'
import I18NText from '../../I18NText'

type TProps = {
  value?: EDealType
}

export function CurrentDealTypeText({ value }: TProps) {
  if (value === EDealType.ExclusiveDeal) {
    return <I18NText id="direct.deals.type.exclusive" />
  }
  if (value === EDealType.StandardDeal) {
    return <I18NText id="direct.deals.type.standard" />
  }

  return <></>
}
