import styled from 'styled-components'
import { StyledButton } from './StyledButton'
import { TPaddingMixin, Padding } from '../styles'

export const ListItemButton = styled(StyledButton)`
  background: none;
  display: flex;
  justify-content: space-between;
  align-items: center;
  line-height: 18px;
  width: 100%;
  ${(props: TPaddingMixin) => Padding({ ...props })};
  &:hover,
  &:focus,
  &:active,
  &:target {
    background-color: transparent;
    opacity: 1;
  }

  &:disabled {
    background-color: transparent;
    opacity: 0.5;
  }
`
