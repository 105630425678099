import * as XLSX from 'xlsx'
import { saveAs } from 'file-saver'

import { IReportTable } from '@unfoldrtech/portal-mic'
import { V2IReportTable, TReportExportType } from '../../models'
import { exportBlobTypeMap } from '../constants'

export const exportTableDataToFile = ({
  tableData,
  headers,
  fileName,
  fileType,
}: {
  tableData: IReportTable | V2IReportTable
  headers: Array<{ label: string; key: string }>
  fileName: string
  fileType: TReportExportType
}) => {
  // transform table data into csv/excel
  const data: Array<Record<string, unknown>> =
    tableData?.data?.map((rowData) => {
      const transformedRowData: Record<string, unknown> = {}

      rowData.forEach((cellData, cellIndex) => {
        const header = headers[cellIndex].label
        transformedRowData[header] = cellData
      })
      return transformedRowData
    }) || []

  // export
  const blobType = exportBlobTypeMap[fileType] ?? exportBlobTypeMap.csv
  const worksheet = XLSX.utils.json_to_sheet(data, { dense: true })
  const workbook = XLSX.utils.book_new()

  XLSX.utils.book_append_sheet(workbook, worksheet)

  // PORT-1630
  // Example : Format columns
  /*
  const currencyColumnIndex = 4
  const currencyFormat = '€0.00'

  const formattedWorksheet = exportDataFormatColumn({
    worksheet,
    columnIndex: currencyColumnIndex,
    columnFormat: currencyFormat,
  })

  XLSX.utils.book_append_sheet(workbook, formattedWorksheet)
  */

  // Buffer to store the generated Excel file
  const workbookBuffer = XLSX.write(workbook, {
    bookType: fileType,
    type: 'array',
    compression: true,
  })
  const blob = new Blob([workbookBuffer], {
    type: blobType,
  })

  saveAs(blob, `${fileName}.${fileType}`)
}
