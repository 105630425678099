import React, { FC, useEffect, useState } from 'react'
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes,
  BrowserRouter,
} from 'react-router-dom'
import { IntlProvider } from 'react-intl'
import { Provider } from 'react-redux'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ReactQueryDevtools } from 'react-query/devtools'
import { ThemeProvider } from 'styled-components'
import {
  CaptureConsole as CaptureConsoleIntegration,
  ReportingObserver as ReportingObserverIntegration,
} from '@sentry/integrations'
import * as Sentry from '@sentry/react'

import store from '../../store'
import ServiceWorkerWrapper from '../ServiceWorkerWrapper'
import messagesEN from '../../i18n/en.json'
import I18NText from '../I18NText'
import {
  AppTheme,
  Container,
  GlobalFonts,
  PORTAL_DEFAULT_THEME,
} from '../Global'

import { sanitizeTheme } from '../../utils/sanitizeTheme'
import { version } from '../../../package.json'
import { FrameKiller } from '../FrameKiller'
import { VersionInfo } from './VersionInfo'
import FeatureFlagProvider from './FeatureFlagProvider'

// import reportWebVitals from './reportWebVitals'

const messages = messagesEN

/* switch (navigator.language) {
  case 'en-NL':
    messages = messagesEN
    break
  default:
    messages = messagesEN
} */

/**
 * Init Sentry
 */
Sentry.init({
  dsn: process.env.REACT_APP_SENTRY_DSN,
  normalizeDepth: 10,
  integrations: [
    new Sentry.BrowserTracing({
      tracingOrigins: [
        new RegExp(/localhost/),
        new RegExp(/\.unfoldr\.dev/),
        new RegExp(/\.streaem\.com/),
      ],
      routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        useEffect,
        useLocation,
        useNavigationType,
        createRoutesFromChildren,
        matchRoutes
      ),
    }),
    new CaptureConsoleIntegration({ levels: ['error'] }),
    new ReportingObserverIntegration({
      // TOFIX: Types
      types: ['crash', 'intervention', 'deprecation'] as unknown as any,
    }),
  ],
  environment: process.env.REACT_APP_NODE_ENV,
  release:
    `${process.env.REACT_APP_SYSTEM}-${process.env.REACT_APP_SUBSYSTEM}@${version}`.toLowerCase(),
  maxBreadcrumbs: 50,
  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

/**
 * Init react query client
 */
const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: 1,
    },
    mutations: {
      retry: 1,
    },
  },
})

const env = process.env.REACT_APP_NODE_ENV

/**
 * Initialises global setup
 * - Router
 * - Locale/Intl
 * - Fonts
 * - Redux store
 * - React Query
 * - Service worker
 * - Sentry
 */
export const SetupApp: FC = ({ children }) => {
  const [isSafe, setIsSafe] = useState<boolean>()

  useEffect(() => {
    if (window.self === window.top) {
      setIsSafe(true)
    } else {
      setIsSafe(false)
    }
  }, [])

  if (isSafe === false) {
    return (
      <>
        <FrameKiller />
      </>
    )
  }

  if (isSafe) {
    return (
      <>
        <BrowserRouter>
          <IntlProvider
            locale={navigator.language.split('-')[0] || 'en'}
            messages={messages}
          >
            <Sentry.ErrorBoundary
              fallback={<I18NText id="error.unknown" />}
              showDialog
            >
              <Provider store={store}>
                <ThemeProvider theme={sanitizeTheme(PORTAL_DEFAULT_THEME)}>
                  <FeatureFlagProvider>
                    <VersionInfo />
                    <GlobalFonts />
                    <AppTheme />
                    <ServiceWorkerWrapper />
                    <QueryClientProvider client={queryClient}>
                      <Container
                        display="flex"
                        backgroundColor="rgba(0,0,0,0.015)"
                        overflowX="auto"
                      >
                        <Container flex="1">&nbsp;</Container>
                        <Container
                          minWidth="1280px"
                          width="100%"
                          maxWidth="1440px"
                          height="100vh"
                          backgroundColor="var(--background-color-default)"
                        >
                          {children}
                        </Container>
                        <Container flex="1">&nbsp;</Container>
                      </Container>
                      <Container>
                        {env === 'local' && (
                          <ReactQueryDevtools initialIsOpen />
                        )}
                      </Container>
                    </QueryClientProvider>
                  </FeatureFlagProvider>
                </ThemeProvider>
              </Provider>
            </Sentry.ErrorBoundary>
          </IntlProvider>
        </BrowserRouter>
      </>
    )
  }

  return <></>
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
// reportWebVitals(console.log)

// @ts-ignore Add store on window when running e2e tests
if (window.Cypress) window.store = store
