import {
  TDAProductsReportChartFilters,
  TSPProductsReportChartFilters,
} from '../../../models/types'

export const selectProductsChartReportingFilters = ({
  reportingFilters: reportingProductsChartFiltersState,
}: {
  reportingFilters:
    | TSPProductsReportChartFilters
    | TDAProductsReportChartFilters
}): TSPProductsReportChartFilters | TDAProductsReportChartFilters =>
  reportingProductsChartFiltersState
