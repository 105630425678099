import React, { ReactNode } from 'react'
import { TooltipProps } from 'react-bootstrap'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import { CSSProperties } from 'styled-components'
import { TDataTest, TTranslationKey } from '../../../models/types'
import { sanitizeDataTestId } from '../../../utils/sanitizeDataTestId'
import I18NText from '../../I18NText'
import { Container } from '../containers'
import { TooltipText, Text } from '../fonts'

export interface TooltipWrapperProps
  extends Omit<TooltipProps, 'id'>,
    Pick<
      CSSProperties,
      | 'display'
      | 'maxWidth'
      | 'whiteSpace'
      | 'textAlign'
      | 'lineHeight'
      | 'padding'
      | 'backgroundColor'
      | 'borderRadius'
      | 'overflow'
    >,
    TDataTest {
  children: ReactNode | ReactNode[]
  tooltip?: string
  tooltipKey?: TTranslationKey
  showTooltip?: boolean
}

export function TooltipWrapper({
  showTooltip: isDisabled,
  children,
  tooltipKey,
  tooltip,
  placement,
  maxWidth,
  whiteSpace,
  textAlign,
  backgroundColor,
  color,
  padding,
  borderRadius,
  lineHeight,
  dataTestId = sanitizeDataTestId(tooltipKey || tooltip || ''),
  display = 'block',
  overflow,
}: TooltipWrapperProps) {
  return isDisabled ? (
    <OverlayTrigger
      placement={placement}
      overlay={(props) => (
        <TooltipText
          {...props}
          maxWidth={maxWidth}
          whiteSpace={whiteSpace}
          textAlign={textAlign}
          backgroundColor={backgroundColor}
          color={color}
          padding={padding}
          border={{ borderRadius }}
          lineHeight={lineHeight}
          id={`tooltip-${dataTestId}`}
          data-testid={`tooltip-hover-${dataTestId}`}
        >
          {tooltipKey && <I18NText id={tooltipKey} />}
          {tooltip && <Text>{tooltip}</Text>}
        </TooltipText>
      )}
    >
      <Container overflow={overflow} data-testid={dataTestId} display={display}>
        {children}
      </Container>
    </OverlayTrigger>
  ) : (
    <Container overflow={overflow} display={display}>
      {children}
    </Container>
  )
}

export default TooltipWrapper
