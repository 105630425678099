import React, { ReactElement } from 'react'
import { CenterContainer, Container, NavLinkButton, Text } from '../Global'

export const FrameKiller = (): ReactElement => {
  const { href } = window.self.location
  return (
    <>
      <CenterContainer>
        <Container width="580px" textAlign="center">
          <Container
            preset="h1"
            textTransform="unset"
            fontSize="40px"
            color="red"
          >
            If you see this page, Streaem link you have clicked on is under
            Clickjacking security attack.
          </Container>
          <Container margin="24px 8px">
            <Text preset="h2" textTransform="unset" fontSize="32px">
              Please inform team with the reference of the application from
              where you clicked this link.
            </Text>
          </Container>
          <Container
            display="flex"
            alignItems="center"
            justifyContent="center"
            margin="24px 8px"
          >
            <Text preset="h2" textTransform="unset">
              Click
            </Text>
            <Container margin="0 8px">
              <NavLinkButton
                href={encodeURI(`${href}`)}
                title="Web Application"
                target="_blank"
                rel="noreferrer"
              >
                here
              </NavLinkButton>
            </Container>
            <Text preset="h2" textTransform="unset">
              to access Streaem safely.
            </Text>
          </Container>
        </Container>
      </CenterContainer>
    </>
  )
}
