import { PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import {
  IDOOHAdGroup,
  IDOOHCampaign,
  IDOOHCreativeWithVideoSpecification,
} from '../../../models'
import { getSelectedDoohAdGroup } from '../utils'

const setDoohCampaignAdGroupCreative = (
  state: WritableDraft<IDOOHCampaign>,
  action: PayloadAction<{
    creative: IDOOHCreativeWithVideoSpecification
    adGroupId: number
  }>
) => {
  const selectedAdGroup =
    getSelectedDoohAdGroup(state, action.payload.adGroupId) ||
    ({} as IDOOHAdGroup)
  if (selectedAdGroup.creativesList === undefined) {
    selectedAdGroup.creativesList = []
  }
  const creativeIndex = selectedAdGroup.creativesList.findIndex(
    (creative) => creative.id === action.payload.creative.id
  )

  if (creativeIndex === -1) {
    selectedAdGroup.creativesList.push(action.payload.creative)
  } else {
    selectedAdGroup.creativesList[creativeIndex] = action.payload.creative
  }
}
export default setDoohCampaignAdGroupCreative
