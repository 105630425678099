import { EUserLocale } from '@unfoldrtech/portal-mic'
import React, { ReactNode, useContext, useEffect, useState } from 'react'
import { IntlProvider } from 'react-intl'
import messagesEN from '../../i18n/en.json'
import { AppContext } from '../../models/contexts'

function Locale({ children }: { children?: ReactNode | ReactNode[] }) {
  const [appContext] = useContext(AppContext)
  const [messages, setMessages] = useState(messagesEN)
  const [locale, setLocale] = useState('en')

  useEffect(() => {
    if (appContext.userProfile?.locale) {
      switch (appContext.userProfile.locale) {
        case EUserLocale.EnNl:
          setMessages(messagesEN)
          setLocale('en')
          break
        default:
          setMessages(messagesEN)
          setLocale('en')
      }
    }
  }, [appContext.userProfile])

  return (
    <>
      <IntlProvider locale={locale} messages={messages}>
        {children}
      </IntlProvider>
    </>
  )
}

export default Locale
