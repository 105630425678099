import React from 'react'
import { useIntl } from 'react-intl'
import { Container, TooltipWrapper, Text } from '../Global'
import I18NText from '../I18NText'

const FooterCopyright = () => {
  const intl = useIntl()

  return (
    <Container
      width="100%"
      display="flex"
      justifyContent="center"
      padding="var(--gutter-4x) 0 var(--gutter-2x) 0"
    >
      <Container
        width="100%"
        textAlign="center"
        color="var(--font-color-default)"
      >
        <TooltipWrapper
          tooltip={`© ${new Date().getFullYear()} ${intl.formatMessage({
            id: 'footer.copyright',
          })}`}
          showTooltip
          dataTestId="footer-copyright-tooltip"
          maxWidth="500px"
          textAlign="justify"
          color="var(--font-color-dark)"
          backgroundColor="var(--background-color-light)"
          padding="var(--gutter-1x)"
          lineHeight={1.14}
          borderRadius="2px"
        >
          <Container data-testid="footer-copyright">
            <Text preset="formfield">© {new Date().getFullYear()} </Text>
            <I18NText
              id="footer.rights.reserved"
              preset="formfield"
              textDecoration="underline"
            />
          </Container>
        </TooltipWrapper>
      </Container>
    </Container>
  )
}

export default FooterCopyright
