import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconInvisible = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 20"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="m2.781 18.75 18.75-18M8.962 16.8a9.95 9.95 0 0 0 3.036.45c4.1.069 8.258-2.813 10.824-5.637.57-.632.57-1.593 0-2.226a20.629 20.629 0 0 0-3.074-2.757m-5.337-2.597a9.616 9.616 0 0 0-2.413-.282c-4.03-.067-8.199 2.752-10.82 5.635a1.663 1.663 0 0 0 0 2.226 20.737 20.737 0 0 0 2.6 2.4" />
      <path d="M8.248 10.5a3.75 3.75 0 0 1 3.75-3.75m3.75 3.75h0a3.75 3.75 0 0 1-3.75 3.751" />
    </g>
  </svg>
)

export default SvgIconInvisible
