import { css, CSSProperties } from 'styled-components'

export type TFontFamilyMixin =
  | 'GalanoGrotesque-Bold'
  | 'GalanoGrotesque-SemiBold'
  | 'GalanoGrotesque-Medium'
  | 'GalanoGrotesque-Regular'
  | 'OpenSans'
  | 'OpenSans-Bold'
  | 'OpenSans-SemiBold'
  | 'OpenSans-Italic'
  | 'OpenSans-SemiBold-Italic'
  | 'Archivo-Regular'
  | 'Archivo-Light'
  | 'Archivo-Medium'
  | 'Archivo-SemiBold'
  | 'Archivo-Bold'
  | 'Archivo-Black'

export type TFontStyleMixin = Pick<
  CSSProperties,
  | 'fontSize'
  | 'fontStyle'
  | 'color'
  | 'fontWeight'
  | 'lineHeight'
  | 'textTransform'
  | 'letterSpacing'
  | 'opacity'
  | 'textDecoration'
  | 'fontStyle'
> & {
  fontFamily?: TFontFamilyMixin
  weight?: string
  lh?: string | number
}

export const FontStyle = ({
  fontFamily,
  fontSize,
  fontStyle,
  color,
  fontWeight,
  weight,
  lineHeight,
  lh,
  textTransform,
  letterSpacing,
  textDecoration,
  opacity,
}: TFontStyleMixin) => css`
  font-family: ${fontFamily};
  font-size: ${fontSize};
  font-style: ${fontStyle};
  color: ${color};
  font-weight: ${weight || fontWeight};
  line-height: ${lh || lineHeight};
  text-transform: ${textTransform};
  letter-spacing: ${letterSpacing};
  opacity: ${opacity};
  text-decoration: ${textDecoration};
  font-style: ${fontStyle};
  -webkit-text-fill-color: ${color};
`
