import { FormatNumberOptions } from 'react-intl'

export const getFormatOptionCurrency = (
  currencyText: string
): FormatNumberOptions => {
  return {
    currency: currencyText,
    maximumFractionDigits: 2,
    minimumFractionDigits: 0,
    style: 'currency',
  }
}
