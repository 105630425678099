import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconImgFolder = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 23"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M21.75 8.25v-3a1.5 1.5 0 0 0-1.5-1.5h-12v-1.5a1.5 1.5 0 0 0-1.5-1.5h-4.5a1.5 1.5 0 0 0-1.5 1.5v17.8a1.7 1.7 0 0 0 3.336.438L6.437 9.334A1.5 1.5 0 0 1 7.879 8.25H21.75a1.5 1.5 0 0 1 1.45 1.886l-2.2 10.5a1.5 1.5 0 0 1-1.45 1.114H2.447" />
      <path d="M9.75 11.625a.375.375 0 1 1 0 .75.375.375 0 0 1 0-.75m8.629 7.125-2.911-4.365a.749.749 0 0 0-1.237-.015l-2 2.851L11 16.232a.751.751 0 0 0-1.093.17L8.34 18.75" />
    </g>
  </svg>
)

export default SvgIconImgFolder
