import { EAllChannelType } from '@unfoldrtech/portal-mic/dist/src/models/enums'

export const mapChannelTypeToName = (channel: EAllChannelType) => {
  switch (channel) {
    case EAllChannelType.DisplayAd:
      return 'display.ads'

    case EAllChannelType.Dooh:
      return 'digital.out.of.home'

    default:
      return ''
  }
}
