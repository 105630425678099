import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconBin = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 23 23"
    {...props}
  >
    <path
      d="M.75 3.75h21m-8.25-3H9a1.5 1.5 0 0 0-1.5 1.5v1.5H15v-1.5a1.5 1.5 0 0 0-1.5-1.5zM9 16.5V9m4.5 7.5V9m4.615 11.374a1.5 1.5 0 0 1-1.495 1.376H5.88c-.78 0-1.43-.598-1.494-1.376L3 3.75h16.5l-1.385 16.624z"
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgIconBin
