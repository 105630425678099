import { PayloadAction } from '@reduxjs/toolkit/dist/createAction'
import { ICampaign } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'

const removeCampaignAdGroup = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{ id: number }>
) => {
  const index = state.adGroupList.findIndex(
    (adGroup) => adGroup.id === action.payload.id
  )
  state.adGroupList.splice(index, 1)
}

export default removeCampaignAdGroup
