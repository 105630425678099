import { PayloadAction } from '@reduxjs/toolkit'
import { ICampaign } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'

const removeCampaignProduct = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{ id: number }>
) => {
  const index = state.productList.findIndex(
    (product) => product.id === action.payload.id
  )
  state.productList.splice(index, 1)
}

export default removeCampaignProduct
