import styled from 'styled-components'
import { IThemeProps } from '../../../models/interfaces'
import { TContainerProps } from '../styles/mixins'
import { Container } from './Container'

export const SecondaryBackgroundContainer = styled(Container).withConfig({
  shouldForwardProp: (prop) =>
    ![
      'justifyContent',
      'maxWidth',
      'alignItems',
      'flexDirection',
      'borderRight',
      'borderLeft',
      'borderTop',
      'borderBottom',
    ].includes(prop as string),
})<TContainerProps>`
  background-color: ${(props: IThemeProps) =>
    props.theme?.colors?.background?.secondary ||
    'var(--background-color-secondary)'};
`
