import styled from 'styled-components'
import { StyledButton } from './StyledButton'
import { Size } from '../styles/mixins'

export const FAB = styled(StyledButton)<{
  dimensions?: 'xs' | 'sm' | 'no' | 'lg'
}>`
  line-height: unset;

  border-radius: 100px 100px;

  :hover {
    border-radius: 100px 100px;
  }

  ${(props) =>
    Size({
      width:
        `var(--button-size-${props.dimensions})` || 'var(--button-size-sm)',
      height:
        `var(--button-size-${props.dimensions})` || 'var(--button-size-sm)',
    })};
`
