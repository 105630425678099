import React from 'react'
import { NOT_AVAILABLE } from '../../../../utils/constants'
import { EllipsisContainer, TooltipWrapper, Text } from '../../../Global'

export const TableDataCellName = ({
  dataValue = NOT_AVAILABLE,
}: {
  dataValue?: string
}) => {
  return (
    <TooltipWrapper showTooltip={true} tooltip={dataValue}>
      <EllipsisContainer maxWidth="240px">
        <Text>{dataValue}</Text>
      </EllipsisContainer>
    </TooltipWrapper>
  )
}
