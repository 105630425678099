import React, { lazy, useContext, useEffect, useState } from 'react'
import { Navigate, Route } from 'react-router-dom'
import { PortalRoutes } from './PortalRoutes'
import Login from '../../pages/Login'
import { AppContext } from '../../models/contexts'
import { lazyRetry } from '../../utils/lazyRetry'

const ForgotPassword = lazy(() =>
  lazyRetry(() => import('../../pages/ForgotPassword'))
)
const LoginWithToken = lazy(() =>
  lazyRetry(() => import('../../pages/LoginWithToken'))
)
const ResetPassword = lazy(() =>
  lazyRetry(() => import('../../pages/ResetPassword'))
)
const Register = lazy(() => lazyRetry(() => import('../../pages/Register')))

const Mfa = lazy(() => lazyRetry(() => import('../../pages/Mfa')))

const ResetPhone = lazy(() => lazyRetry(() => import('../../pages/ResetPhone')))

const PublicRoutes: React.FC = () => {
  const [appContext] = useContext(AppContext)
  const [redirectURL, setRedirectURL] = useState('/login')

  useEffect(() => {
    if (appContext.isLoggedIn) {
      // Does user need MFA, and has successfully logged in?
      setRedirectURL('/mfa')
    } else {
      setRedirectURL('/login')
    }
  }, [appContext.isLoggedIn])

  return (
    <>
      <PortalRoutes>
        <Route path="/" element={<Navigate to={redirectURL} />} />

        <Route path="/login" element={<Login />} /* exact */ />
        <Route
          path="/forgot-password"
          element={<ForgotPassword />}
          /* exact */
        />
        <Route
          path="/reset-password"
          element={<ResetPassword />}
          /* exact */
        />
        <Route
          path="/register"
          element={<Register />}
          /* exact */
        />
        <Route
          path="/login-with-token"
          element={<LoginWithToken />}
          /* exact */
        />
        <Route path="/mfa" element={<Mfa />} /* exact */ />
        <Route path="/reset-phone" element={<ResetPhone />} /* exact */ />
      </PortalRoutes>
    </>
  )
}

export default PublicRoutes
