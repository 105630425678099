import { css, CSSProperties } from 'styled-components'

export type TDisplayMixin = Pick<
  CSSProperties,
  'overflow' | 'display' | 'flex' | 'flexFlow' | 'flexDirection' | 'zIndex'
>

export const Display = ({
  overflow,
  display,
  flex,
  flexFlow,
  flexDirection,
  zIndex,
}: TDisplayMixin) => css`
  display: ${display};
  flex-direction: ${flexDirection};
  flex: ${flex};
  overflow: ${overflow};
  flex-flow: ${flexFlow};
  z-index: ${zIndex};
`
