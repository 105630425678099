import React, { ReactNode } from 'react'
import styled from 'styled-components'
import { Select, SelectProps } from 'antd'
import { SelectValue } from 'antd/lib/select'
import { IThemeProps } from '../../../../models/interfaces'
import {
  Action,
  Border,
  FontPreset,
  FontStyle,
  TBorderMixin,
  TContainerProps,
  TFontPresetMixin,
  TFontStyleMixin,
  TSizeMixin,
  TruncatePreset,
} from '../../styles/mixins'
import { Container } from '../../containers'
import {
  TSelectOptions,
  TFormFieldError,
  TDataTest,
} from '../../../../models/types'
import { sanitizeDataTestId } from '../../../../utils/sanitizeDataTestId'

export type TAntSelectProps<T> = SelectProps<T> &
  TSelectOptions &
  TDataTest &
  TFormFieldError &
  TContainerProps & {
    children?: ReactNode | ReactNode[]
  }

export const AntSelect = styled(Select).withConfig({
  shouldForwardProp: (prop) =>
    ![
      'justifyContent',
      'maxWidth',
      'minWidth',
      'alignItems',
      'flexDirection',
      'borderRight',
      'borderLeft',
      'borderTop',
      'borderBottom',
      'border',
      'textAlign',
      'lineHeight',
      'textTransform',
    ].includes(prop as string),
})<TContainerProps & { search?: boolean }>`
  &&.ant-select {
    width: 100%;
    height: 100%;

    &.input-error .ant-select-selector {
      ${Border({
        borderBottom: {
          width: '4px',
          style: 'solid',
          color: 'var(--border-color-error)',
          radius: '2px',
        },
      })};
    }
  }
  && .ant-select-selector {
    padding: ${(props) =>
      props.search ? '' : props.padding || '0 var(--padding-default)'};
    border-style: none;
    box-shadow: none;

    ${(props: IThemeProps) =>
      Action({
        cursor: 'pointer',
        height: '100%',
        // height: 'var(--button-size-lg)',
        backgroundColor:
          props.theme?.colors?.background?.primary ||
          'var(--background-color-default)',

        fontFamily: 'OpenSans-SemiBold',
        fontSize: 'var(--font-size-pa)',
        textTransform: 'uppercase',
        color:
          props.theme?.colors?.font?.primary || 'var(--font-color-default)',
      })};

    .ant-select-selection-search {
      input {
        display: ${(props) => (props.search ? '' : 'none')};
      }
    }

    .ant-select-selection-placeholder {
      -webkit-text-fill-color: var(--font-color-placeholder);
    }
  }

  && .ant-select-selection-item,
  .ant-select-selection-placeholder {
    margin: auto var(--gutter-mini);
    border-radius: var(--gutter-mini);
    ${(props: TFontStyleMixin) =>
      FontStyle({ textTransform: 'none', ...props })};
    ${(props: TFontPresetMixin) => FontPreset(props.preset)};
  }

  &&.metric-selector {
    display: flex;
    text-align: center;

    .ant-select-arrow {
      top: 28px;
      left: var(--gutter-7x);
      right: unset;
      width: unset;
      height: unset;
      margin-top: unset;
      color: unset;
      font-size: unset;
      line-height: unset;
      text-align: unset;
    }
  }

  && .ant-select-item-option-content {
    text-transform: none;
  }
  && .ant-select-selection-placeholder {
    color: ${(props: IThemeProps) =>
      props.theme?.colors?.font?.placeholder ||
      'var(--font-color-placeholder)'};
    ${TruncatePreset('100%')};
  }
`

const unwrapSizeProps = (props: TSizeMixin) => ({
  ...props,
})

const unwrapBorderProps = (props: TBorderMixin) => ({
  ...props,
})

export const StyledAntSelect = React.forwardRef<
  HTMLSelectElement,
  TAntSelectProps<SelectValue>
>(
  (
    { error, className, padding, dataTestId, showSearch, children, ...props },
    ref
  ) => {
    const selectRef = ref || null
    const sizeProps = unwrapSizeProps(props)
    const borderProps = unwrapBorderProps(props)
    const customClass = className || ''
    return (
      <Container
        border={{
          width: '1px',
          style: 'solid',
          color: 'var(--border-color-default)',
          radius: '2px',
        }}
        {...sizeProps}
        {...borderProps}
      >
        <AntSelect
          getPopupContainer={(triggerNode) => triggerNode.parentElement}
          {...props}
          padding={padding}
          ref={selectRef}
          className={error ? `${customClass} input-error` : `${customClass}`}
          data-testid={sanitizeDataTestId(dataTestId || '')}
          showSearch={showSearch}
          search={showSearch}
        >
          {children || <></>}
        </AntSelect>
      </Container>
    )
  }
)
