import { EOrgType } from '@unfoldrtech/portal-mic'
import useGetAdvertiserReportingProductsTableV3 from '../../../../hooks/use-get-advertiser-reporting-products-table-v3'
import useGetAdvertiserReportingProductsChartV3 from '../../../../hooks/use-get-advertiser-reporting-products-chart-v3'
import useGetRetailerReportingProductsTableV3 from '../../../../hooks/use-get-retailer-reporting-products-table-v3'
import useGetRetailerReportingProductsChartV3 from '../../../../hooks/use-get-retailer-reporting-products-chart-v3'

export const getTableAndChartProductsFn = ({
  orgType = EOrgType.Advertiser,
}: {
  orgType?: EOrgType
}) => {
  let tableFn
  let chartFn
  if (orgType === EOrgType.Advertiser) {
    tableFn = useGetAdvertiserReportingProductsTableV3
    chartFn = useGetAdvertiserReportingProductsChartV3
  }
  if (orgType === EOrgType.Retailer) {
    tableFn = useGetRetailerReportingProductsTableV3
    chartFn = useGetRetailerReportingProductsChartV3
  }

  return { tableFn, chartFn }
}
