import { css, CSSProperties } from 'styled-components'

export type TStickyMixin = {
  sticky?: TStickyProps
}
export type TStickyProps = Pick<
  CSSProperties,
  'position' | 'zIndex' | 'top' | 'left' | 'right' | 'bottom'
>
export const Sticky = ({
  position,
  zIndex,
  top,
  left,
  right,
  bottom,
}: TStickyProps) => css`
  position: ${position || 'sticky'};
  z-index: ${zIndex || '10'};
  top: ${top || '0px'};
  left: ${left || ''};
  right: ${right || ''};
  bottom: ${bottom || ''};
`
