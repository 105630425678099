import { EOrgType } from '@unfoldrtech/portal-mic'
import useGetAdvertiserReportingDOOHLocationsTableV3 from '../../../../hooks/use-get-advertiser-reporting-dooh-locations-table-v3'
import useGetAdvertiserReportingDOOHLocationsChartV3 from '../../../../hooks/use-get-advertiser-reporting-dooh-locations-chart-v3'
import useGetRetailerReportingDOOHLocationsTableV3 from '../../../../hooks/use-get-retailer-reporting-dooh-locations-table-v3'
import useGetRetailerReportingDOOHLocationsChartV3 from '../../../../hooks/use-get-retailer-reporting-dooh-locations-chart-v3'

export const getTableAndChartDOOHLocationsFn = ({
  orgType = EOrgType.Advertiser,
}: {
  orgType?: EOrgType
}) => {
  let tableFn
  let chartFn
  if (orgType === EOrgType.Advertiser) {
    tableFn = useGetAdvertiserReportingDOOHLocationsTableV3
    chartFn = useGetAdvertiserReportingDOOHLocationsChartV3
  }
  if (orgType === EOrgType.Retailer) {
    tableFn = useGetRetailerReportingDOOHLocationsTableV3
    chartFn = useGetRetailerReportingDOOHLocationsChartV3
  }

  return { tableFn, chartFn }
}
