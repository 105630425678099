import { PayloadAction } from '@reduxjs/toolkit'
import { ICampaign, IAdGroup } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { TPlacement } from '../../../models/types/TPlacement'

const addCampaignAdGroup = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<IAdGroup & { placement?: TPlacement }>
) => {
  state.adGroupList.push(action.payload)
}

export default addCampaignAdGroup
