import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconCircleQuestionmark = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 16 16"
    {...props}
  >
    <g stroke="currentColor" strokeWidth={1.5} fill="none" fillRule="evenodd">
      <path
        strokeLinecap="round"
        strokeLinejoin="round"
        d="M6.25 6.25A1.75 1.75 0 1 1 8.583 7.9.874.874 0 0 0 8 8.725v.587m0 1.751a.22.22 0 0 0-.219.218.22.22 0 1 0 .219-.219"
      />
      <path d="M8 14.563A6.564 6.564 0 0 0 8 1.437a6.563 6.563 0 0 0 0 13.126z" />
    </g>
  </svg>
)

export default SvgIconCircleQuestionmark
