import styled from 'styled-components'
import DropdownButton from 'react-bootstrap/DropdownButton'

import { IThemeProps } from '../../../../models/interfaces'
import { Action, FontPreset, TruncatePreset } from '../../styles/mixins'

export const StyledDropdownButton = styled(DropdownButton)`
  & .dropdown-toggle {
    padding-left: 0;
    border: none;

    ${FontPreset('paragraph')};

    ${(props: IThemeProps) =>
      Action({
        backgroundColor:
          props.theme?.colors?.background?.primary ||
          'var(--background-color-default',

        focusAction: {
          color:
            props.theme?.colors?.font?.primary || 'var(--font-color-default',
          backgroundColor:
            props.theme?.colors?.background?.primary ||
            'var(--background-color-default',
        },
      })};

    ${TruncatePreset('100%')};
  }
`
