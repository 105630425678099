import setCampaignDetails from './setCampaignDetails'
import setCampaignSettings from './setCampaignSettings'
import setCampaignStatus from './setCampaignStatus'
import addCampaignProduct from './addCampaignProduct'
import addCampaignProducts from './addCampaignProducts'
import setCampaignProducts from './setCampaignProducts'
import removeCampaignProduct from './removeCampaignProduct'

import addCampaignAdGroup from './addCampaignAdGroup'
import addAdGroupProduct from './addAdGroupProduct'
import addAdGroupPosition from './addAdGroupPosition'
import addAdGroupProducts from './addAdGroupProducts'
import addAdGroupAudience from './addAdGroupAudience'
import setAdGroupDayTimeTarget from './set-ad-group-day-time-target.reducer'
import resetAdGroupDayTimeTarget from './reset-ad-group-day-time-target.reducer'
import setCampaignAdGroup from './setCampaignAdGroup'
import setAdGroupProducts from './setAdGroupProducts'
import removeAdGroupProduct from './removeAdGroupProduct'
import removeAdGroupPosition from './removeAdGroupPosition'
import removeCampaignAdGroup from './removeCampaignAdGroup'
import removeCampaignAdGroupCreative from './removeCampaignAdGroupCreative'
import removeCampaignAdGroupCreatives from './removeCampaignAdGroupCreatives'

import insertAdGroupCategoryTargetList from './insertAdGroupCategoryTargetList'
import setAdGroupCategoryBidPrice from './setAdGroupCategoryBidPrice'
import setAdGroupPositionBidPrice from './setAdGroupPositionBidPrice'
import setAdGroupKeyword from './setAdGroupKeyword'
import setAdGroupKeywordBidPrice from './setAdGroupKeywordBidPrice'
import setAdGroupKeywordMatchType from './setAdGroupKeywordMatchType'
import setAdGroupKeywordTargetList from './setAdGroupKeywordTargetingList'
import removeAdGroupCategoryTarget from './removeAdGroupCategoryTarget'
import removeAdGroupKeywordTarget from './removeAdGroupKeywordTarget'
import setCampaignAdGroupCreative from './setCampaignAdGroupCreative'

export default {
  // campaign
  setCampaignDetails,
  setCampaignSettings,
  setCampaignStatus,
  addCampaignProduct,
  addCampaignProducts,
  setCampaignProducts,
  removeCampaignProduct,

  // ad groups
  addCampaignAdGroup,
  addAdGroupProduct,
  addAdGroupPosition,
  addAdGroupProducts,
  addAdGroupAudience,
  setAdGroupDayTimeTarget,
  resetAdGroupDayTimeTarget,
  setAdGroupProducts,
  setCampaignAdGroup,
  removeAdGroupProduct,
  removeAdGroupPosition,
  removeCampaignAdGroup,
  setCampaignAdGroupCreative,
  removeCampaignAdGroupCreative,
  removeCampaignAdGroupCreatives,

  // ad group targeting
  insertAdGroupCategoryTargetList,
  setAdGroupCategoryBidPrice,
  setAdGroupPositionBidPrice,
  setAdGroupKeyword,
  setAdGroupKeywordBidPrice,
  setAdGroupKeywordMatchType,
  setAdGroupKeywordTargetList,
  removeAdGroupCategoryTarget,
  removeAdGroupKeywordTarget,
}
