import { WritableDraft } from 'immer/dist/internal'
import { ICampaign } from '../../../models/interfaces/ICampaignState'

export function getSelectedAdGroup(
  state: WritableDraft<ICampaign>,
  id: number
) {
  const adGroupIndex = state.adGroupList.findIndex(
    (adGroup) => adGroup.id === id
  )
  if (adGroupIndex > -1) {
    const selectedAdGroup = state.adGroupList[adGroupIndex]
    return selectedAdGroup
  }
  return undefined
}
