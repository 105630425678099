import { ECurrency } from '@unfoldrtech/portal-mic'
import { useState, useEffect, useContext } from 'react'
import { AppContext, RetailerContext } from '../models'

const getCurrencyLocale = (currency?: ECurrency) => {
  switch (currency) {
    case ECurrency.Eur:
      return {
        symbol: '€',
        text: 'EUR',
      }
    case ECurrency.Usd:
      return {
        symbol: '$',
        text: 'USD',
      }
    case ECurrency.Gbp:
      return {
        symbol: '£',
        text: 'GBP',
      }
    case ECurrency.Aud:
      return {
        symbol: '$',
        text: 'AUD',
      }
    case ECurrency.Cad:
      return {
        symbol: '$',
        text: 'CAD',
      }
    case ECurrency.Cny:
      return {
        symbol: '¥',
        text: 'CNY',
      }
    case ECurrency.Inr:
      return {
        symbol: '₹',
        text: 'INR',
      }
    case ECurrency.Dkk:
      return {
        symbol: 'kr.',
        text: 'DKK',
      }
    case ECurrency.Nok:
      return {
        symbol: 'kr',
        text: 'NOK',
      }
    case ECurrency.Sek:
      return {
        symbol: 'Kr.',
        text: 'SEK',
      }
    default:
      return {
        symbol: '€',
        text: 'EUR',
      }
  }
}

export default function useGetCurrentCurrency() {
  const retailerContext = useContext(RetailerContext)
  const [appContext] = useContext(AppContext)

  const [currency, setCurrency] = useState(() =>
    getCurrencyLocale(appContext.userOrg?.currency || retailerContext.currency)
  )

  useEffect(() => {
    if (appContext.userOrg?.currency || retailerContext.currency) {
      const newCurrency = getCurrencyLocale(
        appContext.userOrg?.currency || retailerContext.currency
      )
      setCurrency(newCurrency)
    }
  }, [retailerContext.currency, appContext.userOrg?.currency])

  return currency
}
