import React from 'react'
import Spinner from 'react-bootstrap/Spinner'
import Button, { ButtonProps } from 'react-bootstrap/Button'
import styled, { CSSProperties } from 'styled-components'
import { IThemeProps } from '../../../models/interfaces'
import {
  FontPreset,
  TSizeMixin,
  Action,
  TruncatePreset,
  FontStyle,
  TFontStyleMixin,
} from '../styles/mixins'

export interface IButtonProps
  extends TSizeMixin,
    TFontStyleMixin,
    Omit<ButtonProps, 'color'>,
    Pick<CSSProperties, 'display' | 'opacity' | 'backgroundColor'> {
  isLoading?: boolean
  form?: string
}

export const BootstrapButton = styled(Button).withConfig({
  shouldForwardProp: (prop) =>
    ![
      'justifyContent',
      'maxWidth',
      'minWidth',
      'alignItems',
      'flexDirection',
      'borderRight',
      'borderLeft',
      'borderTop',
      'borderBottom',
    ].includes(prop as string),
})<IButtonProps>`
  ${FontPreset('button')}

  ${(props: TFontStyleMixin) =>
    FontStyle({
      ...props,
    })}
    
  ${(props: IThemeProps & IButtonProps) =>
    Action({
      cursor: 'pointer',
      justifyContent: 'center',
      verticalAlign: 'middle',
      display: props.display || 'block',
      backgroundColor:
        props.backgroundColor ||
        props.theme?.colors?.button?.primary ||
        'var(--button-color-default)',

      maxWidth: props.maxWidth || '',
      minWidth: props.minWidth || '',
      width: props.width || 'fit-content',
      height: props.height || '',

      border: {
        width: '0',
        radius: '0',
      },

      hoverAction: {
        opacity: '0.5',

        color: props.theme?.colors?.font?.action || 'var(--font-color-action)',

        backgroundColor:
          props.theme?.colors?.button?.primary || 'var(--button-color-default)',

        border: {
          width: '0',
          radius: '0',
        },
      },

      disableAction: {
        backgroundColor:
          props.theme?.colors?.button?.disabled ||
          'var(--button-color-disabled)',
      },

      focusAction: {
        backgroundColor:
          props.theme?.colors?.button?.primary || 'var(--button-color-default)',
      },
    })};
  ${TruncatePreset('100%')};

  &&.disabled-btn[disabled] {
    ${(props) => {
      if (props.disabled) {
        return `
      background-color: #d0d0d0;
      opacity: unset;
      `
      }
    }}

    & span {
      -webkit-text-fill-color: #fff;
    }
  }
`

/**
 *
 * Default button component
 * This button component is used as base for all other types of button components
 *
 */
export const StyledButton = ({
  isLoading,
  children,
  disabled,
  ...props
}: IButtonProps) => (
  <BootstrapButton {...props} disabled={disabled || isLoading}>
    {isLoading ? (
      <>
        <Spinner
          as="span"
          animation="border"
          size="sm"
          role="status"
          aria-hidden="true"
        />
      </>
    ) : (
      <>{children}</>
    )}
  </BootstrapButton>
)
