import React from 'react'
import styled from 'styled-components'
import { Modal } from 'react-bootstrap'
import { Container } from '../../../../components/Global'

const VideoModal = styled(Modal)`
  margin: 0;
  .modal-content {
    width: auto;
    margin: auto;
  }

  video {
    max-height: 600px;
  }
`

export const VideoContainer = styled(Container)`
  button {
    position: relative;
    border: none;
    outline: none;
    display: flex;
    background: transparent;
    margin: 0;
    padding: 0;
    align-items: center;
    justify-content: center;
  }

  background: red;
`

interface VideoPlayerProps {
  url: string
  show: boolean
  onClose: () => void
}

export const VideoPlayerModal = ({ url, show, onClose }: VideoPlayerProps) => {
  return (
    <VideoContainer>
      <VideoModal
        autoPlay={true}
        muted={true}
        centered={true}
        show={show}
        onHide={onClose}
      >
        <video
          data-testid="video-player"
          autoPlay={true}
          muted={true}
          controls={true}
          width="100%"
        >
          <source src={url} type="video/mp4" />
        </video>
      </VideoModal>
    </VideoContainer>
  )
}
