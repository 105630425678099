import React, { useEffect, useState } from 'react'
import styled from 'styled-components'
import { Dayjs } from 'dayjs'
import dayjsGenerateConfig from 'rc-picker/lib/generate/dayjs'
import generatePicker from 'antd/es/date-picker/generatePicker'

import 'antd/es/date-picker/style/index'
import 'antd/dist/antd.css'

import { PickerPanelSharedProps } from 'rc-picker/lib/PickerPanel'
import { PickerSharedProps } from 'rc-picker/lib/Picker'
import { FieldError } from 'react-hook-form'
import {
  Action,
  Alignment,
  Border,
  Display,
  FontPreset,
  FontStyle,
  Size,
  TActionMixin,
  TAlignmentMixin,
  TBorderMixin,
  TContainerProps,
  TDisplayMixin,
  TFontStyleMixin,
  Truncate,
  TruncatePreset,
  TSizeMixin,
  TTruncateMixin,
} from '../../styles/mixins'
import { IThemeProps } from '../../../../models/interfaces'
import { Container } from '../../containers'
import { Text } from '../../fonts'

const DatePicker = generatePicker<Dayjs>(dayjsGenerateConfig)

const DatePickerContainer = styled(Container)`
  position: relative;
  label {
    position: absolute;
    transform-origin: top left;
    top: 0;
    left: 0;
    transform: translate(23px, 20px) scale(1);
    transition: color 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      transform 200ms cubic-bezier(0, 0, 0.2, 1) 0ms,
      max-width 200ms cubic-bezier(0, 0, 0.2, 1) 0ms;

    &.float {
      transform: translate(14px, -9px) scale(0.75);
    }
  }

  input {
    border: none;
  }

  fieldset {
    text-align: left;
    position: absolute;
    inset: -5px 0px 0px;
    margin: 0px;
    padding: 0px 8px;
    pointer-events: none;
    border-radius: inherit;
    border-style: solid;
    border-width: 1px;
    overflow: hidden;
    min-width: 0%;
    border-color: var(--border-color-default);

    legend {
      float: unset;
      width: auto;
      overflow: hidden;
      display: block;
      padding: 0;
      height: 11px;
      font-size: var(--font-size-xs);
      visibility: hidden;
      max-width: 0.01px;

      span {
        padding: 0 var(--gutter-mini);
        display: inline-block;
        opacity: 0;
        visibility: visible;
      }
    }
    &.float {
      legend {
        width: auto;
        max-width: 100%;
        span {
          opacity: 0;
        }
      }
    }
  }
`

const StyledDatePickerContainer = styled(DatePicker)<TContainerProps>`
  && {
    background: ${(props) => props.background || ''};
    padding: 0 var(--gutter-3x);
    margin: ${(props) => props.margin || ''};
    ${(props: TDisplayMixin) => Display({ ...props })};
    ${FontPreset('paragraph medium')};
    ${(props: TFontStyleMixin) => FontStyle({ ...props })};
    border: none !important;
    outline: none !important;
    box-shadow: none;
    background: transparent;
    inline-size: 100%;
    display: flex;
    height: 100%;

    ${(props: TBorderMixin) =>
      Border({
        ...props,
      })};

    ${(props: TSizeMixin) =>
      Size({
        height: 'var(--button-size-lg)',
        ...props,
      })};

    ${(props: TAlignmentMixin) =>
      Alignment({
        ...props,
      })};

    ${(props: TActionMixin) => Action({ ...props })}

    ${(props: TTruncateMixin) => Truncate({ ...props })}

    & ::placeholder {
      opacity: 0.5;
      color: ${(props: IThemeProps) =>
        props.theme?.colors?.font?.primary || 'var(--font-color-default)'};
      ${TruncatePreset('100%')};
    }

    .ant-picker-input {
      display: flex;
      flex: 1;
      align-items: stretch;
      > input {
        color: var(--font-color-default);
        display: inline-block;
        :disabled {
          opacity: 0.4;
        }
      }
    }

    &.ant-picker {
      height: 100%;
      min-height: 100%;
      display: flex;
      align-items: stretch;
    }
  }
`

interface StyledDatePicker2Props
  extends Omit<
      PickerPanelSharedProps<Dayjs>,
      'onChange' | 'locale' | 'generateConfig'
    >,
    PickerSharedProps<Dayjs>,
    TContainerProps {
  label?: string
  error?: FieldError
  testId?: string
}

// todo: Rename to StyledDatePicker and replace current date picker
export const StyledDatePicker2 = ({
  label,
  value,
  error,
  disabledDate,
  onChange,
  testId,
  name,
  disabled,
  getPopupContainer,
}: StyledDatePicker2Props) => {
  const [isActive, setIsActive] = useState(false)

  useEffect(() => {
    setIsActive(!!value)
  }, [value])

  return (
    <Container>
      <DatePickerContainer
        display="flex"
        height="64px"
        className={error ? 'input-error' : ''}
        position="relative"
      >
        {label && (
          <label htmlFor={name} className={isActive ? 'float' : ''}>
            {label}
          </label>
        )}
        <StyledDatePickerContainer
          getPopupContainer={getPopupContainer}
          onChange={onChange}
          onSelect={() => setIsActive(true)}
          onFocus={() => setIsActive(true)}
          onBlur={() => !value && setIsActive(false)}
          disabledDate={disabledDate}
          placeholder=""
          value={value}
          name=""
          border={{
            borderColor: '--border-color-error',
            borderWidth: 0,
          }}
          data-testid={testId}
          disabled={disabled}
        />
        <fieldset
          className={isActive && label ? 'float' : ''}
          aria-hidden="true"
        >
          <legend>
            <span>{label}</span>
          </legend>
        </fieldset>
      </DatePickerContainer>
      {error && <Text preset="error">{error.message}</Text>}
    </Container>
  )
}
