import React, { ReactElement, ReactNode } from 'react'
import Form from 'react-bootstrap/Form'
import InputGroup from 'react-bootstrap/InputGroup'
import { FieldError } from 'react-hook-form'
import { CSSProperties } from 'styled-components'
import { FloatingLabelText } from '../fonts'
import { Container } from '../containers'

export interface IFloatingInputWrapperProps
  extends Pick<CSSProperties, 'paddingLeft' | 'paddingRight'> {
  children: ReactNode | ReactNode[]
  placeholder?: string
  error?: FieldError | undefined
}

export const FloatingInputWrapper = ({
  children,
  placeholder,
  error,
  ...props
}: IFloatingInputWrapperProps): ReactElement => {
  return (
    <Form.Group>
      <InputGroup>
        <Container flex="1" maxWidth="100%">
          <FloatingLabelText
            {...props}
            className={error ? 'input-error' : ''}
            label={error?.message ? error.message : placeholder}
          >
            {children}
          </FloatingLabelText>
        </Container>
      </InputGroup>
    </Form.Group>
  )
}
