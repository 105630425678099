import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconVerticalOptions = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 3 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <circle cx={1.5} cy={17.5} r={1.5} fill="currentColor" />
    <circle cx={1.5} cy={9.5} r={1.5} fill="currentColor" />
    <circle cx={1.5} cy={1.5} r={1.5} fill="currentColor" />
  </svg>
)

export default SvgIconVerticalOptions
