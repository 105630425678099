import { PayloadAction } from '@reduxjs/toolkit'
import { ICampaign, EMatchType } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedAdGroup } from '../utils'

const removeAdGroupKeywordTarget = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    keywordId: number
    adGroupId: number
    matchType: EMatchType
  }>
) => {
  const selectedAdGroup = getSelectedAdGroup(state, action.payload.adGroupId)

  const keywordIndex = selectedAdGroup?.keywordsList?.findIndex(
    (k) =>
      k.id === action.payload.keywordId &&
      k.matchType === action.payload.matchType
  )

  if (keywordIndex !== undefined && keywordIndex > -1) {
    selectedAdGroup?.keywordsList?.splice(keywordIndex, 1)
  }
}

export default removeAdGroupKeywordTarget
