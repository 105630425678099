import {
  EAdvertiserReportPageType,
  ERetailerReportPageType,
} from '@unfoldrtech/portal-mic'
import React from 'react'
import { useIntl } from 'react-intl'
import { IReportPageTemplateProps } from '../../models/interfaces/IReportPageTemplateProps'
import { StickyContainer, Container, Text } from '../Global'
import I18NText from '../I18NText'
import SuspenseErrorBoundary from '../SuspenseErrorBoundary'
import ChartWrapper from './ChartWrapper'
import Filters from './Filters'
import TableWrapper from './TableWrapper'

function ReportPageTemplate({
  title,
  filterProps,
  chartProps,
  tableProps,
  subTitle,
}: IReportPageTemplateProps) {
  const intl = useIntl()

  return (
    <>
      {/* Charts and other widgets */}
      <Container
        height="100%"
        overflow="auto"
        flex="1"
        padding="0 var(--gutter-4x)"
      >
        {/* Title and Filters */}
        <StickyContainer
          display="flex"
          alignItems="center"
          backgroundColor="var(--background-color-default)"
          padding="var(--padding-default) 0"
          paddingBottom={subTitle && 0}
          borderBottom={
            !subTitle
              ? {
                  width: '1px',
                  style: 'solid',
                  color: 'var(--border-color-default)',
                }
              : undefined
          }
        >
          <Container
            flex="1"
            maxWidth="100%"
            textOverflow="ellipsis"
            overflow="hidden"
            whiteSpace="nowrap"
          >
            <Text preset="h3" data-testid="report-title">
              {intl.formatMessage({
                id: title,
              })}
            </Text>
          </Container>
          <Filters {...filterProps} />
        </StickyContainer>
        {subTitle && (
          <StickyContainer
            paddingRight="var(--gutter-4x)"
            paddingTop="var(--gutter-2x)"
            paddingBottom="var(--padding-default)"
            borderBottom={{
              width: '1px',
              style: 'solid',
              color: 'var(--border-color-default)',
            }}
            top="var(--gutter-10x)"
            backgroundColor="var(--background-color-default)"
          >
            <I18NText
              id={subTitle}
              preset="paragraph opensans"
              textTransform="unset"
              opacity="0.65"
              fontSize="var(--font-size-sm)"
            />
          </StickyContainer>
        )}
        <SuspenseErrorBoundary>
          {!(
            chartProps.advertiserReportPageType ===
              EAdvertiserReportPageType.KeywordsInsights ||
            chartProps.retailerReportPageType ===
              ERetailerReportPageType.KeywordsInsights
          ) && (
            <Container>
              <ChartWrapper {...chartProps} {...filterProps} />
            </Container>
          )}
          <Container>
            <TableWrapper {...tableProps} {...filterProps} />
          </Container>
        </SuspenseErrorBoundary>
      </Container>
    </>
  )
}

export default ReportPageTemplate
