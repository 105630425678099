import {
  IAdData,
  IAdDataStylingAlignment,
  IAdDataStylingColors,
  ICallToActionActionEnum,
} from '@unfoldrtech/portal-mic'

export const emptyAdData: IAdData = {
  headline: '',
  subline: '',
  cta: { title: '', action: ICallToActionActionEnum.Click },
  destinationURL: '',
  styling: {
    packshotURL: '',
    alignment: {} as IAdDataStylingAlignment,
    colors: {} as IAdDataStylingColors,
  },
  advertiserName: '',
}
