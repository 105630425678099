import React from 'react'

import { IconStore } from '../../../../../../components/Global'

export enum LocationType {
  ALL_STORES = 'ALL_STORES',
  CITIES = 'CITIES',
  STORES = 'STORES',
}

export const getLocationTypeIcon = (type: LocationType) => {
  switch (type) {
    case LocationType.ALL_STORES:
      return (
        <>
          <IconStore width="16px" height="16px" />
          <IconStore width="16px" height="16px" />{' '}
          <IconStore width="16px" height="16px" />
        </>
      )
    case LocationType.CITIES:
      return (
        <>
          <IconStore width="16px" height="16px" />{' '}
          <IconStore width="16px" height="16px" />
        </>
      )
    default:
      return (
        <>
          <IconStore width="16px" height="16px" />
        </>
      )
  }
}
