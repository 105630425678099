import styled from 'styled-components'
import Form from 'react-bootstrap/Form'

import { Display, FontPreset, TDisplayMixin } from '../../styles/mixins'

export const StyledCheckbox = styled(Form.Check)<TDisplayMixin>`
  margin: auto;
  ${FontPreset('paragraph small opensans')}
  ${(props: TDisplayMixin) => Display({ ...props })};
`
