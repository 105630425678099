import Popover from 'react-bootstrap/Popover'
import styled, { CSSProperties } from 'styled-components'
import { TSizeMixin, Border } from '../../styles'

export const StyledPopover = styled(Popover)<
  TSizeMixin & Pick<CSSProperties, 'padding' | 'paddingLeft' | 'paddingRight'>
>`
  && {
    box-shadow: 0 4px 40px 0 var(--border-color-filter-box-shadow);
    padding: ${(props) => props.padding || 'var(--gutter-2x) var(--gutter-2x)'};
    padding-left: ${(props) => props.paddingLeft || 'var(--gutter-2x)'};
    padding-right: ${(props) => props.paddingRight || 'var(--gutter-2x)'};
    max-width: 400px;

    & .popover-arrow {
      display: none;
    }

    & .popover-body {
      padding: 0;
    }

    ${Border({
      border: {
        style: 'hidden',
      },
    })};
  }
`
