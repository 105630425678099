import setDealType from './setDealType'
import setAdGroupIds from './setAdGroupIds'
import setAggregationType from './setAggregationType'
import setCampaignIds from './setCampaignIds'
import setChannelType from './setChannelType'
import setEndDate from './setEndDate'
import setInventoryType from './setInventoryType'
import setSortBy from './setSortBy'
import setSortByV3 from './setSortByV3'
import setSortOrder from './setSortOrder'
import setStartDate from './setStartDate'
import setTimeProperties from './setTimeProperties'
import setTimeWindow from './setTimeWindow'

export default {
  setAggregationType,
  setAdGroupIds,
  setCampaignIds,
  setChannelType,
  setEndDate,
  setInventoryType,
  setSortBy,
  setSortByV3,
  setSortOrder,
  setStartDate,
  setTimeProperties,
  setTimeWindow,
  setDealType,
}
