import styled from 'styled-components'
import { IThemeProps } from '../../../../models/interfaces'
import {
  Action,
  Alignment,
  Border,
  Display,
  FontPreset,
  FontStyle,
  Size,
  TActionMixin,
  TAlignmentMixin,
  TBorderMixin,
  TContainerProps,
  TDisplayMixin,
  TFontPresetMixin,
  TFontStyleMixin,
  Truncate,
  TruncatePreset,
  TSizeMixin,
  TTruncateMixin,
} from '../../styles/mixins'
import { StyledDatePicker } from './StyledDatePicker'

const { RangePicker } = StyledDatePicker
export const StyledRangePicker = styled(RangePicker)<TContainerProps>`
  && {
    background: ${(props) => props.background || ''};
    padding: ${(props) => props.padding || 'var(--padding-default)'};
    margin: ${(props) => props.margin || ''};
    ${(props: TDisplayMixin) => Display({ ...props })};
    ${(props: TFontPresetMixin) => FontPreset(props.preset)};
    ${(props: TFontStyleMixin) => FontStyle({ ...props })};

    ${(props: TBorderMixin) =>
      Border({
        ...props,
      })};

    ${(props: TSizeMixin) =>
      Size({
        height: 'var(--button-size-lg)',
        ...props,
      })};

    ${(props: TAlignmentMixin) =>
      Alignment({
        ...props,
      })};

    ${(props: TActionMixin) =>
      Action({
        ...props,
        hoverAction: {
          cursor: 'pointer',
        },
      })}

    & .ant-picker > span, svg, input {
      ${() =>
        Action({
          hoverAction: {
            cursor: 'pointer',
          },
          disableAction: {
            cursor: 'not-allowed',
          },
        })}
    }

    ${(props: TTruncateMixin) => Truncate({ ...props })}

    & ::placeholder {
      opacity: 0.5;
      text-transform: capitalize;
      color: ${(props: IThemeProps) =>
        props.theme?.colors?.font?.primary || 'var(--font-color-default)'};
      ${TruncatePreset('100%')};
    }

    &&.ant-picker.ant-picker-disabled {
      ${(props: IThemeProps) =>
        Action({
          cursor: 'not-allowed',

          border: {
            color:
              props.theme.colors?.border?.primary ||
              'var(--border-color-default)',
          },

          backgroundColor:
            props.theme?.colors?.background?.disabled ||
            'var(--background-color-disabled)',
        })}
    }

    & .ant-picker-input > input[disabled] {
      ${(props: IThemeProps) =>
        Action({
          color:
            props.theme.colors?.font?.primary || 'var(--font-color-default)',
          opacity: 0.5,
        })}
    }
  }
`
