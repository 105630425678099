import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconToastWarning = (props: SVGProps<SVGSVGElement>) => (
  <svg
    width="1em"
    height="1em"
    viewBox="0 0 21 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.415 10.545c-1.505 0-2.725-1.266-2.725-2.826 0-1.561 1.22-2.826 2.725-2.826v5.652zm2.958-.002c3.198 0 6.324.982 8.985 2.822l.824.57V1.5l-.824.57a15.793 15.793 0 0 1-8.985 2.82v5.652z"
      fill="#fff"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M20.122 6.588v2.26M7.373 10.543A5.56 5.56 0 0 0 9.008 14.5"
      stroke="#fff"
      strokeWidth={1.5}
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgIconToastWarning
