import { PayloadAction } from '@reduxjs/toolkit'
import { ICampaign, IProduct } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'

/**
 * @deprecated
 */
const addCampaignProducts = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<Array<IProduct>>
) => {
  const allProductList = state.productList.concat(...action.payload)
  const productIdList = Array.from(new Set(allProductList.map((p) => p.id)))
  state.productList = productIdList.map(
    (pid) => allProductList.find((p) => p.id === pid) || ({} as IProduct)
  )
}

export default addCampaignProducts
