import React from 'react'
import { useTable, useBlockLayout, Column } from 'react-table'
import { withSize, SizeMeProps } from 'react-sizeme'
import { FixedSizeList } from 'react-window'
import InfiniteLoader from 'react-window-infinite-loader'

import { TABLE_COLUMN_WIDTH, TABLE_HEIGHT, TABLE_ROW_HEIGHT } from '../table'
import { Container } from '../containers'
import { VirtualTableStyles } from './VirtualTableStyles'
import I18NText from '../../I18NText'

type PaginationProps = {
  itemCount: number
  threshold?: number
  isItemLoaded: (index: number) => boolean
  loadNextPage: () => Promise<void>
  isFetching: boolean
}

const ReactVirtualTable = <T extends object>({
  columns,
  data,
  size,
  isReport,
  paginationProps = {
    itemCount: 0,
    isItemLoaded: () => false,
    loadNextPage: () => Promise.resolve(),
    isFetching: false,
  },
}: {
  columns: Array<Column<T>>
  data: Array<T>
  isReport?: boolean
  paginationProps?: PaginationProps
} & SizeMeProps) => {
  const {
    itemCount,
    isFetching = false,
    isItemLoaded,
    loadNextPage,
    threshold = 100, // How many items to scroll through before loading more, It should match the limit of the query
  } = paginationProps

  const defaultColumnSize = React.useMemo(
    () => ({
      width: size.width
        ? Math.floor(size.width / columns.length)
        : TABLE_COLUMN_WIDTH,
    }),
    []
  )

  const listHeight = size.height ? size.height - TABLE_ROW_HEIGHT : TABLE_HEIGHT

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    rows,
    totalColumnsWidth,
    prepareRow,
  } = useTable(
    {
      columns,
      data,
      defaultColumn: isReport ? undefined : defaultColumnSize,
    },
    useBlockLayout
  )

  const RenderRow = React.useCallback(
    ({ index, style }) => {
      const row = rows[index]
      prepareRow(row)

      return (
        <>
          <Container
            {...row.getRowProps({
              style,
            })}
            className="tr"
            width="100%"
          >
            {row.cells.map((cell, cellIndex) => {
              return (
                <Container
                  {...cell.getCellProps()}
                  className="td"
                  padding={isReport ? '0 var(--gutter-2x)' : undefined}
                  width={cell.column?.width}
                  data-testid={`report-table-row-${index}-cell-${cellIndex}`}
                >
                  {cell.render('Cell')}
                </Container>
              )
            })}
          </Container>
        </>
      )
    },
    [prepareRow, rows]
  )

  // Render the UI for your table
  return (
    <VirtualTableStyles>
      <Container className="table-wrap">
        <Container {...getTableProps()} className="table">
          <Container>
            {headerGroups.map((headerGroup) => (
              <Container
                {...headerGroup.getHeaderGroupProps()}
                className="thead"
                width={totalColumnsWidth}
                paddingRight="var(--font-size-pa)"
              >
                {headerGroup.headers.map((column) => (
                  <Container
                    {...column.getHeaderProps()}
                    className="th"
                    padding={
                      isReport
                        ? '0 var(--gutter-2x) !important'
                        : '0 !important'
                    }
                    width={column?.width}
                  >
                    {column.render('Header')}
                  </Container>
                ))}
              </Container>
            ))}
          </Container>

          {rows.length ? (
            <Container {...getTableBodyProps()}>
              <InfiniteLoader
                isItemLoaded={isItemLoaded}
                itemCount={itemCount}
                loadMoreItems={loadNextPage}
                threshold={threshold}
              >
                {({ onItemsRendered, ref }) => (
                  <FixedSizeList
                    height={listHeight}
                    itemCount={rows.length}
                    itemSize={TABLE_ROW_HEIGHT}
                    width={totalColumnsWidth}
                    onItemsRendered={onItemsRendered}
                    ref={ref}
                  >
                    {RenderRow}
                  </FixedSizeList>
                )}
              </InfiniteLoader>
            </Container>
          ) : (
            <Container textAlign="center" padding="var(--gutter-2x)">
              <I18NText id="no.data" />
            </Container>
          )}
        </Container>
      </Container>
      {isFetching && (
        <Container
          width="100%"
          textAlign="center"
          sticky={{
            position: 'absolute',
            bottom: '0px',
            top: 'unset',
          }}
          backgroundColor="var(--background-color-light)"
        >
          <I18NText id="loading" />
        </Container>
      )}
    </VirtualTableStyles>
  )
}

export const StyledVirtualTableInfinite = withSize({
  monitorHeight: true,
})(ReactVirtualTable)
