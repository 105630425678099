import React from 'react'
import { useNavigate, useLocation } from 'react-router-dom'
import I18NText from '../components/I18NText'
import { PageLinkButton } from '../components/Global/buttons'
import { CenterContainer, Container } from '../components/Global'

function NotFound() {
  const location = useLocation()
  const navigate = useNavigate()

  return (
    <CenterContainer data-testid="not-found">
      <Container>
        <code> {location.pathname} </code>
        <I18NText id="notFound" />
      </Container>
      <PageLinkButton onClick={() => navigate(-1)} data-testid="back-button">
        <I18NText id="back" />
      </PageLinkButton>
    </CenterContainer>
  )
}

export default NotFound
