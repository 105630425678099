import * as React from 'react'
import { SVGProps } from 'react'

const IconImage = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 24 24"
    {...props}
  >
    <g
      stroke="currentColor"
      strokeWidth={1.5}
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    >
      <path d="M.75 2.25a1.5 1.5 0 0 1 1.5-1.5h19.5a1.5 1.5 0 0 1 1.5 1.5v19.5a1.5 1.5 0 0 1-1.5 1.5H2.25a1.5 1.5 0 0 1-1.5-1.5V2.25z" />
      <path d="m5.25 17.25 3.462-4.616a1.499 1.499 0 0 1 2.261-.161L12 13.5l3.3-4.4a1.499 1.499 0 0 1 2.4 0l2.67 3.56" />
      <path d="M6.375 8.25a1.875 1.875 0 1 0 0-3.75 1.875 1.875 0 0 0 0 3.75z" />
    </g>
  </svg>
)

export default IconImage
