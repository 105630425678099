import { PayloadAction } from '@reduxjs/toolkit'
import {
  EDOOHReportLocationsColumnHeader,
  TDOOHLocationsReportTableFilters,
} from '../../../models'

const setSortByV3 = (
  state: TDOOHLocationsReportTableFilters,
  action: PayloadAction<{
    sortBy?: EDOOHReportLocationsColumnHeader
  }>
) => {
  state.sortBy = action.payload.sortBy
}

export default setSortByV3
