import { useQuery } from 'react-query'
import { AxiosError, AxiosResponse } from 'axios'
import {
  EAdvertiserReportPageType,
  IError,
  IReportingChartResponse,
  ITotalItems,
} from '@unfoldrtech/portal-mic'
import { coreAPI } from '../services/coreAPI'
import {
  generateCorrelationId,
  getTZOffset,
  getTimezone,
} from '../utils/helpers'
import {
  TPPAdvertiserId,
  TPPRetailerId,
  TReportingFilters,
} from '../models/types'
import { IReportPageTypes } from '../models/interfaces'

export default function useGetAdvertiserReportsChart({
  advertiserId,
  retailerId,
  advertiserReportPageType:
    reportPageType = EAdvertiserReportPageType.Campaigns,
  campaignIds,
  adGroupIds,
  categoryIds,
  keywordIds,
  pageIds,
  positionIds,
  productIds,
  channelType,
  inventoryType,
  sortBy,
  sortOrder,
  pageLimit,
  pageOffset,
  timeWindow,
  endDate,
  startDate,
  tzOffset,
  tzZone,
  aggregationType,
  enabled = true,
  creativeIds,
}: {
  enabled?: boolean
} & TReportingFilters &
  TPPRetailerId &
  TPPAdvertiserId &
  IReportPageTypes) {
  const queryKeyName = `getAdvertiserReportCharts_${advertiserId}_${retailerId}_${reportPageType}_${
    channelType || ''
  }_${inventoryType || ''}`

  return useQuery<
    AxiosResponse<ITotalItems & IReportingChartResponse>,
    AxiosError<IError>
  >(
    [queryKeyName],
    () => {
      return coreAPI
        .advertiserReporting()
        .getAdvertiserReportCharts(
          advertiserId,
          reportPageType,
          generateCorrelationId(),
          retailerId,
          campaignIds,
          adGroupIds,
          categoryIds,
          creativeIds,
          keywordIds,
          pageIds,
          positionIds,
          productIds,
          undefined,
          channelType,
          sortBy,
          pageLimit,
          pageOffset,
          timeWindow,
          startDate,
          endDate,
          sortOrder,
          inventoryType,
          tzOffset || getTZOffset(endDate),
          tzZone || getTimezone(),
          aggregationType
        )
    },
    { suspense: true, enabled }
  )
}
