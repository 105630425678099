export const PORTAL_FF_DOOH_CAMPAIGN_CREATE = 'portal-ff-dooh-campaign-create'
export const PORTAL_FF_DOOH_CAMPAIGN_VIEW = 'portal-ff-dooh-campaign-view'
export const PORTAL_FF_DOOH_CAMPAIGN_EDIT = 'portal-ff-dooh-campaign-edit'
export const PORTAL_FF_DOOH_PERMIT_CREATE = 'portal-ff-dooh-permit-create'
export const PORTAL_FF_DOOH_PERMIT_EDIT = 'portal-ff-dooh-permit-edit'
export const PORTAL_FF_DOOH_PERMIT_VIEW = 'portal-ff-dooh-permit-view'
export const PORTAL_FF_DOOH_CREATIVES_VIEW = 'portal-ff-dooh-creatives-view'
export const PORTAL_FF_DOOH_CREATIVES_EDIT = 'portal-ff-dooh-creatives-edit'
export const PORTAL_FF_DOOH_CREATIVES_REVIEW_VIEW =
  'portal-ff-dooh-creatives-review-view'
export const PORTAL_FF_DOOH_CREATIVES_REVIEW_EDIT =
  'portal-ff-dooh-creatives-review-edit'
export const PORTAL_FF_DOOH_INVENTORY_CREATE = 'portal-ff-dooh-inventory-create'
export const PORTAL_FF_DOOH_INVENTORY_EDIT = 'portal-ff-dooh-inventory-edit'
export const PORTAL_FF_DOOH_INVENTORY_VIEW = 'portal-ff-dooh-inventory-view'
export const PORTAL_FF_DOOH_REPORTING_VIEW = 'portal-ff-dooh-reporting-view'
export const PORTAL_FF_DOOH_REPORTING_EDIT = 'portal-ff-dooh-reporting-edit'
export const PORTAL_FF_DOOH_FAQ_VIEW = 'portal-ff-dooh-faq-view'
export const PORTAL_FF_DOOH_DAY_TIME_TARGETING_VIEW =
  'portal-ff-dooh-day-time-targeting-view'
export const PORTAL_FF_DOOH_DAY_TIME_TARGETING_EDIT =
  'portal-ff-dooh-day-time-targeting-edit'
export const PORTAL_FF_DOOH_AUDIENCES_TARGETING_CREATE =
  'portal-ff-dooh-audiences-targeting-create'
export const PORTAL_FF_DOOH_AUDIENCES_TARGETING_EDIT =
  'portal-ff-dooh-audiences-targeting-edit'
