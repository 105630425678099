import styled from 'styled-components'
import { Container } from './Container'
import {
  Alignment,
  Border,
  Size,
  TAlignmentMixin,
  TBorderMixin,
  TContainerProps,
  TDisplayMixin,
  TMarginMixin,
  TPaddingMixin,
  TSizeMixin,
} from '../styles'
import { IThemeProps } from '../../../models/interfaces'

export const DottedBorderContainer = styled(Container)<TContainerProps>`
  padding: ${(props: TPaddingMixin) =>
    props.padding || 'var(--gutter-6x) var(--gutter-3x)'};
  display: ${(props: TDisplayMixin) => props.display || 'flex'};
  margin: ${(props: TMarginMixin) =>
    props.margin || 'var(--margin-default) 0 0 0'};
  background: ${(props: IThemeProps & TContainerProps) =>
    props.backgroundColor ||
    `${props.theme.colors?.background?.secondary}08` ||
    'var(--background-color-secondary)'};

  ${(props: TBorderMixin) =>
    Border({
      border: {
        width: '1px',
        style: 'dashed',
        color: 'var(--border-color-dashed);',
      },
      ...props,
    })}
  ${(props: TSizeMixin) =>
    Size({
      height: props.height || '240px',
      width: props.width || '100%',
      ...props,
    })};
  ${(props: TAlignmentMixin) =>
    Alignment({
      alignItems: props.alignItems || 'center',
      justifyContent: props.justifyContent || 'center',
      ...props,
    })};
`
