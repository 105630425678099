import * as React from 'react'
import { SVGProps } from 'react'

const SvgIconFilter = (props: SVGProps<SVGSVGElement>) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="1em"
    height="1em"
    viewBox="0 0 25 24"
    {...props}
  >
    <path
      stroke="currentColor"
      strokeWidth={1.5}
      d="M23.45 1.8a.757.757 0 0 0-.201-.562.756.756 0 0 0-.547-.238H1.75a.753.753 0 0 0-.748.8 11.252 11.252 0 0 0 8.972 10.22v9.875a.749.749 0 0 0 1.23.576l3-2.5a.752.752 0 0 0 .27-.576V12.02A11.254 11.254 0 0 0 23.45 1.8z"
      fill="none"
      fillRule="evenodd"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
)

export default SvgIconFilter
