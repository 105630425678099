import { PayloadAction } from '@reduxjs/toolkit'
import { ICampaign, IAdGroup, EChannelType } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { TPlacement } from '../../../models/types/TPlacement'
import { getSelectedAdGroup } from '../utils'

// TODO: Replace this for a better output in the BE when implementing PORT-1634
function mapDayAndTimeAttributes(adGroup: IAdGroup) {
  return {
    ...adGroup,
    criteria: adGroup.criteria || undefined,
    days: adGroup.days || undefined,
    times: adGroup.times || undefined,
  }
}

const setCampaignAdGroup = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<IAdGroup & { placement?: TPlacement }>
) => {
  const selectedAdGroup = getSelectedAdGroup(state, action.payload.id)

  if (selectedAdGroup) {
    Object.assign(selectedAdGroup, action.payload)
  } else {
    if (state.channelType !== EChannelType.SponsoredProduct) {
      state.adGroupList.push(mapDayAndTimeAttributes(action.payload))
      return
    }

    state.adGroupList.push(action.payload)
  }
}

export default setCampaignAdGroup
