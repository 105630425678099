import { PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import { ICampaign } from '@unfoldrtech/portal-mic'

export function getSelectedKeyword(
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    keywordId: number
    adGroupId: number
  }>
) {
  const adGroupIndex = state.adGroupList.findIndex(
    (adGroup) => adGroup.id === action.payload.adGroupId
  )
  const selectedAdGroup = state.adGroupList[adGroupIndex]

  let keywordIndex = selectedAdGroup.keywordsList?.findIndex(
    (k) => k.id === action.payload.keywordId
  )
  if (keywordIndex === undefined) {
    keywordIndex = -1
  }

  const selectedKeyword = selectedAdGroup.keywordsList
    ? selectedAdGroup.keywordsList[keywordIndex]
    : undefined
  return selectedKeyword
}
