import { PayloadAction } from '@reduxjs/toolkit'
import { WritableDraft } from 'immer/dist/internal'
import { EDealType } from '@unfoldrtech/portal-mic'
import { EDOOHChannelType, IDOOHCampaign } from '../../../models'

const setDoohCampaignDetails = (
  state: WritableDraft<IDOOHCampaign>,
  action: PayloadAction<{
    channelType: EDOOHChannelType
    title: string
    dealType: EDealType
  }>
) => {
  state.channelType = action.payload.channelType
  state.title = action.payload.title
  state.dealType = action.payload.dealType
}

export default setDoohCampaignDetails
