import { PayloadAction } from '@reduxjs/toolkit'
import { ICampaign } from '@unfoldrtech/portal-mic'
import { WritableDraft } from 'immer/dist/internal'
import { getSelectedKeyword } from '../utils'

const setAdGroupKeywordBidPrice = (
  state: WritableDraft<ICampaign>,
  action: PayloadAction<{
    bidPrice: number | null
    keywordId: number
    adGroupId: number
  }>
) => {
  const selectedKeyword = getSelectedKeyword(state, action)

  if (selectedKeyword) {
    selectedKeyword.bidPrice = action.payload.bidPrice
  }
}

export default setAdGroupKeywordBidPrice
