import React, { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { Container, PORTAL_DEFAULT_THEME } from '../components/Global'
import I18NText from '../components/I18NText'
import LogoTagline from '../components/LogoTagline'
import FooterCopyright from '../components/FooterCopyright'
import { usePatchUserProfile } from '../hooks/users'
import { EUserEmailPreferencesType } from '../models/core'
import { ErrorToast } from '../components/ToastCard/ErrorToast'
import useShowToast from '../hooks/useShowToast'
import { TTranslationKey } from '../models'

function Unsubscribe() {
  const navigate = useNavigate()
  const theme = PORTAL_DEFAULT_THEME
  const [seconds, setSeconds] = useState(20)

  const {
    mutate: patchUserProfile,
    isSuccess,
    error: errorPatchUserProfile,
    isError: isPatchUserProfileError,
  } = usePatchUserProfile()
  const [showPatchUserProfileErrorToast, setShowPatchUserProfileErrorToast] =
    useShowToast(isPatchUserProfileError)

  useEffect(() => {
    patchUserProfile({
      payload: {
        emailPreferences: [
          {
            type: EUserEmailPreferencesType.Marketing,
            hasConsented: false,
          },
        ],
      },
    })
  }, [])

  useEffect(() => {
    if (isSuccess) {
      if (seconds <= 0) {
        navigate('/organisations')
      } else {
        setTimeout(() => {
          setSeconds(seconds - 1)
        }, 1000)
      }
    }
  }, [isSuccess, seconds])

  return (
    <>
      <Container
        height="100vh"
        display="flex"
        flexDirection="column"
        overflow="auto"
        backgroundColor={
          theme.colors?.background?.dark || 'var(--background-color-dark)'
        }
      >
        <LogoTagline />
        <Container
          width="100%"
          flex="1"
          display="flex"
          justifyContent="center"
          paddingTop="var(--gutter-5x)"
        >
          <Container width="440px">
            <>
              <Container width="440px" padding="0px 50px" textAlign="center">
                <I18NText
                  id="unsubscribe.label"
                  preset="h3"
                  color={
                    theme.colors?.font?.secondary ||
                    'var(--font-color-secondary)'
                  }
                  data-testid="unsubscribe-title"
                />
              </Container>
              <Container paddingTop="var(--gutter-2x)" textAlign="left">
                {isSuccess ? (
                  <>
                    <Container
                      preset="paragraph small opensans"
                      color={
                        theme.colors?.background?.light ||
                        'var(--background-color-light)'
                      }
                    >
                      <I18NText
                        id="unsubscribe.label.success"
                        values={{
                          seconds: <>{seconds}</>,
                          here: (
                            <a href="/organisations">
                              <I18NText id="here" />
                            </a>
                          ),
                        }}
                        data-testid="unsubscribe-success"
                      />
                    </Container>
                  </>
                ) : (
                  <>
                    <I18NText
                      id="unsubscribe.label.loading"
                      preset="paragraph small opensans"
                      textTransform="none"
                      color={
                        theme.colors?.background?.light ||
                        'var(--background-color-light)'
                      }
                    />
                  </>
                )}
              </Container>
            </>
          </Container>
        </Container>
        <FooterCopyright />
      </Container>
      <ErrorToast
        show={showPatchUserProfileErrorToast}
        onClose={() => {
          setShowPatchUserProfileErrorToast(false)
          navigate('/organisations')
        }}
        translationKey={
          errorPatchUserProfile?.response?.data
            .errorKey as unknown as TTranslationKey
        }
      />
    </>
  )
}

export default Unsubscribe
