import styled from 'styled-components'
import { IThemeProps } from '../../../models/interfaces'
import { Container } from './Container'
import {
  TContainerProps,
  Action,
  TruncatePreset,
  Border,
} from '../styles/mixins'

/**
 * Ensure that the children have no borders
 */
export const FilterContainer = styled(Container)<TContainerProps>`
  line-height: 24px;
  box-shadow: 0 2px 3px 0 var(--border-color-filter-box-shadow);

  ${TruncatePreset('280px')};

  ${(props: IThemeProps & TContainerProps) =>
    Action({
      cursor: 'pointer',
      height: props.height || 'var(--button-size-lg)',

      backgroundColor:
        props.theme?.colors?.background?.primary ||
        'var(--background-color-default)',

      fontFamily: 'OpenSans-SemiBold',
      fontSize: 'var(--font-size-pa)',
      color: props.theme?.colors?.font?.primary || 'var(--font-color-default)',

      border: {
        radius: '2px',
        width: '1px',
        style: 'solid',
        color: 'var(--border-color-default)',
      },
    })};

  ${Border({
    borderBottom: {
      width: '3px',
      style: 'solid',
      color: 'var(--border-color-filter-bottom)',
    },
  })};

  &&.product-filter {
    ${TruncatePreset('360px')};
    width: 360px;
  }
`
