import { IPermittedCategoriesWithParent } from '@unfoldrtech/portal-mic'
import { ISelectCategoryOption } from '../../models'
import { sanitizeDataTestId } from '../sanitizeDataTestId'

export const getCategorySelectOptions = (
  categories: IPermittedCategoriesWithParent[]
): Array<ISelectCategoryOption> => {
  const permittedCategoriesList = categories.filter((cat) => cat.permitted)

  return permittedCategoriesList.map((cat) => {
    const parent = categories.find(
      (catItem) => cat.parentCategoryId === catItem.id
    )?.title
    const label = parent ? `${parent} / ${cat.title}` : cat.title

    return {
      id: cat.id,
      fullLabel: label,
      label: cat.title,
      dataTestId: sanitizeDataTestId(`option-${cat.title}`),
      value: cat.id.toString(),
      parentId: cat.parentCategoryId,
      parentLabel: parent,
    } as ISelectCategoryOption
  })
}
