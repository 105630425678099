import styled, { CSSProperties } from 'styled-components'
import { Container } from './Container'

export type TSVGElementProps = Pick<CSSProperties, 'strokeWidth' | 'transform'>
export const SVGContainer = styled(Container)<TSVGElementProps>`
  && svg {
    ${(props: TSVGElementProps) => `
        transform: ${props.transform || ''};
    `}

    path {
      ${(props: TSVGElementProps) => `
        stroke-width: ${props.strokeWidth || ''};
    `}
    }
  }
`
