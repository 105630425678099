import styled from 'styled-components'
import BreadcrumbItem from 'react-bootstrap/BreadcrumbItem'

import { IThemeProps } from '../../../../models/interfaces'
import { FontPreset, FontStyle, TruncatePreset } from '../../styles/mixins'

export const StyledBreadcrumbItem = styled(BreadcrumbItem)`
  ${FontPreset('nav title')}
  ${FontStyle({
    opacity: '0.5',
  })};

  ${TruncatePreset('340px')}

  && a {
    ${(props: IThemeProps) =>
      FontStyle({
        color:
          props.theme?.colors?.font?.secondary || 'var(--font-color-light)',
        textDecoration: 'none',
      })};
  }
  &&.active {
    ${(props: IThemeProps) =>
      FontStyle({
        color:
          props.theme?.colors?.font?.secondary || 'var(--font-color-light)',
        opacity: '1',
      })};
  }
  &&::before {
    color: inherit;
  }
`
