import { EChannelType } from '@unfoldrtech/portal-mic'
import React from 'react'
import I18NText from '../../I18NText'
import { TAllChannelTypes, EDOOHChannelType } from '../../../models'

export function CurrentChannelTypeText({
  channelType,
}: {
  channelType?: TAllChannelTypes
}) {
  if (channelType === EChannelType.DisplayAd) {
    return (
      <>
        <I18NText id="displayAd" />
      </>
    )
  }
  if (channelType === EChannelType.SponsoredProduct) {
    return (
      <>
        <I18NText id="sponsoredProduct" />
      </>
    )
  }

  if (channelType === EDOOHChannelType.Dooh) {
    return (
      <>
        <I18NText id="digital.out.of.home" />
      </>
    )
  }
  return <> </>
}
