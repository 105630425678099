import { PayloadAction } from '@reduxjs/toolkit'
import { TReportingFilters } from '@unfoldrtech/portal-mic/dist/src/models/types'

const setEndDate = (
  state: TReportingFilters,
  action: PayloadAction<{
    endDate: string
  }>
) => {
  state.endDate = action.payload.endDate
}

export default setEndDate
