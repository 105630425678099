import React from 'react'
import { TooltipWrapper, Text } from '../../../Global'
import I18NText from '../../../I18NText'

export const TableDataCellCampaigns = ({
  dataValue = [],
}: {
  dataValue: Array<string>
}) => {
  return (
    <TooltipWrapper
      showTooltip={true}
      maxWidth="none"
      whiteSpace="pre-line"
      textAlign="left"
      tooltip={dataValue.join('\n')}
    >
      <Text>
        {`${dataValue.length} `}
        <I18NText id={dataValue.length === 1 ? 'campaign' : 'campaigns'} />
      </Text>
    </TooltipWrapper>
  )
}
